import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Button } from "antd";

import "./style.css";

interface Props {
    value?: boolean;
    onChange?: (value: boolean) => void;
    label: React.ReactNode;
    hasError?: boolean;
}

export const CheckboxButton = React.forwardRef<HTMLButtonElement, Props>(function CheckboxButton(
    { value, label, hasError, onChange },
    ref
) {
    return (
        <Button
            ref={ref}
            type={value ? "primary" : "default"}
            block
            className={cn({ "CheckboxButton--error": hasError })}
            onClick={() => {
                onChange(!value);
            }}
            aria-checked={value}
            role="checkbox"
        >
            {label}
        </Button>
    );
});
