import React, { type FC, useCallback, useState } from "react";
import { Modal, Segmented } from "antd";
import { GQL } from "@binale-tech/shared";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { XRechnungDataPreview } from "@app/components/shared/XRechnung/XRechnungDataPreview";
import { PdfSingleDocumentViewer } from "@ui-components/PdfViewer/PdfSingleDocumentViewer";

type PreviewMode = "preview" | "invoice";
type IProps = {
    document?: GQL.IDocument;
    onClose: () => void;
};

export const DmsModalPdfViewer: FC<IProps> = ({ document, onClose }) => {
    const [previewMode, setPreviewMode] = useState<PreviewMode>("preview");

    const onChangePreviewMode = useCallback((v: PreviewMode) => {
        setPreviewMode(v);
    }, []);

    if (!document) {
        return null;
    }

    return (
        <Modal
            open={Boolean(document)}
            onCancel={onClose}
            destroyOnClose
            footer={null}
            style={{
                top: "5px",
                padding: "10px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            title={
                <Segmented<PreviewMode>
                    value={previewMode}
                    options={[
                        { label: "PDF", value: "preview" },
                        { label: "E-Rechnung", value: "invoice", disabled: !document.hasXRechnung },
                    ]}
                    onChange={onChangePreviewMode}
                />
            }
            styles={{
                body: {
                    minWidth: "70vw",
                    height: `calc(100vh - 100px)`,
                    backgroundColor: "white",
                    display: "flex",
                },
            }}
        >
            {document && previewMode === "preview" && (
                <PdfSingleDocumentViewer
                    url={document.fileUrl}
                    fileName={DmsUtils.getDownloadName(document)}
                    showThumbnails
                />
            )}
            {document && previewMode === "invoice" && (
                <div style={{ width: "100%" }}>
                    <XRechnungDataPreview fileId={document.key} productKey={GQL.IProductKey.Er} />
                </div>
            )}
        </Modal>
    );
};
