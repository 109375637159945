import { blue } from "@ant-design/colors";
import { CloseOutlined, CopyOutlined, ExportOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Flex, Tooltip } from "antd";
import React, { type FC, ReactNode, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";

import styles from "./documentData.module.scss";
import { RecordDataPopover } from "@dms/components/RecordDataTable/RecordDataPopover";
import { DmsFibuExporter } from "@dms/components/DmsFibuExporter/DmsFibuExporter";
import { GQL } from "@binale-tech/shared";

interface IProps {
    documentData?: GQL.IDocument;
    children: ReactNode;
}

export const DocumentData: FC<IProps> = ({ documentData, children }) => {
    const navigate = useNavigate();
    const { isGroupEditor } = useContext(FormWrapperContext);
    const [tooltipTitleId, setTooltipTitleId] = useState(<FormattedMessage id="app.dms.copyId" />);

    const resetTooltipId = (open: boolean): void => {
        if (!open) {
            setTooltipTitleId(<FormattedMessage id="app.dms.copyId" />);
        }
    };

    const copyId = async (): Promise<void> => {
        if (documentData && !isGroupEditor) {
            await navigator.clipboard.writeText(documentData.key);
            setTooltipTitleId(<FormattedMessage id="app.dms.idCopied" />);
        }
    };

    return (
        <>
            <Flex align="center" justify="flex-end" gap={10} className={styles.dataWrapper}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgContainer: blue[0],
                            colorBorder: blue[1],
                            // colorText: blue.primary,
                        },
                    }}
                >
                    <Tooltip
                        title={isGroupEditor ? null : tooltipTitleId}
                        onOpenChange={(open: boolean) => {
                            resetTooltipId(open);
                        }}
                    >
                        <Button
                            shape={"circle"}
                            disabled={isGroupEditor}
                            icon={<CopyOutlined />}
                            onClick={copyId}
                            type={"text"}
                        />
                    </Tooltip>

                    <Button
                        data-testid={"dms-form-btn-close"}
                        icon={<CloseOutlined />}
                        onClick={() => {
                            navigate("/documents");
                        }}
                    >
                        <span>
                            <FormattedMessage id="app.dms.closeEditor" />
                        </span>
                    </Button>
                </ConfigProvider>

                <DmsFibuExporter documents={[documentData]}>
                    <Button icon={<ExportOutlined />}>
                        FIBU <RecordDataPopover document={documentData} position={"form"} />
                    </Button>
                </DmsFibuExporter>
            </Flex>

            <div className={styles.formWrapper}>{children}</div>
        </>
    );
};
