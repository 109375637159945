import { GenericItem, GenericRecord } from "../GenericRecord";
import { Bu, Contacts, GQL, Periods, Utils } from "@binale-tech/shared";
import dayjs from "dayjs";
import RecordFormState from "../../../appearance/components/recordform/types/RecordFormState";
import RecordFormUtils from "../../../appearance/components/recordform/utils/RecordFormUtils";
import { CurrencyValue } from "@ui-components/AmountInput/BruttoInput";
import { ContactAccountingConverter } from "./ContactAccountingConverter";
import { Product } from "../../core/Product";
import { BuTimeframe } from "../../context/BuContext";
import { ProductAccessUtils } from "../utils/ProductAccessUtils";
import { IDocumentSuggestion } from "@dms/types";
import { GenericRecordUtils } from "../utils/GenericRecordUtils";
import { PDFDocument } from "pdf-lib";
import { extractAttachments } from "@pdf-tools/tools/extractAttachments";
import { getContactVatPrint } from "@app/views/productContacts/form/utils";
import QuickLRU from "quick-lru";
import { BinaleError } from "../../errors/errors";
import { CIIDecoderFactory } from "./CII/CIIDecoderFactory";

export type XRechnungResponse = {
    generated: {
        record: GenericRecord;
        document: IDocumentSuggestion;
    };
    rawXml: string;
    info: { versionText: string; isDeprecated: boolean };
    raw: {
        currency: string;
        paymentReference?: string;
        paymentDescription?: string;
        paymentDueDate?: string;
        paymentType?: string;
        deliveryDate?: string;
        supplier?: {
            name: string;
            addressLines: string[];
            communications: string[];
            IBAN?: string;
            IBANAccountName?: string;
            BIC?: string;
            vatId?: string;
        };
        buyer?: {
            name: string;
            addressLines: string[];
            email: string;
            vatId?: string;
        };
        taxItems: { tax: number; brutto: number; nettoAmount: number; taxAmount: number }[];
        lines: { tax: number; bu: number; netto: number; name: string; description: string }[];
    };
};

export const decodePdfInvoiceData = async (
    pdfBytes: Uint8Array | ArrayBuffer,
    pk: GQL.IProductKey,
    contacts: Contacts.Contact[] = []
): Promise<XRechnungResponse> => {
    const pdfDocText = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
    const attachments = extractAttachments(pdfDocText);
    const invoice = attachments.filter(a => a.name.toLowerCase().endsWith(".xml"));
    if (invoice.length !== 1) {
        return undefined;
    }

    const useSplitBF1 = Utils.ModuleUtils.useBelegfeld1Split(pk);
    const rawXml = new TextDecoder().decode(invoice[0].data);

    const decoder = new CIIDecoderFactory(rawXml, pk).getDecoder();

    const invoiceNumber = decoder.getInvoiceID();
    const { date, ...invoiceDateParsed } = decoder.getInvoiceDate();
    const deliveryDate = decoder.getDeliveryDate();
    const currency = decoder.getCurrency();

    const taxItems = decoder.getTaxes();
    const supplier = decoder.getSupplier();
    const buyer = decoder.getBuyer();
    const lines = decoder.getLineItems();
    const supplierPaymentType = decoder.getSupplierPaymentType();
    const paymentDetails = decoder.getGeneralPaymentDetails();
    const paymentReference = decoder.getPaymentReference();

    const linesBuTexts = new Map<Bu.Bu, Set<string>>();
    const linesBuTexts2 = new Map<Bu.Bu, Set<string>>();
    lines.forEach(line => {
        if (!linesBuTexts.has(line.bu)) {
            linesBuTexts.set(line.bu, new Set());
            linesBuTexts2.set(line.bu, new Set());
        }
        linesBuTexts.get(line.bu).add(line.name);
        linesBuTexts2.get(line.bu).add(line.description);
    });
    const genericItems = taxItems.map(({ bu, brutto }) => {
        return new GenericItem({
            brutto,
            bu,
            text: linesBuTexts.get(bu).size === 1 ? [...linesBuTexts.get(bu)][0] : "Diverse",
            text2: linesBuTexts2.get(bu).size === 1 ? [...linesBuTexts2.get(bu)][0] : "Diverse",
            originalAmount: currency !== GQL.ICurrencyCode.Eur ? brutto : undefined,
            belegfeld1: useSplitBF1 ? invoiceNumber : undefined,
        });
    });

    const getContact = (name: string, vatId: string, IBAN?: string) => {
        for (const c of contacts) {
            const labelName = Contacts.getLabelName(c).toLowerCase();
            if (vatId && vatId === getContactVatPrint(c.legalInfo)) {
                return c;
            }
            if (name) {
                if (name.toLowerCase() === labelName) {
                    return c;
                }
                if ((c.banks ?? []).some(b => b.accountHolder === name)) {
                    return c;
                }
            }

            if (IBAN && (c.banks ?? []).some(b => b.IBAN === IBAN)) {
                return c;
            }
        }
    };

    const getProductContact = () => {
        if (pk === GQL.IProductKey.Deb) {
            return getContact(buyer.name, buyer.vatId);
        }
        if (pk === GQL.IProductKey.Er || pk === GQL.IProductKey.ErA) {
            return getContact(supplier.name, supplier.vatId, supplierPaymentType.IBAN);
        }
    };

    const contact = getProductContact();

    const record = new GenericRecord({
        date,
        num: useSplitBF1 ? undefined : invoiceNumber,
        year: invoiceDateParsed?.year,
        period: invoiceDateParsed?.period,
        day: invoiceDateParsed?.day,
        items: genericItems,
        currency: currency !== GQL.ICurrencyCode.Eur ? { rate: 1, code: currency } : undefined,
        partner: contact ? { id: contact.uuid, name: Contacts.getLabelName(contact) } : undefined,
    });
    record.originalAmount = record.getOriginalAmount();

    const suggestion = DmsAccountingConverter.convertRecordToDms(record, pk);
    if (supplier.vatId) {
        suggestion.landCode = supplier.vatId.substring(0, 2);
        suggestion.UStIdNr = supplier.vatId.substring(2);
    }
    if (lines.length > 1) {
        suggestion.description = "";
    }

    return {
        info: decoder.getVersion(),
        rawXml,
        generated: {
            record,
            document: suggestion,
        },
        raw: {
            taxItems,
            lines,
            currency,
            paymentReference,
            paymentDescription: paymentDetails.description,
            paymentDueDate: paymentDetails.dueDate,
            paymentType: supplierPaymentType.type,
            deliveryDate,
            supplier: {
                name: supplier.name,
                IBAN: supplierPaymentType.IBAN,
                IBANAccountName: supplierPaymentType.accountName,
                BIC: supplierPaymentType.BIC,
                vatId: supplier.vatId,
                addressLines: supplier.addressLines,
                communications: supplier.communications,
            },
            buyer: {
                name: buyer.name,
                addressLines: buyer.addressLines,
                email: buyer.email,
                vatId: buyer.vatId,
            },
        },
    };
};

const responseCache = new QuickLRU<string, XRechnungResponse>({ maxSize: 100 });

export const fetchAndGetInvoiceData = async (
    fileUrl: string,
    pk: GQL.IProductKey,
    contacts: Contacts.Contact[] = []
) => {
    if (responseCache.has(fileUrl)) {
        return responseCache.get(fileUrl);
    }
    return fetch(fileUrl)
        .then(r => r.arrayBuffer())
        .then(buf => decodePdfInvoiceData(buf, pk, contacts))
        .then(res => {
            if (import.meta.env.VITE_PRODUCTION_DATABASE) {
                responseCache.set(fileUrl, res);
            }
            return res;
        });
};

type RecordForm = {
    recordDate?: RecordFormState["recordDate"];
    editableRecord: Partial<RecordFormState["editableRecord"]>;
    editableRecordItem: Partial<RecordFormState["editableRecordItem"]>;
    recordItems?: Partial<RecordFormState["recordItems"]>;
};
type FormData = { isUpdating: boolean; yearBound: number; periodBound: number; recordFormData: RecordForm };

export class DmsAccountingConverter {
    constructor(
        protected company: GQL.ICompany,
        protected product: Product,
        protected contacts: Contacts.Contact[],
        protected fetchTemplates: () => Promise<GenericRecord[]>,
        protected skr: number,
        protected buTimeframes: BuTimeframe[],
        protected recordGroup: string
    ) {}

    static convertRecordToDms(record: GenericRecord, pk: GQL.IProductKey): IDocumentSuggestion {
        const suggestion: IDocumentSuggestion = {};

        suggestion.documentAmount = record.getBrutto();
        suggestion.partner = record.partner;
        suggestion.documentDate = dayjs(record.date).format("DD.MM.YYYY");

        const text = record.items.find(item => item.text)?.text;
        if (text) {
            suggestion.description = text;
        }
        const belegfeld2 = record.items.find(item => item.belegfeld2)?.belegfeld2;
        if (belegfeld2) {
            suggestion.interneNumber = belegfeld2;
        }
        const recordNum = GenericRecordUtils.getInvoiceNumber(record, pk);
        if (recordNum) {
            suggestion.documentNumber = recordNum;
        }

        if (record.currency) {
            suggestion.currency = {
                originalAmount: record.getOriginalAmount(),
                currencyCode: record.currency.code,
                currencyRate: record.currency.rate,
            };
        }
        return suggestion;
    }

    /**
     * this method generates suggestions for both: record form and dms conversion.
     * Contact data automations must be handled separately
     *
     * @param productKey
     * @param documents
     * @param formData
     */
    static getRecordDocumentsSuggestion(productKey: GQL.IProductKey, documents: GQL.IDocument[], formData?: FormData) {
        if (!documents.length) {
            return {
                editableRecord: {},
                editableRecordItem: {},
            };
        }
        const useItemBelegfeld1 = Utils.ModuleUtils.useBelegfeld1Split(productKey);
        const result: RecordForm = formData?.recordFormData
            ? JSON.parse(JSON.stringify(formData.recordFormData))
            : {
                  editableRecord: {},
                  editableRecordItem: {},
              };

        for (const document of documents) {
            if (document.documentDate) {
                this.updateRecordDate(result, document.documentDate, formData);
            }

            if (!result.editableRecord.recordContact && document.partner) {
                result.editableRecord.recordContact = document.partner;
            }
            if (document.documentNumber) {
                if (useItemBelegfeld1) {
                    if (!result.editableRecordItem.itemBelegfeld1) {
                        result.editableRecordItem.itemBelegfeld1 = document.documentNumber;
                    }
                } else {
                    if (!result.editableRecord.recordNum) {
                        result.editableRecord.recordNum = document.documentNumber;
                    }
                }
            }

            if (!result.editableRecordItem.itemText && document.description) {
                result.editableRecordItem.itemText = document.description;
            }
            if (!result.editableRecordItem.itemBelegfeld2 && document.interneNumber) {
                result.editableRecordItem.itemBelegfeld2 = document.interneNumber;
            }

            if (!result.editableRecord.recordBrutto && document.documentAmount) {
                const currencyValue: CurrencyValue = { amount: document.documentAmount };
                if (document.currency !== GQL.ICurrencyCode.Eur) {
                    currencyValue.originalAmount = document.originalAmount;
                    currencyValue.currency = {
                        code: document.currency,
                        rate: document.currencyRate,
                    };
                }
                Object.assign(result.editableRecord, RecordFormUtils.getFormCurrency(currencyValue));
                result.editableRecordItem.itemBrutto = currencyValue.amount;
                result.editableRecordItem.itemOriginalAmount = currencyValue.originalAmount;
            }
        }
        return result;
    }

    // this is used in dms. We only apply a template here if there is exactly 1 matching template for the contact
    static applyContactTemplateSuggestions(suggestion: RecordForm, templates: GenericRecord[]) {
        if (suggestion.editableRecord.recordContact) {
            const contactId = suggestion.editableRecord.recordContact.id;
            const contactTemplates = templates.filter(({ partner }) => partner.id === contactId);
            const template = contactTemplates.length === 1 ? contactTemplates[0] : undefined;
            const contactSuggestions = ContactAccountingConverter.applyContactTemplateToRecord(template, suggestion);
            Object.assign(suggestion.editableRecord, contactSuggestions.editableRecord);
            if (suggestion.editableRecordItem) {
                Object.assign(suggestion.editableRecordItem, contactSuggestions.editableRecordItem);
            }
        }
    }

    async convertDocumentToRecord(document: GQL.IDocument): Promise<GenericRecord | null> {
        if (!ProductAccessUtils.hasCompanyAccounting(this.company)) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: "No access",
            });
        }
        if (!document) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: "Empty document",
            });
        }

        const productKey = this.product.productKey() as GQL.IProductKey;

        const xRechnungData = document.hasXRechnung
            ? await fetchAndGetInvoiceData(document.fileUrl, productKey, this.contacts)
            : null;

        const getInitialSuggestion = (): RecordForm => {
            const documentSuggestion = DmsAccountingConverter.getRecordDocumentsSuggestion(productKey, [document]);
            if (xRechnungData?.generated?.record) {
                const xRechnungSuggestion: RecordForm = {
                    recordDate: RecordFormUtils.getFormDate(xRechnungData.generated.record.date),
                    editableRecord: GenericRecordUtils.convertRecordToForm(xRechnungData.generated.record),
                    editableRecordItem: undefined,
                };
                if (xRechnungData.generated.record.items.length === 1) {
                    xRechnungSuggestion.editableRecordItem = GenericRecordUtils.convertRecordItemToForm(
                        xRechnungData.generated.record.items[0],
                        this.product
                    );
                } else {
                    xRechnungSuggestion.recordItems = xRechnungData.generated.record.items;
                }

                Object.entries(documentSuggestion.editableRecord).forEach(([key, value]) => {
                    if (
                        ["recordContact", "recordNum"].includes(key) &&
                        !xRechnungSuggestion.editableRecord[key as never]
                    ) {
                        xRechnungSuggestion.editableRecord[key as never] = value as never;
                    }
                });

                // if there is no split
                if (xRechnungSuggestion.editableRecordItem) {
                    Object.entries(documentSuggestion.editableRecordItem).forEach(([key, value]) => {
                        if (
                            !["itemBrutto", "itemOriginalAmount"].includes(key) &&
                            !xRechnungSuggestion.editableRecordItem[key as never]
                        ) {
                            xRechnungSuggestion.editableRecordItem[key as never] = value as never;
                        }
                    });
                }

                return xRechnungSuggestion;
            }
            return documentSuggestion;
        };

        const suggestion = getInitialSuggestion();
        suggestion.editableRecord.recordDocuments = [{ id: document.key, url: document.fileUrl }];
        suggestion.editableRecord.recordDraft = true;

        const templates = await this.fetchTemplates();
        DmsAccountingConverter.applyContactTemplateSuggestions(suggestion, templates);

        if (!suggestion.recordDate) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: "Date is empty",
            });
        }

        const { date, period } = suggestion.recordDate;

        if (!this.company.accountingYears.includes(date.getFullYear())) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: `Accounting for year ${date.getFullYear()} is not configured for this company`,
            });
        }
        if (!suggestion.editableRecord.recordBrutto) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: `Brutto amount is empty`,
            });
        }

        suggestion.editableRecord.recordKey = Utils.ModuleUtils.generateKey(date, period, "DmsI");

        const record = RecordFormUtils.constructRecord(
            suggestion as RecordFormState,
            this.product.getConfig(),
            this.skr,
            this.buTimeframes,
            this.recordGroup
        );
        record.items = suggestion.recordItems ?? [
            RecordFormUtils.constructRecordItem(
                suggestion as RecordFormState,
                this.product.getConfig(),
                this.skr,
                this.buTimeframes,
                this.recordGroup
            ),
        ];

        record.calculateBrutto();
        return record;
    }

    protected static updateRecordDate(result: RecordForm, documentDate: string, formData?: FormData) {
        // Check if documentDate exists and can be parsed as a valid date
        const parsedDate = dayjs(documentDate, "DD.MM.YYYY").toDate();
        if (!RecordFormUtils.isDate(parsedDate)) {
            return;
        }

        // Decide based on formData existence and update logic
        if (!formData) {
            result.recordDate = RecordFormUtils.getFormDate(parsedDate);
        } else if (!formData.isUpdating && parsedDate.getFullYear() === formData?.yearBound) {
            this.handlePeriodBoundUpdate(result, parsedDate, formData);
        }
    }

    protected static handlePeriodBoundUpdate(result: RecordForm, date: Date, formData?: FormData) {
        if (!Number.isFinite(formData.periodBound)) {
            result.recordDate = RecordFormUtils.getFormDate(date);
            return;
        }

        const { month, day: strictDay } = Periods.getMonthAndDay(formData.periodBound);
        if (!strictDay && month === date.getMonth()) {
            result.recordDate = RecordFormUtils.getFormDate(date, formData.periodBound);
        }
    }
}
