import React, { FC, useCallback, useContext, useMemo, useRef, useState } from "react";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { gridOptions } from "./config/gridOptions";
import { GQL } from "@binale-tech/shared";
import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-community";
import InvoiceFormatForm from "@inv/components/InvoiceFormats/InvoiceFormatForm";
import { gql } from "@apollo/client";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { AgGridReact } from "ag-grid-react";
import { CellActions, CellActionsProps, HeaderActions } from "@inv/components/TableActions/TableActions";

const invoiceNumberFormatDelete = gql`
    mutation invoiceNumberFormatDelete($input: InvoiceIDDeleteInput!) {
        invoiceNumberFormatDelete(input: $input)
    }
`;

export const InvoiceFormatsList: FC<{ formatsList: GQL.IInvoiceNumberFormat[]; refetch: () => void }> = ({
    refetch,
    formatsList,
}) => {
    const { invoicesMap } = useContext(InvoicesDataContext);
    const mutator = useGqlMutator();
    const apiRef = useRef<AgGridReact>(null);
    const [formData, setFormData] = useState<GQL.IInvoiceNumberFormat>();
    const onClose = useCallback(() => {
        setFormData(undefined);
        setTimeout(() => refetch(), 500);
    }, [refetch]);

    const disabledIds = useMemo(() => {
        const set = new Set();
        for (const [, inv] of invoicesMap) {
            if (inv.invoiceNumberFormatId) {
                set.add(inv.invoiceNumberFormatId);
            }
        }
        return set;
    }, [invoicesMap]);

    const onEdit = useCallback((data: GQL.IInvoiceNumberFormat) => {
        setFormData(data);
    }, []);
    const onDelete = useCallback(
        (data: GQL.IInvoiceNumberFormat) => {
            const { id, companyId } = data;
            mutator
                .mutate<"invoiceNumberFormatDelete", GQL.IInvoiceIdDeleteInput>({
                    mutation: invoiceNumberFormatDelete,
                    input: { id, companyId },
                })
                .then(() => setTimeout(() => refetch(), 500));
        },
        [mutator, refetch]
    );

    const columns: (ColDef<GQL.IInvoiceNumberFormat> | ColGroupDef<GQL.IInvoiceNumberFormat>)[] = useMemo(() => {
        return [
            {
                headerName: "Jahr",
                field: "year",
                width: 80,
                sortable: true,
                comparator: (a, b) => a - b,
            },
            {
                headerName: "Length",
                field: "numberLen",
                width: 80,
                sortable: false,
            },
            {
                headerName: "Prefix",
                field: "prefix",
                width: 150,
                sortable: false,
            },
            {
                headerName: "Number",
                field: "number",
                width: 150,
                sortable: false,
            },
            {
                headerName: "Suffix",
                field: "suffix",
                width: 150,
                sortable: false,
            },
            {
                headerName: "CreatedAt",
                field: "createdAt",
                width: 200,
                sortable: true,
                comparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
            },
            {
                headerName: "Description",
                field: "description",
                width: 350,
                sortable: false,
            },
            {
                headerComponent: HeaderActions,
                cellRenderer: CellActions,
                cellRendererParams: (params: ICellRendererParams<GQL.IInvoiceNumberFormat>): CellActionsProps => ({
                    disabled: disabledIds.has(params.data.id),
                    onEdit: () => onEdit(params.data),
                    onDelete: () => onDelete(params.data),
                }),
                field: "actions" as any,
                floatingFilter: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                width: 100,
                pinned: "right",
                sortable: false,
                lockPosition: true,
            },
        ] satisfies ColDef<GQL.IInvoiceNumberFormat>[];
    }, [disabledIds, onDelete, onEdit]);

    return (
        <>
            <AgGridTable ref={apiRef} columnDefs={columns} gridOptions={gridOptions} rowData={formatsList} />
            <InvoiceFormatForm
                onClose={onClose}
                open={Boolean(formData)}
                data={formData}
                disabled={disabledIds.has(formData?.id)}
            />
        </>
    );
};
