import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { PdfToolsContext, PdfToolsControlContext } from "../../context";
import { PdfMultiDocumentViewer } from "@ui-components/PdfViewer";
import { CarouselPdfTools } from "@ui-components/PdfViewer/DocumentCarousel/Carousel";

export const PdfToolsPreviewBlock: FC = () => {
    const { files } = useContext(PdfToolsContext);
    const actions = useContext(PdfToolsControlContext);
    const [activeFileId, setActiveFileId] = useState<string>("");

    const viewFile = useMemo(() => {
        return files.find(el => el.id === activeFileId);
    }, [files, activeFileId]);

    useEffect(() => {
        setActiveFileId(files[0]?.id);
    }, [files]);

    return (
        <PdfMultiDocumentViewer
            documentData={viewFile}
            colSpans={[4, 13]}
            carousel={
                <CarouselPdfTools
                    files={files}
                    activeFileId={activeFileId}
                    setActiveFileId={setActiveFileId}
                    reorderingFiles={actions.reorderingFiles}
                />
            }
        />
    );
};
