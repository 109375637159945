import React, { FC, PropsWithChildren, useMemo } from "react";
import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/accountingData/RecordsCtx";
import { RecordsControlContext } from "scripts/context/accountingData/RecordsControlCtx";

import {
    TableViewContext,
    TableViewContextProvider,
    useDefaultYearPeriodFilters,
} from "../../../scripts/context/accountingTable/tableViewContext";
import { ProductPOS } from "../../../scripts/core/Product";
import { RecordLogRepository } from "../../../scripts/infrastructure/repository/grRepositories";
import { GQL } from "@binale-tech/shared";

export const POSProvider: FC<PropsWithChildren> = ({ children }) => {
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    const defaultFilters = useDefaultYearPeriodFilters();
    const product = useMemo(() => new ProductPOS(yearConfig.skr, companyGQL), [yearConfig.skr, companyGQL]);

    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view: "posOverview",
            product,
            productKey: GQL.IProductKey.Pos,
            moduleRecords: allRecords.recordsPOS.list || [],
            moduleActions: allActions.actionsPOS,
            moduleLogLister: key => RecordLogRepository.POS.list({ path: [companyGQL?.id, key] }),
        }),
        [allActions.actionsPOS, allRecords.recordsPOS.list, companyGQL?.id, product]
    );
    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            {children}
        </TableViewContextProvider>
    );
};
