import React, { useState } from "react";
import { Alert, Button, Col, Form, Input, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { sendPasswordResetEmail, AuthError } from "firebase/auth";

import { AppRoutes } from "scripts/routing/routeConstants";
import { AuthLayout } from "./AuthLayout";
import { auth } from "scripts/api/firebase/firebase";
import { getFirebaseErrorMessageByError } from "scripts/api/firebase/firebaseErrorMessages";
import { logger } from "scripts/infrastructure/logger";

interface PasswordResetForm {
    email: string;
}

export const PasswordResetView = () => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm<PasswordResetForm>();
    const [error, setError] = useState<AuthError | null>(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values: PasswordResetForm) => {
        setLoading(true);
        try {
            await sendPasswordResetEmail(auth, values.email);
            setShowSuccess(true);
            form.resetFields();
        } catch (err: any) {
            logger.error({ err });
            setError(err);
        }
        setLoading(false);
    };

    const clearMessages = () => {
        setError(null);
        setShowSuccess(false);
    };

    return (
        <AuthLayout title={<FormattedMessage id="app.reset_password.caption" />}>
            {showSuccess && <Alert type="success" message={formatMessage({ id: "app.reset_password.confirmation" })} />}
            {error && <Alert type="error" message={formatMessage({ id: getFirebaseErrorMessageByError(error) })} />}

            {!showSuccess && (
                <>
                    <FormattedMessage id="app.reset_password.description" tagName="div" />

                    <Form layout="vertical" onFinish={handleSubmit} form={form}>
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: formatMessage({ id: "app.validation.error.field_empty" }) },
                                { type: "email", message: formatMessage({ id: "app.validation.error.invalid_email" }) },
                            ]}
                        >
                            <Input
                                suffix={<MailOutlined />}
                                placeholder={formatMessage({ id: "app.global.email" })}
                                onChange={() => clearMessages()}
                                autoComplete="username"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={loading}
                                loading={loading}
                                block
                                size="large"
                            >
                                <FormattedMessage id="app.button.send" tagName="span" />
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}

            <Row justify="space-between">
                <Col>
                    <Link to={AppRoutes.authLogin}>
                        <FormattedMessage id="app.header.login" />
                    </Link>
                </Col>
                <Col>
                    <Link to={AppRoutes.authRegister}>
                        <FormattedMessage id="app.registration.caption" />
                    </Link>
                </Col>
            </Row>
        </AuthLayout>
    );
};
