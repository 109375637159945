import React, { type FC, useContext } from "react";
import { FileUploaderContext } from "../../context/FileUploaderContext";
import { FilesExistingItem } from "./FilesExistingItem";
import { Typography } from "antd";
import { DmsDataContext } from "@dms/types/ContextTypes";

export const FilesExisting: FC = () => {
    const { allFilesInfo } = useContext(FileUploaderContext);
    const { documentsKV } = useContext(DmsDataContext);

    if (!allFilesInfo.length) {
        return null;
    }

    const hadBeenUploadedFiles = allFilesInfo.filter(el => el.status === "alreadyUploaded");

    if (!hadBeenUploadedFiles.length) {
        return null;
    }

    return (
        <div style={{ marginBottom: "20px" }}>
            <Typography.Text type={"secondary"}>
                Dokumente sind bereits vorhanden: {hadBeenUploadedFiles.length}
            </Typography.Text>
            {hadBeenUploadedFiles.map((el, i) => {
                return (
                    <div key={el.hash + i}>
                        <FilesExistingItem document={documentsKV[el.hash]} fileName={el.file.name} />
                    </div>
                );
            })}
        </div>
    );
};
