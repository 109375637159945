import React, { FC, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Empty } from "antd";
import { PdfToolsProvider } from "@pdf-tools/context";
import { PdfToolView } from "@pdf-tools/views/pdfToolView";
import { UserCompaniesContext } from "../scripts/context/UserProvider";
import { PdfStatsView } from "@pdf-tools/views/PdfStats";
import { PdfToolsPaths } from "@pdf-tools/types";

const PdfToolContainer: FC = () => {
    return (
        <PdfToolsProvider>
            <PdfToolView />
        </PdfToolsProvider>
    );
};

const PdfStatsContainer: FC = () => {
    const companies = useContext(UserCompaniesContext);

    return <PdfStatsView companies={companies} />;
};

const PdfToolsApp: FC = () => {
    return (
        <Routes>
            <Route index element={<PdfToolContainer />} />
            <Route path={PdfToolsPaths.Stats} element={<PdfStatsContainer />} />
            <Route path="*" element={<Empty />} />
        </Routes>
    );
};

export default React.memo(PdfToolsApp);
