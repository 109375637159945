import { gql } from "@apollo/client";

const fragment = gql`
    fragment CompanyWithMembersProducts on Company {
        id
        name
        owner {
            id
            email
        }
        members {
            id
            emailKey
            roles
            products {
                id
                productKey
                access
            }
        }
        products {
            id
            productKey
        }
    }
`;
const queryCompanyMembers = gql`
    ${fragment}
    query getCompanyWithMembersAndProducts($id: ID!) {
        company(id: $id) {
            ...CompanyWithMembersProducts
        }
    }
`;
const mutationSaveMember = gql`
    mutation companySaveMember($input: CompanySaveMemberInput!) {
        companySaveMember(input: $input) {
            wasCreated
        }
    }
`;
const mutationSaveMemberRoles = gql`
    mutation companySaveMemberRoles($input: CompanySaveMemberRolesInput!) {
        companySaveMemberRoles(input: $input) {
            id
        }
    }
`;
const mutationDeleteMember = gql`
    ${fragment}
    mutation companyDeleteMember($input: CompanyDeleteMemberInput!) {
        companyDeleteMember(input: $input) {
            ...CompanyWithMembersProducts
        }
    }
`;
const mutationUpdateOwner = gql`
    ${fragment}
    mutation companyUpdateOwner($input: CompanyUpdateOwnerInput!) {
        companyUpdateOwner(input: $input) {
            ...CompanyWithMembersProducts
        }
    }
`;

const companyFragment = gql`
    fragment CompanyForEdit on Company {
        id
        name
        color
        favouritePaymentType
        datevNrCompany
        datevNrConsultant
        accountingYears
        legalForm
        legalName
        owner {
            id
            email
        }
        bankList {
            id
            name
            accountNum
            year
        }
        kasseList {
            id
            name
            year
            accountNum
        }
        accountingConfigs {
            id
            year
            kontoExt
            taxation
            skr
            fiscalYearStart
        }
        products {
            id
            productKey
        }
        type
        invoiceEmail
        invoiceName
        invoiceReceiver
        invoiceAddress {
            addressLine2
            city
            countryCode
            house
            postCode
            street
        }
        invoiceTax {
            bundesland
            stnr
        }
        invoiceVat {
            countryCode
            vatNumber
        }
        invoiceRegister {
            type
            court
            number
        }
        invoiceHeadquarter
        invoiceGeneralPartnerName
        invoiceGeneralPartnerInvoiceRegister {
            type
            court
            number
        }
        director
        IBAN
        BIC
        bankName
        datevOAuth {
            id
            refreshTokenExpiresIn
            accessTokenExpiresIn
            issuedAt
        }
    }
`;

const queryDatevCompanyUserInfo = gql`
    query queryDatevCompanyUserInfo($companyId: ID!) {
        datevCompanyUserInfo(companyId: $companyId) {
            user {
                id
                name
                email
                familyName
                emailVerified
            }
            clients {
                id
                clientNumber
                consultantNumber
                name
                services {
                    name
                    scopes
                }
            }
        }
    }
`;
const queryCompanyForEdit = gql`
    ${companyFragment}
    query getCompanyForEdit($id: ID!) {
        company(id: $id) {
            ...CompanyForEdit
            logo
        }
    }
`;

const mutationCreateCompany = gql`
    ${companyFragment}
    mutation companyCreate($input: CompanyCreateInput!) {
        companyCreate(input: $input) {
            ...CompanyForEdit
        }
    }
`;
const mutationUpdateCompany = gql`
    ${companyFragment}
    mutation companyUpdate($input: CompanyUpdateInput!) {
        companyUpdate(input: $input) {
            ...CompanyForEdit
        }
    }
`;
const mutationSetInvoiceData = gql`
    ${companyFragment}
    mutation companySetInvoiceData($input: CompanySetInvoiceInput!) {
        companySetInvoiceData(input: $input) {
            ...CompanyForEdit
        }
    }
`;

export default class CompanyQueriesGraphql {
    static readonly mutationDeleteMember = mutationDeleteMember;
    static readonly mutationSaveMember = mutationSaveMember;
    static readonly mutationUpdateOwner = mutationUpdateOwner;
    static readonly mutationSaveMemberRoles = mutationSaveMemberRoles;
    static readonly queryCompanyMembers = queryCompanyMembers;
    static readonly queryCompanyForEdit = queryCompanyForEdit;
    static readonly queryDatevCompanyUserInfo = queryDatevCompanyUserInfo;
    static readonly mutationCreateCompany = mutationCreateCompany;
    static readonly mutationUpdateCompany = mutationUpdateCompany;
    static readonly mutationSetInvoiceData = mutationSetInvoiceData;
}
