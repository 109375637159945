import React from "react";
import { BuTimeframe } from "../context/BuContext";
import { ConverterResponse, SHConverter } from "./SollHaben";
import { GQL, Periods } from "@binale-tech/shared";
import { GenericRecord } from "../models/GenericRecord";
import { GenericRecordTableItem, TableColumn } from "../../appearance/components/shared/Table/Table";
import { PaymentsContext } from "../context/accountingData/PaymentsProvider";
import { logger } from "../infrastructure/logger";

export default class TableUtils {
    public static getTableItems(
        records: GenericRecord[],
        payments: React.ContextType<typeof PaymentsContext>,
        buTimeframes: BuTimeframe[],
        yearConfig: GQL.ICompanyAccountingYear,
        showCancelled = false,
        selectedKeys: string[] = []
    ): GenericRecordTableItem[] {
        if (!yearConfig || buTimeframes.length === 0) {
            return [];
        }
        const converter = new SHConverter(yearConfig, buTimeframes, payments);
        let saldo = 0;
        const selected = new Set(selectedKeys);
        if (!showCancelled) {
            records = records.filter(v => !v.cancellation);
        }
        let lastYear = 0;
        return records.map((record, idx) => {
            const paymentMap = new Map();
            paymentMap.set(record, payments.recordRelation.get(record.key) || []);
            const children = this.getChildren(record);
            if (record.date.getFullYear() > lastYear) {
                saldo = 0;
                lastYear = record.date.getFullYear();
            }
            if (!record.draft) {
                saldo += record.getBrutto();
            }
            const conv = converter.convert(record);
            // setup soll haben map for generated children (required for in expanded: true case for underlying items)
            const childrenSollHabenMap = new Map();
            children.forEach(v => {
                childrenSollHabenMap.set(v, converter.convert(v));
            });
            return {
                key: idx,
                item: record,
                journaled: record.journaled,
                children,
                selected: selected.has(record.key),
                extra: {
                    childrenSollHabenMap,
                    sollHaben: conv,
                    payments: paymentMap,
                    saldo: !record.draft ? saldo : null,
                },
            } as GenericRecordTableItem;
        });
    }

    public static getTableRowClassName(tableItem: GenericRecordTableItem, focusIndex: number): string {
        const classNames: string[] = [];
        if (tableItem.item.color) {
            classNames.push(tableItem.item.color);
        }
        if (tableItem.item.cancellation === "counterweight") {
            classNames.push("table-item--row__counterweight");
        }
        if (tableItem.item.cancellation === "cancelled") {
            classNames.push("table-item--row__removed");
        }
        if (tableItem.item.draft) {
            classNames.push("table-item--row__draft");
        }

        if (tableItem.selected) {
            classNames.push("table-item--row__selected");
        }
        if (focusIndex === tableItem.key) {
            classNames.push("table-item--row__focused");
        }
        return classNames.length > 0 ? classNames.join(" ") : undefined;
    }

    public static getTableItemSHContainer(tableItem: GenericRecordTableItem): ConverterResponse {
        let container: ConverterResponse = tableItem.extra.sollHaben;
        if (tableItem.key === null) {
            // expanded child
            container = tableItem.extra.childrenSollHabenMap.get(tableItem.item);
            if (!container) {
                logger.error(
                    "no sh details found in childrenSollHabenMap for",
                    tableItem.item,
                    tableItem.extra.childrenSollHabenMap
                );
                throw new Error("no sh details found in childrenSollHabenMap");
            }
        }
        return container;
    }

    public static getChildren(record: GenericRecord) {
        const children: GenericRecord[] = [];
        if (record.items.length > 1) {
            record.items.forEach(item => {
                children.push(GenericRecord.genegateSingleItemRecord(record, item));
            });
        }
        return children;
    }
}

export class GenericRecordTableItemCalculator {
    protected mappers: ((vs: GenericRecordTableItem[]) => GenericRecordTableItem[])[] = [];
    protected initialTableItems: GenericRecordTableItem[] = [];
    protected tableItems: GenericRecordTableItem[] = [];

    constructor(records: GenericRecord[], initializer: (vs: GenericRecord[]) => GenericRecordTableItem[]) {
        this.initialTableItems = initializer(records);
        this.tableItems = [...this.initialTableItems];
    }

    applyFilters(filters: Map<string, (record: GenericRecord) => boolean>) {
        let vs = this.tableItems;
        filters.forEach(filter => {
            vs = vs.filter(v => filter(v.item));
        });
        this.tableItems = vs;
    }

    mapItems(mapper: (vs: GenericRecordTableItem[]) => GenericRecordTableItem[]) {
        this.tableItems = mapper(this.tableItems);
    }

    sort(sortColumn: TableColumn<GenericRecord>) {
        if (sortColumn && sortColumn.sortDirection !== null) {
            if (sortColumn.sortable) {
                this.tableItems = this.tableItems.sort((a, b) => sortColumn.sortFunc(a, b));
            }
            if (sortColumn.sortDirection === "desc") {
                this.tableItems = this.tableItems.reverse();
            }
        }
    }

    getItems() {
        const tableItems = this.tableItems;
        // ids should be set sequentional after all filters and processings. We rely on this order in KB sequentional checks, sorting
        tableItems.forEach((v, idx) => (v.key = idx));
        return tableItems;
    }

    getSaldoMap(allYears: number[]): Map<number, Map<number, number>> {
        const res = new Map();
        allYears.forEach(y => {
            res.set(y, new Map());
        });
        this.initialTableItems.forEach(v => {
            const year = v.item.date.getFullYear();
            const { period } = v.item;
            if (!v.item.draft && period < Periods.Period.LastDayOfYear) {
                const yearMap = res.get(year);
                if (!yearMap) {
                    console.log("yearMap is empty for year", year, allYears);
                    return;
                }
                // we form header saldo here, so saldo of the previous month is a header saldo of the next month.
                // that's why +1
                yearMap.set(period + 1, v.extra.saldo);
            }
        });
        allYears.forEach(y => {
            let yearSaldo = 0;
            new Array(14)
                .fill(null)
                .map((v, i) => i)
                .forEach(period => {
                    if (!res.get(y).has(period)) {
                        res.get(y).set(period, yearSaldo);
                    }
                    yearSaldo = res.get(y).get(period);
                });
        });
        return res;
    }
}
