import React from "react";
import dayjs from "dayjs";
import { Bu, GQL, Utils } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Spin, Table, TableColumnType, Tooltip } from "antd";

import Payment from "scripts/models/Payment";
import SumOverride from "./SumOverride/SumOverride";
import { BuContext, BuTimeframe } from "scripts/context/BuContext";
import { CompanyContext } from "scripts/context/CompanyContext";
import { PaymentsContext } from "scripts/context/accountingData/PaymentsProvider";
import { RecordsContext } from "scripts/context/accountingData/RecordsCtx";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { default as Creditor } from "scripts/models/Creditor";
import { GenericRecord } from "scripts/models/GenericRecord";
import { PaymentUtils } from "scripts/models/utils/PaymentUtils";
import { ProductKey } from "scripts/models/Product";
import { logger } from "scripts/infrastructure/logger";

interface TableRecord {
    key: number;
    itemData: {
        key: string;
        module: ProductKey;
        date: Date;
        num: string;
        amount: number;
        creditor: Creditor;
        text: React.ReactNode;
        bu: Bu.Bu;
    };
    amountAvailable: number;
    isChildren?: boolean;
    children?: TableRecord[];
}

interface Props {
    visible: boolean;

    selectorRecords?: GenericRecord[];
    deletedPaymentKeys?: string[];

    onOk: (records: SelectionData[]) => void;
    onCancel: () => void;
    readOnly: boolean;
    buTimeframes: BuTimeframe[];
    yearConfig: GQL.ICompanyAccountingYear;
    payments: React.ContextType<typeof PaymentsContext>;
}

interface State {
    selected: TableRecord[];
    tableData: TableRecord[];
    overrides: Map<string, number>;
    // popupKey?: string;
    initialised?: boolean;
}

export interface SelectionData {
    brutto: number;
    keys: { [recordKey: string]: number };
}

class RecordSelector extends React.Component<Props, State> {
    static defaultProps = {};
    public state: State = {
        selected: [],
        tableData: [],
        overrides: new Map(),
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!prevProps.visible && this.props.visible) {
            const tableData = this.getTableDataFromProps(this.props);
            this.setState({ tableData }, () => {
                setTimeout(() => this.setState({ initialised: true }), 250);
            });
        }
        if (prevProps.visible && !this.props.visible) {
            setTimeout(() => {
                this.setState({ selected: [], tableData: [], overrides: new Map(), initialised: false });
            }, 250);
        }
    }

    protected getTableDataFromProps(props: Props): TableRecord[] {
        // console.log("setRecordsFromState", props);
        const suitableRecords = props.selectorRecords;
        const deletedPaymentKeys = props.deletedPaymentKeys || [];
        const tRecords: TableRecord[] = [];
        let idx = 0;
        const vrPaymentsAllocation = PaymentUtils.getVerrechnungPaymensAllocation(
            props.payments.list,
            deletedPaymentKeys
        );
        // console.log("vrPaymentsAllocation", { vrPaymentsAllocation, suitableRecords });
        suitableRecords.forEach(record => {
            if (props.payments.recordRelation.has(record.key)) {
                idx++;
                const payments: Payment[] = props.payments.recordRelation.get(record.key);
                const paymentsSum = payments.reduce((caret, p) => caret + p.zahlungsBetrag, 0);
                const children = payments.map((payment, i) => ({
                    key: idx * 100000 + i + 1,
                    itemData: {
                        key: null as any,
                        module: record.getProductKey(),
                        date: payment.date,
                        num: record.num,
                        amount: payment.zahlungsBetrag,
                        creditor: record.creditor,
                        text: "",
                        bu: null as Bu.Bu,
                    },
                    isChildren: true,
                    amountAvailable: 0,
                }));
                tRecords.push({
                    key: idx,
                    itemData: {
                        key: record.key,
                        module: record.getProductKey(),
                        date: record.date,
                        num: record.num,
                        amount: paymentsSum,
                        creditor: record.creditor,
                        text: record.getText(),
                        bu: record.items[0].extra ? record.items[0].extra.bu : null,
                    },
                    amountAvailable: paymentsSum - (vrPaymentsAllocation.get(record.key) || 0),
                    children,
                });
            }
        });
        const disabled = tRecords.filter(v => this.isDisabled(v));
        const enabled = tRecords.filter(v => !this.isDisabled(v));
        return [...enabled, ...disabled];
    }
    protected isDisabled = (v: TableRecord) => {
        return v.isChildren || v.amountAvailable <= 0;
    };

    protected get columns(): TableColumnType<TableRecord>[] {
        return [
            {
                title: "Modul",
                key: "module",
                width: 170,
                render: (cell: string, record: TableRecord) => {
                    const id = record.isChildren ? "app.fields.payment" : "app.titles." + record.itemData.module;
                    return (
                        <code>
                            <small>
                                <FormattedMessage id={id} />
                            </small>
                        </code>
                    );
                },
            },
            {
                title: <FormattedMessage id="app.fields.date" />,
                key: "date",
                width: 100,
                render: (cell: string, record: TableRecord) => dayjs(record.itemData.date).format("DD.MM.YYYY"),
            },
            {
                title: <FormattedMessage id="app.fields.rechnung_num" />,
                key: "num",
                width: 110,
                render: (cell: string, record: TableRecord) => record.itemData.num,
            },
            {
                title: <FormattedMessage id="app.fields.betrag" />,
                key: "brutto",
                width: 180,
                render: (cell: string, record: TableRecord) => this.bruttoCell(cell, record),
            },
            {
                title: <FormattedMessage id="app.fields.creditor" />,
                key: "rkonto",
                width: 150,
                render: (cell: string, record: TableRecord) => {
                    return (
                        <small>
                            {record.itemData.creditor
                                .getExtNumPrint(this.props.yearConfig.kontoExt)
                                .replace(" ", `\xa0`)}
                            <br />
                            {record.itemData.creditor.name}
                        </small>
                    );
                },
            },
            {
                title: <FormattedMessage id="app.fields.vat%" />,
                key: "vat",
                render: (cell: string, record: TableRecord) => {
                    if (record.itemData.bu === null) {
                        return null;
                    }
                    const { buTimeframes } = this.props;
                    const { skr } = this.props.yearConfig;
                    return BuTaxesSKR.getBuTax(record.itemData.bu, skr, record.itemData.date, buTimeframes).text;
                },
            },
        ];
    }

    protected bruttoCell = (cell: string, record: TableRecord) => {
        if (record.isChildren) {
            return Utils.CurrencyUtils.currencyFormat(record.itemData.amount);
        }
        const selected = this.getRecordSelectedAmount(record);
        if (this.props.readOnly) {
            return (
                <span>
                    {Utils.CurrencyUtils.currencyFormat(selected)} /{" "}
                    {Utils.CurrencyUtils.currencyFormat(record.itemData.amount)}
                </span>
            );
        }
        const tooltipContent = (
            <div>
                Total: <code>{Utils.CurrencyUtils.currencyFormat(record.itemData.amount)}</code>
                <br />
                Available: <code>{Utils.CurrencyUtils.currencyFormat(record.amountAvailable)}</code>
                <br />
                Selected: <code>{Utils.CurrencyUtils.currencyFormat(selected)}</code>
            </div>
        );
        return (
            <span>
                {Utils.CurrencyUtils.currencyFormat(record.amountAvailable)} /{" "}
                {Utils.CurrencyUtils.currencyFormat(record.itemData.amount)}{" "}
                <Tooltip placement="right" title={tooltipContent}>
                    <InfoCircleOutlined />
                </Tooltip>
                <br />
                {this.getSumOverride(record)}
            </span>
        );
    };

    protected getRecordSelectedAmount(record: TableRecord) {
        let brutto = record.amountAvailable;
        if (this.state.overrides.has(record.itemData.key)) {
            brutto = this.state.overrides.get(record.itemData.key);
        }
        return brutto;
    }

    protected getSumOverride(record: TableRecord) {
        if (record.isChildren) {
            return null;
        }
        const brutto = this.getRecordSelectedAmount(record);
        return (
            <SumOverride
                brutto={brutto}
                disabled={this.props.readOnly || brutto === 0}
                onSubmit={v => this.setOverride(record.itemData.key, Number(v))}
            />
        );
    }

    protected setOverride = (k: string, v: number) => {
        this.setState(prevState => {
            const overrides = new Map(prevState.overrides);
            overrides.set(k, v);
            return { overrides };
        });
    };

    protected get rowSelection() {
        if (this.props.readOnly) {
            return null;
        }
        return {
            selectedRowKeys: this.state.selected.map(v => v.key),
            onChange: (selectedRowKeys: string[], selectedRows: TableRecord[]) => {
                this.setState({
                    selected: selectedRows.length ? selectedRows : [],
                });
            },
            getCheckboxProps: (record: TableRecord) => {
                return {
                    disabled: this.isDisabled(record),
                };
            },
        };
    }

    protected onOk = () => {
        const res = this.state.selected
            ? this.state.selected.map(s => {
                  const amount = this.getRecordSelectedAmount(s);
                  const data: SelectionData = {
                      brutto: amount,
                      // date: date,
                      keys: { [s.itemData.key]: amount },
                  };
                  logger.log(data, s);
                  return data;
              })
            : [];
        this.props.onOk(res);
        this.setState({ selected: [] });
    };
    protected onCancel = () => {
        this.props.onCancel();
        this.setState({ selected: [] });
    };

    render() {
        return (
            <Modal
                open={this.props.visible}
                width={1200}
                style={{ top: 20 }}
                okText="Assign"
                cancelText="Ignore"
                onOk={this.onOk}
                onCancel={this.onCancel}
                footer={this.props.readOnly ? null : undefined}
                className="PaymentBindingModal"
                title={
                    <span>
                        <FormattedMessage id="app.titles.ER_A" /> <FormattedMessage id="app.fields.wählen" />
                    </span>
                }
                destroyOnClose
            >
                <Spin spinning={!this.state.initialised}>
                    <h3>Connection</h3>
                    <p>There are some records where we could assign to this payment</p>
                    <Table
                        size="small"
                        rowSelection={this.rowSelection}
                        columns={this.columns}
                        dataSource={this.state.tableData}
                    />
                </Spin>
            </Modal>
        );
    }
}

const Contexted: React.FC<
    Omit<Props, "buTimeframes" | "yearConfig" | "payments" | "readOnly"> & {
        readOnlyRecords?: GenericRecord[];
        creditor?: Creditor;
    }
> = props => {
    const { readOnlyRecords, creditor, ...restProps } = props;
    const { yearConfig } = React.useContext(CompanyContext);
    const { companyBuTimeframes } = React.useContext(BuContext);
    const payments = React.useContext(PaymentsContext);
    const {
        recordsAZ: { list: recordsAZList },
    } = React.useContext(RecordsContext);
    // readOnlyRecords - provided in Payments Table, use it directly when provided
    // otherwise it's a form, and we need to acquire a list of suitable Anzahlungen (recordsAZ) using provided creditor
    const selectorRecords: GenericRecord[] = React.useMemo(() => {
        if (!props.visible) {
            return [];
        }
        return readOnlyRecords
            ? readOnlyRecords
            : recordsAZList.filter(record => record.creditor && creditor && record.creditor.equalsTo(creditor));
    }, [props.visible, readOnlyRecords, creditor, recordsAZList]);
    return (
        <RecordSelector
            {...restProps}
            yearConfig={yearConfig}
            buTimeframes={companyBuTimeframes}
            payments={payments}
            readOnly={Boolean(props.readOnlyRecords)}
            selectorRecords={selectorRecords}
        />
    );
};
export default React.memo(Contexted);
