import { IViewsKeys } from "./User";
import { ProductKey } from "./Product";
import { child, get, set, update as fbUpdate } from "firebase/database";
import { refSettings } from "../api/firebase/firebaseRootRefs";
import { ITableColumns } from "@dms/types";

class ProgramSettings {
    hideBuchText?: boolean;
    useBuchText2?: boolean;
    useBelegfeld2?: boolean;
    useKs?: boolean;
    useExpertBu?: boolean;
    useCurrency?: boolean;
    useDueDate?: boolean;
    hideContacts?: boolean;

    protected static async retrieveFields(companyId: string): Promise<Record<ProductKey, ProgramSettings>> {
        const fbRef = child(refSettings, `${companyId}/global/forms`);
        return get(fbRef)
            .then(s => s.val() ?? {})
            .catch(() => ({}));
    }

    protected static async retrieveColumns(data: { userId: string; companyId: string }) {
        if (!data?.userId || !data?.companyId) {
            return new Map<keyof IViewsKeys, string[]>();
        }

        const fbRef = child(refSettings, `${data.companyId}/user/${data.userId}/tables`);
        const val: Record<string, any> = await get(fbRef)
            .then(s => s.val() ?? {})
            .catch(() => ({}));

        return new Map(Object.entries(val)) as Map<keyof IViewsKeys, string[]>;
    }

    static async getFields(companyId: string) {
        return this.retrieveFields(companyId);
    }

    static async getColumns(data: { userId: string; companyId: string }) {
        return this.retrieveColumns(data);
    }

    static async updateFields(companyId: string, productKey: ProductKey, update: Partial<ProgramSettings>) {
        const fbRef = child(refSettings, `${companyId}/global/forms/${productKey}`);
        await fbUpdate(fbRef, update);
    }

    static async updateColumns(
        data: { userId?: string; companyId?: string },
        view: keyof IViewsKeys,
        update: string[]
    ) {
        const fbRef = child(refSettings, `${data.companyId}/user/${data.userId}/tables/${view}`);
        await set(fbRef, update);
    }

    static async getDmsConfig(keys: { userId: string; companyId: string }) {
        if (!keys?.userId || !keys?.companyId) {
            return { enabledColumns: {} };
        }

        const fbRef = child(refSettings, `${keys.companyId}/user/${keys.userId}/dms_table_columns`);

        const val: Record<string, (keyof ITableColumns)[]> = await get(fbRef)
            .then(s => s.val() ?? {})
            .catch(() => ({}));

        return {
            enabledColumns: val,
        };
    }

    static async updateDmsEnabledColumns(
        data: { userId?: string; companyId?: string },
        typeKey: string,
        enabledColumns: string[]
    ) {
        const fbRef = child(refSettings, `${data.companyId}/user/${data.userId}/dms_table_columns/${typeKey}`);

        await set(fbRef, enabledColumns);
    }
}

export default ProgramSettings;
