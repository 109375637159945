import { useCallback, useContext } from "react";
import { PdfToolsControlContext } from "../context";
import { OcrPage } from "../types";
import { createRotatedImageUrl } from "../tools/createRotatedImageUrl";
import { logger } from "../../scripts/infrastructure/logger";

export const useImageRotation = (pages: OcrPage[]) => {
    const { pageCalculation, rotatePages } = useContext(PdfToolsControlContext);

    const rotateImage = useCallback(async () => {
        const processPage = async (page: OcrPage) => {
            pageCalculation(page.id, true);

            const rotateDegree = 90;
            const currentRotation = page.rotate || 0;
            const newRotation = (currentRotation + rotateDegree) % 360;

            let rotatedImageUrl = page.imageUrl;

            try {
                const rotatedUrl = await createRotatedImageUrl(page.imageUrl, rotateDegree);
                if (rotatedImageUrl) {
                    URL.revokeObjectURL(rotatedImageUrl);
                }
                rotatedImageUrl = rotatedUrl;
            } catch (error) {
                logger.error("Error generating rotated image URL:", error);
            } finally {
                rotatePages([page.id], rotatedImageUrl, newRotation);
                pageCalculation(page.id, false);
            }
        };
        for (const page of pages) {
            await processPage(page);
        }
    }, [pageCalculation, pages, rotatePages]);

    return { rotateImage };
};
