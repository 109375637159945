import { useContext } from "react";

import { TableViewContext } from "../tableViewContext";
import { logger } from "scripts/infrastructure/logger";

export const useModuleRecords = () => {
    const { moduleRecords } = useContext(TableViewContext);
    if (moduleRecords === undefined) {
        logger.warning("trying to get module records, however TableViewContext was not not provided");
    }

    return moduleRecords;
};

export const useModuleActions = () => {
    const { moduleActions } = useContext(TableViewContext);

    if (moduleActions === undefined) {
        logger.warning("trying to get module actions, however TableViewContext was not not provided");
    }

    return moduleActions;
};
