import { Col } from "antd";
import { PdfSingleDocumentViewer } from "./PdfSingleDocumentViewer";
import React, { FC, ReactNode } from "react";
import { GQL } from "@binale-tech/shared";

interface IProps {
    documentData: Pick<GQL.IDocument, "fileUrl" | "fileName">;
    colSpans: [number, number];
    carousel?: ReactNode;
}

export const PdfMultiDocumentViewer: FC<IProps> = ({ documentData, carousel, colSpans }) => {
    return (
        <>
            <Col span={colSpans[0]}>{carousel}</Col>

            <Col span={colSpans[1]} style={{ padding: "0 10px 0 15px", display: "flex", justifyContent: "center" }}>
                <PdfSingleDocumentViewer
                    url={documentData?.fileUrl}
                    fileName={documentData?.fileName}
                    type="application/pdf"
                />
            </Col>
        </>
    );
};
