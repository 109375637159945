import { Modal } from "antd";
import React, { type FC, memo, useCallback, useEffect, useState } from "react";
import { ModalTitle } from "./components/ModalTitle/ModalTitle";
import { FileUploader } from "./FileUploader";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export const FileUploaderModal: FC<IProps> = memo(({ isOpen, onClose }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [isDndFile, setIsDndFile] = useState<boolean>(false);

    const handleClose = useCallback(() => {
        setIsModalOpen(false);
        onClose();
    }, [onClose]);

    const onDragEnter = useCallback(() => {
        setIsDndFile(true);
    }, []);

    const onDragLeave = useCallback(() => {
        setIsDndFile(false);
    }, []);

    return (
        <div onDragEnter={onDragEnter}>
            <Modal
                destroyOnClose
                title={<ModalTitle />}
                open={isModalOpen}
                confirmLoading={false}
                onCancel={handleClose}
                footer={null}
                width={1300}
                style={{ top: 20 }}
            >
                {isModalOpen && (
                    <FileUploader
                        onReady={handleClose}
                        isDropzoneExpanded={isDndFile}
                        onCollapseDropzone={onDragLeave}
                    />
                )}
            </Modal>
        </div>
    );
});
