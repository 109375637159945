import React, { memo, useContext } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { TInvoiceTableItem } from "@inv/types";
import { ContactsContext } from "../../../../scripts/context/ContactsContext";
import { Contacts } from "@binale-tech/shared";

export const ContactCell = memo<ICellRendererParams<TInvoiceTableItem>>(({ api, data, node }) => {
    const { contactsMap } = useContext(ContactsContext);
    if (data.isChildRow) {
        return null;
    }
    const contact = contactsMap.get(data.contactId);
    if (!contact) {
        return null;
    }

    return <div>{Contacts.getLabelName(contact)}</div>;
});
