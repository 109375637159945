import { GQL } from "@binale-tech/shared";
import { InvoiceColumns, InvoicesListColumn } from "@inv/types/enums";

export type TInvoicesValues = Omit<GQL.IInvoice, "__typename" | "createdAt" | "updatedAt" | "createdBy" | "companyId">;

export type ContactAddressFields = Pick<
    TInvoicesValues,
    "address" | "street" | "houseNumber" | "countryCode" | "city" | "zipCode"
>;
export type TContactFormFields = ContactAddressFields &
    Pick<
        TInvoicesValues,
        | "customerNumber"
        | "customerReceiverName"
        | "currencyCode"
        | "customerEmail"
        | "customerName"
        | "customerVat"
        | "customerTax"
    >;
export type TInvoiceFormColumnsConfig = Record<InvoiceColumns, boolean>;

export type TInvoicesListColumnsConfig = Record<InvoicesListColumn, boolean>;

export const getLineItemNetto = (item: GQL.IInvoiceLineItem) => {
    if (!item) {
        return 0;
    }
    return item.quantity * item.price - item.discount;
};
export const getLineItemAmounts = (item: GQL.IInvoiceLineItem) => {
    const netto = getLineItemNetto(item);
    const tax = netto * (item.tax / 100);
    const brutto = netto + tax;
    return { netto, brutto, tax };
};

export interface TInvoicesListColumns {
    invoiceDate: string;
    invoiceNumber: string;
    serviceDescription: string;
    contact: string;
    project: string;
    serviceDate: string;
    currency: string;
    amountNetto: string;
    vatRate: string;
    vat: string;
    amountBrutto: string;
    isConfirmed: boolean;
}

export interface TInvoiceTableItem extends GQL.IInvoice {
    isExpanded?: boolean;
    isChildRow?: boolean;
}
export interface TInvoiceTablePinnedRow {
    brutto?: number;
    netto?: number;
    tax?: number;
}

export type TGoBLOrgParty = {
    name: string;
    tax_id?: {
        country: string;
        code: string;
        type?: string;
    };
    addresses: {
        num: string;
        street: string;
        locality: string;
        code: string;
        country: string;
        street_extra?: string; // line 2 / addresszusatz
    }[];
    emails: { addr: string }[];
    people?: { name?: { given: string }; emails: { addr: string }[] }[];
    telephones: { num: string }[];
};
