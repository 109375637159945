import React from "react";
import { Divider, Form, Row, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { Switch } from "@heroui/switch";

import ProgramSettings from "scripts/models/ProgramSettings";
import { CompanyContext, CompanyControlContext } from "scripts/context/CompanyContext";
import { GQL } from "@binale-tech/shared";
import { ProductAccessUtils } from "../../../scripts/models/utils/ProductAccessUtils";
import { ProductKey } from "scripts/models/Product";
import { ProductFactory } from "../../../scripts/core/ProductFactory";

type Props = {
    pk: ProductKey;
};

const AnsichtView: React.FC<Props> = ({ pk }) => {
    const { programSettingsProvider, yearConfig, companyGQL } = React.useContext(CompanyContext);
    const { updateProductSettings } = React.useContext(CompanyControlContext);
    const settings = React.useMemo(() => programSettingsProvider(pk), [pk, programSettingsProvider]);
    const config = ProductFactory.getProductConfig(pk as GQL.IProductKey);

    const onSaveSettings = React.useCallback(
        (name: keyof ProgramSettings) => (value: boolean) => {
            updateProductSettings(pk, { [name]: value });
        },
        [pk, updateProductSettings]
    );
    const hasContactsAccess = ProductAccessUtils.hasCompanyProductAccess(GQL.IProductKey.Contacts, companyGQL);

    return (
        <Row style={{ margin: 24 }}>
            <Form layout="vertical" style={{ marginTop: 10 }}>
                <FormattedMessage id="app.titles.settings.formSettings" tagName="p" />
                <Space split={<Divider type="vertical" />}>
                    {hasContactsAccess && (
                        <Space data-testid="hideContacts">
                            <Switch
                                isSelected={!settings.hideContacts}
                                onValueChange={value => onSaveSettings("hideContacts")(!value)}
                            />
                            <span>
                                <FormattedMessage id={"app.titles.Contacts"} />
                            </span>
                        </Space>
                    )}
                    {yearConfig?.taxation !== GQL.ICompanyTaxation.Kust ? (
                        <Space data-testid="useExpertBu">
                            <Switch isSelected={settings.useExpertBu} onValueChange={onSaveSettings("useExpertBu")} />
                            <span>BU statt USt</span>
                        </Space>
                    ) : undefined}
                    <Space data-testid="useCurrency">
                        <Switch isSelected={settings.useCurrency} onValueChange={onSaveSettings("useCurrency")} />
                        <span>Use currency</span>
                    </Space>
                </Space>
                <Divider />
                <FormattedMessage id="app.titles.settings.optionalFields" tagName="p" />
                <Space split={<Divider type="vertical" />}>
                    <Space data-testid="useKs">
                        <Switch isSelected={settings.useKs} onValueChange={onSaveSettings("useKs")} />
                        <FormattedMessage id="app.titles.tag" />
                    </Space>
                    <Space data-testid="hideBuchText">
                        <Switch
                            isSelected={!settings.hideBuchText}
                            onValueChange={value => onSaveSettings("hideBuchText")(!value)}
                        />
                        <FormattedMessage id="app.fields.buchtext" />
                    </Space>
                    <Space data-testid="useBuchText2">
                        <Switch isSelected={settings.useBuchText2} onValueChange={onSaveSettings("useBuchText2")} />
                        <FormattedMessage id="app.fields.buchtext2" />
                    </Space>
                    <Space data-testid="useBelegfeld2">
                        <Switch isSelected={settings.useBelegfeld2} onValueChange={onSaveSettings("useBelegfeld2")} />
                        <FormattedMessage id="app.fields.internal_num" tagName="span" />
                    </Space>
                    {config.useFalligkeit && (
                        <Space data-testid="useDueDate">
                            <Switch isSelected={settings.useDueDate} onValueChange={onSaveSettings("useDueDate")} />
                            <FormattedMessage id="app.fields.falligkeit" tagName="span" />
                        </Space>
                    )}
                </Space>
            </Form>
        </Row>
    );
};

export default React.memo(AnsichtView);
