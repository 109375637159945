import { GQL } from "@binale-tech/shared";
import React, { memo, useCallback, type FC } from "react";

import { FileUploaderProvider } from "./context/FileUploaderContext";
import { FileUploaderModal } from "./FileUploaderModal";

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    onFilesAdd?: (arg: string[]) => void;
    productData?: { productKey: GQL.IProductKey; selectedRecordGroup: string };
    documentType?: string[];

    useAutoclose?: boolean;
}

export const DocumentUploader: FC<IProps> = memo(
    ({ isOpen, onCancel, onFilesAdd, productData, documentType, useAutoclose }) => {
        return (
            <FileUploaderProvider
                onFilesAdd={onFilesAdd}
                productData={productData}
                documentType={documentType}
                onClose={onCancel}
                useAutoclose={useAutoclose}
            >
                <FileUploaderModal isOpen={isOpen} onClose={onCancel} />
            </FileUploaderProvider>
        );
    }
);
