import React, { DragEvent, useContext, useEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import "./App.css";

import Header from "appearance/components/header/Header";
import { AppSettingsContext } from "scripts/context/AppSettingsProvider";
import { ErrorBoundary } from "scripts/routing/ErrorBoundary";
import { ErrorView } from "appearance/views/static/ErrorViews";
import { routeSwitch } from "scripts/routing/routes";
import { ReloadPrompt } from "@ui-components/ReloadPrompt";
import { useIntl } from "react-intl";
import { AbstractTableColumns } from "@app/components/shared/AgGridTable/columns/AbstractTableColumns";

const App: React.FC = () => (
    <BrowserRouter>
        <AnimatedApp />
        <ReloadPrompt />
    </BrowserRouter>
);

const AnimatedApp: React.FC = () => {
    // const location = useLocation();

    return (
        <Routes>
            <Route element={<AppLayout />}>{routeSwitch}</Route>
        </Routes>
    );
};
const AppLayout: React.FC = () => {
    const { appError, setAppError } = useContext(AppSettingsContext);

    const intl = useIntl();

    useEffect(() => {
        AbstractTableColumns.setIntl = intl;
    }, [intl]);

    const onDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    return (
        <div className="App__container" onDrop={onDrop} onDragOver={onDragOver}>
            <Header />
            <Layout.Content>
                <ErrorBoundary setError={setAppError}>
                    {Boolean(appError) && <ErrorView />}
                    {!appError && <Outlet />}
                </ErrorBoundary>
            </Layout.Content>
        </div>
    );
};

export default React.memo(App);
