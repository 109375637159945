import { Base, Bu, GQL, Periods } from "@binale-tech/shared";

import Category, { CATEGORY_DIV, CATEGORY_EMPTY } from "./Category";
import Creditor, { CREDITOR_DIV, CREDITOR_EMPTY, Debitor, DEBITOR_DIV, DEBITOR_EMPTY } from "./Creditor";
import Payment from "./Payment";
import Tag, { TAG_DIV, TAG_EMPTY } from "./Tag";
import { BuTaxesSKR } from "./BuTaxUtils";
import {
    IBelegfeld,
    IBrutto,
    ICCD,
    IExtraData,
    IGenericItem,
    IGenericRecord,
    ITag,
    ITax,
    ItemRecordContext,
    IText
} from "./Interfaces";
import { MoneyUtils } from "./utils/MoneyUtils";
import { UnserializeItemMixin } from "./mixins";
import { logger } from "../infrastructure/logger";
import { IstMNFUtils } from "./utils/IstMNFUtils";

export class GenericRecord implements IGenericRecord {
    date?: Date;
    period?: number;
    day?: number;
    year?: number;
    num?: string; // Belegfeld1
    brutto?: number;
    currency?: Base.CurrencyConfig;
    originalAmount?: number; // only when currency is used
    documents?: GQL.IRecordDocumentInput[];
    creditor?: Creditor;
    category?: Category;
    debetor?: Debitor;
    lastschrift?: boolean;
    falligkeit?: Date;
    review?: "changed" | "ok" | "question" | "error";
    key?: string;
    items: IGenericItem[];
    avis?: boolean;
    color?: string;
    createdAt?: Date;
    updatedAt?: Date;
    updatedBy?: string;
    priority?: number;
    partner?: { id?: string; name: string };
    readonly journaled?: boolean;
    readonly draft?: boolean;
    readonly cancellation?: "cancelled" | "counterweight";

    constructor(v: IGenericRecord = {}) {
        v.key = v.key?.toString() ?? null;

        v.date = v.date instanceof Date ? v.date : new Date(v.date);

        v.createdAt = this.parseDate(v.createdAt);
        v.falligkeit = this.parseDate(v.falligkeit);
        v.creditor = v.creditor ? Creditor.unserialize(v.creditor) : undefined;
        v.category = v.category ? Category.unserialize(v.category) : undefined;
        v.debetor = v.debetor ? Debitor.unserialize(v.debetor) : undefined;

        v.items = (v.items || []).map((i: IGenericItem) => new GenericItem(i));
        v.documents = v.documents ?? [];
        Object.assign(this, v);
        this.calculateBrutto();
    }

    getProductKey(): GQL.IProductKey {
        return null;
    }

    clone(): GenericRecord {
        return Object.assign(
            Object.create(Object.getPrototypeOf(this)),
            new GenericRecord(JSON.parse(JSON.stringify(this)))
        );
    }

    protected parseDate(v?: Date | string) {
        if (v instanceof Date) {
            return v;
        }
        if (!v) {
            return undefined;
        }
        return new Date(v);
    }

    savePreProcess(ext: number) {
        if (!Number.isFinite(this.period)) {
            this.period = this.date.getMonth() + 1;
        }
        const makePeriodAdjustment = () => {
            if (this.period === Periods.Period.FirstDayOfYear) {
                this.date.setHours(3);
            } else if (this.period === Periods.Period.LastDayOfYear) {
                this.date.setHours(21);
            } else {
                this.date.setHours(12);
            }
        };
        makePeriodAdjustment();
        this.day = this.date.getDate();
        this.year = this.date.getFullYear();
        this.category && this.category.fixNum(ext);
        this.creditor && this.creditor.fixNum(ext);
        this.debetor && this.debetor.fixNum(ext);
        this.items.forEach(item => {
            item.category && item.category.fixNum(ext);
            item.creditor && item.creditor.fixNum(ext);
            item.debetor && item.debetor.fixNum(ext);
        });
    }

    getOpenBrutto(payments: Payment[] = []) {
        let openBrutto = this.getBrutto();
        if (payments) {
            let paid = 0;
            payments.forEach(payment => {
                if (!payment) {
                    logger.logToSentry("empty payment", payments);
                } else {
                    paid += payment.getSum();
                }
            });

            openBrutto -= paid;
        }
        return Object.is(-0, openBrutto) ? 0 : openBrutto;
    }

    calculateBrutto() {
        this.brutto = this.getBrutto();
        return this;
    }

    getRecordCategoryCreditor(): Base.IExtNum {
        if (this.creditor) {
            return this.creditor;
        }
        if (this.category) {
            return this.category;
        }
        if (this.debetor) {
            return this.debetor;
        }
        return CREDITOR_EMPTY;
    }

    getItemCategoryCreditor(): Base.IExtNum {
        const cred = this.getItemCreditor();
        if (cred === CREDITOR_DIV) {
            return cred;
        }
        const cat = this.getItemCategory();
        if (cat === CATEGORY_DIV) {
            return cat;
        }
        const deb = this.getItemDebitor();
        if (deb !== DEBITOR_EMPTY) {
            return deb;
        }
        if (cred !== CREDITOR_EMPTY) {
            return cred;
        }
        if (cat !== CATEGORY_EMPTY) {
            return cat;
        }
        if (deb === DEBITOR_EMPTY && cred === CREDITOR_EMPTY && cat === CATEGORY_EMPTY) {
            return CATEGORY_EMPTY;
        }
        // at this point the only case left = there is category at one item and creditor ad other item.
        // return any div
        return CATEGORY_DIV;
    }

    public static genegateSingleItemRecord(
        record: GenericRecord,
        item: IGenericItem,
        preserveKey?: boolean
    ): GenericRecord {
        const { brutto, key, items, ...recordData } = record;
        const data: IGenericRecord = {
            ...recordData,
            brutto: item.brutto,
            key: preserveKey ? record.key : null,
            items: [item],
        };
        return Object.assign(Object.create(Object.getPrototypeOf(record)), data);
    }

    // Mixin prototypes

    getTag(): Tag {
        const v = this.items[0].tag;
        const same = this.items.every((item: ITag) => {
            if (!v && !item.tag) {
                return true;
            }
            return item.tag?.equalsTo(v);
        });
        return same ? v || TAG_EMPTY : TAG_DIV;
    }

    getItemCategory(): Category {
        const { category } = this.items[0];
        if (!category) {
            return CATEGORY_EMPTY;
        }
        const same = this.items.every(item => item.category?.equalsTo(category));
        return same ? category : CATEGORY_DIV;
    }

    protected getItemCreditor(): Creditor {
        try {
            const { creditor } = this.items[0];
            if (!creditor) {
                return CREDITOR_EMPTY;
            }
            const same = this.items.every(item => item.creditor?.equalsTo(creditor));
            return same ? creditor : CREDITOR_DIV;
        } catch (e) {
            console.log(this);
            throw e;
        }
    }

    protected getItemDebitor(): Creditor {
        const { debetor: debitor } = this.items[0];
        if (!debitor) {
            return DEBITOR_EMPTY;
        }
        const same = this.items.every(item => item.debetor?.equalsTo(debitor));
        return same ? debitor : DEBITOR_DIV;
    }

    getBrutto(): number {
        return (this.items || []).reduce((caret: number, current: IBrutto) => caret + current.brutto, 0);
    }

    getOriginalAmount(): number {
        return (this.items || []).reduce((caret: number, current: IBrutto) => caret + (current.originalAmount || 0), 0);
    }

    getText(): string {
        const v = this.items[0].text;
        const same = this.items.every((item: IText) => item.text === v);
        return same ? v : "Div.";
    }

    getText2(): string {
        const v = this.items[0].text2;
        const same = this.items.every((item: IText) => item.text2 === v);
        return same ? v : "Div.";
    }

    getUSt13b(): string {
        const v = this.items[0].USt13b;
        const same = this.items.every((item: ITax) => item.USt13b === v);
        return same ? (v || "").toString() : "Div.";
    }

    getBelegfeld1(): string {
        const v = this.items[0].belegfeld1;
        const same = this.items.every((item: IBelegfeld) => item.belegfeld1 === v);
        return same ? v : "Div.";
    }

    getBelegfeld2(): string {
        const v = this.items[0].belegfeld2;
        const same = this.items.every((item: IBelegfeld) => item.belegfeld2 === v);
        return same ? v : "Div.";
    }

    getVatEuro(skr: number): number {
        return this.items.reduce(
            (caret, current) =>
                caret +
                current.getVatEuro(
                    {
                        recordKonto: this.getRecordCategoryCreditor(),
                        product: this.getProductKey(),
                        year: this.year,
                        period: this.period,
                    },
                    skr
                ),
            0
        );
    }

    getNetto(skr: number): number {
        return this.items.reduce(
            (caret, current) =>
                caret +
                current.getNetto(
                    {
                        recordKonto: this.getRecordCategoryCreditor(),
                        product: this.getProductKey(),
                        year: this.year,
                        period: this.period,
                    },
                    skr
                ),
            0
        );
    }

    getTableBuText(skr: number): string {
        const bu = this.items[0].bu;
        const same = this.items.every((item: ITax & ICCD) => item.bu === bu);
        if (!same) {
            return "Div.";
        }
        return BuTaxesSKR.getBuTaxYearPeriod(
            bu,
            skr,
            this.year,
            this.period,
            BuTaxesSKR.dangerouslyGetBuTimeframesData(this.year).buTimeframes
        ).text;
    }

    getTableBu(skr: number): string {
        const bu = this.items[0].getBu(this, skr);
        const same = this.items.every((item: GenericItem) => item.getBu(this, skr) === bu);
        return same ? bu : "Div.";
    }
}

export class GenericItem implements IGenericItem {
    constructor(i: IGenericItem = {}) {
        i.brutto = typeof i.brutto === "number" ? +i.brutto.toFixed(0) : 0;
        i = UnserializeItemMixin.preUnserialize(i);
        Object.assign(this, i);
    }

    bu: Bu.Bu;
    category?: Category;
    creditor?: Creditor;
    debetor?: Debitor;
    brutto?: number;
    originalAmount?: number; // only when currency is used on the record level
    tag?: Tag;
    text?: string;
    text2?: string;
    belegfeld1?: string;
    belegfeld2?: string;
    extra?: IExtraData;
    USt13b?: Bu.USt13bOption;

    getCategoryCreditor(): Base.IExtNum {
        if (this.creditor) {
            return this.creditor;
        }
        if (this.category) {
            return this.category;
        }
        if (this.debetor) {
            return this.debetor;
        }
        return CREDITOR_EMPTY;
    }

    // mixins
    getNetto(recordData: ItemRecordContext, skr: number) {
        return this.calculateNetto(this.brutto, recordData, skr);
    }

    getOriginalCurrencyNetto(recordData: ItemRecordContext, skr: number) {
        return this.calculateNetto(this.originalAmount || this.brutto, recordData, skr);
    }

    protected calculateNetto(brutto: number, recordData: ItemRecordContext, skr: number) {
        if (!brutto) {
            return 0;
        }
        const { recordKonto, product } = recordData;
        const { buTimeframes, taxation, kontoExt } = BuTaxesSKR.dangerouslyGetBuTimeframesData(recordData.year);
        const buTax = BuTaxesSKR.getBuTaxYearPeriod(this.bu, skr, recordData.year, recordData.period, buTimeframes);
        if (BuTaxesSKR.isTaxOnTop(buTax)) {
            return brutto;
        }
        const itemKonto = this.getCategoryCreditor();
        const { useMNFBalancing } = IstMNFUtils.getLogic(
            product,
            { taxation, kontoExt, skr },
            recordKonto,
            itemKonto,
            buTax.bu
        );
        if (useMNFBalancing) {
            return brutto;
        }
        return MoneyUtils.getNettoFromBrutto(brutto, buTax.percent);
    }

    getVatEuro(recordData: ItemRecordContext, skr: number) {
        if (!this.brutto) {
            return 0;
        }
        return this.brutto - this.getNetto(recordData, skr);
    }

    getTax(percent: number, taxOnTop?: boolean) {
        if (!this.brutto) {
            return 0;
        }
        if (!percent) {
            return 0;
        }
        let tax = 0;
        if (taxOnTop) {
            tax = Math.round((this.brutto * percent) / 100);
        } else {
            tax = this.brutto - Math.round((this.brutto * 100) / (100 + percent));
        }
        return tax;
    }

    getBu(record: GenericRecord, skr: number, isDatevExport?: boolean): string {
        const isCounterweight = record.cancellation === "counterweight";
        if (!this.bu) {
            return isCounterweight ? "20" : "";
        }
        const isAutoBu = this.category && this.category.isAutoBu();
        if (isAutoBu && isCounterweight) {
            return "20";
        }
        if (isDatevExport && isAutoBu) {
            return "";
        }
        if (isCounterweight) {
            const { buTimeframes } = BuTaxesSKR.dangerouslyGetBuTimeframesData(record.date.getFullYear());
            const buTax = BuTaxesSKR.getBuTaxYearPeriod(this.bu, skr, record.year, record.period, buTimeframes);
            return Number(buTax.GU).toString();
        }
        return Number(this.bu).toString();
    }
}

export class RecordER extends GenericRecord {
    getProductKey(): GQL.IProductKey {
        return GQL.IProductKey.Er;
    }
}

export class RecordERAnzahlung extends GenericRecord {
    getProductKey(): GQL.IProductKey {
        return GQL.IProductKey.ErA;
    }
}

export class RecordKB extends GenericRecord {
    getProductKey(): GQL.IProductKey {
        return GQL.IProductKey.Kb;
    }
}

export class RecordBank extends RecordKB {
    getProductKey(): GQL.IProductKey {
        return GQL.IProductKey.Bank;
    }
}

export class RecordFE extends GenericRecord {
    getProductKey(): GQL.IProductKey {
        return GQL.IProductKey.Fe;
    }
}

export class RecordPOS extends GenericRecord {
    getProductKey(): GQL.IProductKey {
        return GQL.IProductKey.Pos;
    }
}

export class RecordLA extends GenericRecord {
    getProductKey(): GQL.IProductKey {
        return GQL.IProductKey.La;
    }
}

export class RecordDeb extends GenericRecord {
    getProductKey(): GQL.IProductKey {
        return GQL.IProductKey.Deb;
    }
}
