import React, { FC, useCallback, useContext, useMemo } from "react";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { gridOptions } from "./config/gridOptions";
import { GridReadyEvent, RowClassRules, RowDataUpdatedEvent, SortChangedEvent } from "ag-grid-community";
import { invoicesListTotal } from "@inv/scripts/utils/utils";
import { useColumnConfig } from "./config/useColumnConfig";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { InvoicesListColumn, TInvoiceTableItem } from "@inv/types";

import styles from "./InvoicesList.module.scss";
import { GQL } from "@binale-tech/shared";

export const InvoicesList: FC = () => {
    const { invoicesFilteredList } = useContext(InvoicesDataContext);
    const columns = useColumnConfig();

    const onGridReady = useCallback(({ api }: GridReadyEvent) => {
        api.applyColumnState({
            state: [{ colId: InvoicesListColumn.INVOICE_DATE, sort: "desc" }],
            defaultState: { sort: null },
        });
    }, []);

    const onRowDataUpdated = useCallback(({ api }: RowDataUpdatedEvent<GQL.IInvoice>) => {
        const rowData = api.getRenderedNodes().map(node => node.data);
        const pinnedRow: Partial<TInvoiceTableItem> = { ...invoicesListTotal(rowData), id: "total" };
        api.updateGridOptions({
            pinnedBottomRowData: [pinnedRow],
        });
    }, []);

    const onSortChanged = useCallback(({ api }: SortChangedEvent<TInvoiceTableItem>) => {
        api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [InvoicesListColumn.INVOICE_ROW_NUMBER],
        });
        api.forEachNode(node => {
            if (node.data.isExpanded) {
                node.setData({ ...node.data, isExpanded: false });
            }
            if (node.data.isChildRow) {
                api.applyTransaction({ remove: [node.data] });
            }
        });
    }, []);

    const rowClassRules = useMemo<RowClassRules<TInvoiceTableItem>>(
        () => ({
            [styles.agTotalRow]: params => !!params.node.rowPinned,
            [styles.agChildRow]: params => params.data.isChildRow,
        }),
        []
    );

    return (
        <AgGridTable
            columnDefs={columns}
            gridOptions={gridOptions}
            rowData={invoicesFilteredList}
            onRowDataUpdated={onRowDataUpdated}
            onGridReady={onGridReady}
            rowClassRules={rowClassRules}
            getRowId={params => {
                return params.data.id;
            }}
            onSortChanged={onSortChanged}
        />
    );
};
