import React, { useCallback, useContext, useEffect, useRef } from "react";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CheckSquareOutlined,
    DownloadOutlined,
    FilterOutlined,
    LinkOutlined,
} from "@ant-design/icons";
import {
    Badge,
    Button,
    Flex,
    Form,
    InputRef,
    message,
    Modal,
    Popover,
    Progress,
    Segmented,
    Space,
    Typography,
} from "antd";
import { Base, GQL, KontoNumUtils } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";

import pLimit from "p-limit";
import { Category, GenericRecord } from "scripts/models";
import { CategoryCreditorMode, CategoryCreditorModes, GenericRecordProperties } from "scripts/core/Product";
import { CombinedCombobox } from "appearance/components/shared/form/baseComponents/comboboxes/CombinedCombobox";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { PaymentsControlContext } from "scripts/context/accountingData/PaymentsProvider";
import {
    defaultPredicates,
    GenericToolbarFilters,
} from "../../../../../../scripts/context/accountingTable/tableViewFilters";
import { Layout } from "appearance/components/shared/Layout";
import { ReconciliationMapResult, useKAReconciliationMap } from "./useKAReconciliationMap";
import { TableColumns } from "appearance/columns/ColumnConfig";
import {
    FilterValue,
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext,
} from "../../../../../../scripts/context/accountingTable/tableViewContext";
import { ToolbarComponents } from "appearance/components/toolbar/components";
import { useDocumentTitle } from "scripts/infrastructure/hooks/useDocumentTitle";
import { useIsMounted } from "scripts/infrastructure/hooks";
import { useKAToolbarNumNameList } from "./useKAToolbarNumNameList";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface Props {
    tableColumns?: TableColumns<any>;
    disabledTableColumns: string[];
    onExportClick: () => void;
    kontoMode: CategoryCreditorMode;
    onKontoModeChange: (mode: CategoryCreditorMode) => void;
    displayRecords: GenericRecord[];
    onSelectReconciliationRecords: (ids: Set<string>) => void;
}

const accountOptions = [
    {
        value: CategoryCreditorModes.CAT,
        label: <FormattedMessage id="app.titles.category.pl" />,
    },
    {
        value: CategoryCreditorModes.CRED,
        label: <FormattedMessage id="app.titles.creditor.pl" />,
    },
    {
        value: CategoryCreditorModes.DEB,
        label: <FormattedMessage id="app.titles.debitor.pl" />,
    },
];
type ModalProps = {
    open: boolean;
    onCancel: () => void;
    reconciliationInput: ReconciliationMapResult;
};
const ReconciliationModal: React.FC<ModalProps> = ({ open, onCancel, reconciliationInput }) => {
    const { onPaymentGql } = React.useContext(PaymentsControlContext);
    const [processingIdx, setProcessingIdx] = React.useState<number>();

    const inputs: GQL.IPaymentProtoInput[] = [];
    reconciliationInput?.autoReconciliationRecords.forEach(item => {
        item.paymentRepresentationRecords.forEach(representationRecord => {
            inputs.push({
                sourceRecordKey: item.sourceRecord.key,
                strategy: GQL.IPaymentProtoStrategy.Representation,
                strategyRepresentation: {
                    discountAmount: 0,
                    representationRecordKey: representationRecord.key,
                },
            });
        });
    });
    const totalCount = inputs.length;

    React.useEffect(() => {
        setProcessingIdx(undefined);
    }, [open]);
    const startProgress = async () => {
        setProcessingIdx(0);
        const limit = pLimit(5);
        const input = inputs.map(input =>
            limit(() =>
                onPaymentGql({ payment: input }, true)
                    .catch(e => message.error(e.message))
                    .finally(() => setProcessingIdx(i => i++))
            )
        );
        await Promise.all(input).finally(() => setProcessingIdx(totalCount));
        await new Promise(r => setTimeout(r, 1000));
        setProcessingIdx(undefined);
        onCancel();
    };
    const step = 100 / totalCount;
    const progress = processingIdx === totalCount ? 100 : Math.floor((processingIdx ?? 0) * step);
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            cancelButtonProps={{ disabled: Number.isFinite(processingIdx) }}
            okButtonProps={{ disabled: Number.isFinite(processingIdx) }}
            title={<FormattedMessage id="app.titles.reconciliation" />}
            onOk={startProgress}
            closable={false}
            maskClosable={false}
            destroyOnClose
        >
            {Number.isFinite(processingIdx) ? (
                <div>
                    <p>
                        {processingIdx} / {totalCount}
                    </p>
                    <Progress percent={progress} status={progress < 100 ? "active" : undefined} />
                </div>
            ) : (
                <p>Press OK to start (total {inputs.length})</p>
            )}
        </Modal>
    );
};

const KontoAnsichtToolbar: React.FC<Props> = props => {
    const isMounted = useIsMounted();
    const intl = useIntl();
    const setDocumentTitle = useDocumentTitle();
    const { yearConfig, companyGQL } = useContext(CompanyContext);
    const { view, moduleRecords: records } = useContext(TableViewContext);
    const { numNameList } = useKAToolbarNumNameList({ records });
    const reconciliationData = useKAReconciliationMap({
        displayRecords: props.displayRecords,
    });
    const { year, period, onChangeYear, onChangePeriod } = useContext(YearPeriodContext);
    const { filters } = useContext(TableFiltersContext);
    const accountFilterRef = useRef(new Subject<FilterValue>());
    const { setFilter, resetFilters } = useContext(TableFiltersControlContext);
    const comboboxRef = React.createRef<InputRef>();
    const [searchFilter, setSearchFilter] = React.useState("");
    const [reconciliationModalInput, setReconciliationModalInput] = React.useState<ReconciliationMapResult>();

    const { onKontoModeChange, kontoMode: accountType } = props;
    const { current: combobox } = comboboxRef;
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const accountingYears = companyGQL?.accountingYears || [];
    const categoryCreditor = filters.get(GenericToolbarFilters.Konto)?.value;
    const showReconciliationButton = [CategoryCreditorModes.CRED, CategoryCreditorModes.DEB].includes(accountType);
    const { leftTableSubject } = useContext(TableViewContext);
    const accountTypeRef = useRef(accountType);
    useEffect(() => {
        accountTypeRef.current = accountType;
    }, [accountType]);

    const onFilterKontoChange = React.useCallback(
        (value: Base.IExtNum | undefined) => {
            const isValueDefined = Boolean(value);
            if (isValueDefined && typeof value !== "object") {
                return;
            }
            setDocumentTitle(() => {
                const typeIntl = intl.formatMessage({ id: `app.titles.${accountTypeRef.current}` });
                const defaultPageIntl = intl.formatMessage({ id: "app.titles.KA" });

                return isValueDefined ? `${typeIntl} ${value.getExtNumPrint(kontoExt)}` : defaultPageIntl;
            });
            accountFilterRef.current.next({
                predicate: () => true,
                value,
            });
        },
        [intl, kontoExt, setDocumentTitle]
    );

    const handleRadioChange: React.ComponentProps<typeof Segmented>["onChange"] = useCallback(
        (value: CategoryCreditorMode) => {
            onFilterKontoChange(undefined);
            onKontoModeChange(value);
            setFilter(GenericToolbarFilters.PaymentStatus, {
                predicate: () => true,
                value: null,
            });
            setDocumentTitle(
                intl.formatMessage({
                    id: "app.titles.KA",
                })
            );
        },
        [intl, onKontoModeChange, setDocumentTitle, setFilter, onFilterKontoChange]
    );

    useEffect(() => {
        // left table clicks subscription
        const sub = leftTableSubject.subscribe(({ category, creditor, debetor }) => {
            if (category) {
                handleRadioChange(CategoryCreditorModes.CAT);
                setTimeout(() => onFilterKontoChange(category), 100);
            } else if (creditor) {
                handleRadioChange(CategoryCreditorModes.CRED);
                setTimeout(() => onFilterKontoChange(creditor), 100);
            } else if (debetor) {
                handleRadioChange(CategoryCreditorModes.DEB);
                setTimeout(() => onFilterKontoChange(debetor), 100);
            }
        });
        return () => sub.unsubscribe();
    }, [handleRadioChange, leftTableSubject]);
    useEffect(() => {
        if (combobox) {
            combobox.focus();
        }
    }, [combobox]);

    useEffect(() => {
        const sub = accountFilterRef.current.pipe(debounceTime(250)).subscribe(v => {
            // filtering is handled in the KontenAnsichtView.postProcessTableItems
            setFilter(GenericToolbarFilters.Konto, v);
        });
        return () => {
            sub.unsubscribe();
            setFilter(GenericToolbarFilters.Konto, {
                predicate: () => true,
                value: undefined,
            });
        };
    }, [setFilter]);

    const list = React.useMemo(() => {
        return numNameList.filter(item => {
            if (accountType === CategoryCreditorModes.DEB && KontoNumUtils.isDebitor({ extNum: item }, kontoExt)) {
                return true;
            }
            if (accountType === CategoryCreditorModes.CRED && KontoNumUtils.isCreditor({ extNum: item }, kontoExt)) {
                return true;
            }
            if (accountType === CategoryCreditorModes.CAT && item instanceof Category) {
                return true;
            }
            return false;
        });
    }, [numNameList, accountType, kontoExt]);

    useEffect(() => {
        if (isMounted()) {
            onFilterKontoChange(undefined);
        }
    }, [accountType, isMounted, onFilterKontoChange]);

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            onFilterKontoChange(undefined);
            setSearchFilter("");
            resetFilters();
        }
    };

    const onShift = (v: -1 | 1) => {
        if (!list.length) {
            return;
        }
        if (!categoryCreditor) {
            onFilterKontoChange(list[0]);
            return;
        }

        const idx = list.findIndex(cc => cc.equalsTo(categoryCreditor));
        if (idx === -1) {
            onFilterKontoChange(undefined);
            return;
        }
        let newIdx = idx + v;
        if (newIdx < 0) {
            newIdx = list.length - 1;
        }
        if (newIdx >= list.length) {
            newIdx = 0;
        }
        onFilterKontoChange(list[newIdx]);
    };

    const paymentStatusValue = filters.get(GenericToolbarFilters.PaymentStatus)?.value;
    const reviewStatusValue = filters.get(GenericToolbarFilters.ReviewStatus)?.value;

    return (
        <div className="GenericToolbar" onKeyDown={onKeyDown}>
            <Flex justify={"space-between"} align={"flex-end"}>
                <Space>
                    <ToolbarComponents.DateSection
                        period={period}
                        year={year}
                        years={accountingYears}
                        onPeriodChange={onChangePeriod}
                        onYearChange={onChangeYear}
                        withAll
                    />
                    <Segmented value={accountType} onChange={handleRadioChange} options={accountOptions} />
                    <Space.Compact>
                        <CombinedCombobox
                            placeholder={<FormattedMessage id="app.global.not_selected" />}
                            inputRef={comboboxRef}
                            allowClear
                            value={categoryCreditor}
                            items={list}
                            onChange={onFilterKontoChange}
                            style={{ width: 150 }}
                            showAggregationCategories
                            notFoundContent={<FormattedMessage id="app.messages.no_elements_found" tagName="span" />}
                            inputProps={{
                                "data-testid": "category-creditor",
                            }}
                        />
                        <Button icon={<ArrowLeftOutlined />} onClick={() => onShift(-1)} />
                        <Button icon={<ArrowRightOutlined />} onClick={() => onShift(1)} />
                    </Space.Compact>
                    {categoryCreditor && (
                        <Typography.Text
                            style={{
                                minWidth: 50,
                                maxWidth: "calc(100vw - 1350px)",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                display: "block",
                                overflow: "hidden",
                            }}
                        >
                            {categoryCreditor.name}
                        </Typography.Text>
                    )}
                </Space>

                <Space>
                    {showReconciliationButton && (
                        <Space.Compact>
                            <Button
                                disabled={!reconciliationData.possiblePayments}
                                onClick={() => {
                                    const ids = new Set<string>();
                                    Array.from(reconciliationData.autoReconciliationRecords.values()).forEach(v => {
                                        ids.add(v.sourceRecord.key);
                                        v.paymentRepresentationRecords.forEach(({ key }) => ids.add(key));
                                    });
                                    props.onSelectReconciliationRecords(ids);
                                }}
                                icon={<CheckSquareOutlined />}
                            />
                            <Badge
                                count={reconciliationData.possiblePayments}
                                style={{ zIndex: 1000 }}
                                overflowCount={1000}
                            >
                                <Button
                                    disabled={!reconciliationData.possiblePayments}
                                    icon={<LinkOutlined />}
                                    onClick={() => setReconciliationModalInput(reconciliationData)}
                                >
                                    {" "}
                                    <FormattedMessage id="app.titles.reconciliation" />
                                </Button>
                            </Badge>
                        </Space.Compact>
                    )}
                    <Popover
                        destroyTooltipOnHide
                        trigger="click"
                        placement={"bottomLeft"}
                        content={
                            <Form layout={"vertical"}>
                                <Form.Item label={GenericRecordProperties.RecordReview}>
                                    <ToolbarComponents.ReviewStatusSelector
                                        reviewStatusValue={reviewStatusValue}
                                        onReviewStatusChange={value =>
                                            setFilter(GenericToolbarFilters.ReviewStatus, {
                                                predicate: defaultPredicates.reviewStatus(value),
                                                value,
                                            })
                                        }
                                    />
                                </Form.Item>
                                <Form.Item label={GenericRecordProperties.ComputedStatus} style={{ marginBottom: 0 }}>
                                    <ToolbarComponents.PaymentStatusSelector
                                        paymentStatusValue={paymentStatusValue}
                                        onPaymentStatusChange={value =>
                                            setFilter(GenericToolbarFilters.PaymentStatus, {
                                                // filtering is handled in the KontenAnsichtView.postProcessTableItems
                                                predicate: () => true,
                                                value,
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Form>
                        }
                    >
                        <Badge dot={Boolean(paymentStatusValue) || Boolean(reviewStatusValue)}>
                            <Button icon={<FilterOutlined />} tabIndex={-1} />
                        </Badge>
                    </Popover>
                    <ToolbarComponents.SearchInput
                        onSearch={value => {
                            setFilter(GenericToolbarFilters.TextSearch, {
                                // filtering is handled in the KontenAnsichtView.postProcessTableItems
                                predicate: () => true,
                                value,
                            });
                        }}
                        value={searchFilter}
                        onChange={setSearchFilter}
                    />
                    <Button onClick={props.onExportClick} icon={<DownloadOutlined />} shape="circle" />
                    <ToolbarComponents.AnsichtBtn
                        tableColumns={props.tableColumns}
                        view={view}
                        disabledOptions={props.disabledTableColumns}
                    />
                </Space>
            </Flex>
            <ReconciliationModal
                reconciliationInput={reconciliationModalInput}
                open={Boolean(reconciliationModalInput)}
                onCancel={() => setReconciliationModalInput(undefined)}
            />
        </div>
    );
};

export default React.memo(KontoAnsichtToolbar);
