import React, { type FC, useContext } from "react";
import { Flex, Typography } from "antd";
import { FileUploaderContext } from "@dms/modules/DocumentUploader/context/FileUploaderContext";

export const FilesInvalid: FC = () => {
    const { unsupportedFiles } = useContext(FileUploaderContext);
    if (!unsupportedFiles.length) {
        return null;
    }
    return (
        <div>
            <Typography.Text type={"secondary"}>Nicht unterstützte Dateien: {unsupportedFiles.length} </Typography.Text>
            {unsupportedFiles.map((el, i) => {
                return (
                    <Flex key={i} justify={"space-between"}>
                        <Typography.Text type={"danger"}>{el.file.name}</Typography.Text>
                        <Typography.Text type={"warning"}>{el.reason}</Typography.Text>
                    </Flex>
                );
            })}
        </div>
    );
};
