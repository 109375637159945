import React from "react";
import dayjs from "dayjs";
import { Base } from "@binale-tech/shared";
import { Button, Form, Popconfirm } from "antd";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { SaveOutlined } from "@ant-design/icons";

import { UserContext } from "scripts/context/UserProvider";
import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/accountingData/RecordsCtx";
import { RecordsControlContext } from "scripts/context/accountingData/RecordsControlCtx";
import { GenericRecord } from "scripts/models/GenericRecord";
import { Layout } from "../../components/shared/Layout";
import { Multiselect, MultiselectValue } from "../../components/shared/Multiselect/Multiselect";
import { Page, PageHeader } from "../../components/shared/appearance/page";
import { YearSelect } from "../../components/shared/Toolbar/YearSelect";
import "../../components/shared/Page.css";

interface FestschreibungDispatchProps {
    onJournal: (v: GenericRecord[]) => void;
}
interface FestschreibungStateProps {
    bills: GenericRecord[];
    user: Base.UserInterface;
    intl?: IntlShape;
    years: number[];
}
interface FestschreibungState {
    year: number;
    months: Set<number>;
}
type FestschreibungProps = FestschreibungStateProps & FestschreibungDispatchProps;

class FSView extends React.PureComponent<FestschreibungProps, FestschreibungState> {
    protected readonly ALL_MONTHS = Array.from(Array(12).keys());

    constructor(props: FestschreibungProps) {
        super(props);
        this.state = {
            year: new Date().getFullYear(),
            months: new Set<number>([this.ALL_MONTHS[new Date().getMonth()]]),
        };
    }

    protected onSetYear = (v: number) => this.setState({ year: v });

    getMultiselectValues(): MultiselectValue[] {
        const MONTH_NAMES = dayjs.months();
        return this.ALL_MONTHS.map(v => {
            return { value: String(v), label: MONTH_NAMES[v], selected: this.state.months.has(v) };
        });
    }

    protected onChangeMonth = (mv: MultiselectValue[]) => {
        const disabled = mv.filter(v => !v.selected).map(v => v.value);
        const enabled = mv.filter(v => v.selected).map(v => v.value);
        const newData = new Set<number>();
        this.state.months.forEach(v => {
            if (disabled.indexOf(v + "") === -1) {
                newData.add(v);
            }
            return v;
        });
        enabled.forEach(v => {
            newData.add(+v);
        });
        this.setState({ months: newData });
    };

    protected getSelectedBills(): GenericRecord[] {
        return this.props.bills
            .filter(v => v.date.getFullYear() === this.state.year)
            .filter(v => this.state.months.has(v.date.getMonth()));
    }

    protected onJournal = () => {
        this.props.onJournal(this.getSelectedBills());
    };

    render() {
        const bills = this.getSelectedBills();
        const nonJournaledBills = bills.filter(v => !v.journaled);
        const disabled = this.state.months.size === 0 || bills.length === 0 || nonJournaledBills.length === 0;
        return (
            <Page className="FestschreibungView">
                <PageHeader>
                    <FormattedMessage id="app.titles.ER.festschreibung" />
                </PageHeader>
                <Form layout="vertical">
                    <Layout className="FestschreibungView__buttonGroup">
                        <Form.Item label={<FormattedMessage id="app.fields.date.year" />}>
                            <YearSelect years={this.props.years} value={this.state.year} onChange={this.onSetYear} />
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id="app.fields.date.month" />}>
                            <Multiselect
                                includeSelectAllOption
                                onChange={this.onChangeMonth}
                                data={this.getMultiselectValues()}
                            />
                        </Form.Item>
                        <Form.Item label={<span>&nbsp;</span>}>
                            <Popconfirm
                                onConfirm={this.onJournal}
                                title={<FormattedMessage id="app.confirmation.header" />}
                                okText={<FormattedMessage id="app.button.confirm" />}
                                cancelText={<FormattedMessage id="app.button.cancel" />}
                                disabled={disabled}
                            >
                                <Button type="primary" disabled={disabled} icon={<SaveOutlined />}>
                                    Festschreiben ({nonJournaledBills.length}/{bills.length})
                                </Button>
                            </Popconfirm>
                        </Form.Item>
                    </Layout>
                </Form>
            </Page>
        );
    }
}

type Props = {
    isAZ?: boolean;
};

const FestschreibungView: React.FC<Props> = props => {
    const { companyGQL } = React.useContext(CompanyContext);
    const { recordsER, recordsAZ } = React.useContext(RecordsContext);
    const { actionsER, actionsAZ } = React.useContext(RecordsControlContext);
    const user = React.useContext(UserContext);
    const accountingYears = companyGQL?.accountingYears || [];
    const intl = useIntl();
    return (
        <FSView
            {...props}
            years={accountingYears}
            intl={intl}
            bills={props.isAZ ? recordsAZ.list : recordsER.list}
            user={user}
            onJournal={records => {
                const keys = records.map(({ key }) => key);
                props.isAZ ? actionsAZ.journal(keys) : actionsER.journal(keys);
            }}
        />
    );
};
export const FestschreibungERView: React.FC = props => <FestschreibungView {...props} />;
export const FestschreibungAZView: React.FC = props => <FestschreibungView {...props} isAZ />;
