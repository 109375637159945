import { gray } from "@ant-design/colors";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Flex, Space, Tooltip, Typography } from "antd";
import React, { type FC } from "react";
import { FormattedMessage } from "react-intl";

import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { DmsTypeOptions } from "@dms/configs/constants";
import { GQL } from "@binale-tech/shared";

interface IProps {
    document: GQL.IDocument;
    fileName: string;
}

const { Text } = Typography;

export const FilesExistingItem: FC<IProps> = ({ document, fileName }) => {
    const { fileUrl, type, createdBy, createdAt, updatedAt } = document;

    const finedTypeInfo = DmsTypeOptions.find(el => el.value === type);
    const status = DmsUtils.getDocumentStatus(document);

    const fileInfo = (
        <Space direction={"vertical"}>
            {finedTypeInfo && (
                <Flex vertical>
                    <Text style={{ color: gray[0] }} strong>
                        Type document:
                    </Text>
                    <Text style={{ color: gray[0] }} ellipsis>
                        {finedTypeInfo.translationKey ? (
                            <FormattedMessage id={finedTypeInfo.translationKey} />
                        ) : (
                            finedTypeInfo?.value
                        )}
                    </Text>
                </Flex>
            )}

            <Flex vertical>
                <Text style={{ color: gray[0] }} strong>
                    Created by:
                </Text>
                <Text style={{ color: gray[0] }} ellipsis>
                    {createdBy?.email}
                </Text>
            </Flex>

            <Flex vertical>
                <Text style={{ color: gray[0] }} strong>
                    Uploaded at:
                </Text>
                <Text style={{ color: gray[0] }}>{createdAt}</Text>
                {createdAt !== updatedAt && (
                    <Text style={{ color: gray[0] }}>
                        {createdAt} {updatedAt}
                    </Text>
                )}
            </Flex>

            <Flex vertical>
                <Text style={{ color: gray[0] }} strong>
                    Status:
                </Text>
                <Text style={{ color: gray[0] }}>{status}</Text>
            </Flex>
        </Space>
    );

    return (
        <Space style={{ width: "100%" }}>
            <Tooltip placement="leftBottom" title={fileInfo}>
                <InfoCircleOutlined style={{ cursor: "pointer" }} />
            </Tooltip>
            <a href={fileUrl} target="_blank" rel="noreferrer">
                {fileName}
            </a>
        </Space>
    );
};
