import { Button, Divider, Dropdown, Flex, theme } from "antd";
import { ExportOutlined, MoreOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import { useDropdownAction } from "@dms/components/ActionList/useDropdownAction";
import { ITableDocument } from "@dms/types";
import { IRowNode } from "ag-grid-community";
import { DmsFibuExporter } from "@dms/components/DmsFibuExporter/DmsFibuExporter";
import { ProductAccessUtils } from "../../../scripts/models/utils/ProductAccessUtils";
import { GQL } from "@binale-tech/shared";

type TProps = {
    document: GQL.IDocument;
    mode: "table" | "card";
    node: IRowNode<ITableDocument>;
};

export const DropdownActions: FC<TProps> = ({ document, mode, node }) => {
    const { token } = theme.useToken();
    const items = useDropdownAction({
        document,
        mode,
        node,
    });
    const contentStyle: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    const menuStyle: React.CSSProperties = {
        boxShadow: "none",
    };

    const isAccountingType = ProductAccessUtils.getAccountingKeys().has(document.type as GQL.IProductKey);

    return (
        <Dropdown
            menu={{ items }}
            trigger={["click"]}
            destroyPopupOnHide
            dropdownRender={menu => (
                <div style={contentStyle} data-testid="dms-table-row-actions-dropdown">
                    {React.cloneElement(menu as any, { style: menuStyle })}
                    {isAccountingType && (
                        <>
                            <Divider style={{ margin: 2 }} />
                            <Flex style={{ padding: 8, width: "100%" }}>
                                <DmsFibuExporter documents={[document]}>
                                    <Button icon={<ExportOutlined />} type={"text"} style={{ width: "100%" }}>
                                        FIBU
                                    </Button>
                                </DmsFibuExporter>
                            </Flex>
                        </>
                    )}
                </div>
            )}
        >
            <Button icon={<MoreOutlined rotate={90} style={{ padding: 0 }} />} type="text" shape={"circle"} />
        </Dropdown>
    );
};
