import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, Popconfirm, Segmented, Space, Tooltip } from "antd";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    DownloadOutlined,
    EyeFilled,
    EyeOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";

import TableExporter from "../../shared/TableExporter/TableExporter";
import { FlexRow } from "../../shared/appearance/page/Scaffold";
import { GenericRecord } from "scripts/models/GenericRecord";
import { IViewsKeys } from "scripts/models/User";
import { TableColumns } from "../../../columns/ColumnConfig";
import { TableColumnsSelector } from "../../shared/TableColumnsSelector/TableColumnsSelector";
import { TableExportType } from "../../../../scripts/csv/exporters/Exporters";
import { UserSettingsContext } from "../../../../scripts/context/UserSettingsProvider";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { Switch } from "@heroui/switch";

export interface JournalProps {
    toJournal?: number;
    onJournalClicked?: () => void;
}

export const JournalButton: FC<JournalProps> = memo(function JournalButton(props) {
    const { formatMessage } = useIntl();
    const title = (
        <section>
            <b>Journal</b>
            {props.toJournal === 0 && <div>{formatMessage({ id: "app.kb.journal.no_items_tooltip" })}</div>}
        </section>
    );
    return (
        <Tooltip placement="left" title={title}>
            <Popconfirm
                onConfirm={props.onJournalClicked}
                title={<FormattedMessage id="app.confirmation.header" />}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
                disabled={props.toJournal === 0}
            >
                <Badge count={props.toJournal}>
                    <Button icon={<SaveOutlined />} disabled={props.toJournal === 0}>
                        <span className="Toolbar__LongLabel">Journal</span>
                    </Button>
                </Badge>
            </Popconfirm>
        </Tooltip>
    );
});

export interface EyeButtonProps {
    showRemoved?: boolean;
    onToggleHidden?: () => void;
}

export const ShowRemovedSwitch: React.FC<EyeButtonProps> = ({ showRemoved, onToggleHidden }) => {
    return (
        <Switch
            isSelected={showRemoved}
            onValueChange={onToggleHidden}
            thumbIcon={({ isSelected, className }) =>
                isSelected ? <EyeFilled className={className} /> : <EyeOutlined className={className} />
            }
        >
            entfernte Buchungen anzeigen
        </Switch>
    );
};

export type PaymentStatusSelectorType = "avis" | "offen" | "bezahlt" | null;

export type PaymentStatusSelectorProps = {
    paymentStatusValue?: PaymentStatusSelectorType;
    onPaymentStatusChange?: (v: PaymentStatusSelectorType) => void;
    useAvis?: boolean;
};
export const PaymentStatusSelector: React.FC<PaymentStatusSelectorProps> = memo(
    ({ paymentStatusValue, onPaymentStatusChange, useAvis }) => {
        // using internal status to avoid jumps in Segmented
        const [value, setValue] = useState<PaymentStatusSelectorType>(paymentStatusValue);

        const onChange = useCallback(
            (v: PaymentStatusSelectorType) => {
                setValue(v);
                setTimeout(() => onPaymentStatusChange(v), 0);
            },
            [onPaymentStatusChange]
        );
        useEffect(() => {
            setValue(paymentStatusValue);
        }, [paymentStatusValue]);
        const options = useMemo(() => {
            return [
                { value: null, label: "Alle" },
                useAvis ? { value: "avis", label: "Avis" } : null,
                { value: "offen", label: "offen" },
                { value: "bezahlt", label: "bezahlt" },
            ].filter(Boolean) as SegmentedLabeledOption<PaymentStatusSelectorType>[];
        }, [useAvis]);
        return <Segmented<PaymentStatusSelectorType> value={value} onChange={onChange} options={options} />;
    }
);

export type ReviewStatusSelectorType = GenericRecord["review"] | "new";

export type ReviewStatusSelectorProps = {
    reviewStatusValue?: ReviewStatusSelectorType;
    onReviewStatusChange?: (v: ReviewStatusSelectorType) => void;
};
export const ReviewStatusSelector: React.FC<ReviewStatusSelectorProps> = memo(
    ({ reviewStatusValue, onReviewStatusChange }) => {
        // using internal status to avoid jumps in Segmented
        const [value, setValue] = useState<ReviewStatusSelectorType>(reviewStatusValue);

        const onChange = useCallback(
            (v: ReviewStatusSelectorType) => {
                setValue(v);
                setTimeout(() => onReviewStatusChange(v), 0);
            },
            [onReviewStatusChange]
        );
        useEffect(() => {
            setValue(reviewStatusValue);
        }, [reviewStatusValue]);
        const options = useMemo(() => {
            const statuses: ReviewStatusSelectorType[] = [undefined, "new", "ok", "changed", "error", "question"];
            return statuses.map(value => ({
                value,
                label: value ? <FormattedMessage id={`app.fields.recordReview.${value ?? "new"}`} /> : "Alle",
            }));
        }, []);
        return <Segmented<ReviewStatusSelectorType> value={value} onChange={onChange} options={options} />;
    }
);

export interface AnsichtProps {
    tableColumns?: TableColumns<any>;
    view?: keyof IViewsKeys;
    disabledOptions?: string[];
}

export const AnsichtBtn: FC<AnsichtProps> = React.memo(function AnsichtBtn({ tableColumns, view, disabledOptions }) {
    const { onChangeDisabledColumns, disabledColumns } = React.useContext(UserSettingsContext);
    const intl = useIntl();

    const columnNames = React.useMemo(
        () => tableColumns.getViewableColumnNames(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tableColumns, intl.locale]
    );

    return (
        <TableColumnsSelector
            columns={columnNames}
            unselectedColumns={disabledColumns.get(view)}
            disabledOptions={disabledOptions}
            onChange={list => onChangeDisabledColumns(view, list)}
        />
    );
});

export interface IExportProps {
    exportColumns: string[];
    onDownload?: (type: TableExportType, columns?: string[], timeframe?: { from?: Date; to?: Date }) => Promise<void>;
    tableColumns?: TableColumns<GenericRecord>;
}

export const ExportBtn: FC<IExportProps> = ({ exportColumns, onDownload, tableColumns }) => {
    const columns = tableColumns.getColumnConfig().map(v => v.key);

    const handleDownload = async (type: TableExportType, newColumns: string[]) => {
        if (onDownload) {
            await onDownload(type, newColumns);
        }
    };

    return (
        <TableExporter
            columns={columns}
            onOk={handleDownload}
            defaultColumnsKeys={exportColumns}
            className={"ExportBtn"}
        >
            <Button icon={<DownloadOutlined />} shape={"circle"} />
        </TableExporter>
    );
};

export interface ConfirmProps {
    toConfirm?: number;
    onConfirmClicked?: () => void;
}

export const ConfirmButton: FC<ConfirmProps> = memo(function ConfirmButton(props) {
    const { formatMessage } = useIntl();
    const text = formatMessage({ id: "app.button.confirm" });
    return (
        <Badge count={props.toConfirm}>
            <Button
                // type="primary"
                onClick={props.onConfirmClicked}
                icon={<CheckCircleOutlined />}
                disabled={props.toConfirm === 0}
            >
                <span className="Toolbar__LongLabel">{text}</span>
            </Button>
        </Badge>
    );
});

export interface PrioritySectionProps {
    // toSort?: number;
    // onSortClicked?: () => void;
    toMoveItems?: GenericRecord[];
    toMovePrev?: GenericRecord;
    onMoveUpClick?: (vs: GenericRecord[], prev: GenericRecord) => void;
    toMoveNext?: GenericRecord;
    onMoveDownClick?: (vs: GenericRecord[], next: GenericRecord) => void;
    onMoveOkClick?: () => void;
}

export const PrioritySection: FC<PrioritySectionProps> = memo(function PrioritySection(props) {
    const onMoveUp = () => {
        props.onMoveUpClick(props.toMoveItems, props.toMovePrev);
    };
    const onMoveDown = () => {
        props.onMoveDownClick(props.toMoveItems, props.toMoveNext);
    };

    return (
        <Space.Compact>
            <Button disabled={!props.toMovePrev} icon={<CaretUpOutlined />} onClick={onMoveUp} />
            <Button disabled={!props.toMoveNext} icon={<CaretDownOutlined />} onClick={onMoveDown} />
            <Button
                type="primary"
                disabled={props.toMoveItems.length === 0}
                icon={<CheckOutlined />}
                onClick={props.onMoveOkClick}
            />
            {/* <Button type="primary" onClick={props.onSortClicked} icon={<SwapOutlined />} disabled={props.toSort === 0}> */}
            {/*     Auto ({props.toSort}) */}
            {/* </Button> */}
        </Space.Compact>
    );
});
