import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React, { FC, useCallback, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { InvoiceItemForm } from "@inv/components/InvoiceFormLineItemsTable/components/InvoiceItemForm/InvoiceItemForm";
import { GQL } from "@binale-tech/shared";

export type ActionsCellRendererProps = { isDeleteDisabled: boolean };

export const ActionsCellRenderer: FC<ICellRendererParams<GQL.IInvoiceLineItem> & ActionsCellRendererProps> = ({
    api,
    data,
    node,
    isDeleteDisabled,
}) => {
    const [formValues, setFormValues] = useState<GQL.IInvoiceLineItem>();
    const onClose = useCallback(() => {
        setFormValues(null);
    }, []);
    const onSubmit = useCallback(
        (values: GQL.IInvoiceLineItem) => {
            node.setData(values);
            // trigger rowDataUpdated event
            setTimeout(() => api.applyTransaction({ update: [values] }), 50);
            onClose();
        },
        [api, node, onClose]
    );
    return (
        <Space>
            <Button icon={<EditOutlined />} type={"text"} shape={"circle"} onClick={() => setFormValues(data)} />
            <Button
                icon={<DeleteOutlined />}
                type={"text"}
                shape={"circle"}
                disabled={isDeleteDisabled}
                onClick={() => api.applyTransaction({ remove: [data] })}
            />
            {formValues && <InvoiceItemForm onClose={onClose} values={formValues} onSubmit={onSubmit} />}
        </Space>
    );
};
