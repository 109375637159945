import React, { FC, PropsWithChildren, useMemo } from "react";
import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/accountingData/RecordsCtx";
import { RecordsControlContext } from "scripts/context/accountingData/RecordsControlCtx";
import {
    TableViewContext,
    TableViewContextProvider,
    useDefaultYearPeriodFilters,
} from "../../../scripts/context/accountingTable/tableViewContext";
import { ProductLA } from "../../../scripts/core/Product";
import { RecordLogRepository } from "../../../scripts/infrastructure/repository/grRepositories";
import { GQL } from "@binale-tech/shared";

export const LAProvider: FC<PropsWithChildren> = ({ children }) => {
    const { companyGQL, yearConfig } = React.useContext(CompanyContext);
    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    const defaultFilters = useDefaultYearPeriodFilters();
    const product = useMemo(() => new ProductLA(yearConfig.skr, companyGQL), [yearConfig.skr, companyGQL]);

    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view: "laOverview",
            productKey: GQL.IProductKey.La,
            product,
            moduleRecords: allRecords.recordsLA.list || [],
            moduleActions: allActions.actionsLA,
            moduleLogLister: key => RecordLogRepository.LA.list({ path: [companyGQL.id, key] }),
        }),
        [allActions.actionsLA, allRecords.recordsLA.list, companyGQL.id, product]
    );
    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            {children}
        </TableViewContextProvider>
    );
};
