import dayjs from "dayjs";
import { CsvConverter } from "./CsvConverter";
import { ExportData, ExportPreprocessor } from "./ExportPreprocessor";
import { GenericRecordProperties, TableProperties } from "../../core/Product";
import { PDFMakeWrk } from "../../workers/pdf/PDFLibWrk";
import { downloadCsv } from "../../infrastructure/downloader";
import { logger } from "../../infrastructure/logger";
import { saveAs } from "file-saver";

export enum TableExportType {
    csv = "csv",
    pdf = "pdf",
}

export class Exporters {
    static readonly TYPE_CSV: TableExportType = TableExportType.csv;
    static readonly TYPE_PDF: TableExportType = TableExportType.pdf;

    static get preprocessor() {
        return ExportPreprocessor.getInstance();
    }

    static get builder() {
        return {
            createExporter: (type: TableExportType, options: FileExportOptions): AbstractExporter => {
                switch (type) {
                    case TableExportType.csv:
                        return new CsvExporter(options);
                    case TableExportType.pdf:
                        return new PdfExporter(options);
                }
            },
        };
    }
}

interface FileExportOptions {
    filename?: string;
    companyName?: string;
    title?: string;
    subTitle?: string;
}

interface IExporter {
    download(data: ExportData): void;
}

abstract class AbstractExporter implements IExporter {
    constructor(protected readonly options: FileExportOptions) {}

    abstract download(data: ExportData): void;
}

class CsvExporter extends AbstractExporter {
    protected readonly cmpDates = (a: string, b: string) => {
        const A = dayjs(a, "DD-MM-YYY");
        const B = dayjs(b, "DD-MM-YYY");
        if (A.isBefore(B)) {
            return -1;
        }
        if (A.isAfter(B)) {
            return 1;
        }
        return 0;
    };

    download(data: ExportData): void {
        let rows =
            data.rows instanceof Map
                ? Array.from(data.rows.values()).reduce((prev, cur) => prev.concat(cur), [])
                : data.rows;
        const creditorIdx = data.columnsOrder.indexOf(GenericRecordProperties.RecordCategoryCreditorNum);
        const datumIdx = data.columnsOrder.indexOf(GenericRecordProperties.RecordDatum);
        // filter out group row which are objects
        rows = rows.filter(row => row.length > 0 && typeof row[0] !== "object");
        logger.log("csv filter", rows);
        if (datumIdx !== -1 && creditorIdx !== -1) {
            rows.sort((a, b) => {
                return a[creditorIdx].localeCompare(b[creditorIdx]) || this.cmpDates(a[datumIdx], b[datumIdx]);
            });
        } else if (datumIdx !== -1) {
            rows.sort((a, b) => this.cmpDates(a[datumIdx], b[datumIdx]));
        } else if (creditorIdx !== -1) {
            rows.sort((a, b) => a[creditorIdx].localeCompare(b[creditorIdx]));
        }
        rows = fixIds(rows, data);
        const content = CsvConverter.getCsv([data.columns, ...rows]);
        downloadCsv(content, this.options.filename || "export.csv");
    }
}

function fixIds(flattenRows: string[][], data: ExportData) {
    const idIdx = data.columnsOrder.indexOf(TableProperties.ComputedNr);
    if (idIdx > -1) {
        flattenRows.forEach((row: string[], i) => {
            row[idIdx] = String(i + 1);
        });
    }
    return flattenRows;
}

class PdfExporter extends AbstractExporter {
    download(data: ExportData): void {
        const orientation: any = data.columns.length > 7 ? "LANDSCAPE" : "PORTRAIT";
        const contents: any = [
            {
                text: this.options.companyName || "",
                style: "companyName",
            },
            {
                columns: [
                    this.options.title || "",
                    {
                        text: dayjs().format("DD.MM.YYYY"),
                        alignment: "right",
                    },
                ],
                style: "title",
            },
            {
                text: this.options.subTitle || "",
                style: "subTitle",
            },
        ];
        // If groupped
        if (data.rows instanceof Map) {
            data.rows.forEach((rows, key) => {
                const groupHeader = new Array(data.columns.length).fill({});
                groupHeader[0] = {
                    text: key,
                    colSpan: data.columns.length,
                    style: "tableHeader",
                    fontSize: 10,
                };
                contents.push({
                    style: "table",
                    table: {
                        headerRows: 2,
                        widths: data.widths,
                        body: [
                            groupHeader,
                            data.columns.map((c, idx) => ({
                                text: c,
                                style: "tableHeader",
                            })),
                            ...rows,
                        ],
                    },
                });
            });
        } else {
            const rows = fixIds(data.rows, data);
            contents.push({
                style: "table",
                table: {
                    headerRows: 1,
                    widths: data.widths,
                    body: [
                        data.columns.map((c, idx) => ({
                            text: c,
                            style: "tableHeader",
                        })),
                        ...rows,
                    ],
                },
            });
        }
        PDFMakeWrk.createBlob({ export: { contents, orientation } })
            .then(res => {
                saveAs(res.blob, this.options.filename || "export.pdf");
            })
            .catch(logger.error);
    }
}
