import React, { FC, useContext } from "react";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { useProgramSettings } from "@app/components/recordform/hooks/useProgramSettings";
import { useFormConfig } from "@app/components/recordform/hooks/useFormConfig";
import { useFormHandlerDueDate } from "@app/components/recordform/hooks/handlers/useFormHandlerDueDate";

export const FormRecordDueDate: FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const { useDueDate } = useProgramSettings();
    const config = useFormConfig();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { editableRecord } = useContext(RecordFormStateContext);
    const onDueDateChange = useFormHandlerDueDate();

    if (!useDueDate) {
        return null;
    }
    if (!config.useFalligkeit) {
        return null;
    }
    const { recordFalligkeit } = editableRecord;

    return (
        <DatePicker
            style={{ width: 135, height: 30, marginBottom: 2, marginLeft: 10 }}
            ref={refsHtml.REF_rFALLIGKEIT}
            value={recordFalligkeit ? dayjs(recordFalligkeit) : null}
            showToday={false}
            disabled={isDisabled(refsHtml.REF_rFALLIGKEIT)}
            placeholder="Fälligkeit"
            defaultValue={null}
            onChange={onDueDateChange}
            tabIndex={-1}
            format={"DD.MM.YYYY"}
        />
    );
};
