import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    ExportOutlined,
    LoadingOutlined,
    SendOutlined
} from "@ant-design/icons";
import { DmsType } from "@dms/types";
import { Button, Popconfirm, Space } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { DmsPaths } from "@dms/configs/constants";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { DmsFibuExporter } from "@dms/components/DmsFibuExporter/DmsFibuExporter";
import { GQL } from "@binale-tech/shared";

interface IProps {
    documentData: GQL.IDocument[];
}

export const GroupButtons: FC<IProps> = ({ documentData }) => {
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const [isDownload, setIsDownload] = useState(false);

    const { dmsRecordsData, documents } = useContext(DmsDataContext);
    const mutator = useGqlMutator();

    const { selectedRowKeys, activeType } = useContext(DmsAppContext);
    const { deleteModeToggle, typeChangeModeToggle, setFocusedRow } = useContext(DmsAppControlContext);
    const navigate = useNavigate();

    const selectedDocuments = documentData.filter(el => selectedRowKeys.includes(el.key));

    const { type } = DmsUtils.getActiveTypeAndSubType(activeType);

    useEffect(() => {
        setIsDisabledBtn(false);
    }, [selectedRowKeys]);

    const handleDeleteBtn = async (): Promise<void | boolean> => {
        setFocusedRow(null);
        setIsDisabledBtn(true);
        if (type === DmsType.trash) {
            const foundDoc = selectedDocuments.some(el => {
                return dmsRecordsData.recordsAssetsSet.has(DocumentsApi.urlFormatter(el.fileUrl));
            });

            if (foundDoc) {
                return deleteModeToggle(true);
            }

            return DocumentsApi.removeDocuments(selectedDocuments, mutator);
        }

        await DocumentsApi.sendToTrash(selectedDocuments, mutator);

        setIsDisabledBtn(false);
    };

    const handleDownloadBtn = async (): Promise<void> => {
        setFocusedRow(null);
        const files = documents.filter(el => selectedRowKeys.includes(el.key));
        await DocumentsApi.bulkDownloadFiles(files, setIsDownload);
    };

    return (
        <Space>
            <Button
                disabled={isDisabledBtn}
                onClick={() => {
                    setFocusedRow(null);
                    const params = new URLSearchParams();
                    selectedRowKeys.forEach(key => params.append("key", String(key)));
                    navigate(`/${DmsPaths.ROOT}/${DmsPaths.EDITING_GROUP}?${params.toString()}`, {
                        state: { isGroup: true },
                    });
                }}
                type={"primary"}
                icon={<EditOutlined />}
            >
                <FormattedMessage id="app.button.edit" />({selectedRowKeys.length})
            </Button>
            <Button
                onClick={() => {
                    typeChangeModeToggle(true);
                    setFocusedRow(null);
                }}
                disabled={isDisabledBtn}
                type={"primary"}
                ghost
                icon={<SendOutlined />}
            >
                <FormattedMessage id="app.dms.moveTo" />({selectedRowKeys.length})
            </Button>
            <Popconfirm
                title={
                    <FormattedMessage
                        id={
                            type === DmsType.trash
                                ? "app.global.confirmRemoveDocument"
                                : "app.global.confirmSentToTrash"
                        }
                    />
                }
                destroyTooltipOnHide
                onConfirm={handleDeleteBtn}
                cancelButtonProps={{ autoFocus: true }}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <Button disabled={isDisabledBtn} danger icon={<DeleteOutlined />}>
                    <FormattedMessage id="app.button.delete" />({selectedRowKeys.length})
                </Button>
            </Popconfirm>
            <Button
                disabled={isDisabledBtn}
                onClick={handleDownloadBtn}
                type={"dashed"}
                icon={isDownload ? <LoadingOutlined /> : <DownloadOutlined />}
            >
                <FormattedMessage id="app.button.download" />({selectedRowKeys.length})
            </Button>

            <DmsFibuExporter documents={selectedDocuments}>
                <Button icon={<ExportOutlined />} type={"dashed"}>
                    FIBU
                </Button>
            </DmsFibuExporter>
        </Space>
    );
};
