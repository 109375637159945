import React, { memo } from "react";

import GenericTableViewCtxWrapper from "../productSharedComponents/GenericTableViewCtxWrapper";
import { GenericRecord } from "scripts/models/GenericRecord";
import { GenericRecordProperties, TableProperties } from "scripts/core/Product";
import { GenericRecordTableItem, TableItem, TableItemExtra } from "../../components/shared/Table/Table";
import { GenericTableView, GenericTableViewState } from "../productSharedComponents/GenericTableView";
import { GenericToolbar } from "../../components/toolbar/GenericToolbar";
import { GenericRecordForm } from "../../components/recordform/GenericRecordForm";
import { KassenbuchDomain } from "./domain/KassenbuchDomain";
import { RecordsTableBlock, TableConfigFooters, TableSum } from "../productSharedComponents/RecordsTableBlock";
import { logger } from "scripts/infrastructure/logger";

import { ColumnOverrides } from "../../columns/ColumnConfig";
import { Utils } from "@binale-tech/shared";
import "../../components/shared/Page.css";
import "./KBView.css";
import { KBProvider } from "./KBProvider";

export class KassenbuchViewClass<S extends GenericTableViewState> extends GenericTableView<S> {
    protected readonly domain = new KassenbuchDomain();

    protected getHeader(): React.ReactNode {
        return null;
    }

    protected getBlockTableView(h: number): React.ReactNode {
        const {
            onSort,
            sortColumn,
            canWrite,
            focusIndex,
            onSetFocus,
            product,
            view,
            tableRef,
            tableItems,
            selectedPeriodEditBound,
        } = this.getTableLegacyCommonProps();
        return (
            <RecordsTableBlock
                view={view}
                tableRef={tableRef}
                tableItems={tableItems}
                tableHeight={h}
                tableSaldoHeader={this.getSaldoHeader()}
                selectedPeriodEditBound={selectedPeriodEditBound}
                tableRowClassFunc={this.rowClassFunc}
                onSort={onSort}
                sortColumn={sortColumn}
                containerClassName="KassenbuchView__table"
                product={product}
                canWrite={canWrite}
                onPayments={() => {}}
                focusIndex={focusIndex}
                onSetFocus={onSetFocus}
                tableConfigFooters={this.tableConfigFooters}
                columnConfig={this.tableColumns}
                itemActions={{
                    handleEditItem: (v, e) => this.handleEditItem(v, e),
                    handleCopyItem: (v, e) => this.handleCopyItem(v, e),
                    handleDeleteItems: (v, e) => this.handleDeleteItems(v, e),
                    handleCancelItems: (v, e) => this.handleCancelItems(v, e),
                    handleUpdateItems: (v, e) => this.handleUpdateItems(v, e),
                    handleColorTableItems: (v, c) => this.handleColorTableItems(v, c),
                    handleAvisTableItems: (v, avis) => this.handleAvisTableItems(v, avis),
                    handleBulkEditItems: (v, e) => this.handleBulkEditItems(v, e),
                    handleReviewItems: (vs, review) => this.handleReviewItems(vs, review),
                }}
            />
        );
    }

    protected getSaldoHeader() {
        if (!this.state.saldoMap) {
            return 0;
        }
        const ys = this.state.saldoMap.get(this.props.year);
        if (!ys) {
            logger.log("getSaldoHeader error no year", this.props.year, this.state.saldoMap);
            return 0;
        }
        return ys.get(this.props.period);
    }
    protected getInlineForm(): React.ReactNode {
        return <GenericRecordForm {...this.getInlineFormProps()} />;
    }

    protected handleDeselect = () => {
        const tableItems = this.state.tableItems.map(v => (v.selected ? { ...v, selected: false } : v));
        this.handleUpdateItems(tableItems);
    };

    // protected handleSortWithinDay(vs: GenericRecord[]) {
    //     const finalList = this.domain.sortByBruttoWithinDay(vs);
    //     this.props.moduleActions.saveMany(finalList);
    // }

    protected getSelectedToMove() {
        return this.domain.getSelectedToMove(this.state.tableItems);
    }

    protected getSelectedToMovePrev(selectedToMove: TableItem<GenericRecord>[]) {
        return this.domain.getSelectedToMovePrev(this.state.tableItems, selectedToMove);
    }

    protected getSelectedToMoveNext(selectedToMove: TableItem<GenericRecord>[]): GenericRecord {
        return this.domain.getSelectedToMoveNext(this.state.tableItems, selectedToMove);
    }

    protected tableConfigFooters(
        tableItems: TableItemExtra<GenericRecord>[],
        tableSum: TableSum,
        saldoHeader?: number
    ): TableConfigFooters {
        return {
            [TableProperties.ComputedNr]: {
                mainFooter: tableItems.length.toString(),
                selectedFooter: tableItems.filter(i => i.selected).length.toString(),
            },
            [GenericRecordProperties.RecordBrutto]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.bruttoSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.bruttoSum),
            },
            [GenericRecordProperties.ComputedUSt]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.vatSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.vatSum),
            },
            [GenericRecordProperties.ComputedNetto]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.nettoSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.nettoSum),
            },
            [GenericRecordProperties.RecordCurrencyOriginalAmount]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.originalAmountSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.originalAmountSum),
            },
            [GenericRecordProperties.ComputedSaldo]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(
                    tableItems.length ? tableItems[tableItems.length - 1].extra.saldo : (saldoHeader ?? 0)
                ),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(
                    tableSum.selectedFooter.bruttoSum - tableSum.selectedFooter.nettoSum
                ),
            },
        };
    }

    protected tableConfigGetters() {
        const getters: ColumnOverrides<GenericRecord>["getters"] = {
            [GenericRecordProperties.ItemCategoryCreditorNum]: (
                tableItem: TableItem<GenericRecord>,
                kontoExt: number
            ) => tableItem.item.getItemCategoryCreditor().getExtNumPrint(kontoExt),
            [TableProperties.ControlLog]: (tableItem: GenericRecordTableItem) => this.getControlLogButton(tableItem),
            [TableProperties.ControlAction]: (tableItem: GenericRecordTableItem) => this.getActionButtons(tableItem),
            [GenericRecordProperties.ControlBinding]: (tableItem: GenericRecordTableItem) =>
                this.getRecordBinding(tableItem),
            [GenericRecordProperties.RecordReview]: (tableItem: GenericRecordTableItem) =>
                this.getRecordReview(tableItem, this.handleReviewItem(tableItem)),
        };
        return getters;
    }

    protected onJournal(vs: GenericRecord[]) {
        this.props.moduleActions.journal(vs.map(({ key }) => key));
    }

    protected getToolbarBlock() {
        const selectedToJournal = this.domain.getSelectedToJournal(
            this.state.tableItems,
            this.props.records,
            !this.props.product.getConfig().disableNegativeJournalSaldo
        );
        const selectedToConfirm = this.state.tableItems.filter(v => v.selected && v.item.draft);
        // const itemsToSort = this.domain.getItemsToSort(this.state.tableItems);
        let toMoveItems = this.getSelectedToMove();
        const toMovePrev = this.getSelectedToMovePrev(toMoveItems);
        const toMoveNext = this.getSelectedToMoveNext(toMoveItems);
        if (!toMoveNext && !toMovePrev) {
            toMoveItems = [];
        }
        return (
            <GenericToolbar
                withJournal
                toJournal={selectedToJournal.length}
                onJournalClicked={() => {
                    this.onJournal(selectedToJournal.map(v => v.item));
                    this.handleDeselect();
                }}
                withConfirm
                onConfirmClicked={() => this.onConfirm(selectedToConfirm.map(v => v.item.clone()))}
                toConfirm={selectedToConfirm.length}
                // toSort={itemsToSort.length}
                // onSortClicked={() => this.handleSortWithinDay(itemsToSort.map(v => v.item))}
                toMoveItems={toMoveItems.map(v => v.item)}
                toMovePrev={toMovePrev}
                toMoveNext={toMoveNext}
                onMoveOkClick={this.handleDeselect}
                tableColumns={this.tableColumns}
                showRemoved={this.state.showCancelled}
                onToggleHidden={this.onToggleHidden}
                withKontenAnsicht
                categoryCreditorMode={this.props.product.getConfig().itemAccountMode}
            />
        );
    }

    render() {
        return this.getTablePage({ useInlineForm: true, useLeftTable: true });
    }
}

export const KBView: React.FC = memo(function KBView() {
    return (
        <KBProvider>
            <GenericTableViewCtxWrapper Component={KassenbuchViewClass} />
        </KBProvider>
    );
});
