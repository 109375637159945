import { ContextType, useCallback, useContext, useEffect, useRef } from "react";
import RecordFormState from "../types/RecordFormState";
import { GenericRecord } from "../../../../scripts/models";
import { PaymentUtils } from "../../../../scripts/models/utils/PaymentUtils";
import { BuMNF, GQL } from "@binale-tech/shared";
import { CompanyContext } from "scripts/context/CompanyContext";
import { PaymentsContext } from "scripts/context/accountingData/PaymentsProvider";
import { TableViewContext } from "../../../../scripts/context/accountingTable/tableViewContext";
import { useFormRecordComposer } from "./useFormRecordComposer";

export const useUpdatesOnPaymentConnection = () => {
    const { product } = useContext(TableViewContext);
    const { yearConfig } = useContext(CompanyContext);
    const payments = useContext(PaymentsContext);
    const { composeFormRecord } = useFormRecordComposer();

    const paymentsRecordRelationRef = useRef<ContextType<typeof PaymentsContext>["recordRelation"]>(null);
    const composeRecordRef = useRef<typeof composeFormRecord>(null);
    useEffect(() => {
        paymentsRecordRelationRef.current = payments.recordRelation;
    }, [payments.recordRelation]);
    useEffect(() => {
        composeRecordRef.current = composeFormRecord;
    }, [composeFormRecord]);

    return useCallback(
        (
            editableRecord: RecordFormState["editableRecord"],
            editableRecordItem: RecordFormState["editableRecordItem"],
            paymentSourceRecord?: GenericRecord
        ) => {
            const updatesRecord: Partial<
                Pick<
                    RecordFormState["editableRecord"],
                    "recordContact" | "recordDocuments" | "recordBrutto" | "recordNum"
                >
            > = {};
            const updatesRecordItem: Partial<
                Pick<RecordFormState["editableRecordItem"], "itemText" | "itemBelegfeld1" | "itemBrutto" | "itemBu">
            > = {};
            if (!paymentSourceRecord) {
                return { updatesRecord, updatesRecordItem };
            }

            // editableRecord updates
            if (!editableRecord.recordContact) {
                updatesRecord.recordContact = paymentSourceRecord.partner;
            }
            if (!editableRecord.recordDocuments?.length) {
                updatesRecord.recordDocuments = paymentSourceRecord.documents;
            }

            // editableRecordItem updates
            if (!editableRecordItem.itemText) {
                updatesRecordItem.itemText = paymentSourceRecord.getRecordCategoryCreditor()?.name;
            }
            if (!editableRecordItem.itemBelegfeld1) {
                updatesRecordItem.itemBelegfeld1 = paymentSourceRecord.num;
            }
            const bu = paymentSourceRecord.items[0].bu;
            if (
                BuMNF.isMNFLogicForBu(yearConfig, bu, {
                    extNum: paymentSourceRecord.getRecordCategoryCreditor(),
                })
            ) {
                updatesRecordItem.itemBu = bu;
            }

            // brutto updates (record + item)
            if (!editableRecord.recordBrutto) {
                const brutto = PaymentUtils.getOpenBruttoFromSourceForPaymentRepresentationRecord(
                    composeRecordRef.current(),
                    product.productKey() as GQL.IProductKey,
                    paymentSourceRecord,
                    paymentsRecordRelationRef.current
                );
                updatesRecord.recordBrutto = brutto;
                updatesRecordItem.itemBrutto = brutto;
            }
            return { updatesRecord, updatesRecordItem };
        },
        [product, yearConfig]
    );
};
