import React, { FC, useContext, useEffect, useState } from "react";
import { PdfSingleDocumentViewer } from "@ui-components/PdfViewer/PdfSingleDocumentViewer";
import { useGenerateInvoicePdf } from "@inv/hooks/useGenerateInvoicePdf";
import { useApolloClient } from "@apollo/client";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { useInvoiceGoBL } from "@inv/hooks/useInvoiceGoBL";
import { Button, Flex, Space, Spin, Tooltip } from "antd";
import { ExportOutlined, FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { saveAs } from "file-saver";
import { createXRechnungPdfData } from "@inv/scripts/utils/createXRechnungPdfData";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DocumentsApi } from "@dms/scripts/DocumentsApi";
import { DmsType } from "@dms/types";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { XRechnungValidationModal } from "@app/components/shared/XRechnung/XRechnungValidationModal";
import { XRechnungXmlPreviewComponent } from "@app/components/shared/XRechnung/XRechnungXmlPreviewComponent";

interface IProps {
    invoiceId: string;
}

export const InvoiceViewer: FC<IProps> = ({ invoiceId }) => {
    const client = useApolloClient();
    const [isExportingDms, setIsExportingDms] = useState(false);
    const [isErrorsModalOpen, setIsErrorsModalOpen] = useState(false);
    const mutator = useGqlMutator();
    const { companyGQL } = useContext(CompanyContext);
    const { invoicesMap } = useContext(InvoicesDataContext);
    const { documentsKV } = useContext(DmsDataContext);
    const invoice = invoicesMap.get(invoiceId);
    const [documentData, setDocumentData] = useState<Awaited<ReturnType<typeof createXRechnungPdfData>>>(null);
    const hashExists = documentData && documentData.hash in documentsKV;
    const createInvoicePdf = useGenerateInvoicePdf(invoiceId);
    const data = useInvoiceGoBL(invoiceId);

    const createPdf = async () => {
        console.log("createPdf", JSON.stringify(data));
        if (documentData) {
            console.log("documentData", documentData);
            return;
        }
        const pdfBuffer = await createInvoicePdf();
        const docData = await createXRechnungPdfData(client, { companyId: companyGQL.id, invoiceId }, pdfBuffer, data);
        setDocumentData(docData);
    };

    useEffect(() => {
        if (!data) {
            return;
        }
        createPdf();
    }, [data, invoiceId]);

    const handleDmsExport = () => {
        const { file, hash } = documentData;
        setIsExportingDms(true);
        DocumentsApi.createDocument({
            fileUploadData: {
                fileData: { file, isUploaded: false, hash },
                companyData: companyGQL,
                documentType: { type: DmsType.new_documents },
            },
            mutator,
            setFileStatus: () => {},
        }).finally(() => setIsExportingDms(false));
    };

    return (
        <Flex style={{ height: "100%", width: "100%" }} vertical>
            <Flex style={{ marginBottom: 5 }} justify={"space-between"}>
                <Space>
                    <Button
                        disabled={!documentData}
                        onClick={() => saveAs(documentData.file)}
                        icon={<FilePdfOutlined />}
                    >
                        <FormattedMessage id={"app.button.download"} />
                        PDF
                    </Button>
                    <Tooltip title={"PDF mit E-Rechnung (ZUGFeRD 2.2 / Factur-X)"}>
                        <Button icon={<InfoCircleOutlined />} type={"text"} shape={"circle"} />
                    </Tooltip>
                </Space>

                <Button
                    loading={isExportingDms}
                    icon={<ExportOutlined />}
                    type="primary"
                    disabled={!documentData || !invoice.isConfirmed || hashExists}
                    onClick={handleDmsExport}
                >
                    <FormattedMessage id="app.button.send_to_dms" />
                </Button>

                <XRechnungXmlPreviewComponent
                    xml={documentData?.xmlString}
                    isValidating={!documentData}
                    validationErrors={documentData?.errors}
                    onOpenErrors={() => setIsErrorsModalOpen(true)}
                    filename={documentData?.filename}
                />
            </Flex>
            {documentData ? (
                <PdfSingleDocumentViewer
                    url={documentData.url}
                    type={documentData.type}
                    fileName={documentData.filename}
                />
            ) : (
                <Spin />
            )}

            <XRechnungValidationModal
                open={isErrorsModalOpen}
                onCancel={() => setIsErrorsModalOpen(false)}
                errors={documentData?.errors}
            />
        </Flex>
    );
};
