import dayjs from "dayjs";
import { PDFPageProxy, RenderParameters } from "pdfjs-dist/types/src/display/api";

import { OcrDocument, OcrDocumentViewModel, OcrLoadedFile, OcrPage } from "../../types";
import { PDFLibWrk } from "../../../scripts/workers/pdf/PDFLibWrk";
import { fileToArrayBuffer } from "./helpers";
import { logger } from "../../../scripts/infrastructure/logger";
import { saveAs } from "file-saver";

export const renderPdfPage = async (page: PDFPageProxy, dpi: number) => {
    const viewport = page.getViewport({ scale: 1 });
    const PRINT_UNITS = dpi / 72.0;
    const transform = [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0];
    const canvas = document.createElement("canvas");
    const canvasContext = canvas.getContext("2d");
    const renderContext: RenderParameters = { canvasContext, viewport, transform };
    canvas.height = viewport.height * PRINT_UNITS;
    canvas.width = viewport.width * PRINT_UNITS;
    await page.render(renderContext).promise;
    return canvas;
};

export const getFilename = (arg: {
    docId: string;
    files: OcrLoadedFile[];
    documents: OcrDocument[];
    pages: OcrPage[];
}) => {
    const { docId, files, documents, pages } = arg;
    const doc = documents.find(p => p.id === docId);

    if (doc && doc.name) {
        return doc.name.replace(".pdf", "");
    }

    const firstPage = pages.find(p => p.id === doc.pageIds[0]);
    const file = files.find(p => p.id === firstPage.fileId);

    return file ? file.fileName.replace(".pdf", "") : "";
};

const reduceDocuments = (arg: {
    viewDocuments: OcrDocumentViewModel[];
    files: OcrLoadedFile[];
    documents: OcrDocument[];
    pages: OcrPage[];
    documentId?: string;
}) => {
    const { viewDocuments, files, documents, pages, documentId } = arg;
    const firstFileName = files[0].fileName.replace(".pdf", "");

    const docsToDownload = viewDocuments
        .map((doc, docIdx) => {
            if (documentId && doc.id !== documentId) {
                return null;
            }
            const downloadPages = doc.pages
                .filter(page => !page.deleted)
                .map(page => ({
                    index: page.filePageIndex,
                    fileId: page.fileId,
                    rotate: page.rotate,
                }));

            const fileName = getFilename({ docId: doc.id, files, documents, pages });
            return doc.visible
                ? {
                      pages: downloadPages,
                      name: fileName ? `${fileName}.pdf` : `${firstFileName}.${docIdx + 1}.pdf`,
                  }
                : null;
        })
        .filter(Boolean);

    const downloadFiles: Record<string, Uint8Array> = {};

    files.forEach(file => {
        downloadFiles[file.id] = file.uint8Array;
    });

    return { docsToDownload, downloadFiles };
};

export const downloadDocuments = async (arg: {
    viewDocuments: OcrDocumentViewModel[];
    files: OcrLoadedFile[];
    documents: OcrDocument[];
    pages: OcrPage[];
    documentId?: string;
    ignoreEncryption: boolean;
}) => {
    const { viewDocuments, files, documentId, documents, pages, ignoreEncryption } = arg;
    const { docsToDownload, downloadFiles } = reduceDocuments({ viewDocuments, files, pages, documents, documentId });

    const result = await PDFLibWrk.createBlob({
        files: downloadFiles,
        documents: docsToDownload,
        ignoreEncryption,
    });

    if (Array.isArray(result)) {
        throw new Error("Method PDFLibWrk.createBlob return array");
    }

    const filename =
        docsToDownload.length === 1 && result.fileExt === "pdf"
            ? docsToDownload[0].name
            : dayjs().format("YYYY.MM.DD_HH.mm") + "_Seitentrennung.zip";

    saveAs(result.blob, filename);
};

export const exportDocuments = async (arg: {
    viewDocuments: OcrDocumentViewModel[];
    files: OcrLoadedFile[];
    documents: OcrDocument[];
    pages: OcrPage[];
    documentId?: string;
    ignoreEncryption: boolean;
}) => {
    const { viewDocuments, files, documents, pages, documentId, ignoreEncryption } = arg;
    const { docsToDownload, downloadFiles } = reduceDocuments({ viewDocuments, files, pages, documents, documentId });

    const result = await PDFLibWrk.createBlob({
        files: downloadFiles,
        documents: docsToDownload,
        ignoreEncryption,
        isForExport: true,
    });

    if (!Array.isArray(result)) {
        return [result];
    }

    return result;
};

export const isFileEncrypted = async (file: File): Promise<boolean> => {
    try {
        const arrayBuffer = await fileToArrayBuffer(file);
        const uint8Array = new Uint8Array(arrayBuffer);
        await PDFLibWrk.createBlob({
            files: {
                id: uint8Array,
            },
            documents: [
                {
                    pages: [
                        {
                            index: 0,
                            fileId: "id",
                            rotate: 0,
                        },
                    ],
                    name: "test",
                },
            ],
            ignoreEncryption: false,
        });
    } catch (e) {
        logger.error("File is probably encrypted:", e);
        return true;
    }

    return false;
};
