import React, {
    ContextType,
    type FC,
    type PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";

import { AppConfigUtils } from "@dms/scripts/utils/AppConfigUtils";
import { ITableColumns, TFilterConfigItem } from "@dms/types";
import { DmsDataContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { UserContext } from "../../../scripts/context/UserProvider";
import ProgramSettings from "../../../scripts/models/ProgramSettings";

/**
 Context Provider for User config state
 */
export const DmsUserProvider: FC<PropsWithChildren> = ({ children }) => {
    const [dmsTableEnabledColumns, setDmsTableEnabledColumns] = useState<
        ContextType<typeof DmsUserSettingsContext>["userConfig"]["enabledColumns"]
    >(new Map());
    const [filterConfig, setFilterConfig] = useState<
        ContextType<typeof DmsUserSettingsContext>["userConfig"]["filterConfig"]
    >(new Map());
    const [isTableSize, setIsTableSize] = useState<boolean>(false);
    const { documentTypes } = useContext(DmsDataContext);
    const { companyGQL } = useContext(CompanyContext);
    const { fireUser } = useContext(UserContext);

    // on mount/documentsType re-fetch column config and merge it with default
    useEffect(() => {
        if (!companyGQL?.id || !fireUser?.uid || documentTypes?.length === 0) {
            return;
        }

        ProgramSettings.getDmsConfig({
            userId: fireUser.uid,
            companyId: companyGQL.id,
        }).then(res => {
            const concatColumnConf = AppConfigUtils.concatColumnConfig(documentTypes, res.enabledColumns);
            setDmsTableEnabledColumns(concatColumnConf);
        });
    }, [companyGQL?.id, documentTypes, fireUser?.uid]);

    useEffect(() => {
        const handleResize = () => {
            setIsTableSize(document.documentElement.clientWidth < 1401);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const onSetFilterConfig = useCallback(
        async (typeKey: string[], configData: TFilterConfigItem) => {
            if (!companyGQL?.id || !fireUser?.uid) {
                return;
            }

            const configKey = typeKey.toString();

            setFilterConfig(prev => {
                const typeConfig = prev.get(configKey);
                prev.set(configKey, JSON.parse(JSON.stringify({ ...typeConfig, ...configData })));

                const paginationConfig = new Map();

                prev.forEach((value, key) => {
                    if (value.paginationState) {
                        paginationConfig.set(key, {
                            paginationState: {
                                pageSize: value.paginationState.pageSize,
                                currentPage: value.paginationState.currentPage,
                            },
                        });
                    }
                });

                // console.log("setFilterConfig", typeKey, configData, new Map(prev));
                // ProgramSettings.setDmsPaginationConfig(keys, Object.fromEntries(paginationConfig));
                return new Map(prev);
            });
        },
        [companyGQL?.id, fireUser?.uid]
    );

    const onUpdateDmsEnabledColumns = useCallback(
        (typeKey: string[], enabledColumns: (keyof ITableColumns)[]) => {
            const configKey = typeKey.toString();
            const keys = {
                userId: fireUser.uid,
                companyId: companyGQL.id,
            };
            ProgramSettings.updateDmsEnabledColumns(keys, configKey, enabledColumns);
            setDmsTableEnabledColumns(prev => {
                prev.set(configKey, enabledColumns);
                return new Map(prev);
            });
        },
        [fireUser?.uid, companyGQL?.id]
    );

    const deleteTypeConfig = useCallback((typeKey: string[]) => {
        const configKey = typeKey.toString();
        setFilterConfig(prev => {
            prev.delete(configKey);
            return new Map(prev);
        });
        setDmsTableEnabledColumns(prev => {
            prev.delete(configKey);
            return new Map(prev);
        });
    }, []);

    const value: ContextType<typeof DmsUserSettingsContext> = useMemo(
        () => ({
            userConfig: { enabledColumns: dmsTableEnabledColumns, filterConfig },
            setTableEnabledColumns: onUpdateDmsEnabledColumns,
            setFilterConfig: onSetFilterConfig,
            deleteTypeConfig,
            isTableSize,
        }),
        [
            dmsTableEnabledColumns,
            deleteTypeConfig,
            filterConfig,
            isTableSize,
            onUpdateDmsEnabledColumns,
            onSetFilterConfig,
        ]
    );

    return <DmsUserSettingsContext.Provider value={value}>{children}</DmsUserSettingsContext.Provider>;
};
