import { parseSearchAmount } from "@dms/scripts/helpers/currencyFormatter";
import { GQL } from "@binale-tech/shared";

const searchedDocumentField: (keyof GQL.IDocument)[] = [
    "fileName",
    "documentAmount",
    "originalAmount",
    "partner",
    "documentDate",
    "documentNumber",
    "UStIdNr",
    "landCode",
    "description",
];
const idRegExp: RegExp = /[a-f\d]{64}/i;
export const documentSearchPredicate = (doc: GQL.IDocument, term: string): boolean => {
    term = term.toLowerCase();
    const combinedValue = `${doc.landCode ?? ""}${doc.UStIdNr ?? ""}`;

    if (combinedValue.toLowerCase().includes(term)) {
        return true;
    }

    const arr = Object.entries(doc).filter(i => {
        const [key, value] = i as [keyof GQL.IDocument, any];
        if (key === "key" && idRegExp.test(term)) {
            return value.toLowerCase() === term;
        }

        if (!searchedDocumentField.includes(key)) {
            return false;
        }

        if (key === "partner") {
            return typeof value.name === "string" && value.name.toLowerCase().includes(term);
        }

        if (key === "documentAmount" || key === "originalAmount") {
            return (
                typeof (value / 100) === "number" &&
                (value / 100).toString().includes(parseSearchAmount(term).toString())
            );
        }

        return typeof value === "string" && value.toLowerCase().includes(term);
    });

    return arr.length !== 0;
};
