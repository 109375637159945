import React, { type FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, Flex, Space, Typography } from "antd";
import { FileUploaderContext, FileUploaderControlContext } from "../../context/FileUploaderContext";
import { FormattedMessage } from "react-intl";
import { FilesSameHashItem } from "./FilesSameHashItem";
import { TUploadFile, UploadFileStatus } from "@dms/types";

export const FilesSameHash: FC = () => {
    const [processedFiles, setProcessedFiles] = useState<Record<string, number>>({});

    const { deleteAllNotUniq, confirmChoiceNotUniq, removeNotUniqGroup } = useContext(FileUploaderControlContext);
    const { allFilesInfo } = useContext(FileUploaderContext);

    const notUniqueFiles = useMemo(
        () => allFilesInfo.filter(el => el.status === UploadFileStatus.NOT_UNIQ),
        [allFilesInfo]
    );

    const sameHashFilesMap = useMemo(() => {
        if (!notUniqueFiles.length) {
            return null;
        }

        const obj: Record<string, TUploadFile[]> = {};
        notUniqueFiles.forEach(el => {
            if (!obj[el.hash]) {
                obj[el.hash] = [];
            }
            obj[el.hash].push(el);
        });

        return obj;
    }, [notUniqueFiles]);

    useEffect(() => {
        setProcessedFiles(prev => {
            const copyPrev = { ...prev };
            Object.keys(sameHashFilesMap || {}).forEach(key => {
                if (prev[key]) {
                    return;
                }

                copyPrev[key] = 0;
            });

            return copyPrev;
        });
    }, [sameHashFilesMap]);

    const handleSelectNotUniqFiles = (key: string, idx: number) => {
        if (idx === -1) {
            removeNotUniqGroup(key, sameHashFilesMap);

            return setProcessedFiles(prev => {
                const copyPrev = { ...prev };
                delete copyPrev[key];
                return { ...copyPrev };
            });
        }

        setProcessedFiles(prev => {
            return { ...prev, [key]: idx };
        });
    };

    const handleConfirm = () => {
        confirmChoiceNotUniq(processedFiles, sameHashFilesMap);
        setProcessedFiles({});
    };

    const handleDeleteAll = () => {
        deleteAllNotUniq(processedFiles, sameHashFilesMap);
        setProcessedFiles({});
    };

    if (!sameHashFilesMap) {
        return null;
    }

    return (
        <div>
            <Divider />
            <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text type={"secondary"}>
                    <FormattedMessage id="app.dms.identicalFiles" />
                </Typography.Text>
                {Object.keys(sameHashFilesMap).map(hash => {
                    return (
                        <FilesSameHashItem
                            key={hash}
                            hash={hash}
                            files={sameHashFilesMap ? sameHashFilesMap[hash] : []}
                            handleSelectNotUniqFiles={handleSelectNotUniqFiles}
                        />
                    );
                })}
                <Flex justify={"flex-end"} gap={10} style={{ width: "100%" }}>
                    <Button type={"primary"} onClick={handleConfirm}>
                        <FormattedMessage id="app.dms.confirmResolving" />
                    </Button>
                    <Button onClick={handleDeleteAll}>
                        <FormattedMessage id="app.dms.deleteAll" />
                    </Button>
                </Flex>
            </Space>
            <Divider />
        </div>
    );
};
