import React from "react";
import { GQL } from "@binale-tech/shared";
import { GenericRecord } from "scripts/models/GenericRecord";
import { PaymentPrototype } from "../../models/Payment";
import { preprocessPriorities } from "./utils/recordControlUtils";
import { useRecordsSaver } from "./hooks/useRecordsSaver";

export interface RecordActions {
    save: (v: GenericRecord, payment?: PaymentPrototype) => Promise<GQL.ISaveSummary>;
    saveTemplate: (v: GenericRecord) => Promise<GQL.ISaveSummary>;
    saveDocuments: (id: string, documents: GQL.IRecordDocumentInput[]) => Promise<GQL.ISaveSummary>;
    importMany: (v: GenericRecord[], draft?: boolean) => Promise<any>;
    confirm: (v: string[]) => Promise<void>;
    journal: (v: string[]) => Promise<void>;
    avis: (v: string[], avis: boolean) => Promise<void>;
    color: (v: string[], color: string | null) => Promise<void>;
    review: (v: string[], review: GenericRecord["review"] | null) => Promise<void>;
    deleteByKeys: (v: string[]) => Promise<GQL.ISaveSummary>;
    deleteTemplates: (v: string[]) => Promise<GQL.ISaveSummary>;
    cancelByKeys: (v: string[]) => Promise<GQL.ISaveSummary>;
    // methods to make unified signature for all modules
    sinkFirstDown: (v: GenericRecord[]) => Promise<void>;
    swimLastUp: (v: GenericRecord[]) => Promise<void>;
}
const blackholeActions: RecordActions = {
    save: () => Promise.resolve({ status: true, summary: [] }),
    saveTemplate: () => Promise.resolve({ status: true, summary: [] }),
    saveDocuments: () => Promise.resolve({ status: true, summary: [] }),
    deleteByKeys: () => Promise.resolve({ status: true, summary: [] }),
    deleteTemplates: () => Promise.resolve({ status: true, summary: [] }),
    cancelByKeys: () => Promise.resolve({ status: true, summary: [] }),
    importMany: () => Promise.resolve(),
    confirm: () => Promise.resolve(),
    journal: () => Promise.resolve(),
    avis: () => Promise.resolve(),
    color: () => Promise.resolve(),
    review: () => Promise.resolve(),
    sinkFirstDown: () => Promise.resolve(),
    swimLastUp: () => Promise.resolve(),
};

interface GroupedRecordActions {
    save: (v: GenericRecord, payment: PaymentPrototype, groupId: string) => Promise<GQL.ISaveSummary>;
    saveTemplate: (v: GenericRecord, groupId: string) => Promise<GQL.ISaveSummary>;
    saveDocuments: (id: string, documents: GQL.IRecordDocumentInput[], groupId: string) => Promise<GQL.ISaveSummary>;
    deleteByKeys: (v: string[], groupId: string) => Promise<GQL.ISaveSummary>;
    deleteTemplates: (v: string[], groupId: string) => Promise<GQL.ISaveSummary>;
    cancelByKeys: (v: string[], groupId: string) => Promise<GQL.ISaveSummary>;
    importMany: (v: GenericRecord[], draft: boolean, groupId: string) => Promise<void>;
    confirm: (v: string[], groupId: string) => Promise<void>;
    journal: (v: string[], groupId: string) => Promise<void>;
    avis: (v: string[], avis: boolean, groupId: string) => Promise<void>;
    color: (v: string[], color: string | null, groupId: string) => Promise<void>;
    review: (v: string[], review: GenericRecord["review"] | null, groupId: string) => Promise<void>;
    sinkFirstDown: (v: GenericRecord[], groupId: string) => Promise<void>;
    swimLastUp: (v: GenericRecord[], groupId: string) => Promise<void>;
}

export type RecordsController = {
    actionsER: RecordActions;
    actionsAZ: RecordActions;
    actionsFE: RecordActions;
    actionsPOS: RecordActions;
    actionsLA: RecordActions;
    actionsDeb: RecordActions;
    actionsBank: GroupedRecordActions;
    actionsKB: GroupedRecordActions;

    blackhole: RecordActions;
};

export const convertGroupActions = (groupActions: GroupedRecordActions, groupId: string): RecordActions => ({
    save: (v, payment) => groupActions.save(v, payment, groupId),
    saveTemplate: v => groupActions.saveTemplate(v, groupId),
    saveDocuments: (id, docs) => groupActions.saveDocuments(id, docs, groupId),
    importMany: (v, draft) => groupActions.importMany(v, draft, groupId),
    deleteByKeys: v => groupActions.deleteByKeys(v, groupId),
    deleteTemplates: v => groupActions.deleteTemplates(v, groupId),
    cancelByKeys: v => groupActions.cancelByKeys(v, groupId),
    confirm: v => groupActions.confirm(v, groupId),
    journal: v => groupActions.journal(v, groupId),
    avis: (v, avis) => groupActions.avis(v, avis, groupId),
    color: (v, color) => groupActions.color(v, color, groupId),
    review: (v, review) => groupActions.review(v, review, groupId),
    sinkFirstDown: v => groupActions.sinkFirstDown(v, groupId),
    swimLastUp: v => groupActions.swimLastUp(v, groupId),
});

const defaultActions: RecordActions = {
    ...blackholeActions,
    save: () => Promise.reject(),
    importMany: () => Promise.reject(),
    deleteByKeys: () => Promise.reject(),
};

const defaultGroupedActions: GroupedRecordActions = {
    save: (v: GenericRecord, payment: PaymentPrototype, groupId: string) => Promise.reject(),
    saveTemplate: (v: GenericRecord, groupId: string) => Promise.reject(),
    saveDocuments: (id: string, docs: GQL.IRecordDocumentInput[], groupId: string) => Promise.reject(),
    importMany: (v: GenericRecord[], draft: boolean, groupId: string) => Promise.reject(),
    deleteByKeys: (v: string[], groupId: string) => Promise.reject(),
    deleteTemplates: (v: string[], groupId: string) => Promise.reject(),
    cancelByKeys: (v: string[], groupId: string) => Promise.reject(),
    confirm: (v: string[], groupId: string) => Promise.reject(),
    journal: (v: string[], groupId: string) => Promise.reject(),
    avis: (v: string[], avis: boolean, groupId: string) => Promise.reject(),
    color: (v: string[], color: string | null, groupId: string) => Promise.reject(),
    review: (v: string[], review: GenericRecord["review"] | null, groupId: string) => Promise.reject(),
    sinkFirstDown: (v: GenericRecord[], groupId: string) => Promise.reject("not implemented"),
    swimLastUp: (v: GenericRecord[], groupId: string) => Promise.reject("not implemented"),
};

export const RecordsControlContext = React.createContext<RecordsController>({
    actionsER: defaultActions,
    actionsAZ: defaultActions,
    actionsFE: defaultActions,
    actionsPOS: defaultActions,
    actionsLA: defaultActions,
    actionsDeb: defaultActions,
    actionsKB: defaultGroupedActions,
    actionsBank: defaultGroupedActions,
    blackhole: defaultActions,
});

const getController = (recordSaver: ReturnType<typeof useRecordsSaver>): RecordsController => {
    return {
        actionsER: {
            ...blackholeActions,
            save: record => recordSaver.save(record, null, GQL.IProductKey.Er),
            saveTemplate: record => recordSaver.save(record, null, GQL.IProductKey.Er, { isTemplate: true }),
            saveDocuments: (id, docs) => recordSaver.saveDocuments(id, GQL.IProductKey.Er, docs),
            deleteByKeys: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.Er),
            deleteTemplates: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.Er, { isTemplate: true }),
            cancelByKeys: ids => recordSaver.cancelByKeys(ids, GQL.IProductKey.Er),
            confirm: ids => recordSaver.confirm(ids, GQL.IProductKey.Er),
            journal: ids => recordSaver.journal(ids, GQL.IProductKey.Er),
            avis: (ids, avis) => recordSaver.avis(ids, GQL.IProductKey.Er, avis),
            color: (ids, color) => recordSaver.color(ids, GQL.IProductKey.Er, color),
            review: (ids, review) => recordSaver.review(ids, GQL.IProductKey.Er, review),
            importMany: (records: GenericRecord[], draft?: boolean) =>
                recordSaver.importMany(records, GQL.IProductKey.Er, draft),
        },
        actionsAZ: {
            ...blackholeActions,
            save: record => recordSaver.save(record, null, GQL.IProductKey.ErA),
            saveTemplate: record => recordSaver.save(record, null, GQL.IProductKey.ErA, { isTemplate: true }),
            saveDocuments: (id, docs) => recordSaver.saveDocuments(id, GQL.IProductKey.ErA, docs),
            deleteByKeys: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.ErA),
            deleteTemplates: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.ErA, { isTemplate: true }),
            cancelByKeys: ids => recordSaver.cancelByKeys(ids, GQL.IProductKey.ErA),
            confirm: ids => recordSaver.confirm(ids, GQL.IProductKey.ErA),
            journal: ids => recordSaver.journal(ids, GQL.IProductKey.ErA),
            avis: (ids, avis) => recordSaver.avis(ids, GQL.IProductKey.ErA, avis),
            color: (ids, color) => recordSaver.color(ids, GQL.IProductKey.ErA, color),
            review: (ids, review) => recordSaver.review(ids, GQL.IProductKey.ErA, review),
            importMany: (records, draft) => recordSaver.importMany(records, GQL.IProductKey.ErA, draft),
        },
        actionsFE: {
            ...blackholeActions,
            save: (record, payment) => recordSaver.save(record, payment, GQL.IProductKey.Fe),
            saveTemplate: record => recordSaver.save(record, null, GQL.IProductKey.Fe, { isTemplate: true }),
            saveDocuments: (id, docs) => recordSaver.saveDocuments(id, GQL.IProductKey.Fe, docs),
            deleteByKeys: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.Fe),
            deleteTemplates: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.Fe, { isTemplate: true }),
            cancelByKeys: ids => recordSaver.cancelByKeys(ids, GQL.IProductKey.Fe),
            confirm: ids => recordSaver.confirm(ids, GQL.IProductKey.Fe),
            journal: ids => recordSaver.journal(ids, GQL.IProductKey.Fe),
            avis: (ids, avis) => recordSaver.avis(ids, GQL.IProductKey.Fe, avis),
            color: (ids, color) => recordSaver.color(ids, GQL.IProductKey.Fe, color),
            review: (ids, review) => recordSaver.review(ids, GQL.IProductKey.Fe, review),
            importMany: (records, draft) => recordSaver.importMany(records, GQL.IProductKey.Fe, draft),
        },
        actionsPOS: {
            ...blackholeActions,
            save: record => recordSaver.save(record, null, GQL.IProductKey.Pos),
            saveTemplate: record => recordSaver.save(record, null, GQL.IProductKey.Pos, { isTemplate: true }),
            saveDocuments: (id, docs) => recordSaver.saveDocuments(id, GQL.IProductKey.Pos, docs),
            deleteByKeys: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.Pos),
            deleteTemplates: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.Pos, { isTemplate: true }),
            cancelByKeys: ids => recordSaver.cancelByKeys(ids, GQL.IProductKey.Pos),
            confirm: ids => recordSaver.confirm(ids, GQL.IProductKey.Pos),
            journal: ids => recordSaver.journal(ids, GQL.IProductKey.Pos),
            avis: (ids, avis) => recordSaver.avis(ids, GQL.IProductKey.Pos, avis),
            color: (ids, color) => recordSaver.color(ids, GQL.IProductKey.Pos, color),
            review: (ids, review) => recordSaver.review(ids, GQL.IProductKey.Pos, review),
            importMany: (records, draft) => recordSaver.importMany(records, GQL.IProductKey.Pos, draft),
        },
        actionsLA: {
            ...blackholeActions,
            save: record => recordSaver.save(record, null, GQL.IProductKey.La),
            saveTemplate: record => recordSaver.save(record, null, GQL.IProductKey.La, { isTemplate: true }),
            saveDocuments: (id, docs) => recordSaver.saveDocuments(id, GQL.IProductKey.La, docs),
            deleteByKeys: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.La),
            deleteTemplates: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.La, { isTemplate: true }),
            cancelByKeys: ids => recordSaver.cancelByKeys(ids, GQL.IProductKey.La),
            confirm: ids => recordSaver.confirm(ids, GQL.IProductKey.La),
            journal: ids => recordSaver.journal(ids, GQL.IProductKey.La),
            avis: (ids, avis) => recordSaver.avis(ids, GQL.IProductKey.La, avis),
            color: (ids, color) => recordSaver.color(ids, GQL.IProductKey.La, color),
            review: (ids, review) => recordSaver.review(ids, GQL.IProductKey.La, review),
            importMany: (records, draft) => recordSaver.importMany(records, GQL.IProductKey.La, draft),
        },
        actionsDeb: {
            ...blackholeActions,
            save: record => recordSaver.save(record, null, GQL.IProductKey.Deb),
            saveTemplate: record => recordSaver.save(record, null, GQL.IProductKey.Deb, { isTemplate: true }),
            saveDocuments: (id, docs) => recordSaver.saveDocuments(id, GQL.IProductKey.Deb, docs),
            deleteByKeys: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.Deb),
            deleteTemplates: ids => recordSaver.deleteByKeys(ids, GQL.IProductKey.Deb, { isTemplate: true }),
            cancelByKeys: ids => recordSaver.cancelByKeys(ids, GQL.IProductKey.Deb),
            confirm: ids => recordSaver.confirm(ids, GQL.IProductKey.Deb),
            journal: ids => recordSaver.journal(ids, GQL.IProductKey.Deb),
            avis: (ids, avis) => recordSaver.avis(ids, GQL.IProductKey.Deb, avis),
            color: (ids, color) => recordSaver.color(ids, GQL.IProductKey.Deb, color),
            review: (ids, review) => recordSaver.review(ids, GQL.IProductKey.Deb, review),
            importMany: (records, draft) => recordSaver.importMany(records, GQL.IProductKey.Deb, draft),
        },
        actionsKB: {
            save: (record, payment, groupId) => recordSaver.save(record, payment, GQL.IProductKey.Kb, { groupId }),
            saveTemplate: (record, groupId) =>
                recordSaver.save(record, null, GQL.IProductKey.Kb, { groupId, isTemplate: true }),
            saveDocuments: (id, docs, groupId) => recordSaver.saveDocuments(id, GQL.IProductKey.Kb, docs, groupId),
            deleteByKeys: (ids, groupId) => recordSaver.deleteByKeys(ids, GQL.IProductKey.Kb, { groupId }),
            deleteTemplates: (ids, groupId) =>
                recordSaver.deleteByKeys(ids, GQL.IProductKey.Kb, { groupId, isTemplate: true }),
            cancelByKeys: (ids, groupId) => recordSaver.cancelByKeys(ids, GQL.IProductKey.Kb, groupId),
            confirm: (ids, groupId) => recordSaver.confirm(ids, GQL.IProductKey.Kb, groupId),
            journal: (ids, groupId) => recordSaver.journal(ids, GQL.IProductKey.Kb, groupId),
            avis: (ids, avis, groupId) => recordSaver.avis(ids, GQL.IProductKey.Kb, avis, groupId),
            color: (ids, color, groupId) => recordSaver.color(ids, GQL.IProductKey.Kb, color, groupId),
            review: (ids, review, groupId) => recordSaver.review(ids, GQL.IProductKey.Kb, review, groupId),
            importMany: (records, draft, groupId) =>
                recordSaver.importMany(records, GQL.IProductKey.Kb, draft, groupId),
            swimLastUp: (vs, groupId) => {
                if (vs.length < 2) {
                    return Promise.resolve();
                }
                const prioList = preprocessPriorities(vs);

                const topPrio = prioList[0].priority;
                const downPrio = prioList[prioList.length - 1].priority;
                prioList.slice(0, prioList.length - 1).reduceRight((prio, v) => {
                    const _prio = v.priority;
                    v.priority = prio;
                    return _prio;
                }, downPrio);
                prioList[prioList.length - 1].priority = topPrio;
                const updates: Record<string, number> = {};
                prioList.forEach(r => (updates[r.key] = r.priority));
                return recordSaver.setPriorities(updates, GQL.IProductKey.Kb, groupId);
            },
            sinkFirstDown: (vs, groupId) => {
                if (vs.length < 2) {
                    return Promise.resolve();
                }
                const prioList = preprocessPriorities(vs);
                const topPrio = prioList[0].priority;
                const downPrio = prioList[prioList.length - 1].priority;
                prioList.slice(1).reduce((prio, v) => {
                    const _prio = v.priority;
                    v.priority = prio;
                    return _prio;
                }, topPrio);
                prioList[0].priority = downPrio;
                const updates: Record<string, number> = {};
                prioList.forEach(r => (updates[r.key] = r.priority));
                return recordSaver.setPriorities(updates, GQL.IProductKey.Kb, groupId);
            },
        },
        actionsBank: {
            save: (record, payment, groupId) => recordSaver.save(record, payment, GQL.IProductKey.Bank, { groupId }),
            saveTemplate: (record, groupId) =>
                recordSaver.save(record, null, GQL.IProductKey.Bank, { groupId, isTemplate: true }),
            saveDocuments: (id, docs, groupId) => recordSaver.saveDocuments(id, GQL.IProductKey.Bank, docs, groupId),
            deleteByKeys: (ids, groupId) => recordSaver.deleteByKeys(ids, GQL.IProductKey.Bank, { groupId }),
            deleteTemplates: (ids, groupId) =>
                recordSaver.deleteByKeys(ids, GQL.IProductKey.Bank, { groupId, isTemplate: true }),
            cancelByKeys: (ids, groupId) => recordSaver.cancelByKeys(ids, GQL.IProductKey.Bank, groupId),
            confirm: (ids, groupId) => recordSaver.confirm(ids, GQL.IProductKey.Bank, groupId),
            journal: (ids, groupId) => recordSaver.journal(ids, GQL.IProductKey.Bank, groupId),
            avis: (ids, avis, groupId) => recordSaver.avis(ids, GQL.IProductKey.Bank, avis, groupId),
            color: (ids, color, groupId) => recordSaver.color(ids, GQL.IProductKey.Bank, color, groupId),
            review: (ids, review, groupId) => recordSaver.review(ids, GQL.IProductKey.Bank, review, groupId),
            importMany: (records, draft, groupId) =>
                recordSaver.importMany(records, GQL.IProductKey.Bank, draft, groupId),
            swimLastUp: () => Promise.reject("not implemented"),
            sinkFirstDown: () => Promise.reject("not implemented"),
        },
        blackhole: { ...blackholeActions },
    };
};

export const RecordsControlProvider: React.FC<React.PropsWithChildren> = props => {
    const recordSaver = useRecordsSaver();

    const control: RecordsController = React.useMemo(() => {
        return getController(recordSaver);
    }, [recordSaver]);

    return <RecordsControlContext.Provider value={control}>{props.children}</RecordsControlContext.Provider>;
};
