import { Button, Drawer, Form, Input, Select } from "antd";
import React, { FC, memo, useCallback, useContext } from "react";
import { GQL } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";
import { validation } from "../../../scripts/infrastructure/helpers/validation";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { gql } from "@apollo/client";
import { BruttoInputAntForm } from "@ui-components/AmountInput/BruttoInput";
import { useLineItemOptions } from "@inv/hooks/useLineItemOptions";
import { InvoiceFormInputTranslate } from "@inv/types";

type Props = {
    open: boolean;
    onClose: () => void;
    data?: GQL.IInvoiceLineItemTemplate;
    disabled?: boolean;
};

const invoiceLineItemTemplateSave = gql`
    mutation invoiceLineItemTemplateSave($input: InvoiceLineItemTemplateSaveInput!) {
        invoiceLineItemTemplateSave(input: $input)
    }
`;

const InvoiceTemplateLineItemForm: FC<Props> = ({ data, open, onClose, disabled }) => {
    const intl = useIntl();
    const [form] = Form.useForm<GQL.IInvoiceLineItemTemplate>();
    const { unitOptions, taxOptions } = useLineItemOptions();
    const mutator = useGqlMutator();
    const { companyGQL } = useContext(CompanyContext);
    const companyId = companyGQL?.id;

    const handleClose = useCallback(() => {
        form.resetFields();
        onClose();
    }, [form, onClose]);
    const onFinish = useCallback(
        (values: GQL.IInvoiceLineItemTemplate) => {
            mutator
                .mutate<"invoiceLineItemTemplateSave", GQL.IInvoiceLineItemTemplateSaveInput>({
                    mutation: invoiceLineItemTemplateSave,
                    input: {
                        ...values,
                        companyId,
                    },
                })
                .then(() => handleClose());
        },
        [companyId, mutator, handleClose]
    );

    const initialValues: Partial<GQL.IInvoiceLineItemTemplate> = data ?? {
        tax: 19,
    };

    return (
        <Drawer open={open} onClose={handleClose}>
            <Form<GQL.IInvoiceLineItemTemplate>
                onFinish={onFinish}
                initialValues={initialValues}
                form={form}
                layout="vertical"
                disabled={disabled}
            >
                <Form.Item<GQL.IInvoiceLineItemTemplate> name="id" style={{ display: "none" }} />
                <Form.Item<GQL.IInvoiceLineItemTemplate>
                    name="name"
                    label={<FormattedMessage id={InvoiceFormInputTranslate.NAME} />}
                    rules={[validation.required(intl)]}
                >
                    <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item<GQL.IInvoiceLineItemTemplate>
                    name="description"
                    label={<FormattedMessage id="app.fields.description" />}
                >
                    <Input.TextArea maxLength={255} showCount />
                </Form.Item>
                <Form.Item<GQL.IInvoiceLineItemTemplate>
                    name="unit"
                    label={<FormattedMessage id={InvoiceFormInputTranslate.UNIT} />}
                >
                    <Select options={unitOptions} />
                </Form.Item>
                <Form.Item<GQL.IInvoiceLineItemTemplate>
                    name="price"
                    label={<FormattedMessage id={InvoiceFormInputTranslate.PRICE} />}
                >
                    <BruttoInputAntForm />
                </Form.Item>
                <Form.Item<GQL.IInvoiceLineItemTemplate>
                    name="tax"
                    label={<FormattedMessage id={InvoiceFormInputTranslate.TAX} />}
                >
                    <Select options={taxOptions} />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    <FormattedMessage id={"app.button.save"} />
                </Button>
            </Form>
        </Drawer>
    );
};
export default memo(InvoiceTemplateLineItemForm);
