import { GQL } from "@binale-tech/shared";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { documentsUpdate } from "../mutations/documentMutations.graphql";
import { logger } from "../../infrastructure/logger";
import React, { useContext, useMemo } from "react";
import { useGqlMutator } from "../../graphql/useGqlMutator";
import { GenericItem, GenericRecord } from "../../models";
import { DmsAccountingConverter } from "../../models/converters/DmsAccountingConverter";
import { applyDocumentSuggestions } from "@dms/scripts/helpers/applyDocumentSuggestions";
import { CompanyContext } from "../CompanyContext";
import { ProductFactory } from "../../core/ProductFactory";
import { RecordsControlContext } from "../accountingData/RecordsControlCtx";
import { AllowedPartialEditItemFields } from "@app/components/recordform/hooks/useFormIsRefDisabled";
import { PaymentBindingUtils } from "../../models/utils/PaymentBindingUtils";
import { RecordsContext } from "../accountingData/RecordsCtx";
import { PaymentsContext } from "../accountingData/PaymentsProvider";
import { GenericRecordUtils } from "../../models/utils/GenericRecordUtils";
import RecordFormUtils from "@app/components/recordform/utils/RecordFormUtils";
import { notification } from "antd";
import { ContactsContext } from "../ContactsContext";

export interface IDocumentActions {
    updateDocumentsFromRecord: (
        recordDocuments: GQL.IRecordDocumentInput[],
        record: GenericRecord,
        pk: GQL.IProductKey
    ) => Promise<void>;
    updateRecordFromDocuments: (record: GenericRecord) => Promise<void>;
}

export const useDocumentActions = (groupId?: string): IDocumentActions => {
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const recordActions = useContext(RecordsControlContext);
    const { documentsKV } = useContext(DmsDataContext);
    const { allRecords } = useContext(RecordsContext);
    const { contacts } = useContext(ContactsContext);
    const { recordRelation } = useContext(PaymentsContext);

    const mutator = useGqlMutator();

    return useMemo((): IDocumentActions => {
        return {
            updateRecordFromDocuments: async record => {
                if (!record.getProductKey()) {
                    return;
                }
                if (!record.draft) {
                    return;
                }
                if (!record.documents?.length) {
                    return;
                }
                await new Promise(r => setTimeout(r, 1000));

                const config = ProductFactory.getAccountingProductConfig(
                    record.getProductKey(),
                    yearConfig,
                    companyGQL,
                    recordActions,
                    groupId
                );

                // we skip date and amount/currency update as date and amount are always filled and currency depends on the amount

                // same as in the form
                const selectedPayment = PaymentBindingUtils.getRepresentationRecordPaymentProto({
                    allRecords,
                    paymentsRecordRelation: recordRelation,
                    representationRecord: record,
                });
                const isPartialEditMode = Boolean(selectedPayment);
                const isDisabled = (field: keyof GenericItem | keyof GenericRecord) => {
                    return isPartialEditMode && !AllowedPartialEditItemFields.includes(field);
                };

                const recordFormData = {
                    editableRecord: GenericRecordUtils.convertRecordToForm(record),
                    editableRecordItem: GenericRecordUtils.convertRecordItemToForm(record.items[0], config.product),
                };

                // similar to DmsAccountingConverter.convertDocumentToRecord
                const recordSuggestion = DmsAccountingConverter.getRecordDocumentsSuggestion(
                    record.getProductKey(),
                    record.documents.map(v => documentsKV[v.id]),
                    { isUpdating: true, yearBound: record.year, periodBound: record.period, recordFormData }
                );
                // DmsAccountingConverter.applyContactSuggestions(recordSuggestion, record.getProductKey(), contacts);

                let recordUpdates = 0;
                const addRecordUpdate = (fn: () => void) => {
                    fn();
                    recordUpdates++;
                };

                // now similar to RecordFormDomainProvider.addDocumentPropertiesToRecord
                if (recordSuggestion.editableRecord.recordNum && !isDisabled("num")) {
                    addRecordUpdate(() => {
                        record.num = recordSuggestion.editableRecord.recordNum;
                    });
                }

                if (recordSuggestion.editableRecord.recordContact && !isDisabled("partner")) {
                    addRecordUpdate(() => {
                        record.partner = recordSuggestion.editableRecord.recordContact;
                    });
                }
                if (recordSuggestion.editableRecord.recordCategoryCreditor) {
                    const { category, creditor, debitor } = RecordFormUtils.resolveCCD(
                        recordSuggestion.editableRecord.recordCategoryCreditor
                    );
                    addRecordUpdate(() => {
                        if (category) {
                            record.category = category;
                        } else if (creditor) {
                            record.creditor = creditor;
                        } else if (debitor) {
                            record.debetor = debitor;
                        }
                    });
                }

                for (const item of record.items) {
                    const recordItemFormData = {
                        editableRecord: GenericRecordUtils.convertRecordToForm(record),
                        editableRecordItem: GenericRecordUtils.convertRecordItemToForm(item, config.product),
                    };
                    const itemSuggestion = DmsAccountingConverter.getRecordDocumentsSuggestion(
                        record.getProductKey(),
                        record.documents.map(v => documentsKV[v.id]),
                        {
                            isUpdating: true,
                            yearBound: record.year,
                            periodBound: record.period,
                            recordFormData: recordItemFormData,
                        }
                    );
                    console.log("itemSuggestion", itemSuggestion);
                    // DmsAccountingConverter.applyContactSuggestions(itemSuggestion, record.getProductKey(), contacts);

                    if (itemSuggestion.editableRecordItem.itemText && !isDisabled("text")) {
                        addRecordUpdate(() => {
                            item.text = itemSuggestion.editableRecordItem.itemText;
                        });
                    }
                    if (itemSuggestion.editableRecordItem.itemTag && !isDisabled("tag")) {
                        addRecordUpdate(() => {
                            item.tag = itemSuggestion.editableRecordItem.itemTag;
                        });
                    }
                    if (itemSuggestion.editableRecordItem.itemBelegfeld1 && !isDisabled("belegfeld1")) {
                        addRecordUpdate(() => {
                            item.belegfeld1 = itemSuggestion.editableRecordItem.itemBelegfeld1;
                        });
                    }
                    if (itemSuggestion.editableRecordItem.itemBelegfeld2 && !isDisabled("belegfeld2")) {
                        addRecordUpdate(() => {
                            item.belegfeld2 = itemSuggestion.editableRecordItem.itemBelegfeld2;
                        });
                    }
                    if (itemSuggestion.editableRecordItem.itemCategoryCreditor && !isDisabled("category")) {
                        const { category, creditor, debitor } = RecordFormUtils.resolveCCD(
                            recordSuggestion.editableRecordItem.itemCategoryCreditor
                        );
                        addRecordUpdate(() => {
                            if (category) {
                                item.category = category;
                            } else if (creditor) {
                                item.creditor = creditor;
                            } else if (debitor) {
                                item.debetor = debitor;
                            }
                        });
                        if (itemSuggestion.editableRecordItem.itemBu) {
                            addRecordUpdate(() => {
                                item.bu = itemSuggestion.editableRecordItem.itemBu;
                            });
                        }
                        if (itemSuggestion.editableRecordItem.itemUSt13b) {
                            addRecordUpdate(() => {
                                item.USt13b = itemSuggestion.editableRecordItem.itemUSt13b;
                            });
                        }
                    }
                }

                if (recordUpdates > 0) {
                    await config.actions.save(record, selectedPayment);
                    notification.info({
                        description: (
                            <div style={{ textAlign: "left" }}>
                                There were {recordUpdates} updates made to the record from the attached document
                            </div>
                        ),
                        message: "Record was updated",
                        placement: "topRight",
                    });
                }
            },
            updateDocumentsFromRecord: async (recordDocuments, record, pk) => {
                const suggestion = DmsAccountingConverter.convertRecordToDms(record, pk);
                const updatedDocuments: GQL.IDocumentUpdateInput[] = [];
                recordDocuments.forEach(el => {
                    if (documentsKV[el.id]) {
                        const inputUpdates = applyDocumentSuggestions(documentsKV[el.id], suggestion);
                        if (inputUpdates) {
                            updatedDocuments.push(inputUpdates);
                        }
                    } else {
                        logger.error(new Error("attempted to update document with unknown id " + el.id));
                    }
                });

                if (!updatedDocuments.length) {
                    return;
                }

                await mutator.mutate<"documentsUpdate", GQL.IDocumentUpdateInput[]>({
                    mutation: documentsUpdate,
                    input: updatedDocuments,
                    hideMessages: true,
                });
            },
        };
    }, [contacts, allRecords, companyGQL, documentsKV, groupId, mutator, recordActions, recordRelation, yearConfig]);
};
