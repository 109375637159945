import React, { CSSProperties, useContext, useMemo } from "react";
import { Button, Input, Radio, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import Category from "scripts/models/Category";
import Container from "./appearance/page/Container";
import Creditor, { Debitor } from "scripts/models/Creditor";
import LazyRender from "./LazyRender";
import Tag from "scripts/models/Tag";
import { Base } from "@binale-tech/shared";
import { BrowserDetection } from "scripts/infrastructure/helpers/browser";
import { CategoryUtils } from "../../../scripts/models/utils/CategoryUtils";
import { CompanyContext } from "scripts/context/CompanyContext";
import { KontoContext } from "scripts/context/accountingData/KontoEntitiesProvider";
import { TableViewContext } from "../../../scripts/context/accountingTable/tableViewContext";
import { useCategoriesByYearMonth } from "scripts/infrastructure/hooks";
import "./LeftTable.css";
import { CategoryCreditorMode, CategoryCreditorModes } from "../../../scripts/core/Product";

interface LeftTableOwnProps {
    show: SidePanels;
    onClickCategory: (v: Category) => void;
    onClickCreditor: (v: Creditor) => void;
    onClickDebitor: (v: Debitor) => void;
    onClickTag: (v: Tag) => void;
}

interface LeftTableStateProps {
    kontoExt?: number;
    skr?: number;
    allCategories: Category[];
    userCategories: Category[];
    creditors?: Base.IExtNum[];
    debitors?: Base.IExtNum[];
    tags?: Tag[];
}

declare type CatMode = "all" | "user";
const MODE_ALL: CatMode = "all";
const MODE_USER: CatMode = "user";

enum SidePanels {
    SIDE_CATEGORY = "category",
    SIDE_TAG = "tag",
    SIDE_CREDITOR = "creditor",
    SIDE_DEBITOR = "debitor",
}

interface State {
    searchTermDisplay: string;
    searchTerm: string;
    mode: CatMode;
}

class InternalLeftTable extends React.Component<LeftTableOwnProps & LeftTableStateProps, State> {
    protected rowItemCache = new Map<string, React.ReactNode>();
    protected _subject = new Subject<string>();
    protected _subscription: Subscription;

    static defaultProps = {
        kontoExt: 0,
    };

    state: State = {
        searchTerm: "",
        searchTermDisplay: "",
        mode: MODE_USER,
    };

    constructor(props: LeftTableOwnProps & LeftTableStateProps) {
        super(props);
        this._subscription = this._subject.pipe(debounceTime(250), distinctUntilChanged()).subscribe(v => {
            this.setState({ searchTerm: v.toLowerCase() });
        });
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div className="LeftTable">
                {this.radioBlock}
                {this.searchBlock}
                {this.getItemsTable}
            </div>
        );
    }

    protected get getItemsTable() {
        if (this.props.show === SidePanels.SIDE_CATEGORY) {
            return this.categoryTable;
        } else if (this.props.show === SidePanels.SIDE_CREDITOR) {
            return this.creditorTable;
        } else if (this.props.show === SidePanels.SIDE_TAG) {
            return this.tagTable;
        } else if (this.props.show === SidePanels.SIDE_DEBITOR) {
            return this.debitorTable;
        }
        return null;
    }

    protected handleSizeChange = (e: any) => {
        this.setState({ mode: e.target.value });
    };

    protected get radioBlock() {
        const disabled = this.props.show !== SidePanels.SIDE_CATEGORY;
        const className = BrowserDetection.isFirefox ? "LeftTable__ButtonGroup--ff" : "";
        return (
            <Radio.Group
                value={this.state.mode}
                onChange={this.handleSizeChange}
                className={"LeftTable__ButtonGroup " + className}
                disabled={disabled}
            >
                <Radio.Button value={MODE_ALL}>
                    <FormattedMessage id="app.components.all" />
                </Radio.Button>
                <Radio.Button value={MODE_USER}>
                    <FormattedMessage id="app.components.used" />
                </Radio.Button>
            </Radio.Group>
        );
    }

    protected get searchBlock() {
        return <Input value={this.state.searchTermDisplay} placeholder="Suchen" onChange={this.onChangeInput} />;
    }

    protected onChangeInput: React.ComponentProps<typeof Input>["onChange"] = e => {
        const value = e?.target?.value || "";
        this.setState({ searchTermDisplay: value });
        this._subject.next(value);
    };

    UNSAFE_componentWillReceiveProps(props: LeftTableStateProps & LeftTableOwnProps) {
        if (!this.props.show && props.show) {
            this.setState({ mode: MODE_ALL });
        }
        if (this.props.show !== props.show) {
            this.onChangeInput(null);
        }
        this.rowItemCache.clear();
    }

    shouldComponentUpdate(nextProps: LeftTableStateProps & LeftTableOwnProps, nextState: State) {
        if (nextState !== this.state) {
            return true;
        }
        if (nextProps.allCategories !== this.props.allCategories) {
            return true;
        }
        if (nextProps.userCategories !== this.props.userCategories) {
            return true;
        }
        if (nextProps.show !== this.props.show) {
            return true;
        }
        if (nextProps.tags !== this.props.tags) {
            return true;
        }
        if (nextProps.creditors !== this.props.creditors) {
            return true;
        }
        if (nextProps.debitors !== this.props.debitors) {
            return true;
        }
        return false;
    }

    componentWillUnmount() {
        this._subscription.unsubscribe();
    }

    protected getRow(i: Category): React.ReactNode {
        const key = i.num;
        if (this.rowItemCache.has(key)) {
            return this.rowItemCache.get(key);
        } else {
            const node = this._getRowCategory(i);
            this.rowItemCache.set(key, node);
            return node;
        }
    }

    protected _getRowCategory(i: Category): React.ReactNode {
        const printNum = i.getExtNumPrint(this.props.kontoExt);
        const isDisabled = CategoryUtils.isAggregationCategory(i, this.props.skr);
        return (
            <tr
                key={"NumNameTable_cat_" + i.num}
                style={{ height: 25, cursor: isDisabled ? undefined : "pointer" }}
                onClick={isDisabled ? undefined : () => this.props.onClickCategory(i)}
                className={isDisabled ? "NumNameTable__Row--disabled" : undefined}
            >
                <td style={{ width: this.getNumWidth(i) }}>{printNum}</td>

                <Tooltip
                    destroyTooltipOnHide
                    placement="right"
                    title={
                        <span>
                            <strong>{printNum}</strong>
                            <br />
                            {i.name}
                        </span>
                    }
                >
                    <td>{i.name}</td>
                </Tooltip>
            </tr>
        );
    }

    protected getCategories() {
        return this.state.mode === MODE_USER ? this.props.userCategories : this.props.allCategories;
    }

    protected getFiltered(input: Base.INumName[]) {
        if (!this.state.searchTerm || input.length === 0) {
            return input;
        }
        const fNum = input.filter(v => v.num.includes(this.state.searchTerm));
        if (fNum.length > 0) {
            return fNum;
        }
        return input.filter(v => {
            if (!v.name) {
                return false;
            }
            return v.name.toLowerCase().includes(this.state.searchTerm);
        });
    }

    protected get categoryTable() {
        const rows = this.getFiltered(this.getCategories()).map((i: Category, idx) => this.getRow(i));
        return this.getTable(rows, this.getNumWidth(new Category("", "")));
    }

    protected getTable(rows: React.ReactNode[], width: number) {
        const headerHeight = 33;
        const table = (
            <div className="NumNameTable">
                <Container absolute>
                    {(w, h) => (
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width }}>
                                        <FormattedMessage id="app.fields.konto" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="app.fields.bezeichnung" />
                                    </th>
                                </tr>
                            </thead>
                            <LazyRender height={h - headerHeight} itemPadding={5} useTbody={true}>
                                {rows}
                            </LazyRender>
                        </table>
                    )}
                </Container>
            </div>
        );
        return table;
    }

    protected get creditorTable() {
        const rows = this.getFiltered(this.props.creditors).map((i: Creditor, idx) =>
            this._getRowCreditor(i, this.props.onClickCreditor)
        );
        return this.getTable(rows, this.getNumWidth(new Creditor("", "")));
    }

    protected get debitorTable() {
        const rows = this.getFiltered(this.props.debitors).map((i: Debitor, idx) =>
            this._getRowCreditor(i, this.props.onClickDebitor)
        );
        return this.getTable(rows, this.getNumWidth(new Creditor("", "")));
    }

    protected _getRowCreditor(i: Creditor, onClick: (i: Creditor) => any): React.ReactNode {
        const printNum = i.getExtNumPrint(this.props.kontoExt);
        return (
            <tr
                key={"NumNameTable_cred_" + i.num + "_" + Math.random().toFixed(3)}
                style={{ height: 25, cursor: "pointer" }}
                onClick={() => onClick(i)}
            >
                <td style={{ width: this.getNumWidth(i) }}>{printNum}</td>
                <Tooltip
                    placement="right"
                    destroyTooltipOnHide
                    title={
                        <span>
                            <strong>{printNum}</strong>
                            <br />
                            {i.name}
                        </span>
                    }
                >
                    <td>{i.name}</td>
                </Tooltip>
            </tr>
        );
    }

    protected get tagTable() {
        const rows = this.getFiltered(this.props.tags).map((i: Tag, idx) => this._getRowTag(i));
        return this.getTable(rows, this.getNumWidth(new Tag("", "")));
    }

    protected getNumWidth(i: Tag | Category | Creditor) {
        if (i instanceof Category) {
            return 60 + this.props.kontoExt * 5;
        } else if (i instanceof Creditor) {
            return 65 + this.props.kontoExt * 5;
        }
        return 60;
    }

    protected _getRowTag(i: Tag): React.ReactNode {
        return (
            <tr
                key={"NumNameTable_tag_" + i.num + "_" + Math.random().toFixed(3)}
                style={{ height: 25, cursor: "pointer" }}
                onClick={() => this.props.onClickTag(i)}
            >
                <td style={{ width: this.getNumWidth(i) }}>{i.num}</td>
                <Tooltip
                    placement="right"
                    destroyTooltipOnHide
                    title={
                        <span>
                            <strong>{i.num}</strong>
                            <br />
                            {i.name}
                        </span>
                    }
                >
                    <td>{i.name}</td>
                </Tooltip>
            </tr>
        );
    }
}
const LeftTable: React.FC<
    Omit<LeftTableOwnProps, "onClickTag" | "onClickCategory" | "onClickCreditor" | "onClickDebitor">
> = React.memo(({ show }) => {
    const { yearConfig } = React.useContext(CompanyContext);
    const { onSelectLeftTableAccount } = React.useContext(TableViewContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const skr = yearConfig?.skr;
    const { creditors, debitors, tags } = React.useContext(KontoContext);
    const { allCategories, userCategories } = useCategoriesByYearMonth();
    return (
        <InternalLeftTable
            show={show}
            onClickTag={tag => {
                onSelectLeftTableAccount({ tag });
            }}
            onClickDebitor={debetor => {
                onSelectLeftTableAccount({ debetor });
            }}
            onClickCreditor={creditor => {
                onSelectLeftTableAccount({ creditor });
            }}
            onClickCategory={category => {
                onSelectLeftTableAccount({ category });
            }}
            allCategories={allCategories}
            userCategories={userCategories}
            creditors={creditors}
            debitors={debitors}
            tags={tags}
            kontoExt={kontoExt}
            skr={skr}
        />
    );
});

export const LeftBlockWrapper: React.FC<React.PropsWithChildren<{ style?: CSSProperties }>> = ({ children, style }) => {
    const { programSettingsProvider } = React.useContext(CompanyContext);
    const { product, productKey } = useContext(TableViewContext);
    const productConfig = useMemo(() => product.getConfig(), [product]);
    const [sidePanel, showSidePanel] = React.useState<SidePanels | null>(null);
    const handleClick = React.useCallback((v: SidePanels) => {
        showSidePanel(showSide => {
            if (showSide && showSide === v) {
                return null;
            }
            return v;
        });
    }, []);

    const leftSideButtons = React.useMemo(() => {
        const settings = programSettingsProvider(productKey);
        const s = new Set([SidePanels.SIDE_CATEGORY]);

        const isCredSupported = (v: CategoryCreditorMode) =>
            [CategoryCreditorModes.CRED, CategoryCreditorModes.CCD, CategoryCreditorModes.RecordKontoFE].includes(v);
        const isDebSupported = (v: CategoryCreditorMode) =>
            [CategoryCreditorModes.DEB, CategoryCreditorModes.CCD, CategoryCreditorModes.RecordKontoFE].includes(v);

        if (isCredSupported(productConfig.recordAccountMode) || isCredSupported(productConfig.itemAccountMode)) {
            s.add(SidePanels.SIDE_CREDITOR);
        }

        if (isDebSupported(productConfig.recordAccountMode) || isDebSupported(productConfig.itemAccountMode)) {
            s.add(SidePanels.SIDE_DEBITOR);
        }

        if (settings.useKs) {
            s.add(SidePanels.SIDE_TAG);
        }
        return (
            <>
                {s.has(SidePanels.SIDE_CATEGORY) && (
                    <Button
                        className="LeftBlockWrapper__leftSideButton"
                        tabIndex={-1}
                        onClick={() => handleClick(SidePanels.SIDE_CATEGORY)}
                        type={sidePanel === SidePanels.SIDE_CATEGORY ? "primary" : "default"}
                    >
                        <span className="LeftBlockWrapper__leftSideButtonText">
                            <FormattedMessage id="app.titles.category" />
                        </span>
                    </Button>
                )}
                {s.has(SidePanels.SIDE_CREDITOR) && (
                    <Button
                        key="lsbcred"
                        className="LeftBlockWrapper__leftSideButton"
                        tabIndex={-1}
                        onClick={() => handleClick(SidePanels.SIDE_CREDITOR)}
                        type={sidePanel === SidePanels.SIDE_CREDITOR ? "primary" : "default"}
                    >
                        <span className="LeftBlockWrapper__leftSideButtonText">
                            <FormattedMessage id="app.titles.creditor.pl" />
                        </span>
                    </Button>
                )}
                {s.has(SidePanels.SIDE_DEBITOR) && (
                    <Button
                        className="LeftBlockWrapper__leftSideButton"
                        tabIndex={-1}
                        onClick={() => handleClick(SidePanels.SIDE_DEBITOR)}
                        type={sidePanel === SidePanels.SIDE_DEBITOR ? "primary" : "default"}
                    >
                        <span className="LeftBlockWrapper__leftSideButtonText">
                            <FormattedMessage id="app.titles.debitor.pl" />
                        </span>
                    </Button>
                )}
                {s.has(SidePanels.SIDE_TAG) && (
                    <Button
                        className="LeftBlockWrapper__leftSideButton"
                        tabIndex={-1}
                        onClick={() => handleClick(SidePanels.SIDE_TAG)}
                        type={sidePanel === SidePanels.SIDE_TAG ? "primary" : "default"}
                    >
                        <span className="LeftBlockWrapper__leftSideButtonText">
                            <FormattedMessage id="app.titles.tag.pl" />
                        </span>
                    </Button>
                )}
            </>
        );
    }, [programSettingsProvider, productKey, productConfig, sidePanel, handleClick]);

    return (
        <div className="LeftBlockWrapper LeftBlockWrapper__flexRow" style={style}>
            <div className="LeftBlockWrapper__flexCol">{leftSideButtons}</div>
            <LeftTable show={sidePanel} />
            {children}
        </div>
    );
};
