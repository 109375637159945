import React, { useEffect } from "react";
import { Col, message, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";

import { AppRoutes } from "scripts/routing/routeConstants";
import { AuthLayout } from "./AuthLayout";
import { NewPasswordForm } from "../profile/ProfilePassword/form";
import { auth } from "scripts/api/firebase/firebase";
import { logger } from "scripts/infrastructure/logger";

export const NewPasswordView = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [query] = useSearchParams();

    const checkCode = async (code: string) => {
        if (!code) {
            message.error(formatMessage({ id: "app.global.error" }));
            navigate(AppRoutes.home);
            return;
        }
        try {
            await verifyPasswordResetCode(auth, code);
        } catch (error) {
            message.error(formatMessage({ id: "app.global.error" }));
            navigate(AppRoutes.home);
        }
    };

    useEffect(() => {
        checkCode(query.get("oobCode"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (newPassword: string): Promise<{ redirect: boolean } | void> => {
        try {
            await confirmPasswordReset(auth, query.get("oobCode"), newPassword);
            message.info(formatMessage({ id: "app.new_password.confirmation" }));
            navigate(AppRoutes.authLogin);
            return { redirect: true };
        } catch (error) {
            logger.error(error);
            message.error(formatMessage({ id: "app.global.error" }));
        }
    };

    return (
        <AuthLayout title={<FormattedMessage id="app.new_password.caption" />}>
            <NewPasswordForm onSubmit={handleSubmit} />
            <Row justify="space-between">
                <Col>
                    <Link to={AppRoutes.authLogin}>
                        <FormattedMessage id="app.global.login_now" />
                    </Link>
                </Col>
                <Col>
                    <Link to={AppRoutes.authRegister}>
                        <FormattedMessage id="app.global.register_now" />
                    </Link>
                </Col>
            </Row>
        </AuthLayout>
    );
};
