import { findNode, getChild, htmlDecode } from "./CIITools";
import { AbstractDecoder } from "./AbstractDecoder";

export class DecoderLegacy extends AbstractDecoder {
    getVersion() {
        return { versionText: "ZUGFeRD 1.0", isDeprecated: true, version: "" };
    }
    protected getHeader(): Record<string, any>[] {
        return getChild(this.CII, "rsm:HeaderExchangedDocument");
    }
    protected getTransaction(): Record<string, any>[] {
        return getChild(this.CII, "rsm:SpecifiedSupplyChainTradeTransaction");
    }
    protected getTransactionTradeAgreement(): Record<string, any>[] {
        return getChild(this.getTransaction(), "ram:ApplicableSupplyChainTradeAgreement");
    }
    protected getTransactionTradeSettlement(): Record<string, any>[] {
        return getChild(this.getTransaction(), "ram:ApplicableSupplyChainTradeSettlement");
    }

    protected getTransactionTradeDelivery(): Record<string, any>[] {
        return getChild(this.getTransaction(), "ram:ApplicableSupplyChainTradeDelivery");
    }

    protected tagTaxApplicablePercent(nodes: Record<string, any>[]): number {
        return Number(findNode(nodes, "ram:ApplicablePercent")?.content ?? "0");
    }

    protected getLineItem(v: Record<string, any>[]): {
        name: string;
        description: string;
        netto: number;
        tax: number;
    } {
        const settlement = getChild(v, "ram:SpecifiedSupplyChainTradeSettlement");

        const tradeTax = getChild(settlement, "ram:ApplicableTradeTax");
        const tax = Number(findNode(tradeTax, "ram:ApplicablePercent")?.content ?? "0"); // BT-152

        const summation = getChild(settlement, "ram:SpecifiedTradeSettlementMonetarySummation");
        const netto = Number(findNode(summation, "ram:LineTotalAmount")?.content ?? "0") * 100; // BT-131

        const product = getChild(v, "ram:SpecifiedTradeProduct");
        const name = htmlDecode(findNode(product, "ram:Name")?.content); // BT-153
        const description = htmlDecode(findNode(product, "ram:Description")?.content); // BT-154
        return {
            name,
            description,
            netto,
            tax,
        };
    }
}
