import { message } from "antd";
import { cloneElement, FC, PropsWithChildren, useContext, useMemo, useState } from "react";
import { GQL } from "@binale-tech/shared";
import { useIntl } from "react-intl";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { useDmsAccountingConverter } from "../../../scripts/models/converters/useDmsAccountingConverter";
import { ProductAccessUtils } from "../../../scripts/models/utils/ProductAccessUtils";
import { GenericRecord } from "../../../scripts/models";
import { BinaleError } from "../../../scripts/errors/errors";

type Props = {
    documents: GQL.IDocument[];
};

export const DmsFibuExporter: FC<PropsWithChildren<Props>> = ({ documents, children }) => {
    const intl = useIntl();
    const { documentTypes } = useContext(DmsDataContext);
    const [importing, setImporting] = useState(false);

    const { type, subType } = useMemo(() => {
        const typeSet = new Set<string>();
        const subTypeSet = new Set<string>();
        documents.filter(Boolean).forEach(d => {
            typeSet.add(d.type);
            subTypeSet.add(d.subType);
        });
        if (typeSet.size > 1 || subTypeSet.size > 1) {
            return { type: undefined, subType: undefined };
        }
        return { type: [...typeSet][0], subType: [...subTypeSet][0] };
    }, [documents]);
    const groupId = DmsUtils.getGroupId(type, subType, documentTypes);
    const { converter, actions } = useDmsAccountingConverter(type as GQL.IProductKey, 2024, groupId);

    const isAccountingType = ProductAccessUtils.getAccountingKeys().has(type as GQL.IProductKey);
    if (!isAccountingType) {
        return null;
    }

    const isDisabled = DmsUtils.isAccountingExportDisabled(documents, type, groupId);

    const toGenericRecord = async () => {
        if (!documents.length || !actions) {
            return;
        }

        setImporting(true);

        const records: GenericRecord[] = [];
        const errors: string[] = [];
        for (const document of documents) {
            try {
                const record = await converter.convertDocumentToRecord(document);
                records.push(record);
            } catch (e) {
                if (e instanceof BinaleError) {
                    errors.push(intl.formatMessage({ id: e.translationKey }, e.values));
                }
            }
        }

        for (const e of errors) {
            message.warning(e);
        }

        if (records.length) {
            try {
                await actions.importMany(records, true);
                message.success(intl.formatMessage({ id: "app.button.done" }));
            } catch (e) {
                message.error(e.message);
            }
        }

        setImporting(false);
    };

    return cloneElement(children as any, { disabled: isDisabled, onClick: toGenericRecord, loading: importing });
};
