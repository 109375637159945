import React, { useContext, useMemo, useState } from "react";
import { Card, Col, Drawer, Radio, Row, Segmented, Space } from "antd";
import { FormattedMessage } from "react-intl";

import { Base, GQL } from "@binale-tech/shared";
import { Category } from "scripts/models";
import { CategoryForm } from "./CategoryForm";
import { CategoryUtils } from "../../../../../scripts/models/utils/CategoryUtils";
import { CompanyContext } from "scripts/context/CompanyContext";
import { KontoContext, KontoControlContext } from "scripts/context/accountingData/KontoEntitiesProvider";
import { GlobalYearSelect } from "../../../../components/shared/Toolbar/YearSelect";
import { NumNameTable } from "../NumNameTable";
import { ProductKeys } from "scripts/models/Product";
import { getFiltered, SearchField } from "../helpers";
import { gql } from "@apollo/client";
import { useCanWrite, useDefaultCategoriesByYearMonth } from "scripts/infrastructure/hooks";
import { useGqlMutator } from "../../../../../scripts/graphql/useGqlMutator";
import { RecordsContext } from "../../../../../scripts/context/accountingData/RecordsCtx";

enum Mode {
    all = "all",
    user = "user",
}

interface IFilter {
    mode: Mode;
    search: string;
}

const categoryMark = gql`
    mutation categoryMark($input: CategoryMarkInput!) {
        categoryMark(input: $input) {
            id
            marked
        }
    }
`;

const EditCategoryView: React.FC = () => {
    const { allRecords } = useContext(RecordsContext);
    const { categoryOverrides } = React.useContext(KontoContext);
    const { yearConfig, yearBanks, yearKbs, companyGQL } = React.useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const skr = yearConfig?.skr;
    const { categoryOverrideSave, categoryOverrideDelete } = React.useContext(KontoControlContext);
    const mutator = useGqlMutator();

    const [activeItem, setActiveItem] = React.useState<Base.INumName>();
    const handleClear = React.useCallback(() => setActiveItem(null), []);

    const defaultCategories = useDefaultCategoriesByYearMonth();
    const canWrite = useCanWrite(ProductKeys.AccountingCommon);

    const [filters, setFilters] = useState<IFilter>({ mode: Mode.user, search: "" });

    const usedItems = useMemo(() => {
        const set = new Set<string>();
        allRecords.list.forEach(r => {
            if (r.category) {
                set.add(r.category.num);
            }
            r.items.forEach(i => {
                if (i.category) {
                    set.add(i.category.num);
                }
            });
        });
        return set;
    }, [allRecords]);

    const items = useMemo(() => {
        const userCategoriesMap = new Map<string, Category>();
        categoryOverrides.forEach(c => {
            userCategoriesMap.set(c.num, c as Category);
        });
        const arr = filters.mode === Mode.all ? Array.from(defaultCategories.values()) : categoryOverrides;
        return getFiltered(arr, filters.search, kontoExt).map(entity => ({
            entity: Category.unserialize({
                ...entity,
                name: userCategoriesMap.get(entity.num)?.name ?? entity.name,
                marked: userCategoriesMap.get(entity.num)?.marked,
            }),
            isUsed: usedItems.has(entity.num),
            disabled: CategoryUtils.isAggregationCategory(entity as Base.IExtNum, skr),
            defaultCategory: defaultCategories.get(Number(entity.num.substring(0, Base.KontoDefaultLen.Category))),
        }));
    }, [usedItems, filters, categoryOverrides, defaultCategories, kontoExt, skr]);

    const isRemovable = React.useCallback(
        (v: Category) => {
            if (!yearConfig || !canWrite || filters.mode === Mode.all) {
                return false;
            }
            const ext = yearConfig.kontoExt;
            const isCompanyBank = yearBanks.findIndex(b => CategoryUtils.areEqual(String(b.accountNum), v.num)) > -1;
            if (isCompanyBank) {
                return false;
            }
            const isCompanyKasse = yearKbs.findIndex(b => CategoryUtils.areEqual(String(b.accountNum), v.num)) > -1;
            if (isCompanyKasse) {
                return false;
            }
            if (usedItems.has(v.num)) {
                return false;
            }

            // we can not remove categories ending on `00` (not user created categories)
            // 0500 00 -> can't be removed, 0500 01 -> can
            if (yearConfig.kontoExt > 0) {
                return Number(v.getExtNum(ext).substring(Category.DefaultLen)) !== 0;
            }
            return true;
        },
        [filters.mode, usedItems, canWrite, yearBanks, yearConfig, yearKbs]
    );

    return (
        <div className="ManageAccount__container">
            <Space style={{ marginTop: 16, marginBottom: 16 }}>
                <GlobalYearSelect />
                <Segmented
                    options={[
                        { value: Mode.all, label: <FormattedMessage id="app.components.all" /> },
                        { value: Mode.user, label: <FormattedMessage id="app.components.used" /> },
                    ]}
                    onChange={mode => setFilters({ ...filters, mode })}
                />

                <SearchField onSearch={search => setFilters({ ...filters, search })} />
            </Space>

            <NumNameTable
                items={items}
                onClick={item => setActiveItem(filters.mode === Mode.user ? item : { name: item.name, num: item.num })}
                isRemovable={isRemovable}
                onDeleteClick={item => categoryOverrideDelete(item.key, item.num)}
                showDefaultName={true}
                onMarkClick={(v, marked) => {
                    mutator.mutate<"categoryMark", GQL.ICategoryMarkInput>({
                        mutation: categoryMark,
                        input: {
                            accountNumString: new Category(v.num, "").getExtNum(kontoExt),
                            name: v.name,
                            companyId: companyGQL.id,
                            year: yearConfig.year,
                            marked,
                        },
                    });
                }}
            />
            <Drawer open={Boolean(activeItem)} onClose={handleClear} destroyOnClose>
                <Card>
                    <CategoryForm
                        item={activeItem}
                        disabled={!activeItem || !canWrite}
                        onSave={categoryOverrideSave}
                        onClose={handleClear}
                    />
                </Card>
            </Drawer>
        </div>
    );
};
export default React.memo(EditCategoryView);
