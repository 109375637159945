import { Avatar, Flex, MenuProps, Tooltip } from "antd";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import {
    ClearOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    ExportOutlined
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import React, { useContext, useMemo, useState } from "react";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { Link } from "react-router-dom";
import { DmsPaths } from "@dms/configs/constants";
import { DmsType, ITableDocument } from "@dms/types";
import { TableRowColors } from "@dms/modules/DocumentTableModule/consts";
import { IRowNode } from "ag-grid-community";

type Props = {
    document: GQL.IDocument;
    mode: "table" | "card";
    node: IRowNode<ITableDocument>;
};

export const useDropdownAction = ({ document, mode, node }: Props): MenuProps["items"] => {
    const [title, setTitle] = useState(<FormattedMessage id="app.dms.copyId" />);

    const { dmsRecordsData } = useContext(DmsDataContext);

    const { deleteModeToggle, setFocusedRow } = useContext(DmsAppControlContext);
    const { selectRow, typeChangeModeToggle } = useContext(DmsAppControlContext);
    const { selectedRowKeys } = useContext(DmsAppContext);

    const mutator = useGqlMutator();

    return useMemo(() => {
        if (!document) {
            return [];
        }

        const setColorDocument = async (color: GQL.IDocumentColors | null, data: GQL.IDocument) => {
            const doc = { ...data, color };

            try {
                await DocumentsApi.updateDocuments({
                    documents: [doc],
                    mutator,
                });
            } catch (error) {
                console.error("Failed to update document:", error);
            } finally {
                setFocusedRow(node.data.key);
            }
        };

        const colorItems = Object.values(GQL.IDocumentColors).map(el => ({
            key: el,
            label: (
                <Flex align={"center"} gap={5}>
                    <Avatar style={{ backgroundColor: TableRowColors[el as GQL.IDocumentColors] }} size={14} />
                    <FormattedMessage tagName="div" id={`app.button.color.${el}`} />
                </Flex>
            ),
            onClick: () => setColorDocument(el, document),
        }));

        const handleDelete = async () => {
            if (document.type === DmsType.trash) {
                if (dmsRecordsData.recordsAssetsSet.has(DocumentsApi.urlFormatter(document.fileUrl))) {
                    return deleteModeToggle(true);
                }

                return DocumentsApi.removeDocuments([document], mutator);
            }

            await DocumentsApi.sendToTrash([document], mutator);
        };

        const checkedElement = (id: string): boolean => {
            return selectedRowKeys.includes(id);
        };

        const moveTo = (): void => {
            typeChangeModeToggle(true);
            if (!checkedElement(document.key)) {
                selectRow(document.key);
            }
        };

        const copyIdNumber = async (id: string): Promise<void> => {
            await navigator.clipboard.writeText(id);
            setTitle(<FormattedMessage id="app.dms.idCopied" />);
        };

        const resetTooltip = (open: boolean): void => {
            if (!open) {
                setTitle(<FormattedMessage id="app.dms.copyId" />);
            }
        };
        switch (mode) {
            case "table":
                return [
                    {
                        label: (
                            <div>
                                <DownloadOutlined /> <FormattedMessage id="app.button.download" />
                            </div>
                        ),
                        key: "0",
                        onClick: () => {
                            if (document) {
                                DocumentsApi.downloadFile(document.fileUrl, DmsUtils.getDownloadName(document));
                            }
                        },
                    },
                    {
                        label: (
                            <div>
                                <DeleteOutlined /> <FormattedMessage id="app.button.delete" />
                            </div>
                        ),
                        key: "1",
                        onClick: async () => {
                            if (document) {
                                await handleDelete();
                            }
                        },
                    },
                    { type: "divider" },
                    {
                        label: (
                            <div>
                                <ClearOutlined /> <FormattedMessage id="app.button.color.remove_color" />
                            </div>
                        ),

                        key: "removeColor",
                        onClick: () => setColorDocument(null, document),
                    },
                    ...colorItems,
                ];
            case "card":
                return [
                    {
                        label: (
                            <Link
                                to={`/${DmsPaths.ROOT}/${DmsPaths.EDITING}/${document.key}`}
                                state={{ isGroup: false, documentId: document.key }}
                            >
                                <EditOutlined /> <FormattedMessage id="app.button.edit" />
                            </Link>
                        ),
                        key: "1",
                    },
                    {
                        label: (
                            <div>
                                <ExportOutlined /> <FormattedMessage id="app.dms.moveTo" />
                            </div>
                        ),
                        key: "2",
                        onClick: () => moveTo(),
                    },
                    {
                        label: (
                            <div>
                                <DownloadOutlined /> <FormattedMessage id="app.button.download" />
                            </div>
                        ),
                        key: "3",
                        onClick: () => DocumentsApi.downloadFile(document.fileUrl, DmsUtils.getDownloadName(document)),
                    },
                    {
                        label: (
                            <div>
                                <Tooltip title={title} onOpenChange={resetTooltip}>
                                    <CopyOutlined /> <FormattedMessage id="app.dms.copyId" />
                                </Tooltip>
                            </div>
                        ),
                        key: "4",
                        onClick: () => copyIdNumber(document.key),
                    },
                    {
                        label: (
                            <div onClick={handleDelete}>
                                <DeleteOutlined /> <FormattedMessage id="app.button.delete" />
                            </div>
                        ),
                        key: "5",
                        onClick: () => handleDelete(),
                    },
                ];
        }
    }, [
        document,
        mode,
        mutator,
        setFocusedRow,
        node,
        dmsRecordsData.recordsAssetsSet,
        deleteModeToggle,
        selectedRowKeys,
        typeChangeModeToggle,
        selectRow,
        title,
    ]);
};
