import React, { memo, useContext } from "react";

import { DmsItem } from "@dms/components/DocumentCarouselItems/components/DmsItem";
import { CarouselContext } from "@dms/modules/DocumentFormModule/context/CarouselContext";

import styles from "./DmsViewList.module.scss";
import { GQL } from "@binale-tech/shared";

interface IProps {
    documents: GQL.IDocument[];
    activeDocumentId: string | undefined;
    handleClick: (i: string) => void;
}

export const DmsViewList: React.FC<IProps> = memo(({ documents, handleClick, activeDocumentId }) => {
    const { handleCheckedItems } = useContext(CarouselContext);

    return (
        <>
            {documents.map((document, index) => {
                return (
                    <div
                        className={styles.dmsViewListItem}
                        style={{
                            outline: document.key === activeDocumentId ? "2px solid #1890ff" : undefined,
                        }}
                        key={document.key}
                        onClick={() => handleClick(document.key)}
                    >
                        <DmsItem
                            document={document}
                            handleCheckedItems={() => handleCheckedItems(documents)}
                            index={index}
                            key={document.key}
                        />
                    </div>
                );
            })}
        </>
    );
});
