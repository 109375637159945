import React from "react";
import dayjs from "dayjs";
import { Button, Collapse, List, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { LogItem } from "scripts/models/LogItem";
import { TableViewContext } from "../../../../scripts/context/accountingTable/tableViewContext";
import "./ModalLog.css";

interface Props {
    onHide?: () => void;
    visible?: boolean;
    recordKey?: string;
    lister?: (recordKey: string) => Promise<LogItem[]>;
}

interface ModalLogState {
    logItems: LogItem[];
    loading: boolean;
}

class ModalLogClass extends React.PureComponent<Props, ModalLogState> {
    static defaultProps: Props = {
        onHide: () => {},
        visible: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            logItems: [],
            loading: true,
        };
    }

    handleHide = () => {
        this.props.onHide();
    };

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!this.props.visible && nextProps.visible) {
            this.setState({ logItems: [], loading: true });
            this.setLogData(nextProps.recordKey);
        }
    }

    protected setLogData(recordKey: string) {
        this.props.lister(recordKey).then(logItems => {
            this.setState({ logItems: logItems.filter(v => v.changes), loading: false });
        });
    }

    protected readonly footer = [
        <Button key="back" onClick={this.handleHide}>
            <FormattedMessage id="app.button.done" />
        </Button>,
    ];

    protected isCreated(diff: any) {
        return diff.kind === "E" && !Object.getOwnPropertyDescriptor(diff, "lhs");
    }
    protected getDiffTitle(diff: any) {
        if (this.isCreated(diff)) {
            return "Created";
        }
        return this.getKindTitle(diff.kind) + " [ " + diff.path.join(".") + " ] ";
    }
    protected getKindTitle(kind: string) {
        switch (kind) {
            case "N":
                return "Newly added";
            case "D":
                return "Deleted";
            case "E":
                return "Edited";
            case "A":
                return "Edited";
            default:
                return "Edited";
        }
    }
    protected getDiffContent(diff: any) {
        if (this.isCreated(diff)) {
            return <pre>{JSON.stringify(diff.rhs, null, 2)}</pre>;
        }
        return <pre>{JSON.stringify({ old: diff.lhs, new: diff.rhs }, null, 2)}</pre>;
    }

    render() {
        return (
            <Modal
                className="ModalLog"
                width={1000}
                style={{ top: 20 }}
                destroyOnClose
                title="Log"
                open={this.props.visible}
                onOk={this.handleHide}
                onCancel={this.handleHide}
                footer={null}
            >
                <div className="ModalLog__container">
                    <List
                        size="large"
                        itemLayout="vertical"
                        className="ModalLog__List"
                        loading={this.state.loading}
                        dataSource={this.state.logItems}
                        renderItem={(item: LogItem) => (
                            <List.Item key={item.key}>
                                <List.Item.Meta
                                    title={dayjs(item.date).format("DD.MM.YYYY HH:mm")}
                                    description={item.userEmail}
                                />
                                <Collapse className="ModalLog__Collapse">
                                    {item.changes.deepDiff.map((v, i) => {
                                        return (
                                            <Collapse.Panel
                                                header={this.getDiffTitle(v)}
                                                key={"" + i}
                                                className="ModalLog__CollapsePanel"
                                            >
                                                {this.getDiffContent(v)}
                                            </Collapse.Panel>
                                        );
                                    })}
                                </Collapse>
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
        );
    }
}

export const ModalLog: React.FC<Omit<Props, "visible" | "lister">> = props => {
    const { moduleLogLister } = React.useContext(TableViewContext);
    return <ModalLogClass {...props} visible={!!props.recordKey} lister={moduleLogLister} />;
};
