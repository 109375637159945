import { GQL } from "@binale-tech/shared";
import { GenericRecord } from "../../../models";
import React, { useEffect, useMemo, useRef } from "react";
import { CompanyContext } from "../../CompanyContext";
import { useEnrichAccounts } from "./useEnrichAccounts";
import { child, DataSnapshot } from "firebase/database";
import { refTemplates } from "../../../api/firebase/firebaseRootRefs";
import {
    RecordBank,
    RecordDeb,
    RecordER,
    RecordERAnzahlung,
    RecordFE,
    RecordKB,
    RecordLA,
    RecordPOS,
} from "../../../models/GenericRecord";
import { useCollectionListener } from "../../hooks/useCollectionListener";

const initializers: Map<GQL.IProductKey, (v: any) => GenericRecord> = new Map([
    [GQL.IProductKey.Er, v => new RecordER(v)],
    [GQL.IProductKey.ErA, v => new RecordERAnzahlung(v)],
    [GQL.IProductKey.Deb, v => new RecordDeb(v)],
    [GQL.IProductKey.Fe, v => new RecordFE(v)],
    [GQL.IProductKey.Kb, v => new RecordKB(v)],
    [GQL.IProductKey.Bank, v => new RecordBank(v)],
    [GQL.IProductKey.La, v => new RecordLA(v)],
    [GQL.IProductKey.Pos, v => new RecordPOS(v)],
]);

export const useTemplatesFBRef = (pk: GQL.IProductKey, year: number, groupId?: string) => {
    const { companyGQL } = React.useContext(CompanyContext);
    const enrichAccounts = useEnrichAccounts();
    const enricherRef = useRef(enrichAccounts);
    useEffect(() => {
        enricherRef.current = enrichAccounts;
    }, [enrichAccounts]);
    return useMemo(
        () => ({
            ref: child(refTemplates, `${companyGQL?.id}/${pk}/${groupId ?? "default"}/${year}`),
            initializer: (snap: DataSnapshot | Record<string, any>) => {
                const data: Record<string, any> = snap instanceof DataSnapshot ? snap.val() : snap;
                const record = initializers.get(pk)(data);
                return enricherRef.current(record);
            },
        }),
        [companyGQL?.id, groupId, pk, year]
    );
};

export const useTemplateData = (pk: GQL.IProductKey, groupId?: string) => {
    const { companyGQL, yearConfig } = React.useContext(CompanyContext);

    const isAccountingKey = initializers.has(pk);
    const shouldSkipLoad = useMemo(
        () => !companyGQL || !yearConfig || !isAccountingKey,
        [companyGQL, isAccountingKey, yearConfig]
    );
    const { ref, initializer } = useTemplatesFBRef(pk, yearConfig.year, groupId);

    const { list: templates } = useCollectionListener<GenericRecord>(ref, initializer, shouldSkipLoad);

    return useMemo(() => (shouldSkipLoad ? [] : templates), [templates, shouldSkipLoad]);
};
