import React, { ContextType, useContext, useEffect, useMemo, useRef, useState } from "react";
import RecordFormState from "./types/RecordFormState";
import { IGenericItem } from "../../../scripts/models/Interfaces";
import { FieldError } from "react-hook-form";
import { FormExternalRef } from "./types/ref";
import { PaymentPrototype } from "../../../scripts/models/Payment";

import { GenericRecordFormLifecycle } from "./GenericRecordFormLifecycle";
import { useFormRefsHtml } from "./hooks/useFormRefsHtml";
import {
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext,
} from "../../../scripts/context/accountingTable/tableViewContext";
import { ExternalRecordFormProps, RecordFormPropsContext } from "./context/RecordFormPropsContext";
import { InlineForm } from "./form/InlineForm";
import { useCanWrite } from "../../../scripts/infrastructure/hooks";
import { YearPeriodContext } from "scripts/context/CompanyContext";
import {
    getDefaultItem,
    getDefaultRecord,
    RecordFormStateContext,
    RecordFormStateControlContext,
} from "./context/RecordFormState";

import "./GenericRecordForm.css";
import { useFormRefsData } from "@app/components/recordform/hooks/useFormRefsData";

export const GenericRecordForm = React.forwardRef<FormExternalRef, ExternalRecordFormProps>((props, ref) => {
    const {
        onSave,
        onClear,
        record,
        showDoppelErfassungAlert,
        onDoppelErfassungConfirm,
        onRechnungsnummerBlur,
        isTemplate,
    } = props;
    const { productKey } = useContext(TableViewContext);
    const canWrite = useCanWrite(productKey);
    const refsHtml = useFormRefsHtml();

    const { year, period: periodBound } = useContext(YearPeriodContext);
    const { e2eFilterEntityFormFollow, e2eFilterMode } = useContext(TableFiltersContext);
    const { setE2eFilterEntity } = useContext(TableFiltersControlContext);
    const tableFiltersRef = useRef({ e2eFilterEntityFormFollow, e2eFilterMode });

    const [recordValidationStates, setRecordValidationStates] = useState<Map<React.RefObject<any>, FieldError>>(
        new Map()
    );
    const [itemValidationStates, setItemValidationStates] = useState<Map<React.RefObject<any>, FieldError>>(new Map());
    const [isNettoMode, setIsNettoMode] = useState(false);
    const [isSplitCardOpen, setIsSplitCardOpen] = useState(false);
    const [isDocumentsCardOpen, setIsDocumentsCardOpen] = useState(false);
    const [isModalTemplateOpen, setIsModalTemplateOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<PaymentPrototype>();
    const [recordVirtualNetto, setRecordVirtualNetto] = useState<number>();
    const [recordDate, setRecordDate] = useState<{ date: Date; period: number }>({
        date: undefined,
        period: undefined,
    });
    const [recordItems, setRecordItems] = useState<IGenericItem[]>([]);
    const [editableRecord, setEditableRecord] = useState<RecordFormState["editableRecord"]>(getDefaultRecord());
    const [editableRecordItem, setEditableRecordItem] =
        useState<RecordFormState["editableRecordItem"]>(getDefaultItem());
    const isUpdating = Boolean(record?.key);
    const yearBound = isUpdating ? record.date.getFullYear() : year;

    useEffect(() => {
        tableFiltersRef.current = { e2eFilterEntityFormFollow, e2eFilterMode };
    }, [e2eFilterEntityFormFollow, e2eFilterMode]);

    useEffect(() => {
        const v = editableRecord.recordCategoryCreditor;
        const { e2eFilterEntityFormFollow: follow, e2eFilterMode: mode } = tableFiltersRef.current;

        if (v && follow && mode === "account") {
            requestAnimationFrame(() => setE2eFilterEntity({ account: v }));
        }
    }, [setE2eFilterEntity, editableRecord.recordCategoryCreditor]);

    useEffect(() => {
        const v = editableRecord.recordContact;
        const { e2eFilterEntityFormFollow: follow, e2eFilterMode: mode } = tableFiltersRef.current;
        if (follow && mode === "contact") {
            requestAnimationFrame(() => setE2eFilterEntity(v ? { contact: v } : undefined));
        }
    }, [setE2eFilterEntity, editableRecord.recordContact]);

    const formState: RecordFormState = useMemo(
        () => ({
            recordValidationStates,
            itemValidationStates,
            isNettoMode,
            isSplitCardOpen,
            isDocumentsCardOpen,
            isModalTemplateOpen,
            selectedPayment,
            recordTmpNetto: recordVirtualNetto,
            editableRecordItem,
            editableRecord,
            recordDate,
            recordItems,
        }),
        [
            editableRecord,
            editableRecordItem,
            isSplitCardOpen,
            isDocumentsCardOpen,
            isModalTemplateOpen,
            isNettoMode,
            itemValidationStates,
            recordDate,
            recordItems,
            recordValidationStates,
            recordVirtualNetto,
            selectedPayment,
        ]
    );

    const refsData = useFormRefsData(formState);

    const control: ContextType<typeof RecordFormStateControlContext> = useMemo(
        () => ({
            setRecordValidationStates,
            setItemValidationStates,
            setIsNettoMode,
            setIsSplitCardOpen,
            setIsDocumentsCardOpen,
            setIsModalTemplateOpen,
            setSelectedPayment,
            setRecordVirtualNetto,
            setRecordDate,
            setRecordItems,
            setEditableRecord,
            setEditableRecordItem,
        }),
        []
    );

    const formProps: ContextType<typeof RecordFormPropsContext> = useMemo(
        () => ({
            yearBound,
            refsHtml,
            refsData,
            record,
            isUpdating,
            periodBound,
            disabled: !canWrite || showDoppelErfassungAlert,
            showDoppelErfassungAlert,
            onDoppelErfassungConfirm,
            onRechnungsnummerBlur,
            onSave,
            onClear,
            isTemplate,
        }),
        [
            canWrite,
            isUpdating,
            onDoppelErfassungConfirm,
            onRechnungsnummerBlur,
            periodBound,
            record,
            refsHtml,
            refsData,
            showDoppelErfassungAlert,
            yearBound,
            onSave,
            onClear,
            isTemplate,
        ]
    );

    return (
        <RecordFormPropsContext.Provider value={formProps}>
            <RecordFormStateContext.Provider value={formState}>
                <RecordFormStateControlContext.Provider value={control}>
                    <GenericRecordFormLifecycle ref={ref}>
                        <InlineForm />
                    </GenericRecordFormLifecycle>
                </RecordFormStateControlContext.Provider>
            </RecordFormStateContext.Provider>
        </RecordFormPropsContext.Provider>
    );
});
