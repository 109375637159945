import Dropzone from "react-dropzone";
import React, { memo } from "react";
import { KontoNumUtils } from "@binale-tech/shared";
import { Button, Flex, message, Space } from "antd";
import { CloseOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import Container from "../../../components/shared/appearance/page/Container";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { KontoContext, KontoControlContext } from "scripts/context/accountingData/KontoEntitiesProvider";
import { Creditor } from "scripts/models";
import { Debitor } from "scripts/models/Creditor";
import { FlexFillBlock, PageFullScreen } from "../../../components/shared/appearance/page/Scaffold";
import { GlobalYearSelect } from "../../../components/shared/Toolbar/YearSelect";
import { PageHeader } from "../../../components/shared/appearance/page";
import { DatevTools } from "./datevTools/DatevTools";

const DatevAccountsImportView: React.FC = () => {
    const intl = useIntl();
    const { yearConfig } = React.useContext(CompanyContext);
    const { year: globalYear } = React.useContext(YearPeriodContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;

    const { creditors, debitors } = React.useContext(KontoContext);
    const { creditorSave, debSave } = React.useContext(KontoControlContext);

    const [isImporting, setIsImporting] = React.useState(false);

    const [missingAcconts, setMissingAccounts] = React.useState<{
        creditors?: Creditor[];
        debitors?: Debitor[];
    }>({});
    const isPreviewActive = missingAcconts.creditors?.length > 0 || missingAcconts.debitors?.length > 0;

    const handleDatevAccountsAccepted = async (file: File) => {
        Promise.resolve()
            .then(async () => {
                const { config, data } = await DatevTools.parseDatevFile(file);
                DatevTools.validateHeader(config, {
                    year: globalYear,
                    kontoExt,
                    headerStart: "700,16,Debitoren/Kreditoren,5",
                });

                const missingCred: Creditor[] = [];
                const missingDeb: Debitor[] = [];
                data.forEach(line => {
                    const num = line.Konto;
                    const name = line["Name (Adressattyp Unternehmen)"];
                    if (KontoNumUtils.isDebitor({ stringNum: num.substring(0, 5) }, 0)) {
                        const account = new Debitor(num, name);
                        if (!debitors.find(v => v.equalsTo(account))) {
                            missingDeb.push(account);
                        }
                    } else if (KontoNumUtils.isCreditor({ stringNum: num.substring(0, 5) }, 0)) {
                        const account = new Creditor(num, name);
                        if (!creditors.find(v => v.equalsTo(account))) {
                            missingCred.push(account);
                        }
                    } else {
                        console.error("unknown", num, name);
                    }
                });

                setMissingAccounts({
                    creditors: missingCred,
                    debitors: missingDeb,
                });
            })
            .catch(e => {
                message.error(e.message);
            });
    };

    const importAccounts = async () => {
        setIsImporting(true);
        for (const deb of missingAcconts.debitors) {
            await debSave(deb);
        }
        for (const cred of missingAcconts.creditors) {
            await creditorSave(cred);
        }
        setIsImporting(false);
    };

    const handleReset = () => {
        setMissingAccounts({});
        setIsImporting(false);
    };

    return (
        <PageFullScreen>
            <PageHeader>
                DATEV <FormattedMessage id={"app.titles.data_import"} />:{" "}
                <FormattedMessage id="app.titles.debitor.pl" /> / <FormattedMessage id="app.titles.creditor.pl" />
            </PageHeader>
            <Flex justify={"space-between"} style={{ margin: "5px 0" }}>
                <Space>
                    <GlobalYearSelect />

                    {isPreviewActive ? null : (
                        <Dropzone
                            accept={{ "text/csv": [".csv"] }}
                            multiple={false}
                            onDropAccepted={files => {
                                handleDatevAccountsAccepted(files[0]);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <Button
                                        style={{ width: 250 }}
                                        type="primary"
                                        icon={<UploadOutlined />}
                                        htmlType="button"
                                    >
                                        {intl.formatMessage({ id: "app.button.upload" })}
                                        &nbsp;DATEV&nbsp;CSV
                                    </Button>
                                </div>
                            )}
                        </Dropzone>
                    )}
                </Space>

                <Space>
                    {isPreviewActive && (
                        <>
                            <div>
                                <span>
                                    <FormattedMessage id="app.titles.debitor.pl" /> : {missingAcconts?.debitors?.length}
                                </span>
                                <span> / </span>
                                <span>
                                    <FormattedMessage id="app.titles.creditor.pl" />:{" "}
                                    {missingAcconts?.creditors?.length}
                                </span>
                            </div>
                            <Button icon={<CloseOutlined />} onClick={handleReset}>
                                {intl.formatMessage({ id: "app.button.cancel" })}
                            </Button>
                        </>
                    )}

                    <Button
                        style={{ width: 250 }}
                        type="primary"
                        icon={<SaveOutlined />}
                        disabled={!isPreviewActive || isImporting}
                        loading={isImporting}
                        onClick={() => importAccounts()}
                    >
                        {intl.formatMessage({ id: "app.button.save" })}{" "}
                    </Button>
                </Space>
            </Flex>
            {isPreviewActive && (
                <FlexFillBlock style={{ marginBottom: 5 }}>
                    <Container absolute>{(w, h) => <div></div>}</Container>
                </FlexFillBlock>
            )}
        </PageFullScreen>
    );
};
export default memo(DatevAccountsImportView);
