import React, { FC, useCallback } from "react";
import { getIBANcountryCode, InputIBANLabel, InputIbanToBanks } from "@app/components/shared/form/baseComponents";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { useApolloClient } from "@apollo/client";
import { validation } from "../../../scripts/infrastructure/helpers/validation";
import styles from "./FormFields.module.scss";

// TODO temporary solution
import { ExtractIBANResult, getCountrySpecifications } from "@banks/scripts/ibantools";
import { Form } from "antd";

const feedbackIcons = () => {
    let isValidated = false;
    return ({ status }: { status: "" | "success" | "warning" | "error" | "validating" }) => {
        if (status === "validating") {
            isValidated = true;
            return {};
        }
        if (!isValidated) {
            return { success: <span></span> };
        }
        isValidated = false;
        return {};
    };
};

type TProps = {
    value: string;
};

export const IbanField: FC<TProps> = ({ value }) => {
    const form = Form.useFormInstance();
    const client = useApolloClient();
    const countryCode = getIBANcountryCode(value);
    const countrySpec = getCountrySpecifications()[countryCode];
    const maxLength = countrySpec?.chars;
    const onBlur = useCallback(
        (bankData: ExtractIBANResult) => {
            form.setFieldValue("accountNumber", bankData.accountNumber);
            form.setFieldValue("bankId", bankData.bankIdentifier);
            form.validateFields(["accountNumber", "bankId"]);
        },
        [form]
    );
    return (
        <FieldLabel
            validateDebounce={200}
            className={styles.bankFormField}
            label={<InputIBANLabel countryCode={countryCode} />}
            name={"IBAN"}
            rules={[validation.iban(client)]}
            validateTrigger={["onBlur"]}
            hasFeedback={{
                icons: feedbackIcons(),
            }}
        >
            <InputIbanToBanks maxLength={maxLength} showCount onBlur={onBlur}></InputIbanToBanks>
        </FieldLabel>
    );
};
