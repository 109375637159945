import { Form } from "antd";
import { Switch } from "@heroui/switch";
import { FormattedMessage } from "react-intl";
import React, { FC, useContext } from "react";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormConfig } from "../hooks/useFormConfig";
import { useFormHandlerRecordLastschrift } from "../hooks/handlers/useFormHandlerRecordLastschrift";

export const FormRecordLastschrift: FC = () => {
    const { useLastschrift } = useFormConfig();
    const { disabled } = useContext(RecordFormPropsContext);
    const { editableRecord } = useContext(RecordFormStateContext);
    const onChangeLastschrift = useFormHandlerRecordLastschrift();
    if (!useLastschrift) {
        return null;
    }
    return (
        <Form.Item>
            <Switch
                size="sm"
                color="default"
                isDisabled={disabled}
                isSelected={editableRecord.recordLastschrift}
                tabIndex={-1}
                onValueChange={onChangeLastschrift}
            >
                <FormattedMessage id="app.fields.lastschrift" />
            </Switch>
        </Form.Item>
    );
};
