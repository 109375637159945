import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Input, Row, Space } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Rule } from "antd/es/form";

import Category from "scripts/models/Category";
import { Base } from "@binale-tech/shared";
import { CompanyContext } from "scripts/context/CompanyContext";
import { BuContext } from "scripts/context/BuContext";
import { KontoContext } from "scripts/context/accountingData/KontoEntitiesProvider";
import { BuTaxesSKR } from "../../../../../scripts/models/BuTaxUtils";
import { CopyOutlined } from "@ant-design/icons";
import { validation } from "scripts/infrastructure/helpers/validation";

interface OwnProps {
    item: Base.INumName;
    disabled?: boolean;
    onSave?: (v: Base.INumName) => Promise<void>;
    onClose?: () => void;
}

export const CategoryForm: FC<OwnProps> = ({ item, disabled, onClose, onSave }) => {
    const intl = useIntl();
    const [form] = Form.useForm<Base.INumName>();
    const { yearConfig } = useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const { categoryOverrides } = useContext(KontoContext);
    const { companyBuTimeframes } = useContext(BuContext);
    const defaultCategories = BuTaxesSKR.getBuTimeframeCategoriesByYear(
        yearConfig.skr,
        new Date().getFullYear(),
        companyBuTimeframes
    );

    const categoryNumFixed = item ? item.num.substring(0, Category.DefaultLen) : "";

    const uniqueCategories = useMemo(
        () =>
            categoryOverrides
                .filter(category => category.num.startsWith(categoryNumFixed))
                .map(category => category.num.substring(Category.DefaultLen)),
        [categoryNumFixed, categoryOverrides]
    );

    const isCreating = !item?.key;
    const [loading, setLoading] = useState(false);

    const numValidator = React.useMemo(() => {
        const list: Rule[] = [...validation.commonNumber({ intl, min: kontoExt, max: kontoExt })];
        if (isCreating) {
            list.push(validation.unique(uniqueCategories, item?.num));
        }
        if (kontoExt > 0) {
            list.push(validation.required(intl));
        }
        return list;
    }, [intl, isCreating, item?.num, kontoExt, uniqueCategories]);

    useEffect(() => {
        if (item) {
            const formItem = { ...item, num: kontoExt ? item.num.substring(Category.DefaultLen) : "" };
            form.setFieldsValue(formItem);
        } else {
            form.setFieldsValue(item);
        }
    }, [item, form, kontoExt]);

    const handleSubmit = async (values: Base.INumName) => {
        setLoading(true);
        const num = categoryNumFixed + (values.num || "");
        await onSave({ ...values, num });
        setLoading(false);
        requestAnimationFrame(() => onClose());
    };

    const defaultCategoryName =
        defaultCategories.get(Number(item?.num.substring(0, Base.KontoDefaultLen.Category)))?.name ?? "";

    return (
        <Form onFinish={handleSubmit} layout="vertical" form={form}>
            <Form.Item label={intl.formatMessage({ id: "app.fields.konto" })} name="num" rules={numValidator}>
                <Input addonBefore={categoryNumFixed} disabled={disabled || !isCreating} maxLength={kontoExt} />
            </Form.Item>
            <Form.Item
                label={intl.formatMessage({ id: "app.fields.bezeichnung" })}
                name="name"
                rules={[
                    { max: 200, message: "too long" },
                    { required: true, message: intl.formatMessage({ id: "app.validation.error.field_empty" }) },
                ]}
            >
                <Input disabled={disabled} maxLength={200} />
            </Form.Item>
            <Form.Item
                label={
                    <span>
                        Original <FormattedMessage id={"app.fields.bezeichnung"} />
                    </span>
                }
            >
                <Space.Compact style={{ width: "100%" }}>
                    <Input disabled value={defaultCategoryName} />
                    <Button
                        icon={<CopyOutlined />}
                        disabled={!item}
                        onClick={() => navigator.clipboard.writeText(defaultCategoryName)}
                    />
                </Space.Compact>
            </Form.Item>
            <Row>
                <Button type="primary" htmlType="submit" disabled={disabled || loading} loading={loading}>
                    <FormattedMessage id="app.button.save" tagName="span" />
                </Button>
            </Row>
        </Form>
    );
};
