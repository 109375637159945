import React, { FC } from "react";
import { Flex } from "antd";
import {
    InvoiceLineItemTemplateListHeader
} from "@inv/components/InvoiceLineItemTemplates/InvoiceLineItemTemplateListHeader";
import { InvoiceLineItemTemplateList } from "@inv/components/InvoiceLineItemTemplates/InvoiceLineItemTemplateList";
import { useInvoiceLineItemTemplates } from "@inv/hooks/useInvoiceLineItemTemplates";

export const InvoiceLineItemTemplateListPage: FC = () => {
    const { templatesList, refetch } = useInvoiceLineItemTemplates();
    return (
        <Flex
            vertical
            style={{
                height: "100%",
            }}
        >
            <InvoiceLineItemTemplateListHeader refetch={refetch} />
            <InvoiceLineItemTemplateList templatesList={templatesList} refetch={refetch} />
        </Flex>
    );
};
