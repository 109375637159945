import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { child, DataSnapshot } from "firebase/database";
import { TInvoicesListColumnsConfig } from "@inv/types";
import { searchInAllFields } from "@inv/scripts/utils/utils";
import { InvoicesListTableColumns } from "@inv/components/InvoicesList/config/tableColumns";
import { InvoicesApi } from "@inv/scripts/api";
import { CompanyContext } from "../../scripts/context/CompanyContext";
import { useGqlMutator } from "../../scripts/graphql/useGqlMutator";
import { useCollectionListener } from "../../scripts/context/hooks/useCollectionListener";
import { refInvoices } from "../../scripts/api/firebase/firebaseRootRefs";
import { GQL } from "@binale-tech/shared";

type TValue = {
    invoicesFilteredList?: GQL.IInvoice[];
    invoicesMap: Map<string, GQL.IInvoice>;
    invoiceListColumnsConfig: TInvoicesListColumnsConfig;
    isOpenSettingsColumns: boolean;
    searchValue: string;
};

type TActionValue = {
    setColumnConfig: (newValue: TInvoicesListColumnsConfig) => void;
    setViewConfig: (newValue: boolean) => void;
    setSearchValue: (newValue: string) => void;
};

const initialValue: TValue = {
    invoiceListColumnsConfig: InvoicesListTableColumns.invoicesListInitColumnsConfig,
    isOpenSettingsColumns: false,
    searchValue: "",
    invoicesMap: new Map(),
};

const initialActionValue = {
    setColumnConfig: () => {},
    setViewConfig: () => {},
    setSearchValue: () => {},
};

export const InvoicesDataContext = createContext<TValue>(initialValue);
export const InvoicesListControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoicesDataProvider: FC<TProps> = ({ children }) => {
    const { companyGQL } = useContext(CompanyContext);

    const companyId = companyGQL?.id;
    const mutator = useGqlMutator();

    useEffect(() => {
        InvoicesApi.mutator = mutator;
        InvoicesApi.companyId = companyId;
    }, [companyId, mutator]);

    const [invoiceListColumnsConfig, setInvoiceListColumnsConfig] = useState<TInvoicesListColumnsConfig>(
        initialValue.invoiceListColumnsConfig
    );
    const [isOpenSettingsColumns, setIsOpenSettingsColumns] = useState<boolean>(initialValue.isOpenSettingsColumns);
    const [searchValue, setSearchValue] = useState<string>("");

    const shouldSkipLoad = useMemo(() => !companyId, [companyId]);
    const ref = useMemo(() => child(refInvoices, companyId), [companyId]);
    const initializer = useCallback((snap: DataSnapshot) => snap.val(), []);

    const { list: invoicesList, state: invoicesMap } = useCollectionListener(ref, initializer, shouldSkipLoad);

    const invoicesFilteredList = useMemo(
        () => (searchValue ? invoicesList.filter(el => searchInAllFields(el, searchValue)) : invoicesList),
        [invoicesList, searchValue]
    );

    const value: React.ContextType<typeof InvoicesDataContext> = useMemo(
        () => ({
            invoicesMap,
            invoicesFilteredList,
            invoiceListColumnsConfig,
            isOpenSettingsColumns,
            searchValue,
        }),
        [invoiceListColumnsConfig, invoicesMap, invoicesFilteredList, isOpenSettingsColumns, searchValue]
    );
    const actions = useMemo(
        () => ({
            setColumnConfig: (newValue: TInvoicesListColumnsConfig) => setInvoiceListColumnsConfig(newValue),
            setViewConfig: (newValue: boolean) => setIsOpenSettingsColumns(newValue),
            setSearchValue: (newValue: string) => setSearchValue(newValue),
        }),
        []
    );

    return (
        <InvoicesDataContext.Provider value={value}>
            <InvoicesListControlContext.Provider value={actions}>{children}</InvoicesListControlContext.Provider>
        </InvoicesDataContext.Provider>
    );
};
