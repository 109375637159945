import Category from "../../models/Category";
import Creditor, { Debitor } from "../../models/Creditor";
import Tag from "../../models/Tag";
import { Bu, Periods } from "@binale-tech/shared";
import { GenericRecord } from "../../models";
import { IGenericItem, ItemRecordContext } from "../../models/Interfaces";
import { PaymentStatusSelectorType, ReviewStatusSelectorType } from "@app/components/toolbar/components/buttons";

export enum GenericToolbarFilters {
    Year = "year",
    Period = "period",
    Konto = "konto",
    E2EKonto = "e2eKonto",
    Contact = "contact",
    TextSearch = "search",
    Duplicates = "duplicates",
    PaymentStatus = "paymentStatus",
    ReviewStatus = "reviewStatus",
}

export interface IFilters {
    dateFrom?: Date | null;
    dateTo?: Date | null;
    year?: number;
    period?: Periods.Period;
    creditor?: Creditor | null;
    debitor?: Debitor | null;
    num?: string | null;

    konto?: Creditor | Category;
    e2eKonto?: Creditor | Category;
    contact?: { id?: string; name: string };
    tag?: Tag;
    bu?: Bu.Bu | "all";
    brutto?: number;
    text?: string;
    text2?: string;
    belegfeld2?: string;
    default?: string;
    search?: string;
    duplicates?: string;
    paymentStatus?: PaymentStatusSelectorType;
    reviewStatus?: ReviewStatusSelectorType;
}

export type FilterPredicateType = (v: GenericRecord) => boolean;

export const searchPredicate = (record: GenericRecord, value: string, skr: number): boolean => {
    if (!value) {
        return true;
    }
    value = value.toLowerCase();
    const recordCtx: ItemRecordContext = {
        recordKonto: record.getRecordCategoryCreditor(),
        product: record.getProductKey(),
        year: record.year,
        period: record.period,
    };

    const accountNumberSearch = (record.num ?? "").toLowerCase().includes(value);
    if (accountNumberSearch) {
        return accountNumberSearch;
    }

    const brutto = String(record.brutto);
    const bruttoSearch = `${brutto.slice(0, -2)},${brutto.slice(-2)}`.includes(value);
    if (bruttoSearch) {
        return bruttoSearch;
    }

    const ust = String(record.items.reduce((acc, i) => acc + i.getVatEuro(recordCtx, skr), 0));
    const ustSearch = `${ust.slice(0, -2)},${ust.slice(-2)}`.includes(value);
    if (ustSearch) {
        return ustSearch;
    }

    const netto = String(record.items.reduce((acc, i) => acc + i.getNetto(recordCtx, skr), 0));
    const nettoSearch = `${netto.slice(0, -2)},${netto.slice(-2)}`.includes(value);
    if (nettoSearch) {
        return nettoSearch;
    }

    const textSearch = record.items?.some(
        v =>
            v?.text?.toLowerCase().includes(value) ||
            v?.text2?.toLowerCase().includes(value) ||
            v?.belegfeld2?.toLowerCase().includes(value) ||
            v?.belegfeld1?.toLowerCase().includes(value)
    );
    if (textSearch) {
        return textSearch;
    }

    for (const doc of record.documents) {
        if (doc.id.toLowerCase() === value) {
            return true;
        }
    }

    return false;
};
const stringPredicate = (valueName: keyof IFilters) => (value: string | null) => (record: GenericRecord) => {
    if (!value) {
        return true;
    }
    const lowerCase = value.toLowerCase();
    return record.items.some(v =>
        (v[valueName as keyof IGenericItem] || "").toString().toLowerCase().includes(lowerCase)
    );
};

const kontoFilter = (value?: Creditor | Category | null) => (record: GenericRecord) =>
    !value ||
    value?.num === undefined ||
    record.getRecordCategoryCreditor().equalsTo(value) ||
    record.items.some(item => item.getCategoryCreditor().equalsTo(value));

export const defaultPredicates: Record<keyof IFilters, (value: unknown) => FilterPredicateType> = {
    creditor: (value: Creditor | null) => (record: GenericRecord) =>
        !value || value.key === null || record.creditor.equalsTo(value),
    debitor: (value: Debitor | null) => (record: GenericRecord) =>
        !value || value.key === null || record.debetor.equalsTo(value),
    year: (year: number) => record => (Number.isFinite(year) ? record.date.getFullYear() === year : true),
    period: (period: number) => record => (Number.isFinite(period) ? record.period === period : true),
    dateFrom: (value: Date | null) => bill => !value || new Date(bill.date) >= value,
    dateTo: (value: Date | null) => bill => !value || new Date(bill.date) <= value,
    num: (value: string | null) => bill =>
        value === "" ||
        (bill.num?.length && bill.num.includes(value)) ||
        !!bill.items.find(v => v.belegfeld1?.length && v.belegfeld1.includes(value)),

    brutto: (value: number) => bill => !value || Math.abs(bill.getBrutto()) === Math.abs(value),
    konto: kontoFilter,
    e2eKonto: kontoFilter,
    contact: (value?: { id?: string; name: string }) => record => {
        if (!value) {
            return true;
        }
        if (!record.partner) {
            return false;
        }
        if (value.id) {
            return record.partner.id === value.id;
        }
        if (value.name) {
            return record.partner.name.includes(value.name);
        }
        return false;
    },
    tag: (value: Tag) => record => {
        if (!value) {
            return true;
        }
        const filtered = record.items.filter(item => item.tag && item.tag.num === value.num);
        return value.key === null || filtered.length > 0;
    },
    bu: (value: Bu.Bu | null) => bill => value == null || bill.items.filter(item => +item.bu === value).length > 0,
    text: stringPredicate("text"),
    text2: stringPredicate("text2"),
    belegfeld2: stringPredicate("belegfeld2"),
    default: () => () => true,
    search: () => () => true,
    duplicates: () => () => true,
    paymentStatus: () => () => true,
    reviewStatus: (value: ReviewStatusSelectorType) => record => {
        if (!value) {
            return true;
        }
        if (value === "new") {
            return !record.review;
        }
        return record.review === value;
    },
};
