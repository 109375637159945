import BinaleForm from "../shared/form/BinaleForm";
import React, { useCallback, useContext, useMemo } from "react";
import { CompanyContext, YearPeriodContext } from "../../../scripts/context/CompanyContext";
import {
    ConfirmProps,
    EyeButtonProps,
    JournalProps,
    PaymentStatusSelectorType,
    PrioritySectionProps,
    ReviewStatusSelectorType,
} from "./components/buttons";
import {
    defaultPredicates,
    GenericToolbarFilters,
    searchPredicate,
} from "../../../scripts/context/accountingTable/tableViewFilters";
import { KAProps } from "./components/comboboxes";
import { TableColumns } from "../../columns/ColumnConfig";
import {
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext,
} from "../../../scripts/context/accountingTable/tableViewContext";
import { ToolbarComponents } from "./components";
import "./GenericToolbar.css";
import { GQL, Utils } from "@binale-tech/shared";
import { GenericRecord } from "../../../scripts/models";
import { Badge, Button, Flex, Form, Popover, Space } from "antd";
import { PaymentsContext } from "../../../scripts/context/accountingData/PaymentsProvider";
import { FilterOutlined } from "@ant-design/icons";
import { GenericRecordProperties } from "../../../scripts/core/Product";

interface GenericToolbarProps
    extends Omit<KAProps, "years" | "creditor" | "onCreditorChange" | "onFormFollowChange" | "isFormFollowEnabled">,
        JournalProps,
        ConfirmProps,
        Omit<PrioritySectionProps, "onMoveUpClick" | "onMoveDownClick">,
        EyeButtonProps {
    tableColumns?: TableColumns<any>;
    withJournal?: boolean;
    withConfirm?: boolean;
    withKontenAnsicht?: boolean;
}

export const GenericToolbar: React.FC<GenericToolbarProps> = props => {
    const [searchFilter, setSearchFilter] = React.useState("");
    const { year, period, onChangeYear, onChangePeriod } = useContext(YearPeriodContext);
    const { yearConfig, companyGQL } = useContext(CompanyContext);
    const { recordRelation } = useContext(PaymentsContext);
    const { view, productKey, selectedRecordGroup, product, moduleActions } = useContext(TableViewContext);
    const skr = yearConfig?.skr;
    const accountingYears = useMemo(() => {
        if (productKey === GQL.IProductKey.Kb || productKey === GQL.IProductKey.Bank) {
            return [...companyGQL.bankList, ...companyGQL.kasseList]
                .filter(v => v.id === selectedRecordGroup)
                .map(v => v.year);
        }
        return companyGQL?.accountingYears || [];
    }, [companyGQL, selectedRecordGroup, productKey]);

    const { setFilter } = useContext(TableFiltersControlContext);
    const { e2eFilterEntity, e2eFilterEntityFormFollow, filters } = useContext(TableFiltersContext);
    const { showRemoved, onToggleHidden } = props;

    const handleSearch = (value: string) => {
        setFilter(GenericToolbarFilters.TextSearch, {
            value,
            predicate: record => searchPredicate(record, value, skr),
        });
    };
    const handlePaymentStatus = useCallback(
        (value: PaymentStatusSelectorType) => {
            setFilter(GenericToolbarFilters.PaymentStatus, {
                value,
                predicate: record => {
                    if (!value) {
                        return true;
                    }
                    const isOpen = record.getOpenBrutto(recordRelation.get(record.key)) !== 0;
                    if (value === "bezahlt") {
                        return !isOpen;
                    }
                    if (value === "offen") {
                        return isOpen;
                    }
                    if (value === "avis") {
                        return record.avis && isOpen;
                    }
                    return false;
                },
            });
        },
        [recordRelation, setFilter]
    );
    const handleReviewStatus = useCallback(
        (value: ReviewStatusSelectorType) => {
            setFilter(GenericToolbarFilters.ReviewStatus, {
                value,
                predicate: defaultPredicates.reviewStatus(value),
            });
        },
        [setFilter]
    );
    const onMoveUpClick = useCallback((vs: GenericRecord[], prev: GenericRecord) => {
        moduleActions.sinkFirstDown([prev, ...vs]);
    }, []);
    const onMoveDownClick = useCallback((vs: GenericRecord[], next: GenericRecord) => {
        moduleActions.swimLastUp([...vs, next]);
    }, []);

    const isActiveFollowBlock = Boolean(e2eFilterEntity) || e2eFilterEntityFormFollow;
    const paymentStatusValue = filters.get(GenericToolbarFilters.PaymentStatus)?.value;
    const reviewStatusValue = filters.get(GenericToolbarFilters.ReviewStatus)?.value;

    return (
        <BinaleForm className="GenericToolbar" disableEnterNavigation>
            <Flex justify={"space-between"} align={"flex-end"}>
                <Space align={"end"}>
                    <ToolbarComponents.DateSection
                        period={period}
                        year={year}
                        years={accountingYears}
                        withAll
                        withArrows
                        onPeriodChange={onChangePeriod}
                        onYearChange={onChangeYear}
                        disableYear={isActiveFollowBlock}
                    />
                    <div style={{ width: 100 }} />
                    {props.withKontenAnsicht && (
                        <ToolbarComponents.KASection
                            className="KASectionContainer"
                            categoryCreditorMode={props.categoryCreditorMode}
                            years={accountingYears}
                        />
                    )}
                </Space>
                <Space>
                    <ToolbarComponents.SearchInput
                        style={{ marginLeft: "auto" }}
                        onSearch={handleSearch}
                        onChange={setSearchFilter}
                        value={searchFilter}
                    />
                    {product.getConfig().usePriorityOrder && (
                        <ToolbarComponents.PrioritySection
                            // toSort={props.toSort}
                            // onSortClicked={props.onSortClicked}
                            toMoveItems={props.toMoveItems}
                            toMovePrev={props.toMovePrev}
                            onMoveUpClick={onMoveUpClick}
                            toMoveNext={props.toMoveNext}
                            onMoveDownClick={onMoveDownClick}
                            onMoveOkClick={props.onMoveOkClick}
                        />
                    )}

                    {props.withConfirm && (
                        <ToolbarComponents.ConfirmButton
                            onConfirmClicked={props.onConfirmClicked}
                            toConfirm={props.toConfirm}
                        />
                    )}
                    {props.withJournal && (
                        <ToolbarComponents.JournalButton
                            onJournalClicked={props.onJournalClicked}
                            toJournal={props.toJournal}
                        />
                    )}
                    <Popover
                        destroyTooltipOnHide
                        trigger="click"
                        placement={"bottomLeft"}
                        content={
                            <Form layout={"vertical"}>
                                <Form.Item label={GenericRecordProperties.RecordReview}>
                                    <ToolbarComponents.ReviewStatusSelector
                                        reviewStatusValue={reviewStatusValue}
                                        onReviewStatusChange={handleReviewStatus}
                                    />
                                </Form.Item>
                                {Utils.PaymentUtils.isRecordPaymentSource(product.productKey() as GQL.IProductKey) && (
                                    <Form.Item label={GenericRecordProperties.ComputedStatus}>
                                        <ToolbarComponents.PaymentStatusSelector
                                            paymentStatusValue={paymentStatusValue}
                                            onPaymentStatusChange={handlePaymentStatus}
                                            useAvis
                                        />
                                    </Form.Item>
                                )}
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <ToolbarComponents.ShowRemovedSwitch
                                        showRemoved={showRemoved}
                                        onToggleHidden={onToggleHidden}
                                    />
                                </Form.Item>
                            </Form>
                        }
                    >
                        <Badge dot={Boolean(paymentStatusValue) || showRemoved || Boolean(reviewStatusValue)}>
                            <Button icon={<FilterOutlined />} tabIndex={-1} />
                        </Badge>
                    </Popover>

                    <ToolbarComponents.AnsichtBtn tableColumns={props.tableColumns} view={view} />
                </Space>
            </Flex>
        </BinaleForm>
    );
};
