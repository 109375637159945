import { CsvParser } from "../../../../../scripts/csv/parser/CsvParser";

export class DatevTools {
    static async parseDatevFile(file: File): Promise<{ config: string[]; data: Record<string, string>[] }> {
        const [config]: string[][] = await CsvParser.parseFile(file, { toLine: 2, columns: false });
        const data: Record<string, string>[] = await CsvParser.parseFile(file, { fromLine: 2 });
        return { config, data };
    }
    static validateHeader(config: string[], data: { year: number; kontoExt: number; headerStart: string }) {
        const headerStart = config.slice(1, 5).toString();
        if (headerStart !== data.headerStart) {
            throw new Error(
                `Invalid header: DATEV import file should contain "${data.headerStart}", parsed "${headerStart}". Try different import mode`
            );
        }
        if (Number(config[13]) - 4 !== data.kontoExt) {
            throw new Error(`Konten length mismatch, file: ${config[13]}, your company: ${data.kontoExt + 4}`);
        }
        const year = Number(config[12].substring(0, 4));
        if (data.year !== year) {
            throw new Error(`Import file is configured for year ${year}. Please select this year`);
        }
    }
}
