import { WalletOutlined } from "@ant-design/icons";
import { Base, GQL } from "@binale-tech/shared";
import { Menu, type MenuProps } from "antd";
import React, { type FC, useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { ALL_DOCUMENTS } from "@dms/configs/constants";
import { useMenuDocumentTypes } from "@dms/hooks/useMenuDocumentTypes";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

import { SidebarMenuItemContent } from "./components/SidebarMenuItemContent/SidebarMenuItemContent";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { UserContext as UserContextGlobal } from "../../../scripts/context/UserProvider";
import { ProductAccessUtils } from "../../../scripts/models/utils/ProductAccessUtils";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { DmsType } from "@dms/types";
import { MenuItemType } from "antd/es/menu/interface";

const backgroundColorSelectedType = "#c4e5ff";

export const hasFolderAccess = (type: string, userCtx: Base.UserInterface, companyGQL: GQL.ICompany) => {
    const hasDmsAdminRole = ProductAccessUtils.hasUserRole(companyGQL, userCtx, GQL.IUserRole.DmsAdmin);
    if (hasDmsAdminRole) {
        return true;
    }
    const hasDmsNewDocsRole = ProductAccessUtils.hasUserRole(companyGQL, userCtx, GQL.IUserRole.DmsNewDocuments);
    if (hasDmsNewDocsRole) {
        return type === DmsType.new_documents;
    }

    return ProductAccessUtils.canRead(GQL.IProductKey.Dms, companyGQL, userCtx);
};

export const SidebarMenu: FC = () => {
    const navigate = useNavigate();
    const [activeTypeChangeMode, setActiveTypeChangeMode] = useState<string>("");
    const documentTypesArray = useMenuDocumentTypes(activeTypeChangeMode);
    const mutator = useGqlMutator();

    const { activeType, isTypeChangeMode, isDraggingRightNow, selectedRowKeys, openedMenuItemKeys } =
        useContext(DmsAppContext);
    const { setActiveType, setOpenedMenuItemKeys, typeChangeModeToggle } = useContext(DmsAppControlContext);
    const { documents } = useContext(DmsDataContext);
    const userCtx = useContext(UserContextGlobal);
    const { companyGQL } = useContext(CompanyContext);

    const allDocumentsDraftCount = useMemo(
        () => documents.filter(el => DmsUtils.getDocumentStatus(el) === GQL.IDocumentStatus.Draft).length,
        [documents]
    );

    const onOpenChange = useCallback(
        (keys: string[]): void => {
            setOpenedMenuItemKeys(keys);
        },
        [setOpenedMenuItemKeys]
    );

    const handleChangeDocumentsType = useCallback(
        async (typeKeyPath: string[]): Promise<void> => {
            const { type, subType } = DmsUtils.getActiveTypeAndSubType(typeKeyPath);

            const updateDocuments = documents
                .filter(el => selectedRowKeys.includes(el.key))
                .map(el => ({ ...el, type, subType }));
            await DocumentsApi.changeDocumentsType(updateDocuments, mutator);
            setActiveTypeChangeMode("");
        },
        [documents, mutator, selectedRowKeys]
    );

    const handleMenuLink: MenuProps["onClick"] = useCallback(
        async (data): Promise<void> => {
            // setFilterConfig(activeType, {
            //     selectedFilter: false,
            //     filterState: null,
            //     sortState: null,
            // });

            const { type } = DmsUtils.getActiveTypeAndSubType(data.keyPath);

            const isSelectTypeMode =
                isTypeChangeMode && data.key !== DmsType.new_documents && data.key !== `create_new_${type}`;
            if (isSelectTypeMode) {
                setActiveTypeChangeMode(data.key);
                typeChangeModeToggle(false);
                await handleChangeDocumentsType(data.keyPath);
                return;
            }

            if (data.key !== `create_new_${type}`) {
                setActiveType(data.keyPath);
                setActiveTypeChangeMode("");
                navigate("/documents");
                return;
            }
        },
        [handleChangeDocumentsType, isTypeChangeMode, navigate, setActiveType, typeChangeModeToggle]
    );

    const items: MenuProps["items"] = useMemo(
        () => [
            {
                label: (
                    <SidebarMenuItemContent
                        id={ALL_DOCUMENTS}
                        name={<FormattedMessage id="app.dms.allDocuments" />}
                        countDraft={allDocumentsDraftCount}
                        countTotal={documents.length}
                        isSelected={activeType[0] === ALL_DOCUMENTS}
                        isNode={false}
                        typeKeyPath={[ALL_DOCUMENTS]}
                    />
                ),
                key: ALL_DOCUMENTS,
                disabled:
                    isTypeChangeMode || isDraggingRightNow || !hasFolderAccess(ALL_DOCUMENTS, userCtx, companyGQL),
                icon: <WalletOutlined />,
                style: {
                    backgroundColor: activeType[0] === ALL_DOCUMENTS ? backgroundColorSelectedType : "",
                },
            },
            ...documentTypesArray.map(({ typeKeyPath, ...item }) => {
                const { type } = DmsUtils.getActiveTypeAndSubType(typeKeyPath);
                return {
                    ...item,
                    disabled: (item as MenuItemType).disabled || !hasFolderAccess(type, userCtx, companyGQL),
                };
            }),
        ],
        [
            activeType,
            allDocumentsDraftCount,
            companyGQL,
            documentTypesArray,
            documents.length,
            isDraggingRightNow,
            isTypeChangeMode,
            userCtx,
        ]
    );

    return (
        <Menu
            mode="inline"
            defaultSelectedKeys={[`${activeType || GQL.IProductKey.Er}`]}
            openKeys={openedMenuItemKeys}
            style={{
                borderRight: 0,
                background: "#F4F6F7",
                marginTop: "5px",
            }}
            items={items}
            onClick={handleMenuLink}
            onOpenChange={onOpenChange}
        />
    );
};
