import React, { FC, useRef } from "react";
import styles from "./styles.module.scss";
import { InboxOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Typography } from "antd";

const { Text } = Typography;

type TProps = {
    height?: number;
    onChange: (arg: File[]) => void;
};

export const FileUploaderDropzone: FC<TProps> = ({ height, onChange }) => {
    const ref = useRef(null);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files);
        onChange(files);
        event.target.value = null;
    };

    const handleDrag = async (event: React.DragEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const files = Array.from(event.dataTransfer.files);
        onChange(files);
    };

    const handleDragOver = (event: React.DragEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClick = () => {
        ref.current.click();
    };

    return (
        <div
            data-testid="dms-file-uploader-dropzone"
            onClick={handleClick}
            onDrop={event => handleDrag(event)}
            onDragOver={event => handleDragOver(event)}
            role={"button"}
            className={styles.uploaderWrapperInner}
            style={{ height: height ?? "100%" }}
        >
            <InboxOutlined style={{ color: "#1677ff", fontSize: 48 }} />
            <Text style={{ fontSize: "1.1rem", marginTop: 16 }}>
                <FormattedMessage id="app.dms.clickOrDrug" />
            </Text>
            <Text type={"secondary"} style={{ fontSize: "1rem" }}>
                <FormattedMessage id="app.dms.supportFor" />
            </Text>

            <input
                ref={ref}
                type="file"
                style={{ display: "none" }}
                accept="application/pdf, application/xml, text/xml"
                onChange={event => handleChange(event)}
                multiple
            />
        </div>
    );
};
