import React, { FC, useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { PdfItemDraggable } from "@pdf-tools/components/PdfToolsPreviewBlock/components/PdfItemDraggable";

import styles from "./PdfViewList.module.scss";
import { DroppableIds, OcrLoadedFile } from "@pdf-tools/types";

interface IProps {
    documents: OcrLoadedFile[];
    activeFileId: string | undefined;
    handleClick: (id: string) => void;
    reorderingFiles?: (files: OcrLoadedFile[]) => void;
}

export const PdfViewList: FC<IProps> = ({ documents, handleClick, reorderingFiles, activeFileId }) => {
    const [items, setItems] = useState(documents);

    useEffect(() => {
        setItems(documents);
    }, [documents]);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setItems(newItems);

        reorderingFiles(newItems);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
                droppableId={DroppableIds.Carousel}
                isDropDisabled={false}
                isCombineEnabled={false}
                direction={"vertical"}
            >
                {provided => (
                    <div className={styles.droppable} ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((document, index) => (
                            <PdfItemDraggable
                                key={document.id}
                                draggableId={document.id}
                                id={activeFileId}
                                handleClick={handleClick}
                                previewUrl={document.previewUrl}
                                index={index}
                                document={document}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
