import { Base, KontoNumUtils, Utils } from "@binale-tech/shared";
import { CategoryUtils } from "../../../../../../scripts/models/utils/CategoryUtils";
import { GenericRecord } from "scripts/models";
import { SHConverter } from "scripts/core/SollHaben";
import { PaymentStatusSelectorType } from "@app/components/toolbar/components/buttons";
import { PaymentBindingUtils } from "../../../../../../scripts/models/utils/PaymentBindingUtils";
import { PaymentsCtxData } from "../../../../../../scripts/context/accountingData/PaymentsProvider";
import { AllRecordsData } from "../../../../../../scripts/context/accountingData/RecordsCtx";

export const getTaxMap = (records: GenericRecord[], converter: SHConverter, kontoExt: number) => {
    const taxMap = new Map<string, Base.IExtNum>();
    records.forEach(record => {
        converter
            .getAllItems(record)
            .filter(item => item.isTax)
            .forEach(buTax => {
                taxMap.set(buTax.konto.getExtNum(kontoExt), buTax.konto);
            });
    });

    return taxMap;
};

export const hasRecordTaxKonto = (record: GenericRecord, toolbarKonto: Base.IExtNum, converter: SHConverter) => {
    const suitableItem = converter
        .getAllItems(record)
        .find(shItem => shItem.isTax && shItem.konto.equalsTo(toolbarKonto));
    return Boolean(suitableItem);
};

export const isKontoMatchesFilter = (
    konto: Base.IExtNum,
    filterKonto: Base.IExtNum,
    yearConfig: { skr: number; kontoExt: number }
) => {
    if (!filterKonto) {
        return false;
    }
    if (konto.equalsTo(filterKonto)) {
        return true;
    }
    const { skr, kontoExt } = yearConfig;
    const isFilterKontoWildcardCreditor = CategoryUtils.isCategoryWildcardCreditor(filterKonto, skr);
    const isFilterKontoWildcardDebitor = CategoryUtils.isCategoryWildcardDebitor(filterKonto, skr);
    if (isFilterKontoWildcardCreditor) {
        return KontoNumUtils.isCreditor({ extNum: konto }, kontoExt);
    }
    if (isFilterKontoWildcardDebitor) {
        return KontoNumUtils.isDebitor({ extNum: konto }, kontoExt);
    }
    return false;
};

export const isRecordMatchesPaymentStatus = (
    paymentStatus: PaymentStatusSelectorType,
    record: GenericRecord,
    paymentsRecordRelation: PaymentsCtxData["recordRelation"],
    allRecords: AllRecordsData
) => {
    if (!["offen", "bezahlt"].includes(paymentStatus)) {
        return true;
    }
    const isPaymentSource = Utils.PaymentUtils.isRecordPaymentSource(record.getProductKey());
    const isPaymentRepresentation = Utils.PaymentUtils.isProductPaymentRepresentation(record.getProductKey());
    const recordPayments = paymentsRecordRelation.get(record.key);
    const openBrutto = record.getOpenBrutto(recordPayments);
    const { record: connectedSourceRecord } = PaymentBindingUtils.getConnectedPaymentSourceRecord({
        allRecords,
        paymentsRecordRelation,
        representationRecordKey: record.key,
    });
    if (paymentStatus === "offen") {
        // if it's payment source (ER/Deb), then we hide paid records (openBrutto === 0)
        if (isPaymentSource && openBrutto === 0) {
            return false;
        }
        // if it's a payment representation (KB/Bank/FE), then we need to KEEP
        // - not connected bank/kb/fe records or
        // - records connected to the source where open brutto not 0
        // accordingly, we HIDE bank/kb/fe records connected to the source with openBrutto === 0
        if (isPaymentRepresentation) {
            if (
                connectedSourceRecord &&
                connectedSourceRecord.getOpenBrutto(paymentsRecordRelation.get(connectedSourceRecord.key)) === 0
            ) {
                return false;
            }
        }
    }

    if (paymentStatus === "bezahlt") {
        if (isPaymentSource && openBrutto !== 0) {
            return;
        }
        if (isPaymentRepresentation) {
            if (
                !connectedSourceRecord ||
                connectedSourceRecord.getOpenBrutto(paymentsRecordRelation.get(connectedSourceRecord.key)) !== 0
            ) {
                return false;
            }
        }
    }
    return true;
};
