import React, { memo } from "react";

import GenericTableViewCtxWrapper from "../productSharedComponents/GenericTableViewCtxWrapper";
import { ColumnOverrides } from "../../columns/ColumnConfig";
import { Utils } from "@binale-tech/shared";
import { GenericRecord } from "scripts/models";
import { CategoryCreditorModes, GenericRecordProperties, ProductFE, TableProperties } from "scripts/core/Product";
import { GenericRecordTableItem, TableItemExtra } from "../../components/shared/Table/Table";
import { GenericTableViewState } from "../productSharedComponents/GenericTableView";
import { GenericToolbar } from "../../components/toolbar/GenericToolbar";
import { KassenbuchViewClass } from "../productKB/KBView";
import { SollHabenPrintHelper } from "../../../scripts/core/SollHabenHelper";
import { TableConfigFooters, TableSum } from "../productSharedComponents/RecordsTableBlock";
import { FEProvider } from "./FEProvider";

export class FreieErfassungViewClass extends KassenbuchViewClass<GenericTableViewState> {
    protected getHeader(): React.ReactNode {
        return null;
    }

    protected getToolbarBlock() {
        const selectedToJournal = this.domain.getSelectedToJournal(this.state.tableItems, this.props.records, true);
        const selectedToConfirm = this.state.tableItems.filter(v => v.selected && v.item.draft);
        return (
            <GenericToolbar
                withJournal
                toJournal={selectedToJournal.length}
                onJournalClicked={() => {
                    this.onJournal(selectedToJournal.map(v => v.item));
                    this.handleDeselect();
                }}
                withConfirm
                onConfirmClicked={() => this.onConfirm(selectedToConfirm.map(v => v.item.clone()))}
                toConfirm={selectedToConfirm.length}
                tableColumns={this.tableColumns}
                showRemoved={this.state.showCancelled}
                onToggleHidden={this.onToggleHidden}
                withKontenAnsicht
                categoryCreditorMode={CategoryCreditorModes.CCD}
            />
        );
    }

    protected getSaldoHeader(): number {
        return undefined;
    }

    private getAmount = (amount: number) => {
        if (!Number.isFinite(amount)) {
            return null;
        }
        return Utils.CurrencyUtils.currencyFormat(this.props.product.useAbsAmounts() ? Math.abs(amount) : amount);
    };

    protected tableConfigGetters() {
        const getters: ColumnOverrides<GenericRecord>["getters"] = {
            [GenericRecordProperties.RecordCategoryCreditorNum]: (
                tableItem: GenericRecordTableItem,
                kontoExt?: number
            ) => {
                return (
                    <>
                        {tableItem.item.getRecordCategoryCreditor().getExtNumPrint(kontoExt)}{" "}
                        {ProductFE.getSHHint(tableItem.item.getBrutto()).konto}
                    </>
                );
            },
            [GenericRecordProperties.RecordCategoryCreditorName]: (tableItem: GenericRecordTableItem) => {
                return tableItem.item.getRecordCategoryCreditor().name;
            },
            [GenericRecordProperties.ItemCategoryCreditorNum]: (
                tableItem: GenericRecordTableItem,
                kontoExt?: number
            ) => {
                return (
                    <>
                        {tableItem.item.getItemCategoryCreditor().getExtNumPrint(kontoExt)}{" "}
                        {ProductFE.getSHHint(tableItem.item.getBrutto()).gkonto}
                    </>
                );
            },
            [GenericRecordProperties.ItemCategoryCreditorName]: (tableItem: GenericRecordTableItem) => {
                return tableItem.item.getItemCategoryCreditor().name;
            },
            [GenericRecordProperties.RecordBrutto]: (tableItem: GenericRecordTableItem) => {
                const amount = tableItem.item.getBrutto();
                return (
                    <>
                        {this.getAmount(amount)} {ProductFE.getSHHint(tableItem.item.getBrutto()).konto}
                    </>
                );
            },
            [TableProperties.ControlLog]: (tableItem: GenericRecordTableItem) => this.getControlLogButton(tableItem),
            [TableProperties.ControlAction]: (tableItem: GenericRecordTableItem) => this.getActionButtons(tableItem),
            [GenericRecordProperties.ControlBinding]: (tableItem: GenericRecordTableItem) =>
                this.getRecordBinding(tableItem),
            [GenericRecordProperties.RecordReview]: (tableItem: GenericRecordTableItem) =>
                this.getRecordReview(tableItem, this.handleReviewItem(tableItem)),
            [GenericRecordProperties.RecordCurrencyOriginalAmount]: (tableItem: GenericRecordTableItem) => {
                const amount = tableItem.item.getOriginalAmount();
                if (amount === 0) {
                    return null;
                }
                return (
                    <>
                        {this.getAmount(amount)} {ProductFE.getSHHint(tableItem.item.getBrutto()).konto}
                    </>
                );
            },
            [GenericRecordProperties.ComputedUSt]: (tableItem: GenericRecordTableItem) => {
                const amount = tableItem.item.getVatEuro(this.props.yearConfig?.skr);
                if (amount === 0) {
                    return null;
                }
                return (
                    <>
                        {this.getAmount(amount)} {ProductFE.getSHHint(tableItem.item.getBrutto()).gkonto}
                    </>
                );
            },
            [GenericRecordProperties.ComputedNetto]: (tableItem: GenericRecordTableItem) => {
                const amount = tableItem.item.getNetto(this.props.yearConfig?.skr);
                return (
                    <>
                        {this.getAmount(amount)} {ProductFE.getSHHint(tableItem.item.getBrutto()).gkonto}
                    </>
                );
            },
        };
        return getters;
    }

    protected tableConfigFooters(
        tableItems: TableItemExtra<GenericRecord>[],
        tableSum: TableSum,
        saldoHeader?: number
    ): TableConfigFooters {
        const config = super.tableConfigFooters(tableItems, tableSum, saldoHeader);
        config[GenericRecordProperties.RecordBrutto] = {
            mainFooter: <>{SollHabenPrintHelper.printSH(tableSum.mainFooter.bruttoSum)}</>,
            selectedFooter: <>{SollHabenPrintHelper.printSH(tableSum.selectedFooter.bruttoSum)}</>,
        };
        config[GenericRecordProperties.RecordCurrencyOriginalAmount] = {
            mainFooter: SollHabenPrintHelper.printSH(tableSum.mainFooter.originalAmountSum),
            selectedFooter: SollHabenPrintHelper.printSH(tableSum.selectedFooter.originalAmountSum),
        };
        config[GenericRecordProperties.ComputedUSt] = {
            mainFooter: SollHabenPrintHelper.printSH(-tableSum.mainFooter.vatSum),
            selectedFooter: SollHabenPrintHelper.printSH(-tableSum.selectedFooter.vatSum),
        };
        config[GenericRecordProperties.ComputedNetto] = {
            mainFooter: SollHabenPrintHelper.printSH(-tableSum.mainFooter.nettoSum),
            selectedFooter: SollHabenPrintHelper.printSH(-tableSum.selectedFooter.nettoSum),
        };
        return config;
    }

    render() {
        return this.getTablePage({ useInlineForm: true, useLeftTable: true });
    }
}

export const FEView: React.FC = memo(function FEView() {
    return (
        <FEProvider>
            <GenericTableViewCtxWrapper Component={FreieErfassungViewClass} />
        </FEProvider>
    );
});
