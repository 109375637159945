import React, { useContext, useMemo, useState } from "react";
import cn from "classnames";
import dayjs from "dayjs";
import { Base, KontoNumUtils, Periods, Utils } from "@binale-tech/shared";
import { ColumnGroupType, ColumnsType } from "antd/es/table";
import { FormattedMessage, useIntl } from "react-intl";
import { Table, Tooltip } from "antd";

import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { KontoContext } from "scripts/context/accountingData/KontoEntitiesProvider";
import { BuContext } from "scripts/context/BuContext";
import { RecordsContext } from "scripts/context/accountingData/RecordsCtx";
import { PaymentsContext } from "scripts/context/accountingData/PaymentsProvider";
import { ReadinessContext } from "scripts/context/DataReadyness";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { Category, Creditor, Debitor } from "scripts/models";
import { Container } from "appearance/components/shared/appearance/page";
import { CsvConverter } from "../../../../../scripts/csv/exporters/CsvConverter";
import { SHConverter } from "scripts/core/SollHaben";
import { ScrollbarDetection } from "scripts/infrastructure/helpers/browser";
import { SelectorPeriods } from "../../../../components/shared/Toolbar/PeriodSelect";
import { SollHabenPrintHelper } from "scripts/core/SollHabenHelper";
import { SusaFilter, SusaToolbar } from "./SusaToolbar";
import { TableItem, TableItemExtra } from "appearance/components/shared/Table/Table";
import { downloadCsv } from "scripts/infrastructure/downloader";
import { logger } from "scripts/infrastructure/logger";

import "./SusaView.css";
import "appearance/components/shared/Page.css";

const { Period, ExtraPeriod } = Periods;

interface TableRecord {
    konto: Base.IExtNum;
    isSumRow?: boolean;
    monthAmountsSoll: Map<number, number>;
    monthAmountsHaben: Map<number, number>;
}

const formatNumber = (tableItem: TableItem<TableRecord>, amount: number): string => {
    if (tableItem.item.isSumRow) {
        return Utils.CurrencyUtils.currencyFormat(amount);
    }
    return amount > 0 ? Utils.CurrencyUtils.currencyFormat(amount) : null;
};

const getterSoll = (tableItem: TableItem<TableRecord>, month: number) => {
    const amount = tableItem.item.monthAmountsSoll.get(month);
    return formatNumber(tableItem, amount);
};

const getterHaben = (tableItem: TableItem<TableRecord>, month: number) => {
    const amount = tableItem.item.monthAmountsHaben.get(month);
    return formatNumber(tableItem, amount);
};

const getItems = (susa: Map<string, TableRecord>): TableItem<TableRecord>[] => {
    return Array.from(susa.values()).map((item, index) => ({
        key: index,
        item,
        children: [] as TableItem<TableRecord>["children"],
    }));
};

const getSum = (record: TableRecord, period: number) => {
    return record.monthAmountsSoll.get(period) - record.monthAmountsHaben.get(period);
};

const getSumByMonth = (record: TableRecord, getter: Map<number, number>) => {
    return getter.get(null) - getter.get(Period.FirstDayOfYear) - getter.get(Period.LastDayOfYear);
};

const renderCSVSum = (record: TableRecord, period: number) => {
    const amount = getSum(record, period);
    return amount !== 0 ? Utils.CurrencyUtils.currencyFormat(Math.abs(amount)) + " " + (amount > 0 ? "S" : "H") : null;
};

const getterBezeichnung = (tableItem: TableItemExtra<TableRecord>) => tableItem.item.konto.name;

const periodValues: (Periods.Period | Periods.ExtraPeriod)[] = [ExtraPeriod.AllYear, ...Object.values(Period)].filter(
    val => typeof val !== "string"
) as any;
const emptyPeriodValues = new Map(periodValues.map(key => [key, 0]));

export const SusaView: React.FC = () => {
    const { companyBuTimeframes } = useContext(BuContext);
    const { yearConfig } = useContext(CompanyContext);
    const { year } = useContext(YearPeriodContext);
    const { creditors, categoryOverrides, debitors } = useContext(KontoContext);
    const { allRecords } = useContext(RecordsContext);
    const yearRecords = React.useMemo(
        () => (allRecords.list || []).filter(record => record.date.getFullYear() === year && !record.draft),
        [allRecords, year]
    );
    const payments = useContext(PaymentsContext);
    const { isReady } = useContext(ReadinessContext);
    const intl = useIntl();

    const [filter, setFilter] = useState<SusaFilter>({
        period: null,
        showAllMonth: false,
    });
    const [selected, setSelected] = useState<number>();

    const getPeriodName = React.useCallback(
        (period: SelectorPeriods) => {
            switch (period) {
                case Period.FirstDayOfYear:
                    return "01.01.";
                case Period.LastDayOfYear:
                    return "31.12.";
                case ExtraPeriod.AllYear: // All Year
                    return intl.formatMessage({ id: "app.labels.allYear" });
                default:
                    return dayjs.months()[period - 1];
            }
        },
        [intl]
    );

    const columns: ColumnsType<TableItem<TableRecord>> = React.useMemo(() => {
        const resultColumns: ColumnsType<TableItem<TableRecord>> = [
            {
                key: "category.num",
                title: <FormattedMessage id="app.fields.konto" />,
                render: (text: string, tableItem: TableItem<TableRecord>) =>
                    tableItem.item.isSumRow ? null : tableItem.item.konto.getExtNumPrint(yearConfig.kontoExt),
                fixed: "left",
                width: 100,
            },
            {
                key: "category.name",
                title: <FormattedMessage id="app.fields.bezeichnung" />,
                render: (text: string, tableItem: TableItem<TableRecord>) => (
                    <Tooltip placement="right" title={getterBezeichnung(tableItem)} destroyTooltipOnHide>
                        <div className="SuSaTable__KontoName" style={{ width: 280 }}>
                            {getterBezeichnung(tableItem)}
                        </div>
                    </Tooltip>
                ),
                width: 300,
                ellipsis: true,
                fixed: "left",
            },
        ];

        periodValues.forEach((period, index) => {
            if (
                (filter.period === period && filter.period !== ExtraPeriod.AllYear) ||
                (filter.showAllMonth && period !== ExtraPeriod.AllYear) ||
                (filter.period === ExtraPeriod.AllYear &&
                    (period === Period.FirstDayOfYear || period === Period.LastDayOfYear))
            ) {
                resultColumns.push({
                    title: getPeriodName(period),
                    key: "month." + period,
                    children: [
                        {
                            key: "month.s." + period,
                            title: "Soll",
                            render: (text: string, tableItem: TableItem<TableRecord>) => getterSoll(tableItem, period),
                            width: 100,
                            align: "right",
                            className: cn({ "SuSaTable--odd-column": filter.showAllMonth && index % 2 === 1 }),
                        },
                        {
                            key: "month.h." + period,
                            title: "Haben",
                            render: (text: string, tableItem: TableItem<TableRecord>) => getterHaben(tableItem, period),
                            width: 100,
                            align: "right",
                            className: cn({ "SuSaTable--odd-column": filter.showAllMonth && index % 2 === 1 }),
                        },
                    ],
                });
            }
        });

        if (filter.period === ExtraPeriod.AllYear && !filter.showAllMonth) {
            // Januar - Dezember
            const getSumByMonths = (tableItem: TableItem<TableRecord>, getter: Map<number, number>) => {
                const sum = filter.showAllMonth ? getter.get(null) : getSumByMonth(tableItem.item, getter);
                return formatNumber(tableItem, sum);
            };
            resultColumns.splice(resultColumns.length - 1, 0, {
                title: `${dayjs.months()[0]} - ${dayjs.months()[11]}`,
                key: "month.m",
                children: [
                    {
                        key: "month.s.m",
                        title: "Soll",
                        render: (text: string, tableItem: TableItem<TableRecord>) =>
                            getSumByMonths(tableItem, tableItem.item.monthAmountsSoll),
                        width: 100,
                        align: "right",
                        className: "SuSaTable--odd-column",
                    },
                    {
                        key: "month.h.m",
                        title: "Haben",
                        render: (text: string, tableItem: TableItem<TableRecord>) =>
                            getSumByMonths(tableItem, tableItem.item.monthAmountsHaben),
                        width: 100,
                        align: "right",
                        className: "SuSaTable--odd-column",
                    },
                ],
            });
        }

        if (filter.period === ExtraPeriod.AllYear) {
            // Ganzes Jahr
            resultColumns.push({
                title: intl.formatMessage({ id: "app.labels.allYear" }),
                key: "month.j",
                children: [
                    {
                        key: "month.s.j",
                        title: "Soll",
                        render: (text: string, tableItem: TableItem<TableRecord>) => (
                            <b>{getterSoll(tableItem, null)}</b>
                        ),
                        width: 100,
                        align: "right",
                        className: "SuSaTable--filled-column",
                    },
                    {
                        key: "month.h.j",
                        title: "Haben",
                        render: (text: string, tableItem: TableItem<TableRecord>) => (
                            <b>{getterHaben(tableItem, null)}</b>
                        ),
                        width: 100,
                        align: "right",
                        className: "SuSaTable--filled-column",
                    },
                ],
            });
        }

        const pad = { paddingRight: ScrollbarDetection.getWidth() };
        // Ganzes Jahr/Februar Saldo
        resultColumns.push({
            key: "sum",
            title: (
                <span>
                    {getPeriodName(filter.period)}
                    <br />
                    <FormattedMessage id="app.fields.saldo" />
                </span>
            ),
            className: "SuSaTable--filled-column",
            render: (text: string, tableItem: TableItem<TableRecord>) => (
                <b style={pad}>{SollHabenPrintHelper.printSH(getSum(tableItem.item, filter.period))}</b>
            ),
            width: 125 + ScrollbarDetection.getWidth(),
            align: "right",
        });

        if (filter.period !== ExtraPeriod.AllYear) {
            resultColumns.push({
                key: "sum.j",
                title: (
                    <span>
                        {getPeriodName(ExtraPeriod.AllYear)}
                        <br />
                        <FormattedMessage id="app.fields.saldo" />
                    </span>
                ),
                className: "SuSaTable--filled-column",
                render: (text: string, tableItem: TableItem<TableRecord>) => (
                    <b style={pad}>{SollHabenPrintHelper.printSH(getSum(tableItem.item, ExtraPeriod.AllYear))}</b>
                ),
                width: 125 + ScrollbarDetection.getWidth(),
                align: "right",
            });
        }
        return resultColumns;
    }, [filter.period, filter.showAllMonth, getPeriodName, intl, yearConfig]);

    const defaultCategories: Map<number, Category> = useMemo(
        () => BuTaxesSKR.getBuTimeframeCategoriesByYear(yearConfig.skr, year, companyBuTimeframes),
        [yearConfig, year, companyBuTimeframes]
    );

    const susaData = React.useMemo(() => {
        if (!yearConfig || yearRecords.length === 0) {
            return new Map();
        }
        if (!companyBuTimeframes) {
            return new Map();
        }
        const { skr, kontoExt } = yearConfig;
        const converter = new SHConverter(yearConfig, companyBuTimeframes, payments);

        const maxLenDefaultCats = new Map<string, Category>();
        defaultCategories.forEach(cat => {
            const maxLenNum = cat.getExtNum(kontoExt);
            maxLenDefaultCats.set(maxLenNum, cat);
        });

        const susa = new Map<string, TableRecord>();

        // userSavedAccounts - collecting user data to retrieve later names for labels later on
        const userSavedAccounts = new Map<string, Category>();
        const categoryWildcardCreditorNum = KontoNumUtils.getExtNum(
            String(skr === 4 ? KontoNumUtils.CAT_CRED_4 : KontoNumUtils.CAT_CRED_3),
            kontoExt,
            Category.DefaultLen
        );
        const categoryWildcardDebitorNum = KontoNumUtils.getExtNum(
            String(skr === 4 ? KontoNumUtils.CAT_DEB_4 : KontoNumUtils.CAT_DEB_3),
            kontoExt,
            Category.DefaultLen
        );
        const aggregationCategories = [
            Category.unserialize({
                num: categoryWildcardCreditorNum,
                name: "Kreditoren",
            }),
            Category.unserialize({
                num: categoryWildcardDebitorNum,
                name: "Debitoren",
            }),
        ];

        [...categoryOverrides, ...debitors, ...creditors, ...aggregationCategories].forEach((v: Base.IExtNum) => {
            userSavedAccounts.set(v.getExtNum(kontoExt), v as Category);
        });
        const categories = new Map<string, Category>(aggregationCategories.map(v => [v.num, v]));

        const susaAdd = (item: { amount: number; isHaben: boolean }, period: number, key: string) => {
            if (!susa.has(key)) {
                const record: TableRecord = {
                    konto: categories.get(key),
                    monthAmountsSoll: new Map(emptyPeriodValues),
                    monthAmountsHaben: new Map(emptyPeriodValues),
                };
                susa.set(key, record);
            }
            if (item.isHaben) {
                susa.get(key).monthAmountsHaben.set(period, item.amount + susa.get(key).monthAmountsHaben.get(period));
            } else {
                susa.get(key).monthAmountsSoll.set(period, item.amount + susa.get(key).monthAmountsSoll.get(period));
            }
        };

        yearRecords.forEach(v => {
            converter.getAllItems(v).forEach(item => {
                if (!item.konto) {
                    logger.log("empty item, konto: ", item);
                }
                const key = item.konto.getExtNum(kontoExt);
                if (item.konto.num === "") {
                    logger.log("konto num is empty", item.konto.name, item, v);
                    return;
                }
                if (!item.konto.name) {
                    logger.log("konto name is empty", v);
                }
                const period = v.period;
                // if it's one of saved categories/creditors/dedetors, use it then
                if (userSavedAccounts.has(key)) {
                    categories.set(key, userSavedAccounts.get(key));
                } else {
                    // otherwise
                    // if it's a default category - use name from the latest default categories
                    // otherwise use what is saved in the record
                    const maxLenNum = item.konto.getExtNum(kontoExt);
                    const konto = maxLenDefaultCats.has(maxLenNum)
                        ? maxLenDefaultCats.get(maxLenNum)
                        : (item.konto as Category);
                    categories.set(key, konto);
                }

                susaAdd(item, period, key);
                if (KontoNumUtils.isCreditor({ extNum: item.konto }, kontoExt)) {
                    susaAdd(item, period, categoryWildcardCreditorNum);
                }
                if (KontoNumUtils.isDebitor({ extNum: item.konto }, kontoExt)) {
                    susaAdd(item, period, categoryWildcardDebitorNum);
                }
            });
        });
        // category names override
        Array.from(userSavedAccounts.values()).forEach((v: Base.IExtNum) => {
            const k = v.getExtNum(kontoExt);
            if (categories.has(k)) {
                categories.set(v.getExtNum(kontoExt), v as Category);
            }
        });
        logger.log({ susa, userSavedAccounts });
        const catList = Array.from(susa.keys()).sort((a, b) => Number(a) - Number(b));
        const susaSorted = new Map<string, TableRecord>();
        let prevNumMask: string = null;
        let groupSumSoll = new Map(emptyPeriodValues);
        let groupSumHaben = new Map(emptyPeriodValues);
        const getKonto = (numMask: string) => {
            let konto: Base.IExtNum;
            if (numMask.length - kontoExt === Category.DefaultLen) {
                konto = new Category(numMask, "Summe Klasse " + numMask[0]);
            } else if (numMask[0] < KontoNumUtils.KRED_MIN.toString()[0]) {
                konto = new Debitor(numMask, "Summe Debitoren " + numMask[0]);
            } else {
                konto = new Creditor(numMask, "Summe Kreditoren " + numMask[0]);
            }
            return konto;
        };
        const periodKeys = Array.from(periodValues.filter(k => k !== null));
        catList.forEach(k => {
            const record = susa.get(k);
            try {
                const numMask = k[0] + "x".repeat(k.length - 1);
                if (prevNumMask !== null && prevNumMask !== numMask) {
                    susaSorted.set(prevNumMask, {
                        konto: getKonto(prevNumMask),
                        monthAmountsSoll: groupSumSoll,
                        monthAmountsHaben: groupSumHaben,
                        isSumRow: true,
                    });
                    groupSumSoll = new Map(emptyPeriodValues);
                    groupSumHaben = new Map(emptyPeriodValues);
                }
                const sumSoll = periodKeys
                    .map((v, i) => i)
                    .reduce((caret, i) => caret + record.monthAmountsSoll.get(i), 0);
                const sumHaben = periodKeys
                    .map((v, i) => i)
                    .reduce((caret, i) => caret + record.monthAmountsHaben.get(i), 0);
                record.monthAmountsSoll.set(null, sumSoll);
                record.monthAmountsHaben.set(null, sumHaben);

                record.monthAmountsSoll.forEach((v, key) => {
                    groupSumSoll.set(key, groupSumSoll.get(key) + v);
                });
                record.monthAmountsHaben.forEach((v, key) => {
                    groupSumHaben.set(key, groupSumHaben.get(key) + v);
                });
                prevNumMask = numMask;
                susaSorted.set(k, record);
            } catch (e) {
                logger.crit(e, "GenericDatenExportView", susa.get(k));
            }
        });
        if (prevNumMask) {
            susaSorted.set(prevNumMask, {
                konto: getKonto(prevNumMask),
                monthAmountsSoll: groupSumSoll,
                monthAmountsHaben: groupSumHaben,
                isSumRow: true,
            });
        }
        return susaSorted;
    }, [yearConfig, yearRecords, companyBuTimeframes, payments, year, categoryOverrides, debitors, creditors]);

    const getHeaderByKey = (key: string) => {
        if (key === "sum") {
            return intl.formatMessage({ id: "app.fields.saldo" });
        }
        if (key === "sum.j") {
            return getPeriodName(ExtraPeriod.AllYear) + " " + intl.formatMessage({ id: "app.fields.saldo" });
        }
        if (key === "category.num") {
            return intl.formatMessage({ id: "app.fields.konto" });
        }
        if (key === "category.name") {
            return intl.formatMessage({ id: "app.fields.bezeichnung" });
        }
        if (key === "month.s.j") {
            return getPeriodName(ExtraPeriod.AllYear) + " Soll";
        }
        if (key === "month.h.j") {
            return getPeriodName(ExtraPeriod.AllYear) + " Haben";
        }
        if (key === "month.s.m") {
            return `${dayjs.months()[0]} - ${dayjs.months()[11]} Soll`;
        }
        if (key === "month.h.m") {
            return `${dayjs.months()[0]} - ${dayjs.months()[11]} Haben`;
        }
        if (key.startsWith("month.s.")) {
            const num = Number(key.slice("month.s.".length));
            return getPeriodName(num) + " Soll";
        }
        if (key.startsWith("month.h.")) {
            const num = Number(key.slice("month.h.".length));
            return getPeriodName(num) + " Haben";
        }
        return "";
    };
    const dataSource = React.useMemo(() => {
        return getItems(susaData);
    }, [susaData]);

    const onCsvClick = () => {
        const columnsData = columns.reduce(
            (acc, item) => {
                const clonedItem = { ...item };
                if (clonedItem.key === "category.name") {
                    clonedItem.render = (text: string, tableItem: TableItem<TableRecord>) =>
                        getterBezeichnung(tableItem);
                }
                if (clonedItem.key === "sum" || clonedItem.key === "sum.j") {
                    clonedItem.render = (text: string, tableItem: TableItem<TableRecord>) =>
                        renderCSVSum(tableItem.item, item.key === "sum" ? ExtraPeriod.AllYear : filter.period);
                }
                if ((clonedItem as ColumnGroupType<TableItem<TableRecord>>).children) {
                    (clonedItem as ColumnGroupType<TableItem<TableRecord>>).children.forEach(child =>
                        acc.push({ ...child, title: `${clonedItem.title} ${child.title}` })
                    );
                } else {
                    acc.push(clonedItem);
                }
                return acc;
            },
            [] as ColumnsType<TableItem<TableRecord>>
        );

        const rows = dataSource
            .filter(v => !v.item.isSumRow)
            .map(tableItem =>
                columnsData.map((c, index) => {
                    if (c.key === "month.s.j") {
                        return Utils.CurrencyUtils.currencyFormat(tableItem.item.monthAmountsSoll.get(null));
                    }
                    if (c.key === "month.h.j") {
                        return Utils.CurrencyUtils.currencyFormat(tableItem.item.monthAmountsHaben.get(null));
                    }
                    return c.render("", tableItem, index) as string;
                })
            );

        const header = columnsData.map(c => getHeaderByKey(c.key.toString()));
        const month = getPeriodName(filter.period);

        const name =
            [dayjs().format("YYYY.MM.DD"), year, month, "SuSa"].filter(v => v !== undefined && v !== null).join("_") +
            ".csv";
        const res = CsvConverter.getCsv([header, ...rows]);

        downloadCsv(res, name);
    };

    return (
        <div onClick={() => setSelected(null)} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <Container
                style={{
                    width: "calc(100% - 20px)",
                    height: "calc(100% - 100px)",
                    marginLeft: 10,
                    position: "absolute",
                    maxWidth: Number.isFinite(filter.period) ? 1000 : undefined,
                }}
            >
                {(w, h) => {
                    return (
                        <div>
                            <SusaToolbar
                                filter={filter}
                                onChangePeriod={period => {
                                    setFilter({ showAllMonth: false, period });
                                }}
                                onShowAllMonths={showAllMonth =>
                                    setFilter(prevState => ({ ...prevState, showAllMonth }))
                                }
                                onDownloadCsv={onCsvClick}
                            />
                            <Table
                                loading={!isReady}
                                className="SusaTable antd-table-with-selection"
                                pagination={{ hideOnSinglePage: true, defaultPageSize: 10000 }}
                                columns={columns}
                                dataSource={dataSource}
                                size="small"
                                rowClassName={tableItem =>
                                    cn({
                                        "susa__row--disabled": tableItem.item.isSumRow,
                                        "row--selected": selected === tableItem.key,
                                    })
                                }
                                bordered
                                onRow={data => ({
                                    onClick: e => {
                                        setSelected(data.item.isSumRow ? null : data.key);
                                        e.stopPropagation();
                                    },
                                })}
                                scroll={{ y: h - 50, x: Number.isFinite(filter.period) ? 980 : w - 20 }}
                            />
                        </div>
                    );
                }}
            </Container>
        </div>
    );
};
