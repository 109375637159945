import React from "react";
import { Contacts } from "@binale-tech/shared";
import { DefaultOptionType } from "antd/es/select";
import { AutoComplete, RefSelectProps, SelectProps } from "antd";

const options = [
    "Aachen",

    "Altenburg",

    "Amberg",

    "Ansbach",

    "Apolda",

    "Arnsberg",

    "Arnstadt",

    "Arnstadt Zweigstelle Ilmenau",

    "Aschaffenburg",

    "Augsburg",

    "Aurich",

    "Bad Hersfeld",

    "Bad Homburg v.d.H.",

    "Bad Kreuznach",

    "Bad Oeynhausen",

    "Bad Salzungen",

    "Bamberg",

    "Bayreuth",

    "Berlin (Charlottenburg)",

    "Bielefeld",

    "Bochum",

    "Bonn",

    "Braunschweig",

    "Bremen",

    "Chemnitz",

    "Coburg",

    "Coesfeld",

    "Cottbus",

    "Darmstadt",

    "Deggendorf",

    "Dortmund",

    "Dresden",

    "Duisburg",

    "Düren",

    "Düsseldorf",

    "Eisenach",

    "Erfurt",

    "Eschwege",

    "Essen",

    "Flensburg",

    "Frankfurt am Main",

    "Frankfurt/Oder",

    "Freiburg",

    "Friedberg",

    "Fritzlar",

    "Fulda",

    "Fürth",

    "Gelsenkirchen",

    "Gera",

    "Gießen",

    "Gotha",

    "Göttingen",

    "Greiz",

    "Gütersloh",

    "Hagen",

    "Hamburg",

    "Hamm",

    "Hanau",

    "Hannover",

    "Heilbad Heiligenstadt",

    "Hildburghausen",

    "Hildesheim",

    "Hof",

    "Homburg",

    "Ingolstadt",

    "Iserlohn",

    "Jena",

    "Kaiserslautern",

    "Kassel",

    "Kempten (Allgäu)",

    "Kiel",

    "Kleve",

    "Koblenz",

    "Köln",

    "Königstein",

    "Korbach",

    "Krefeld",

    "Landau",

    "Landshut",

    "Langenfeld",

    "Lebach",

    "Leipzig",

    "Lemgo",

    "Limburg",

    "Lübeck",

    "Ludwigshafen a.Rhein (Ludwigshafen)",

    "Lüneburg",

    "Mainz",

    "Mannheim",

    "Marburg",

    "Meiningen",

    "Memmingen",

    "Merzig",

    "Mönchengladbach",

    "Montabaur",

    "Mühlhausen",

    "München",

    "Münster",

    "Neubrandenburg",

    "Neunkirchen",

    "Neuruppin",

    "Neuss",

    "Nordhausen",

    "Nürnberg",

    "Offenbach am Main",

    "Oldenburg (Oldenburg)",

    "Osnabrück",

    "Ottweiler",

    "Paderborn",

    "Passau",

    "Pinneberg",

    "Pößneck",

    "Pößneck Zweigstelle Bad Lobenstein",

    "Potsdam",

    "Recklinghausen",

    "Regensburg",

    "Rostock",

    "Rudolstadt",

    "Saarbrücken",

    "Saarlouis",

    "Schweinfurt",

    "Schwerin",

    "Siegburg",

    "Siegen",

    "Sömmerda",

    "Sondershausen",

    "Sonneberg",

    "Stadthagen",

    "Stadtroda",

    "Steinfurt",

    "Stendal",

    "St. Ingbert (St Ingbert)",

    "Stralsund",

    "Straubing",

    "Stuttgart",

    "St. Wendel (St Wendel)",

    "Suhl",

    "Tostedt",

    "Traunstein",

    "Ulm",

    "Völklingen",

    "Walsrode",

    "Weiden i. d. OPf.",

    "Weimar",

    "Wetzlar",

    "Wiesbaden",

    "Wittlich",

    "Wuppertal",

    "Würzburg",

    "Zweibrücken",
];
export const RegisterCourtCombobox = React.forwardRef<RefSelectProps, SelectProps>(
    function RegisterCourtCombobox(props, ref) {
        const dataValue: DefaultOptionType[] = options.map((key: Contacts.ContactConstants.RegisterEnum) => ({
            label: key,
            value: key,
        }));
        const filterFn = (inputValue: string, option: DefaultOptionType) => {
            return option.value.toString().toLowerCase().includes(inputValue.toLowerCase());
        };
        const handleOnBlur = () => {
            const selectedValue = dataValue.find(opt => opt.value === props.value);
            if (selectedValue || !props.value) {
                return;
            }
            const findValue = dataValue.filter(option => filterFn(props.value, option));
            const resultValue = findValue.length === 1 ? findValue[0].value : undefined;

            props.onChange(resultValue, null);
        };
        return (
            <AutoComplete
                allowClear
                {...props}
                options={dataValue}
                ref={ref}
                filterOption={filterFn}
                onBlur={handleOnBlur}
                // getPopupContainer={trigger => trigger.parentNode}
                dropdownRender={menu => <div data-testid="legalInfo-registerCourt-dropdown">{menu}</div>}
            />
        );
    }
);
