import { Collapse, Flex, Modal } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import React from "react";
import { GQL } from "@binale-tech/shared";

type Props = {
    open: boolean;
    onCancel: () => void;
    errors?: GQL.IInvoiceXRechnungValidationError[];
};
export const XRechnungValidationModal: React.FC<Props> = ({ open, onCancel, errors }) => {
    return (
        <Modal destroyOnClose style={{ top: 25 }} width={1200} open={open} onCancel={onCancel} footer={null}>
            <Collapse
                items={(errors ?? []).map((err, index) => {
                    const [message, source] = err.Message.split(" from /");
                    return {
                        key: String(index),
                        label: (
                            <Flex style={{ width: "100%" }} gap={8} align={"center"}>
                                {["24", "4"].includes(err.Type) && <WarningTwoTone twoToneColor={"#e31313"} />}
                                {["27"].includes(err.Type) && <WarningTwoTone twoToneColor={"#c87f4b"} />}
                                <span>{message}</span>
                            </Flex>
                        ),
                        children: (
                            <Flex vertical gap={8}>
                                {source && (
                                    <>
                                        <b>XLST</b>
                                        <code>{source}</code>
                                    </>
                                )}
                                <b>Regel</b>
                                <code>{err.Criterion}</code>
                                <b>Lokation</b>
                                <div>
                                    {err.Location.split("/").map((str, index) => (
                                        <pre key={index}>{" ".repeat(index) + str.replace(/\[namespace-.*']/, "")}</pre>
                                    ))}
                                </div>
                            </Flex>
                        ),
                    };
                })}
            />
        </Modal>
    );
};
