import React from "react";
import { RefSelectProps, Select, SelectProps } from "antd";
import { FormattedMessage } from "react-intl";
import { Currency, GQL } from "@binale-tech/shared";

interface Props extends Omit<SelectProps<GQL.ICurrencyCode>, "value" | "onChange"> {
    value?: GQL.ICurrencyCode;
    onChange?: (value: GQL.ICurrencyCode) => void;
}

export const currencyOptions: React.ComponentProps<typeof Select>["options"] = Currency.CommonCurrencyList.map(
    code => ({
        label: code,
        value: code,
    })
);

export const CurrencySelect = React.forwardRef<RefSelectProps, Props>(function CurrencyCombobox(props, ref) {
    return (
        <Select<GQL.ICurrencyCode>
            popupMatchSelectWidth={false}
            getPopupContainer={trigger => trigger.parentNode}
            notFoundContent={<FormattedMessage id="app.components.table.no_items" />}
            {...props}
            ref={ref}
            options={currencyOptions}
        />
    );
});
