import { Options, parse } from "csv-parse/browser/esm/sync";

export class CsvParser {
    private static fileTextEncoding = "CP1252";
    static parseFile = async (file: File, options?: Options) => {
        const text = await this.fileToString(file);
        const opts = {
            skip_empty_lines: true,
            skip_records_with_empty_values: true,
            skip_records_with_error: true,
            delimiter: [";"],
            trim: true,
            columns: true,
            ...options,
        };
        return parse(text, opts);
    };

    static flattenRawResponse(arg: { record: Record<string, string>; raw: string }[]) {
        return arg.map(el => {
            const obj: Record<string, string> = {
                csvLine: el.raw,
            };
            Object.keys(el.record).forEach(key => {
                obj[key] = el.record[key];
            });
            return obj;
        });
    }

    protected static fileToString = (f: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(f, this.fileTextEncoding);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };
}
