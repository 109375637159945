import React, { useContext, useEffect, useState } from "react";
import { GQL } from "@binale-tech/shared";
import { useApolloClient } from "@apollo/client";
import { UserContext } from "./UserProvider";
import { message } from "antd";
import { datevUserInfoQuery } from "./queries/ctxQueries.graphql";
import { captureException } from "@sentry/react";
import { datevTokenRefresh } from "../infrastructure/oauth/oauth";

interface IDatevContext {
    datevUserInfo?: GQL.IDatevUserInfo;
    token?: GQL.IOAuthTokenMeta;
    isLoading: boolean;
}

const defaultValue: IDatevContext = {
    datevUserInfo: null,
    token: null,
    isLoading: false,
};
type IDatevControlContext = {
    triggerRefetch?: () => Promise<void | boolean>;
};
export const DatevContext = React.createContext<IDatevContext>(defaultValue);
export const DatevControlContext = React.createContext<IDatevControlContext>({});

export const DatevContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const client = useApolloClient();
    const { fireUser } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState<GQL.IOAuthTokenMeta>();
    const [datevUserInfo, setDatevUserInfo] = useState<GQL.IDatevUserInfo>();

    const reset = () => {
        setDatevUserInfo(null);
        setToken(null);
        setIsLoading(false);
    };
    const refetch = React.useCallback(async () => {
        setIsLoading(true);
        await client
            .query<Pick<GQL.IQuery, "datevUserInfo">>({
                query: datevUserInfoQuery,
                fetchPolicy: "network-only",
            })
            .then(res => setDatevUserInfo(res?.data?.datevUserInfo));
        await client
            .mutate<Pick<GQL.IMutation, "datevTokenRefresh">>({
                mutation: datevTokenRefresh,
                variables: { input: {} },
            })
            .then(res => setToken(res.data.datevTokenRefresh))
            .catch(e => {
                if (e.message.includes("Refresh token is missing or expired")) {
                    setToken(null);
                    return;
                }
                if (e.message.includes("outgoing request timed out")) {
                    return;
                }
                captureException(e);
                message.error(e.message);
            });
        setIsLoading(false);
    }, [client]);
    useEffect(() => {
        if (!fireUser?.uid) {
            reset();
        } else {
            refetch();
        }
    }, [client, fireUser?.uid, refetch]);
    const value: IDatevContext = React.useMemo(
        () => ({
            isLoading,
            token,
            datevUserInfo,
        }),
        [isLoading, token, datevUserInfo]
    );
    const control: IDatevControlContext = React.useMemo(
        () => ({
            triggerRefetch: () => refetch(),
        }),
        [refetch]
    );
    return (
        <DatevContext.Provider value={value}>
            <DatevControlContext.Provider value={control}>{children}</DatevControlContext.Provider>
        </DatevContext.Provider>
    );
};
