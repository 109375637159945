import React, { useEffect } from "react";
import { message, Row, Spin } from "antd";
import { useIntl } from "react-intl";

import { applyActionCode } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { AuthLayout } from "./AuthLayout";
import { MODE_VERIFY_EMAIL } from "scripts/api/firebase/firebaseOodCodeEnums";
import { auth } from "scripts/api/firebase/firebase";
import { logger } from "scripts/infrastructure/logger";

const VerifyEmailView: React.FC = () => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const intl = useIntl();

    useEffect(() => {
        const mode = query.get("mode");
        const oobCode = query.get("oobCode");

        if (mode !== MODE_VERIFY_EMAIL) {
            return;
        }

        applyActionCode(auth, oobCode)
            .then(() => {
                message.info(intl.formatMessage({ id: "app.profile.email_verification.success_message" }));
                setTimeout(() => {
                    navigate(AppRoutes.home);
                    window.location.reload();
                }, 5000);
            })
            .catch(error => {
                logger.error(error);
                setTimeout(() => {
                    navigate(AppRoutes.home);
                }, 5000);
                // message.error(intl.formatMessage({ id: "app.profile.email_verification.fail_message" }));
            });
    }, [intl, navigate, query]);

    return (
        <AuthLayout>
            <Row justify="center" align="middle">
                <Spin tip="Confirming email, please wait..." />
            </Row>
        </AuthLayout>
    );
};

export default VerifyEmailView;
