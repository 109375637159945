import { Checkbox, Tooltip } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";
import { RecordDataPopover } from "../RecordDataTable/RecordDataPopover";
import { CardActionsList } from "@dms/components/ActionList";
import { GQL } from "@binale-tech/shared";

import styles from "./documetCard.module.scss";

interface IProps {
    document: GQL.IDocument;
    index: number;
}

export const DocumentCardTitle: FC<IProps> = ({ document, index }) => {
    const { selectedRowKeys } = useContext(DmsAppContext);
    const { selectRow, unselectRow } = useContext(DmsAppControlContext);

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(selectedRowKeys.includes(document.key));
    }, [selectedRowKeys]);

    const handleChange = (checked: boolean) => {
        if (checked) {
            selectRow(document.key);
            return;
        }

        unselectRow(document.key);
    };

    const handleClick = () => {
        handleChange(!checked);
    };

    return (
        <div className={styles["card-header"]}>
            <Checkbox
                checked={checked}
                onChange={e => {
                    handleChange(e.target.checked);
                }}
            />
            <span className={styles["card-header__index"]}>{index + 1}</span>

            <RecordDataPopover document={document} position={"grid"} />

            <div onClick={handleClick}>
                <Tooltip title={document.fileName} placement="topLeft">
                    <div className={styles["card-header__tooltip"]}>
                        {document.partner?.name ? document.partner.name : "-----"}
                    </div>
                </Tooltip>
            </div>

            <div
                className={styles["card-header__dashboard"]}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <CardActionsList document={document} />
            </div>
        </div>
    );
};
