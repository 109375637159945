import React, { FC } from "react";
import { Button, Flex, Form, Tooltip } from "antd";
import { InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { InvoiceFormItemWrapper } from "@inv/components/InvoiceForm/components/InvoiceFormItemWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { InvoiceContact } from "@inv/components/InvoiceForm/components/InvoiceContact/InvoiceContact";
import { InfoCircleOutlined } from "@ant-design/icons";
import { StringInput } from "@app/components/shared/form/baseComponents/StringInput/StringInput";

export const ContactBlock: FC = () => {
    const intl = useIntl();
    return (
        <InvoiceFormItemWrapper
            title={
                <>
                    <FormattedMessage id={"app.titles.Contact"} /> 🪄
                </>
            }
        >
            <Flex style={{ width: "100%" }} gap={10}>
                <Form.Item name={InvoiceInputs.CONTACT} style={{ width: "100%" }}>
                    <InvoiceContact />
                </Form.Item>
                <Form.Item>
                    <Tooltip title={<FormattedMessage id={InvoiceFormInputTranslate.CONTACT_HINT} />}>
                        <Button icon={<InfoCircleOutlined />} shape={"circle"} type={"text"} />
                    </Tooltip>
                </Form.Item>
            </Flex>
            <Form.Item
                name={InvoiceInputs.CUSTOMER_NUMBER}
                label={intl.formatMessage({ id: InvoiceFormInputTranslate.CUSTOMER_NUMBER })}
            >
                <StringInput
                    placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.CUSTOMER_NUMBER })}
                    disabled={true}
                />
            </Form.Item>
        </InvoiceFormItemWrapper>
    );
};
