import { blue, green, orange, volcano } from "@ant-design/colors";
import Checkbox from "antd/es/checkbox/Checkbox";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { CarouselItem } from "@ui-components/PdfViewer/DocumentCarousel/CarouselItem";

import styles from "./DmsItem.module.scss";

interface IProps {
    document: GQL.IDocument;
    index: number;
    handleCheckedItems: () => void;
}

export const DmsItem: FC<IProps> = ({ document, handleCheckedItems, index }) => {
    const [checked, setChecked] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string>();

    const { checkboxCarousel, setCheckboxCarousel } = useContext(FormWrapperContext);

    const processedStatus = DmsUtils.getDocumentStatus(document);

    const onChange = (arg: boolean) => {
        if (arg) {
            setCheckboxCarousel(prev => {
                if (prev.includes(document.key)) {
                    return [...prev];
                }
                return [...prev, document.key];
            });
        } else {
            setCheckboxCarousel(prev => prev.filter(el => el !== document.key));
        }
    };

    const documentStatusColor = {
        ready: green[6],
        draft: orange[5],
        new: blue[5],
        trash: "",
    };

    useEffect(() => {
        setChecked(checkboxCarousel.includes(document.key));
    }, [checkboxCarousel]);

    useEffect(() => {
        handleCheckedItems();
    }, [checked]);

    useEffect(() => {
        DmsUtils.getPreviewUrl(document).then(r => setPreviewUrl(r));
    }, [document]);

    return (
        <>
            <div className={styles.dmsItem}>
                <div className={styles.dmsItemTop}>
                    <Checkbox
                        checked={checked}
                        onChange={event => {
                            onChange(event.target.checked);
                        }}
                    />
                    <div
                        title={(index + 1).toString()}
                        className={styles.dmsItemNumber}
                        style={{ backgroundColor: volcano[1] }}
                    >
                        {index + 1}
                    </div>
                </div>
            </div>
            <div className={styles.dmsItemBody}>
                <CarouselItem id={document.key} previewUrl={previewUrl} />

                <div className={styles.dmsItemFooter}>
                    <div style={{}}>
                        <FormattedMessage id="app.dms.pages" values={{ count: document.numPages }} />
                    </div>

                    <div style={{ padding: "0 2px" }}>{document.documentDate}</div>

                    <div style={{ color: documentStatusColor[processedStatus], fontWeight: 600 }}>
                        {processedStatus}
                    </div>
                </div>
            </div>
        </>
    );
};
