import { Base, GQL, Utils } from "@binale-tech/shared";
import { useContext } from "react";

import { GenericRecord } from "../../../../../../scripts/models";
import { PaymentBindingUtils } from "../../../../../../scripts/models/utils/PaymentBindingUtils";
import { RecordsContext } from "scripts/context/accountingData/RecordsCtx";
import { PaymentsContext } from "scripts/context/accountingData/PaymentsProvider";

type AutoReconciliationRecords = {
    sourceRecord: GenericRecord;
    paymentRepresentationRecords: GenericRecord[];
    bf1: string;
    openAmount: number;
};
export type ReconciliationMapResult = {
    autoReconciliationRecords: Map<string, AutoReconciliationRecords>;
    possiblePayments: number;
};
type Props = {
    displayRecords: GenericRecord[];
};
export const useKAReconciliationMap = ({ displayRecords }: Props): ReconciliationMapResult => {
    const payments = useContext(PaymentsContext);
    const { allRecords } = useContext(RecordsContext);
    const matchingMap: Map<string, AutoReconciliationRecords> = new Map();
    displayRecords
        .filter(v => {
            if (!Utils.PaymentUtils.isProductPaymentRepresentation(v.getProductKey())) {
                return false;
            }
            const { record: sourceRecord } = PaymentBindingUtils.getConnectedPaymentSourceRecord({
                allRecords,
                paymentsRecordRelation: payments.recordRelation,
                representationRecordKey: v.key,
            });
            if (sourceRecord) {
                return false;
            }
            if (v.getProductKey() === GQL.IProductKey.Fe) {
                const kontoNums = new Set(
                    v.items.map(item => item.getCategoryCreditor().getExtNum(Base.CompanyKontoExtMax))
                );
                if (kontoNums.size > 1) {
                    return false;
                }
            }
            return true;
        })
        .forEach(paymentRepresentationRecord => {
            const matchingSourceRecords = PaymentBindingUtils.getMatchingPaymentSourceRecordsForReconciliation({
                payments,
                paymentRepresentationRecord,
                displayRecords,
            });
            matchingSourceRecords.forEach(sourceRecord => {
                const relatedPayments = payments.recordRelation.get(sourceRecord.key);
                const openAmount = sourceRecord.getOpenBrutto(relatedPayments);
                if (!matchingMap.has(sourceRecord.key)) {
                    matchingMap.set(sourceRecord.key, {
                        paymentRepresentationRecords: [],
                        bf1: sourceRecord.num,
                        openAmount,
                        sourceRecord,
                    });
                }
                // console.log("pushing", source.key, paymentRepresentationRecord);
                matchingMap.get(sourceRecord.key).paymentRepresentationRecords.push(paymentRepresentationRecord);
            });
        });
    // console.log(new Map(matchingMap));

    const utilisedRepresentationRecordIds: Set<string> = new Set();
    matchingMap.forEach((value, sourceRecordKey) => {
        const paymentRepresentationsAmount = value.paymentRepresentationRecords
            .filter(v => !utilisedRepresentationRecordIds.has(v.key))
            .reduce((sum, paymentRepresentationRecord) => {
                const { amount } = Utils.PaymentUtils.getPaymentDataFromRepresentationRecord(
                    paymentRepresentationRecord.getBrutto(),
                    paymentRepresentationRecord.getProductKey(),
                    value.sourceRecord.getProductKey(),
                    { category: paymentRepresentationRecord.category?.num },
                    paymentRepresentationRecord.items.map(item => ({ category: item.category?.num }))
                );

                return sum + amount;
            }, 0);
        if (paymentRepresentationsAmount !== value.openAmount) {
            matchingMap.delete(sourceRecordKey);
        } else {
            value.paymentRepresentationRecords.forEach(v => utilisedRepresentationRecordIds.add(v.key));
        }
    });
    let representationRecordsCount = 0;
    matchingMap.forEach(value => {
        representationRecordsCount += value.paymentRepresentationRecords.length;
    });
    return { autoReconciliationRecords: matchingMap, possiblePayments: representationRecordsCount };
};
