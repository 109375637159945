import { GQL } from "@binale-tech/shared";
import { CurrencyValue } from "@ui-components/AmountInput/BruttoInput";
import * as React from "react";
import { type FC, type PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DmsType, type TDocumentInputs } from "@dms/types";
import { necessaryFieldsForDocumentTypes } from "@dms/configs/constants";
import { DocumentFieldConfig } from "@dms/configs/documentConfig";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { compareObjectsByKey } from "@dms/scripts/helpers";
import { getFileNameAndSuffix } from "@dms/scripts/helpers/getFileNameAndSuffix";
import { FormWrapperContext } from "../../DocumentFormModule/context/FormWrapperContext";
import { useGqlMutator } from "../../../../scripts/graphql/useGqlMutator";

interface TValue {
    groupFormInitialValue: TDocumentInputs | undefined;
    disableInputs: (arg: boolean) => void;
    notEqualField: string[];
    isCancel: boolean;
    actionConfirm: (arg: TDocumentInputs) => Promise<void>;
    requiredFields: string[];
    setRequiredFields: (arg: string[]) => void;
    fileNameSuffix: string;
    necessaryGroupFields?: readonly (keyof Partial<GQL.IDocument>)[];
    handleSetNecessaryGroupFields: (type: string) => void;
}

type TProps = {
    documentsData: GQL.IDocument[];
};

/**
 Context for Document Form
 */
export const GroupFormContext = React.createContext({} as TValue);

/**
 Context Provider for Document Form
 */

export const GroupFormProvider: FC<PropsWithChildren & TProps> = ({ children, documentsData }) => {
    const [groupFormInitialValue, setGroupFormInitialValue] = useState<TDocumentInputs>();
    const [notEqualField, setNotEqualField] = useState<(keyof TDocumentInputs)[]>([]);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [requiredFields, setRequiredFields] = useState<string[]>([]);
    const [necessaryGroupFields, setNecessaryGroupFields] = useState<readonly (keyof Partial<GQL.IDocument>)[]>([]);

    const [fileNameSuffix, setFileNameSuffix] = useState<string>(".pdf");

    const { checkboxCarousel } = useContext(FormWrapperContext);

    const mutator = useGqlMutator();

    const initialDocuments = useRef<GQL.IDocument[]>(null);

    useEffect(() => {
        if (!initialDocuments?.current) {
            initialDocuments.current = documentsData;
            return;
        }

        if (initialDocuments.current.length === documentsData.length) {
            return;
        }

        const dataKeys = documentsData.map(d => d.key);

        initialDocuments.current = initialDocuments.current.filter(el => dataKeys.includes(el.key));
    }, [documentsData]);

    const updateFormInitialValue = useCallback(() => {
        if (!initialDocuments?.current) {
            return;
        }

        const initialData = initialDocuments.current;

        const selectedDocuments = initialData.filter((document: { key: string }) =>
            checkboxCarousel.includes(document.key)
        );

        if (selectedDocuments?.length === 0) {
            return;
        }

        let docType = selectedDocuments[0].type;

        for (const doc of selectedDocuments) {
            if (doc.type !== docType) {
                docType = "diverse";
                break;
            }
        }

        const notEqualFieldArr: (keyof TDocumentInputs)[] = [];

        const fields: Record<string, TDocumentInputs[keyof TDocumentInputs]> = {};

        Object.keys(DocumentFieldConfig.getDocumentField(docType)).forEach(key => {
            const parameter = key as keyof GQL.IDocument;
            const isNotIdentity = compareObjectsByKey(selectedDocuments, parameter);

            if (isNotIdentity) {
                notEqualFieldArr.push(parameter);

                return (fields[parameter] = undefined);
            }

            return (fields[parameter] = selectedDocuments[0][parameter]);
        });

        const type = fields.subType ? fields.subType : fields.type;
        const { fileName, documentAmount, originalAmount, currency, currencyRate, ...restValue } = fields;

        let clippedFileName = fileName as string;
        if (fileName) {
            const { fileName: name, suffix } = getFileNameAndSuffix(fileName as string);
            clippedFileName = name;
            setFileNameSuffix(suffix || ".pdf");
        }

        const currencyData: CurrencyValue = {
            amount: documentAmount as number,
            originalAmount: originalAmount as number,
            currency: { code: currency as GQL.ICurrencyCode, rate: (currencyRate as number) ?? 1 },
        };

        setGroupFormInitialValue({
            ...restValue,
            type: type as string,
            currencyData,
            fileName: clippedFileName,
        });
        setNotEqualField(notEqualFieldArr);
        setNecessaryGroupFields(necessaryFieldsForDocumentTypes[fields.type as DmsType] || []);
    }, [checkboxCarousel]);

    useEffect(() => {
        updateFormInitialValue();
    }, [updateFormInitialValue]);

    const value: TValue = useMemo(() => {
        return {
            groupFormInitialValue,
            notEqualField,
            isCancel,
            requiredFields,
            fileNameSuffix,
            necessaryGroupFields,
            setRequiredFields,
            disableInputs: (arg: boolean): void => {
                setIsCancel(arg);
            },
            actionConfirm: async (data: TDocumentInputs): Promise<void> => {
                if (!initialDocuments) {
                    return;
                }

                const confirmDocuments = initialDocuments.current.map(el => {
                    if (!checkboxCarousel.includes(el.key)) {
                        return el;
                    }

                    return {
                        ...el,
                        ...data,
                    };
                });

                const documentsToSave: GQL.IDocument[] = confirmDocuments.filter(el =>
                    checkboxCarousel.includes(el.key)
                );
                await DocumentsApi.updateDocuments({ documents: documentsToSave, mutator });

                initialDocuments.current = confirmDocuments;
                updateFormInitialValue();
            },
            handleSetNecessaryGroupFields: (type: string) => {
                setNecessaryGroupFields(necessaryFieldsForDocumentTypes[type as DmsType] || []);
            },
        };
    }, [
        groupFormInitialValue,
        notEqualField,
        isCancel,
        requiredFields,
        fileNameSuffix,
        necessaryGroupFields,
        mutator,
        updateFormInitialValue,
        checkboxCarousel,
    ]);

    return <GroupFormContext.Provider value={value}>{children}</GroupFormContext.Provider>;
};
