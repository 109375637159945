import { Contacts, GQL } from "@binale-tech/shared";
import { removeEmpty } from "scripts/infrastructure/helpers";

export const ContactUtils = {
    mapContactFromGQL: (gqlContact: GQL.IContact): Contacts.Contact => {
        const mapTypes = (type: GQL.IContactRelation): GQL.IContactRelationItem => {
            const relationItem = gqlContact.relations && gqlContact.relations.find(r => r.type === type);

            if (!relationItem) {
                return undefined;
            }

            return { ...relationItem };
        };

        const mappedContact: Contacts.Contact = {
            ...gqlContact,
            type: gqlContact.type,
            organizationLegalForm: gqlContact.organizationLegalForm as Contacts.LegalFormType,
            relations: Contacts.ContactConstants.relationTypeKontoOrder.map(mapTypes).filter(Boolean),
        };

        return mappedContact;
    },

    mapContactToGQL: (contact: Contacts.Contact): GQL.IContact => {
        const mapTypes = (type: GQL.IContactRelation): GQL.IContactRelationItem => {
            const typeItem = contact.relations.find(r => r.type === type);
            if (!typeItem) {
                return undefined;
            }

            return {
                type,
                internalNumber: typeItem.internalNumber,
                paymentTerms: typeItem.paymentTerms,
            };
        };
        const removeEmptyCommunications = (communications: GQL.IContactCommunication[]) =>
            communications && communications.filter(value => !!value.value || !!value.comment);

        const contactToSave: GQL.IContact = {
            ...contact,
            // isPrimary always exists, so the whole address should be removed if isPrimary = false
            addresses:
                contact.addresses &&
                contact.addresses.filter(address => {
                    const emptyAddress = removeEmpty(address);
                    const removeExtraFieldsKeys = emptyAddress ? Object.keys(emptyAddress) : [];
                    const onlyPrimaryLeft =
                        removeExtraFieldsKeys.length === 1 &&
                        removeExtraFieldsKeys[0] === "isPrimary" &&
                        !address.isPrimary;
                    return !onlyPrimaryLeft;
                }),
            relations: Contacts.ContactConstants.relationTypeKontoOrder.map(mapTypes).filter(Boolean),
            communications: removeEmptyCommunications(contact.communications),
            contactPersons: contact.contactPersons?.length
                ? contact.contactPersons.map(person => ({
                      ...person,
                      communications: removeEmptyCommunications(person.communications),
                  }))
                : undefined,
        };
        if (contactToSave.legalInfo?.vat && !contactToSave.legalInfo.vat.vatNumber) {
            delete contactToSave.legalInfo.vat;
        }

        return removeEmpty(contactToSave);
    },
};
