import React, { CSSProperties, useCallback, useContext, useEffect, useMemo } from "react";
import { Card, ConfigProvider, Splitter } from "antd";

import {
    RecordFormStateContext,
    RecordFormStateControlContext,
} from "@app/components/recordform/context/RecordFormState";
import { useFormIsRefDisabled } from "@app/components/recordform/hooks/useFormIsRefDisabled";
import { RecordFormPropsContext } from "@app/components/recordform/context/RecordFormPropsContext";
import { TableViewContext } from "../../../../scripts/context/accountingTable/tableViewContext";
import { useFormHandlerDocument } from "@app/components/recordform/hooks/handlers/useFormHandlerDocument";
import { RecordFormFileUploader } from "@app/components/recordform/FileUploader/RecordFormFileUploader";
import { GQL } from "@binale-tech/shared";
import { SplitCard } from "@app/components/recordform/form/SplitCard";

const FormCardStyleGlass: CSSProperties = {
    width: "calc(100vw-10px)",
    left: 5,
    right: 5,
    position: "fixed",
    top: 122,
    height: "calc(100vh - 221px)",
    zIndex: 50,
    boxShadow: "0px 1px 10px 1px #87919c",
    background: "rgba(220, 220, 220, 0.5)",
    borderRadius: 16,
    // backdropFilter: "blur(2px)",
    border: "1px solid rgba(255, 255, 255, 0.9)",
};

const defaultDocumentIds: string[] = [];
const defaultSplit: [string, string] = ["50%", "50%"];
export const OverflowCard: React.FC = () => {
    const { isDocumentsCardOpen, isSplitCardOpen } = useContext(RecordFormStateContext);
    const isDisabled = useFormIsRefDisabled();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setIsDocumentsCardOpen } = useContext(RecordFormStateControlContext);
    const { product } = useContext(TableViewContext);
    const { editableRecord } = useContext(RecordFormStateContext);
    const [sizes, setSizes] = React.useState<(number | string)[]>(defaultSplit);
    const [showSplit, setShowSplit] = React.useState<boolean>(false);
    const [showDocs, setShowDocs] = React.useState<boolean>(false);
    useEffect(() => {
        if (!isDocumentsCardOpen && !isSplitCardOpen) {
            setSizes(defaultSplit);
        }
    }, [isDocumentsCardOpen, isSplitCardOpen]);

    // we need to delay a bit mount of the cards due to the glitch with splitter
    useEffect(() => {
        if (!isSplitCardOpen) {
            setShowSplit(false);
        } else {
            setTimeout(() => setShowSplit(true), 20);
        }
    }, [isSplitCardOpen]);
    useEffect(() => {
        if (!isDocumentsCardOpen) {
            setShowDocs(false);
        } else {
            setTimeout(() => setShowDocs(true), 20);
        }
    }, [isDocumentsCardOpen]);

    const onDocumentsChange = useFormHandlerDocument();

    const resetState = useCallback(() => setIsDocumentsCardOpen(false), [setIsDocumentsCardOpen]);
    const documentIds = useMemo(
        () =>
            editableRecord.recordDocuments?.length
                ? editableRecord.recordDocuments.map(el => el.id)
                : defaultDocumentIds,
        [editableRecord.recordDocuments]
    );

    if (!isDocumentsCardOpen && !isSplitCardOpen) {
        return null;
    }
    return (
        <Card style={FormCardStyleGlass} styles={{ body: { height: "100%", padding: 5 } }}>
            <ConfigProvider
                theme={{
                    components: {
                        Splitter: {
                            splitBarSize: 4,
                        },
                    },
                }}
            >
                <Splitter onResize={setSizes} style={{ height: "100%" }}>
                    <Splitter.Panel size={sizes[0]} min="20%" style={{ paddingRight: 15 }}>
                        {showDocs && (
                            <RecordFormFileUploader
                                onHide={resetState}
                                allowEdit={!isDisabled(refsHtml.REF_cASSET)}
                                mode={"form"}
                                currentDocumentIds={documentIds}
                                productKey={product.productKey() as GQL.IProductKey}
                                onChangeDocuments={onDocumentsChange}
                            />
                        )}
                    </Splitter.Panel>
                    <Splitter.Panel size={sizes[1]} min="20%" style={{ paddingLeft: 15 }}>
                        {showSplit && <SplitCard />}
                    </Splitter.Panel>
                </Splitter>
            </ConfigProvider>
        </Card>
    );
};
