import { GQL } from "@binale-tech/shared";

/**
 * @param selectedDocuments
 * @param parameter
 * @return
 * true: If the values of the specified key are different in the array of objects.
 * false: If the values of the specified key are the same in the array of objects.
 */

export function compareObjectsByKey(selectedDocuments: GQL.IDocument[], parameter: keyof GQL.IDocument): boolean {
    const selectedValues = selectedDocuments.map(document => JSON.stringify(document[parameter]));
    return new Set(selectedValues).size !== 1;
}
