import React from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { Contacts, GQL } from "@binale-tech/shared";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { CheckboxButton } from "appearance/components/shared/form/baseComponents";

interface Props {
    index: number;
    typeKey: GQL.IContactRelation;
}

const RelationButton: React.FC<Props> = React.memo(function RelationButton({ index, typeKey }) {
    const { setValue, watch } = useFormContext<Contacts.Contact>();
    const intl = useIntl();
    const relationItem = watch(`relations.${index}`);

    return (
        <Col span={8} data-testid={`contacts-konto-${index}`}>
            <Controller
                name={`relations.${index}.type`}
                render={({ field }) => {
                    // console.log({ typeKey, v: field.value });
                    return (
                        <Form.Item style={{ marginBottom: 12 }}>
                            <CheckboxButton
                                label={<FormattedMessage id={`app.titles.contacts.${typeKey}`} />}
                                value={Boolean(field.value)}
                                onChange={value => {
                                    if (value) {
                                        setValue(`relations.${index}`, {
                                            internalNumber: "",
                                            type: typeKey,
                                        });
                                    } else {
                                        setValue(`relations.${index}`, {
                                            internalNumber: "",
                                            type: undefined,
                                        });
                                    }
                                }}
                            />
                        </Form.Item>
                    );
                }}
            />

            <Controller
                name={`relations.${index}.internalNumber`}
                shouldUnregister
                render={({ field }) => {
                    return (
                        <Form.Item style={{ marginBottom: 12 }}>
                            <Input
                                placeholder={intl.formatMessage({ id: "app.fields.customerNumber" })}
                                disabled={!relationItem?.type}
                                maxLength={20}
                                role={"textbox"}
                                {...field}
                            />
                        </Form.Item>
                    );
                }}
            />
        </Col>
    );
});

export const RelationButtons = React.memo(function RelationButtons() {
    return (
        <>
            <Typography.Title level={5} style={{ margin: "16px 0" }}>
                <FormattedMessage id="app.titles.contacts.classification" />
            </Typography.Title>
            <Row gutter={8} justify="center">
                {Contacts.ContactConstants.relationTypeKontoOrder.map((key: GQL.IContactRelation, index) => (
                    <RelationButton key={key} typeKey={key} index={index} />
                ))}
            </Row>
        </>
    );
});
