import React, { FC } from "react";
import { Button, Flex } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Periods } from "@binale-tech/shared";

import { GlobalYearSelect } from "../../../../components/shared/Toolbar/YearSelect";
import { PeriodSelect, SelectorPeriods } from "../../../../components/shared/Toolbar/PeriodSelect";
import { Switch } from "@heroui/switch";

export interface SusaFilter {
    period: SelectorPeriods;
    showAllMonth: boolean;
}

interface Props {
    filter: SusaFilter;
    onShowAllMonths: (v: boolean) => void;
    onChangePeriod: (v: Periods.Period) => void;
    onDownloadCsv: () => void;
}

export const SusaToolbar: FC<Props> = props => {
    const { filter, onShowAllMonths } = props;

    return (
        <Flex align="center" gap={"small"} style={{ padding: "8px 0" }}>
            <GlobalYearSelect />
            <PeriodSelect withAll value={filter.period} onChange={props.onChangePeriod} />
            {filter.period === null && (
                <Switch onValueChange={onShowAllMonths} checked={filter.showAllMonth}>
                    <FormattedMessage id="app.components.all_months" />
                </Switch>
            )}
            <div style={{ flexGrow: 1 }} />
            <Button onClick={props.onDownloadCsv} htmlType="button">
                <FileExcelOutlined /> CSV
            </Button>
        </Flex>
    );
};
