import React, { useContext, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";

import tableStyles from "./VatTable.module.css";
import { Button, Modal, Table } from "antd";
import { gql, useApolloClient } from "@apollo/client";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { GQL } from "@binale-tech/shared";
import { EyeOutlined } from "@ant-design/icons";
import { PdfSingleDocumentViewer } from "@ui-components/PdfViewer";
import { base64StringToFile } from "../../../../../scripts/converters/converters";

const query = gql`
    query elsterExports($companyId: String!, $year: Int!) {
        elsterExports(companyId: $companyId, year: $year) {
            id
            year
            period
            createdAt
            ericResultCode
            pdf
            transferticket
        }
    }
`;
export const VatExports: React.FC = () => {
    const client = useApolloClient();
    const { companyGQL } = useContext(CompanyContext);
    const { year } = useContext(YearPeriodContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [elsterExports, setElsterExports] = useState<GQL.IElsterExport[]>([]);
    const [modalExport, setModalExport] = useState<GQL.IElsterExport>();
    const [pdf, setPdf] = useState<{ fileUrl: string; filename: string }>();

    useEffect(() => {
        if (!modalExport) {
            setPdf(undefined);
            return;
        }
        const filename = modalExport.transferticket + ".pdf";
        const filePdf = base64StringToFile(modalExport.pdf, filename, "application/pdf");
        const fileUrl = URL.createObjectURL(filePdf);
        setPdf({ fileUrl, filename });
    }, [modalExport]);

    useEffect(() => {
        if (!companyGQL?.id) {
            return;
        }
        client
            .query<Pick<GQL.IQuery, "elsterExports">, GQL.IQueryElsterExportsArgs>({
                query,
                fetchPolicy: "network-only",
                variables: {
                    companyId: companyGQL.id,
                    year,
                },
            })
            .then(res => {
                setElsterExports(res.data.elsterExports);
            })
            .finally(() => setIsLoading(false));
    }, [client, companyGQL?.id, year]);

    const columns: ColumnsType<GQL.IElsterExport> = [
        {
            key: "year",
            dataIndex: "year",
        },
        {
            key: "period",
            title: "Periode",
            dataIndex: "period",
        },
        {
            key: "createdAt",
            dataIndex: "createdAt",
        },
        {
            key: "transferticket",
            dataIndex: "transferticket",
        },
        {
            key: "pdf",
            dataIndex: "pdf",
            render: (cell: string, record: GQL.IElsterExport) => (
                <Button shape={"circle"} icon={<EyeOutlined />} onClick={() => setModalExport(record)} />
            ),
        },
    ];

    return (
        <div className={tableStyles.container}>
            <Table loading={isLoading} dataSource={elsterExports} columns={columns} />
            <Modal
                destroyOnClose
                width={800}
                footer={null}
                styles={{ body: { height: "70vh", position: "relative", display: "flex" } }}
                open={Boolean(modalExport)}
                onCancel={() => setModalExport(undefined)}
                title={
                    <>
                        Transferticket: <b>{modalExport?.transferticket}</b>
                    </>
                }
            >
                {pdf && <PdfSingleDocumentViewer url={pdf.fileUrl} type="application/pdf" fileName={pdf.filename} />}
            </Modal>
        </div>
    );
};
