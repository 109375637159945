import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes } from "scripts/routing/routeConstants";
import { Link, useLocation } from "react-router-dom";
import { InvoicingPaths } from "@inv/types";
import { FormattedMessage } from "react-intl";
import { DownOutlined } from "@ant-design/icons";

type Props = { menuProps: MenuProps };
const ModuleInvoicingLinks: React.FC<Props> = ({ menuProps }) => {
    const { pathname = "" } = useLocation();

    const linkFormats = AppRoutes.invoicing + "/" + InvoicingPaths.FORMATS;
    const linkLITemplates = AppRoutes.invoicing + "/" + InvoicingPaths.LINE_ITEMS_TEMPLATES;
    const linkPTTemplates = AppRoutes.invoicing + "/" + InvoicingPaths.PAYMENT_TEMPLATES;

    return (
        <Menu
            selectedKeys={[pathname]}
            {...menuProps}
            items={[
                {
                    key: AppRoutes.invoicing,
                    label: (
                        <Link to={AppRoutes.invoicing}>
                            <FormattedMessage id={"app.invoice.invoicesList"} />
                        </Link>
                    ),
                },
                { key: linkFormats, label: <Link to={linkFormats}>Formats</Link> },

                {
                    key: "Templates",
                    label: (
                        <span>
                            <FormattedMessage id={"app.titles.templates"} /> <DownOutlined />
                        </span>
                    ),
                    children: [
                        {
                            key: linkLITemplates,
                            label: (
                                <Link to={linkLITemplates}>
                                    <FormattedMessage id={"app.invoice.invoiceLineItemTemplates"} />
                                </Link>
                            ),
                        },
                        {
                            key: linkPTTemplates,
                            label: (
                                <Link to={linkPTTemplates}>
                                    <FormattedMessage id={"app.invoice.invoicePaymentTemplates"} />
                                </Link>
                            ),
                        },
                    ],
                },
            ]}
        ></Menu>
    );
};

export default React.memo(ModuleInvoicingLinks);
