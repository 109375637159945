import React, { useContext, useEffect, useState } from "react";
import { AppleFilled } from "@ant-design/icons";
import { Button, Form, Input, Space, Steps, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { KeyEncodings } from "@otplib/core";
import { authenticator } from "@otplib/preset-default";

import googlePlayBtn from "../../../assets/google-play-colors.svg";
import pencil from "../../../assets/pencil.svg";
import { UserControlContext } from "scripts/context/UserProvider";
import { useSecretQrCode } from "./useSecretQrCode";
import { validation } from "scripts/infrastructure/helpers/validation";

import "./stepsStyle.css";
import { getServerlessApi } from "../../../../scripts/api/backendApi";

interface IForm {
    token: string;
}

interface Props {
    onCancel: () => void;
}

export const StepsLayout: React.FC<Props> = ({ onCancel }) => {
    const intl = useIntl();
    const [step, setStep] = useState(0);
    const [secret, setSecret] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm<IForm>();
    const qrCode = useSecretQrCode(secret);

    const { savePrivateToken } = useContext(UserControlContext);

    useEffect(() => {
        if (step === 1 && !secret) {
            authenticator.options = {
                encoding: KeyEncodings.HEX,
            };

            setSecret(authenticator.generateSecret());
        }
    }, [step, secret]);

    const handleSubmit = async (values: IForm) => {
        setSaving(true);
        await savePrivateToken(secret);
        setSaving(false);
    };

    const isFormValid = () => form.getFieldsError().some(item => item.errors.length > 0);

    return (
        <div style={{ textAlign: "center" }} data-testid="StepsLayout">
            <Typography.Title level={4} style={{ marginBottom: 24 }}>
                <FormattedMessage id="app.2fa.step.title" />
            </Typography.Title>
            <Steps
                progressDot
                current={step}
                size="small"
                onChange={setStep}
                items={[
                    { title: <FormattedMessage id="app.2fa.step.download.title" /> },
                    { title: <FormattedMessage id="app.2fa.step.scan.title" /> },
                    { title: <FormattedMessage id="app.2fa.step.backup.title" /> },
                    { title: <FormattedMessage id="app.2fa.step.enable.title" /> },
                ]}
            />

            <div>
                <Typography.Title level={5} style={{ marginBottom: 24, marginTop: 12 }}>
                    <FormattedMessage id="app.global.step" /> {step + 1}
                </Typography.Title>
            </div>

            {step === 0 && (
                <div data-testid="step1">
                    <FormattedMessage id="app.2fa.step.download.description" tagName="p" />
                    <Typography.Paragraph>
                        <div>
                            <b>FreeOTP</b>
                        </div>
                    </Typography.Paragraph>

                    <Space size="large" style={{ marginBottom: 12 }}>
                        <a
                            href="https://apps.apple.com/us/app/freeotp-authenticator/id872559395"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="StepsLayout__app_button" style={{ marginRight: 48 }}>
                                <AppleFilled />
                                <div>
                                    <FormattedMessage id="app.button.download" tagName="div" />
                                    <h4>App Store</h4>
                                </div>
                            </div>
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="StepsLayout__app_button">
                                <img src={googlePlayBtn} alt="google play icon" />
                                <div>
                                    <FormattedMessage id="app.button.download" tagName="div" />
                                    <h4>Google Play</h4>
                                </div>
                            </div>
                        </a>
                    </Space>
                    <Typography.Paragraph>
                        <div>Google Authentificator</div>
                        <div>Microsoft Authentificator</div>
                    </Typography.Paragraph>

                    <Space>
                        <Button type="text" size="large" className="wide-button" onClick={onCancel}>
                            <FormattedMessage id="app.button.cancel" />
                        </Button>
                        <Button type="primary" size="large" className="wide-button" onClick={() => setStep(1)}>
                            <FormattedMessage id="app.button.next" />
                        </Button>
                    </Space>
                </div>
            )}

            {step === 1 && (
                <div data-testid="step2">
                    <FormattedMessage id="app.2fa.step.scan.description" tagName="p" />
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 16, justifyContent: "center" }}>
                        {qrCode && <img src={qrCode} alt="qr code" />}
                        <div>
                            <FormattedMessage id="app.2fa.step.scan.manual_enter" tagName="p" />
                            <p className="StepsLayout__code" data-testid="secret">
                                {secret}
                            </p>
                        </div>
                    </div>
                    <Space>
                        <Button type="text" size="large" className="wide-button" onClick={() => setStep(0)}>
                            <FormattedMessage id="app.global.previous_step" />
                        </Button>
                        <Button type="primary" size="large" className="wide-button" onClick={() => setStep(2)}>
                            <FormattedMessage id="app.button.next" />
                        </Button>
                    </Space>
                </div>
            )}
            {step === 2 && (
                <div data-testid="step3">
                    <div style={{ display: "flex", alignItems: "center", margin: "24px 0", justifyContent: "center" }}>
                        <img src={pencil} className="StepsLayout__saveIcon" alt="" />
                        <div>
                            <FormattedMessage id="app.2fa.step.backup.description" tagName="p" />
                            <p className="StepsLayout__code" data-testid="secret">
                                {secret}
                            </p>
                        </div>
                    </div>
                    <Space>
                        <Button type="text" size="large" className="wide-button" onClick={() => setStep(1)}>
                            <FormattedMessage id="app.global.previous_step" />
                        </Button>
                        <Button type="primary" size="large" className="wide-button" onClick={() => setStep(3)}>
                            <FormattedMessage id="app.button.next" />
                        </Button>
                    </Space>
                </div>
            )}
            {step === 3 && (
                <div data-testid="step4">
                    <Form onFinish={handleSubmit} layout="vertical" form={form}>
                        <Form.Item
                            name="token"
                            label={<FormattedMessage id="app.2fa.input_label" />}
                            style={{ marginBottom: 24, alignContent: "center" }}
                            validateFirst
                            shouldUpdate
                            required={false}
                            rules={[
                                ...validation.commonNumber({ intl, max: 6, min: 6 }),
                                validation.required(intl),
                                () => ({
                                    validateTrigger: "onSubmit",
                                    validator: async (_, inputToken) => {
                                        const { host, headers } = await getServerlessApi();
                                        const res = await fetch(`${host}/auth/v1/validate2faToken`, {
                                            method: "POST",
                                            body: JSON.stringify({ data: { inputToken, secret } }),
                                            headers: { ...headers, "Content-Type": "application/json" },
                                        });
                                        if (res.ok) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                new Error(intl.formatMessage({ id: "app.2fa.token_error" }))
                                            );
                                        }
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Space>
                                    <Button type="text" size="large" className="wide-button" onClick={() => setStep(2)}>
                                        <FormattedMessage id="app.global.previous_step" />
                                    </Button>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="wide-button"
                                        loading={saving}
                                        disabled={isFormValid()}
                                        htmlType="submit"
                                    >
                                        <FormattedMessage id="app.button.confirm" />
                                    </Button>
                                </Space>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            )}
        </div>
    );
};
