import React, { FC, PropsWithChildren, useContext, useState } from "react";
import { BarsOutlined, HomeOutlined } from "@ant-design/icons";
import { Button, Drawer, Menu } from "antd";
import { FormattedMessage } from "react-intl";
import { ItemType } from "antd/es/menu/interface";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { AppSettingsContext } from "scripts/context/AppSettingsProvider";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ReadinessContext } from "scripts/context/DataReadyness";
import { UserContext } from "scripts/context/UserProvider";
import { GQL } from "@binale-tech/shared";
import { GroupKey, ProductDetails, ProductGroups, ProductKeys } from "scripts/models/Product";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";

const LinkWrapper: FC<PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>> = ({ children, ...props }) => {
    return (
        <a {...props} target="_blank" rel="noopener noreferrer" onClick={e => e.preventDefault()}>
            {children}
        </a>
    );
};
export const AppsDrawer: React.FC = () => {
    const user = useContext(UserContext);
    const { isReady } = useContext(ReadinessContext);
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const { appError } = useContext(AppSettingsContext);

    const navigate = useNavigate();
    const { pathname = "" } = useLocation();
    const [open, setOpen] = useState(false);

    const hasAccountingCommon = ProductAccessUtils.canRead(ProductKeys.AccountingCommon, companyGQL, user);

    const generateUrl = (key: string) => location.origin + key;

    const getGroupListItems = (gk: GroupKey) => {
        return ProductDetails.getGroup(gk).map(d => {
            const disabled = !isReady || !ProductAccessUtils.canRead(d.pk, companyGQL, user);
            return {
                key: d.link,
                disabled,
                label: (
                    <LinkWrapper href={generateUrl(d.link)} data-testid={`header-menu-link-${gk}-${d.pk}`}>
                        {d.icon} <FormattedMessage id={`app.titles.${d.pk}`} tagName="span" />
                    </LinkWrapper>
                ),
            };
        });
    };

    const menuItems: ItemType[] = [
        {
            key: AppRoutes.home,
            label: (
                <LinkWrapper href={generateUrl(AppRoutes.home)} data-testid={"header-menu-link-home"}>
                    <>
                        <HomeOutlined />
                        <FormattedMessage id="app.header.workplace" tagName="span" />
                    </>
                </LinkWrapper>
            ),
        },
        {
            type: "divider",
        },
        ...getGroupListItems(ProductGroups.Buch),
        {
            type: "divider",
        },
        {
            key: AppRoutes.accountsView,
            disabled: !hasAccountingCommon,
            label: (
                <LinkWrapper href={generateUrl(AppRoutes.accountsView)}>
                    <FormattedMessage id="app.titles.KA" />
                </LinkWrapper>
            ),
        },
        {
            key: AppRoutes.SuSa,
            disabled: !hasAccountingCommon,
            label: (
                <LinkWrapper href={generateUrl(AppRoutes.SuSa)}>
                    <FormattedMessage id="app.titles.SuSa" />
                </LinkWrapper>
            ),
        },
        {
            key: AppRoutes.BWA,
            disabled: !hasAccountingCommon,
            label: <LinkWrapper href={generateUrl(AppRoutes.BWA)}>BWA</LinkWrapper>,
        },
        yearConfig?.taxation !== GQL.ICompanyTaxation.Kust
            ? {
                  key: AppRoutes.UStVA + AppRoutes.UStVAOverview,
                  disabled: !hasAccountingCommon,
                  label: (
                      <LinkWrapper href={generateUrl(AppRoutes.UStVA + AppRoutes.UStVAOverview)}>
                          <FormattedMessage id="app.titles.Vat" />
                      </LinkWrapper>
                  ),
              }
            : null,
        {
            key: AppRoutes.recordImportExport,
            disabled: !hasAccountingCommon,
            label: (
                <LinkWrapper href={generateUrl(AppRoutes.recordImportExport)}>
                    <FormattedMessage id="app.titles.import_export" tagName="span" />
                </LinkWrapper>
            ),
        },
        {
            key: AppRoutes.filtersAndSearchView,
            disabled: !hasAccountingCommon,
            label: (
                <LinkWrapper href={generateUrl(AppRoutes.filtersAndSearchView)}>
                    <FormattedMessage id="app.titles.filtern" />
                </LinkWrapper>
            ),
        },
        {
            key: AppRoutes.manageAccount,
            disabled: !hasAccountingCommon,
            label: (
                <LinkWrapper href={generateUrl(AppRoutes.manageAccount)}>
                    <FormattedMessage id="app.titles.kontenverwaltung" />
                </LinkWrapper>
            ),
        },
        {
            type: "divider",
        },
        ...getGroupListItems(ProductGroups.Contacts),
        ...getGroupListItems(ProductGroups.DMS),
        ...getGroupListItems(ProductGroups.Banks),
        ...getGroupListItems(ProductGroups.Invoices),
        ...getGroupListItems(ProductGroups.PDF),
    ].filter(Boolean) as ItemType[];

    const menu = (
        <Menu
            onClick={({ key }) => {
                setOpen(false);
                navigate(key.toString());
            }}
            selectedKeys={[pathname.split("/").slice(0, 2).join("/")]}
            mode="inline"
            items={menuItems}
        />
    );

    return (
        <>
            <Button
                shape="circle"
                className="Header--Button Header--Button__margin"
                icon={<BarsOutlined />}
                disabled={Boolean(appError) || !user.isAuthenticated || !user?.fireUser?.emailVerified}
                loading={user.isLoading}
                onClick={() => setOpen(true)}
                data-testid={"header-menu-btn"}
            />
            <Drawer
                placement="left"
                open={open}
                onClose={() => setOpen(false)}
                closable={false}
                destroyOnClose
                data-testid={"header-menu-drawer"}
            >
                {menu}
            </Drawer>
        </>
    );
};
