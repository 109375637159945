import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import {
    bankCreate,
    bankDelete,
    bankUpdate,
    markFavoriteBank,
    transactionsCreate,
    transactionsDelete,
    transactionsUpdate
} from "../../../scripts/context/mutations/bankMutations.graphql";
import { GQL } from "@binale-tech/shared";

export class BanksApi {
    private static _companyId: string;
    private static _mutator: ReturnType<typeof useGqlMutator>;

    static set companyId(id: string) {
        this._companyId = id;
    }

    static set mutator(mutator: ReturnType<typeof useGqlMutator>) {
        this._mutator = mutator;
    }

    static createBank = async (input: GQL.IBankCreateInput) => {
        const res = await this._mutator.mutate({
            mutation: bankCreate,
            input: { ...input, companyId: this._companyId },
            hideMessages: true,
        });

        return res.bankCreate?.id;
    };

    static updateBank = async (input: GQL.IBank) => {
        const { createdBy, createdAt, updatedAt, ...restInput } = input;
        await this._mutator.mutate({
            mutation: bankUpdate,
            input: { ...restInput, companyId: this._companyId },
            hideMessages: true,
        });
    };

    static deleteBank = async (input: GQL.IBank) => {
        const { id } = input;
        await this._mutator.mutate({
            mutation: bankDelete,
            input: { id, companyId: this._companyId },
            hideMessages: true,
        });
    };

    static markFavoriteBank = async (input: GQL.IBank) => {
        const { id } = input;
        await this._mutator.mutate({
            mutation: markFavoriteBank,
            input: { id, companyId: this._companyId },
            hideMessages: true,
        });
    };

    static transactionsCreate = async (input: GQL.ITransactionCreateInput[]) => {
        return this._mutator.mutate({
            mutation: transactionsCreate,
            input,
            hideMessages: true,
        });
    };

    static transactionUpdate = async (input: GQL.ITransactionUpdateInput[]) => {
        return this._mutator.mutate({
            mutation: transactionsUpdate,
            input,
            hideMessages: true,
        });
    };

    static transactionDelete = async (input: GQL.ITransactionDeleteInput) => {
        return this._mutator.mutate({
            mutation: transactionsDelete,
            input,
            hideMessages: true,
        });
    };
}
