import { useEffect, useMemo, useRef, useState } from "react";
import { DatabaseReference, DataSnapshot, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { map, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { auth } from "../../api/firebase/firebase";

type CollectionState<T> = {
    state: Map<string, T>;
    list: T[];
    isLoaded: boolean;
};
export const useCollectionListener = <T,>(
    ref: DatabaseReference,
    initializer: (v: DataSnapshot) => T,
    shouldSkipLoad?: boolean
): CollectionState<T> => {
    const subjectRef = useRef(new Subject<{ type: "set" | "delete"; snap: DataSnapshot }>());

    const [collectionMap, setCollectionMap] = useState<Map<string, T>>(new Map());
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        if (shouldSkipLoad) {
            return;
        }
        // const t = Date.now();
        const data = new Map<string, T>();
        const subscription = subjectRef.current
            .pipe(
                map(event => {
                    if (event.type === "set") {
                        data.set(event.snap.key, initializer(event.snap));
                    }
                    if (event.type === "delete") {
                        data.delete(event.snap.key);
                    }
                    return data;
                }),
                debounceTime(100)
            )
            .subscribe(v => {
                setIsLoaded(true);
                setCollectionMap(new Map(v));
                // console.log(Date.now() - t, "subscribe", v.size, ref.toString());
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [shouldSkipLoad, initializer]);

    useEffect(() => {
        // default state: loaded + empty map
        setIsLoaded(true);
        setCollectionMap(prevState => {
            if (prevState.size === 0) {
                return prevState;
            }
            return new Map();
        });
        if (shouldSkipLoad) {
            return () => {};
        }
        // loading starts now, indicating that it's in progress
        setIsLoaded(false);
        auth.currentUser
            .getIdToken()
            .then(accessToken => fetch(`${ref.toString()}/.json?shallow=true&auth=${accessToken}`))
            .then(r => {
                if (r.status === 401) {
                    return null;
                }
                return r.json();
            })
            .then(keys => {
                if (!keys) {
                    setIsLoaded(true);
                }
            });

        const unsubAdded = onChildAdded(ref, snap => {
            subjectRef.current.next({ type: "set", snap });
        });
        const unsubChanged = onChildChanged(ref, snap => {
            subjectRef.current.next({ type: "set", snap });
        });
        const unsubRemoved = onChildRemoved(ref, snap => {
            subjectRef.current.next({ type: "delete", snap });
        });

        return () => {
            unsubAdded();
            unsubChanged();
            unsubRemoved();
        };
    }, [initializer, ref, shouldSkipLoad]);

    return useMemo(
        () => ({
            state: collectionMap,
            list: Array.from(collectionMap.values()),
            isLoaded,
        }),
        [collectionMap, isLoaded]
    );
};
