import { Col, Row } from "antd";
import React, { type FC } from "react";

import { SingleFormModule } from "@dms/modules/SingleFormModule/SingleFormModule";

import { DocumentData } from "../DocumentData/DocumentData";
import { PdfSingleDocumentViewer } from "@ui-components/PdfViewer";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { GQL } from "@binale-tech/shared";

interface IProps {
    documentData: GQL.IDocument;
}

export const SingleDocumentLayout: FC<IProps> = React.memo(({ documentData }) => {
    return (
        <Row className={"h-full"}>
            <Col span={16} style={{ padding: "0 15px", display: "flex", justifyContent: "center" }}>
                <PdfSingleDocumentViewer
                    url={documentData?.fileUrl}
                    fileName={DmsUtils.getDownloadName(documentData)}
                />
            </Col>
            <Col span={8} style={{ paddingRight: 5 }}>
                <DocumentData documentData={documentData}>
                    <SingleFormModule documentInitialValue={documentData} />
                </DocumentData>
            </Col>
        </Row>
    );
});
