import React, { ComponentProps, type FC, memo, useEffect, useState } from "react";
import { PdfViewer } from "./Viewer/PdfViewer";
import { getAppCheckToken } from "../../scripts/api/firebase/firebase";
import { Spin } from "antd";

type IProps = Omit<ComponentProps<typeof PdfViewer>, "appCheckToken">;

export const PdfSingleDocumentViewer: FC<IProps> = memo(({ showThumbnails, url, fileName, type }) => {
    const [appCheckToken, setAppCheckToken] = useState<string>();
    const [viewerUrl, setViewerUrl] = useState<string>();

    useEffect(() => {
        if (!url) {
            return;
        }

        getAppCheckToken().then(token => {
            setTimeout(() => {
                setAppCheckToken(token);
                setViewerUrl(url);
            }, 100);
        });
        return () => setViewerUrl(undefined);
    }, [url]);
    const isLoading = url && !viewerUrl;

    return isLoading ? (
        <Spin />
    ) : (
        <PdfViewer
            showThumbnails={showThumbnails}
            url={viewerUrl}
            fileName={fileName}
            type={type}
            appCheckToken={appCheckToken}
        />
    );
});
