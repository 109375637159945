import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import Tag, { TAG_EMPTY } from "scripts/models/Tag";
import { AppRoutes } from "scripts/routing/routeConstants";
import { ExtNumCombobox, ExtNumComboboxProps } from "./ExtNumCombobox";
import { KontoContext } from "scripts/context/accountingData/KontoEntitiesProvider";

export const TagCombobox: React.FC<ExtNumComboboxProps<Tag>> = ({ inputProps, ...props }) => {
    const { tags } = React.useContext(KontoContext);
    const [items, setItems] = React.useState([]);

    React.useEffect(() => {
        setItems(tags.length > 0 ? [TAG_EMPTY, ...tags] : []);
    }, [tags]);

    return (
        <ExtNumCombobox<Tag>
            items={items}
            maxLength={4}
            notFoundContent={
                <span>
                    <p>
                        <FormattedMessage id="app.messages.no_elements_found" />
                    </p>
                    <Link to={AppRoutes.manageAccountTag} target="_blank">
                        <Button type="primary">
                            <FormattedMessage id="app.items.create_tag" />
                        </Button>
                    </Link>
                </span>
            }
            {...props}
        />
    );
};
