import "./FileUploader.css";
import { Button, Progress, Space, Spin } from "antd";
import React, { type FC, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";

import { FilesExisting } from "@dms/modules/DocumentUploader/components/FilesExisting/FilesExisting";
import { UploadFileList } from "./components/IsUploadingFiles/UploadFileList";
import { FilesSameHash } from "@dms/modules/DocumentUploader/components/FilesSameHash/FilesSameHash";
import { FilesInvalid } from "@dms/modules/DocumentUploader/components/FilesInvalid/FilesInvalid";
import { FileUploaderContext, FileUploaderControlContext } from "./context/FileUploaderContext";
import { hashCalculate } from "../../../scripts/infrastructure/helpers/hashCalculate";
import { debounce } from "@dms/scripts/helpers";
import { FileUploaderDropzone } from "@dms/modules/DocumentUploader/components/FileUploaderDropzone/FileUploaderDropzone";
import { TUploadFile } from "@dms/types";
import { CIIDecoderFactory } from "../../../scripts/models/converters/CII/CIIDecoderFactory";
import { GQL } from "@binale-tech/shared";
import { gql, useApolloClient } from "@apollo/client";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { base64StringToFile } from "../../../scripts/converters/converters";

interface IProps {
    onReady: () => void;
    isDropzoneExpanded: boolean;
    onCollapseDropzone: () => void;
}

const invoiceConvertXRechnungXml = gql`
    mutation invoiceConvertXRechnungXml($input: InvoiceConvertXRechnungXmlInput!) {
        invoiceConvertXRechnungXml(input: $input) {
            pdf
        }
    }
`;

export const FileUploader: FC<IProps> = ({ onReady, isDropzoneExpanded, onCollapseDropzone }) => {
    const { companyGQL } = useContext(CompanyContext);
    const client = useApolloClient();
    const [progress, setProgress] = useState<number>(null);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);

    const { queueIsEmpty } = useContext(FileUploaderContext);
    const { handleFilesDrop, setUnsupportedFiles } = useContext(FileUploaderControlContext);

    useEffect(() => {
        const handleResize = debounce(() => setInnerHeight(window.innerHeight), 200);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const height = useMemo((): number | undefined => {
        if (isDropzoneExpanded) {
            return innerHeight * 0.5;
        }
    }, [innerHeight, isDropzoneExpanded]);

    const handleChange = async (files: File[]) => {
        onCollapseDropzone();
        setProgress(1);

        const addedFiles: Pick<TUploadFile, "file" | "hash">[] = [];

        for (const [index, file] of files.entries()) {
            setProgress((index / files.length) * 100);
            if (!["application/pdf", "application/xml", "text/xml"].includes(file.type)) {
                setUnsupportedFiles(prev => [...prev, { file, reason: "Unsupported file type" }]);
                continue;
            }

            if (file.type === "application/pdf") {
                const isValidPDF = await DocumentsApi.checkPDF(file);
                if (!isValidPDF) {
                    setUnsupportedFiles(prev => [...prev, { file, reason: "Invalid pdf" }]);
                    continue;
                }

                const hash = await hashCalculate(file);
                addedFiles.push({ file, hash });
            } else if (["application/xml", "text/xml"].includes(file.type)) {
                const rawXml = new TextDecoder().decode(await file.arrayBuffer());
                const decoder = new CIIDecoderFactory(rawXml, GQL.IProductKey.Er).getDecoder();
                if (!decoder.getVersion().version.startsWith("urn:cen.eu:en16931:2017")) {
                    setUnsupportedFiles(prev => [...prev, { file, reason: "Invalid E-Rechnung" }]);
                    continue;
                }

                const pdfBytes = await client
                    .mutate<
                        Pick<GQL.IMutation, "invoiceConvertXRechnungXml">,
                        GQL.IMutationInvoiceConvertXRechnungXmlArgs
                    >({
                        mutation: invoiceConvertXRechnungXml,
                        variables: {
                            input: {
                                companyId: companyGQL.id,
                                xml: rawXml,
                            },
                        },
                    })
                    .then(res => res.data.invoiceConvertXRechnungXml.pdf)
                    .catch(err => {
                        setUnsupportedFiles(prev => [...prev, { file, reason: err.message }]);
                    });
                if (pdfBytes) {
                    const filePdf = base64StringToFile(
                        pdfBytes,
                        file.name + ".pdf",
                        "application/pdf",
                        decoder.getInvoiceDate()?.date?.getTime()
                    );
                    const hash = await hashCalculate(filePdf);

                    addedFiles.push({ file: filePdf, hash });
                }
            }
        }

        handleFilesDrop(addedFiles);
        setProgress(100);
        await new Promise(r => setTimeout(r, 500)).finally(() => setProgress(null));
    };

    // console.log("FileUploader");

    return (
        <Spin spinning={Number.isFinite(progress)}>
            <Space direction={"vertical"} style={{ width: "100%" }}>
                <div style={{ marginBottom: 20 }}>
                    <FileUploaderDropzone onChange={handleChange} height={height} />
                </div>
                <div style={{ height: 25 }}>
                    {Number.isFinite(progress) && <Progress percent={Number(progress.toFixed(0))} />}
                </div>

                <FilesInvalid />
                <FilesSameHash />
                <FilesExisting />
                <UploadFileList />

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "40px" }}>
                    <Button
                        type={"primary"}
                        disabled={!queueIsEmpty}
                        onClick={onReady}
                        data-testid="dms-file-uploader-submit"
                        aria-disabled={!queueIsEmpty}
                    >
                        <FormattedMessage id="app.button.done" />
                    </Button>
                </div>
            </Space>
        </Spin>
    );
};
