import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { CustomHeaderProps } from "ag-grid-react";
import { transactionFieldsDict } from "@banks/configs/constants/transactionFieldsDict";
import { ConfigProvider, Select } from "antd";
import { checkForExcluded } from "@banks/scripts/helpers/headerKeyTools";
import { OptionsValues } from "@banks/types/enums";
import { FormattedMessage } from "react-intl";

import { green, grey } from "@ant-design/colors";
import {
    PreviewParseDataContext,
    PreviewParseDataControlContext
} from "@banks/modules/ParseTransactionModule/context/PreviewParseDataProvider";

export const CustomTableHeader: FC<CustomHeaderProps> = ({ displayName }) => {
    const [value, setValue] = useState<string>();
    const { fieldMatching, unmappedDataKeys, isResetIbanSelector } = useContext(PreviewParseDataContext);
    const { setFieldMatching, setIsResetIbanSelector } = useContext(PreviewParseDataControlContext);

    useEffect(() => {
        if (fieldMatching[displayName]) {
            return setValue(fieldMatching[displayName]);
        }

        if (checkForExcluded(displayName)) {
            setValue(OptionsValues.UNMAPPED);
        }
    }, [displayName, fieldMatching]);

    useEffect(() => {
        if (!isResetIbanSelector) {
            return;
        }
        setValue(null);
        setIsResetIbanSelector(false);
    }, [isResetIbanSelector]);

    const options = useMemo(() => {
        return Object.keys(transactionFieldsDict).map(el => {
            const key = el as keyof typeof transactionFieldsDict;
            return {
                label: <FormattedMessage id={transactionFieldsDict[key]} />,
                value: el,
                disabled: !unmappedDataKeys.includes(key),
            };
        });
    }, [unmappedDataKeys]);

    const handleSelect = (value: keyof typeof transactionFieldsDict | OptionsValues.UNMAPPED) => {
        setValue(value);
        setFieldMatching(displayName, value);
    };

    const isDisabled = !!checkForExcluded(displayName);
    const isMapped = !isDisabled && value && value !== OptionsValues.UNMAPPED;

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                padding: "15px 0",
            }}
        >
            {displayName !== "No" ? (
                <ConfigProvider
                    theme={{
                        components: {
                            Select: {
                                selectorBg: isMapped ? green[0] : null,
                                activeBorderColor: isMapped ? green[5] : null,
                                activeOutlineColor: isMapped ? green[1] : null,
                                hoverBorderColor: isMapped ? green[4] : null,
                            },
                        },
                    }}
                >
                    <Select
                        value={value}
                        options={[
                            {
                                label: <FormattedMessage id={"app.banks.unmapped"} />,
                                value: OptionsValues.UNMAPPED,
                                disabled: !value,
                            },
                            ...options,
                        ]}
                        onSelect={arg =>
                            handleSelect(arg as unknown as keyof typeof transactionFieldsDict | OptionsValues.UNMAPPED)
                        }
                        labelRender={arg => (
                            <div
                                style={{
                                    color: !isDisabled && value && value !== OptionsValues.UNMAPPED ? green[6] : null,
                                }}
                            >
                                {arg.label}
                            </div>
                        )}
                        optionRender={arg => {
                            let optionDisabled;
                            if (arg.key !== OptionsValues.UNMAPPED) {
                                optionDisabled = !unmappedDataKeys.includes(arg.key as string);
                            } else {
                                optionDisabled = !value;
                            }

                            return (
                                <div
                                    style={{
                                        color: optionDisabled ? grey[1] : "#000",
                                    }}
                                >
                                    {arg.label}
                                </div>
                            );
                        }}
                        placeholder={<FormattedMessage id={"app.banks.selectDataField"} />}
                        disabled={isDisabled}
                    />
                </ConfigProvider>
            ) : null}

            <div style={{ marginTop: 8, padding: "0 3px" }}>{displayName}</div>
        </div>
    );
};
