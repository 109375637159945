import React, { memo, useCallback, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Flex, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, VerticalAlignMiddleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { GridApi, ICellRendererParams, IRowNode } from "ag-grid-community";
import { InvoiceFormBlocksTranslate, InvoicingPaths, TInvoiceTableItem } from "@inv/types";
import { InvoicesApi } from "@inv/scripts/api";

import { InvoiceModalControlContext } from "@inv/context/InvoiceModalProvider";
import { InvoiceViewer } from "@inv/components/InvoiceViewer";

export const InvoicesActionList = memo<ICellRendererParams<TInvoiceTableItem>>(({ api, data, node }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { openModal } = useContext(InvoiceModalControlContext);

    const toggleLineItems = useCallback((api: GridApi, data: TInvoiceTableItem, node: IRowNode) => {
        if (data?.lineItems?.length > 1 && !data.isChildRow) {
            const newRowData = data.lineItems.map((item, idx) => {
                return {
                    ...data,
                    id: `${data.id}-${idx + 1}`,
                    isChildRow: true,
                    lineItems: [item],
                } satisfies TInvoiceTableItem;
            });

            if (data.isExpanded) {
                node.setData({ ...data, isExpanded: false });
                api.applyTransaction({ remove: newRowData });
            } else {
                node.setData({ ...data, isExpanded: true });
                api.applyTransaction({ add: newRowData, addIndex: node.rowIndex + 2 });
            }
        }
    }, []);

    if (node.rowPinned || node.data.isChildRow) {
        return null;
    }

    const editPath = `/${InvoicingPaths.ROOT}/${InvoicingPaths.EDITING}/${node.data.id}`;

    const handleDeleteInvoice = async () => {
        await InvoicesApi.invoiceDelete(node.data.id);
    };

    const handleInvoiceView = () => {
        openModal({
            title: InvoiceFormBlocksTranslate.INVOICE_VIEWER,
            component: <InvoiceViewer invoiceId={node.data.id} />,
        });
    };

    const isBtnEnabled = data?.lineItems?.length > 1 && !data.isChildRow;

    return (
        <Flex
            style={{ height: "100%" }}
            align="center"
            justify="space-between"
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
            <Tooltip title={<FormattedMessage id="app.dms.view" />}>
                <Button icon={<EyeOutlined />} type={"text"} shape={"circle"} onClick={handleInvoiceView} />
            </Tooltip>
            <Button
                disabled={!isBtnEnabled}
                icon={<VerticalAlignMiddleOutlined />}
                type={"text"}
                shape={"circle"}
                onClick={() => toggleLineItems(api, data, node)}
            />
            <Tooltip title={<FormattedMessage id="app.button.edit" />}>
                <Button type="text" icon={<EditOutlined />} shape={"circle"} onClick={() => navigate(editPath)} />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.delete" />}>
                <Popconfirm
                    disabled={data.isConfirmed}
                    title={intl.formatMessage({ id: "app.confirmation.invoice_remove.body" })}
                    onConfirm={handleDeleteInvoice}
                    onCancel={null}
                    okText={intl.formatMessage({ id: "app.global.yes" })}
                    cancelText={intl.formatMessage({ id: "app.global.no" })}
                >
                    <Button icon={<DeleteOutlined />} type="text" shape={"circle"} disabled={data.isConfirmed} />
                </Popconfirm>
            </Tooltip>
        </Flex>
    );
});
