import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Divider, Flex, List, Popover, Switch, Typography } from "antd";

import styles from "./ViewColumnsControl.module.scss";

const { Text } = Typography;

interface SettingsColumnsProps {
    columnsConfig: Record<string, boolean>;
    requiredColumns: string[];
    columnTranslations: Record<string, string>;
    isOpenSettingsCol: boolean;
    setColumnsConfig: (config: Record<string, boolean>) => void;
    setIsOpenSettingsCol: (open: boolean) => void;
}

export const ViewColumnsControl: FC<SettingsColumnsProps> = ({
    columnsConfig,
    requiredColumns,
    columnTranslations,
    setColumnsConfig,
    isOpenSettingsCol,
    setIsOpenSettingsCol,
}) => {
    const columnsList = useMemo(() => {
        if (!columnsConfig) {
            return [];
        }

        const values = Object.keys(columnsConfig).filter(el => !requiredColumns.includes(el));

        return values.map(el => ({
            value: el,
            checked: columnsConfig[el],
            translate: columnTranslations[el],
        }));
    }, [columnsConfig, requiredColumns, columnTranslations]);

    const handleChange = (value: string, checked: boolean) => {
        const updatedConfig = { ...columnsConfig };
        updatedConfig[value] = checked;
        setColumnsConfig(updatedConfig);
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Divider: {
                        marginLG: 8,
                        margin: 8,
                    },
                    Collapse: {
                        headerPadding: 0,
                        padding: 0,
                    },
                },
            }}
        >
            <div>
                <Popover
                    destroyTooltipOnHide={true}
                    open={isOpenSettingsCol}
                    trigger="click"
                    onOpenChange={open => {
                        if (!open) {
                            setIsOpenSettingsCol(false);
                        }
                    }}
                    title={
                        <div>
                            <Flex justify={"space-between"}>
                                <FormattedMessage id={"app.settings.button.columns.title"} />
                                <Button
                                    icon={<CloseOutlined />}
                                    onClick={() => setIsOpenSettingsCol(false)}
                                    size={"small"}
                                    shape={"circle"}
                                />
                            </Flex>
                            <Divider />
                        </div>
                    }
                    content={
                        <Flex vertical={true} gap={10} className={styles.content}>
                            <List
                                itemLayout="horizontal"
                                dataSource={columnsList}
                                grid={{ column: 2 }}
                                renderItem={item => {
                                    if (!item.translate) {
                                        console.warn(
                                            `Translation key "${item.value}" is missing, check translations:`,
                                            columnTranslations
                                        );
                                    }
                                    return (
                                        <List.Item>
                                            <Switch
                                                checked={item.checked}
                                                onChange={checked => handleChange(item.value, checked)}
                                                size={"small"}
                                            />
                                            <Text style={{ marginLeft: "15px" }}>
                                                <FormattedMessage id={item.translate} />
                                            </Text>
                                        </List.Item>
                                    );
                                }}
                            />
                        </Flex>
                    }
                >
                    <Button
                        type="text"
                        shape={"circle"}
                        icon={<SettingOutlined />}
                        onClick={() => setIsOpenSettingsCol(true)}
                    />
                </Popover>
            </div>
        </ConfigProvider>
    );
};
