import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Flex, Modal, Space } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { DocumentTableModule } from "@dms/modules/DocumentTableModule/DocumentModalTableModule";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { DmsModalPdfViewer } from "@dms/components/ModalPdfViewer/DmsModalPdfViewer";
import { Toolbar } from "@dms/modules/DmsModalModule/components/Toolbar/Toolbar";
import { DmsType, ITableDocument } from "@dms/types";
import { ALL_DOCUMENTS, DmsTaxesOptions, DmsTypeOptions } from "@dms/configs/constants";
import { documentSearchPredicate, getSubTypeOptions } from "@dms/scripts/helpers";
import { RecordDataPopover } from "@dms/components/RecordDataTable/RecordDataPopover";
import { GQL } from "@binale-tech/shared";

type TProps = {
    visible: boolean;
    onCancel: () => void;
    selectedFiles: string[];
    onSave: (arg: string[]) => void;
    productKey: GQL.IProductKey;
};

export const DmsModal = memo<TProps>(function DmsModal({ visible, onCancel, onSave, selectedFiles, productKey }) {
    const intl = useIntl();
    const [viewerDocument, setViewerDocument] = useState<GQL.IDocument>();
    const { documentTypes, documentsKV, dmsRecordsData } = useContext(DmsDataContext);
    const { searchValue, selectedRowKeys } = useContext(DmsAppContext);
    const { setSearchValue, resetSelectedRows, selectRows } = useContext(DmsAppControlContext);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [selectedType, setSelectedType] = useState<string>(productKey);
    const [selectedSubType, setSelectedSubType] = useState<string>();

    const documentsData: ITableDocument[] = useMemo(() => {
        if (!documentsKV || !documentTypes || !dmsRecordsData) {
            return null;
        }

        const documentData = Object.values(documentsKV);
        const filteredTypeDocument =
            selectedType === ALL_DOCUMENTS
                ? documentData
                : documentData.filter(el => {
                      if (!selectedType || selectedSubType === "all_subTypes") {
                          return el.type === selectedType;
                      }
                      if (selectedSubType === "no_subTypes") {
                          return el.type === selectedType && !el.subType;
                      }
                      return el.type === selectedType && el.subType === selectedSubType;
                  });

        return filteredTypeDocument.map(document => {
            let newType = document.type;
            if (document.subType) {
                let keyType = "";
                DmsTypeOptions.forEach(option => {
                    if (option.value === document.type) {
                        keyType = option.translationKey ?? "";
                    }
                });
                if (document.type === DmsType.taxes) {
                    DmsTaxesOptions.forEach(el => {
                        if (el.value === document.subType) {
                            newType = `${intl.formatMessage({ id: keyType })} / ${intl.formatMessage({
                                id: el.translationKey,
                            })}`;
                        }
                    });
                } else {
                    const subTypeOptions = getSubTypeOptions(documentTypes, document.type as DmsType);
                    subTypeOptions.forEach(el => {
                        if (el.value === document.subType) {
                            newType = `${intl.formatMessage({ id: keyType })} / ${el.label}`;
                        }
                    });
                }
            } else {
                DmsTypeOptions.forEach(option => {
                    if (option.value === document.type) {
                        newType = intl.formatMessage({ id: option.translationKey });
                    }
                });
            }
            return {
                ...document,
                isAttached: <RecordDataPopover document={document} position={"table"} />,
                partnerName: document.partner?.name,
                type: newType,
            };
        });
    }, [documentsKV, documentTypes, dmsRecordsData, selectedType, selectedSubType, intl]);

    const searchedDocuments = useMemo(() => {
        if (!searchValue) {
            return documentsData;
        }

        return documentsData.filter(el => documentSearchPredicate(el, searchValue));
    }, [searchValue, documentsData]);

    const handleCancel = () => {
        onCancel();
        resetSelectedRows();
    };

    const handleSave = useCallback(() => {
        if (!selectedRowKeys.length) {
            return onSave([]);
        }

        onSave(selectedRowKeys as string[]);
        resetSelectedRows();
    }, [selectedRowKeys]);

    const handleTypeSelect = (value: string) => {
        setSelectedType(value);
        setSearchValue(undefined);
    };

    useEffect(() => {
        if (!selectedFiles.length || !visible) {
            return;
        }

        selectRows(selectedFiles);
    }, [visible]);

    useEffect(() => {
        if (selectedRowKeys.length === selectedFiles.length) {
            const set = new Set(selectedFiles);
            selectedRowKeys.forEach(el => set.add(el as string));
            if (set.size === selectedFiles.length) {
                return setIsBtnDisabled(true);
            }
        }
        setIsBtnDisabled(false);
    }, [selectedRowKeys, selectedFiles]);

    useEffect(() => {
        setSelectedType(productKey);
    }, [productKey]);

    return (
        <Modal
            open={visible}
            confirmLoading={false}
            onCancel={handleCancel}
            footer={
                <Button style={{ marginLeft: 10 }} type={"primary"} onClick={handleSave} disabled={isBtnDisabled}>
                    <FormattedMessage id="app.button.save" />
                </Button>
            }
            width={1500}
            style={{ top: 10 }}
            styles={{
                body: { height: `calc(100vh - 220px)`, overflow: "hidden" },
            }}
            destroyOnClose
        >
            <div style={{ position: "relative" }}>
                <Space direction="vertical" style={{ width: "100%", height: "100%", minHeight: 200 }}>
                    <DmsModalPdfViewer document={viewerDocument} onClose={() => setViewerDocument(undefined)} />
                    <Flex
                        vertical={true}
                        gap={12}
                        className="ag-theme-quartz custom-scrollbar"
                        style={{ height: "calc(100vh - 220px)", paddingTop: 20 }}
                    >
                        <Toolbar
                            handleTypeSelect={handleTypeSelect}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            selectedSubType={selectedSubType}
                            setSelectedSubType={setSelectedSubType}
                        />
                        <DocumentTableModule
                            dataSource={searchedDocuments}
                            activeType={[selectedSubType ?? "", selectedType]}
                        />
                    </Flex>
                </Space>
            </div>
        </Modal>
    );
});
