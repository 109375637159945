import GenericTableViewCtxWrapper from "../productSharedComponents/GenericTableViewCtxWrapper";
import React from "react";
import { RechnungenViewClass } from "../productER/erfassung/ERView";
import { DebProvider } from "./DebProvider";

class DebitorenViewClass extends RechnungenViewClass {
    protected getHeader(): React.ReactNode {
        return null;
    }

    render() {
        return this.getTablePage({ useInlineForm: true, useLeftTable: true });
    }
}

export const DebView: React.FC<React.PropsWithChildren> = () => {
    return (
        <DebProvider>
            <GenericTableViewCtxWrapper Component={DebitorenViewClass} />
        </DebProvider>
    );
};
