import React, { FC, memo, useContext } from "react";
import { Empty, Spin } from "antd";

import styles from "./RecordFormPreviewPdf.module.scss";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { PdfSingleDocumentViewer } from "@ui-components/PdfViewer";

type TProps = {
    fileId?: string;
};

const RecordFormPreviewPdf: FC<TProps> = ({ fileId }) => {
    const { documentsKV } = useContext(DmsDataContext);
    const doc = documentsKV[fileId];

    if (!fileId) {
        return (
            <div className={styles.previewEmpty}>
                <Empty />
            </div>
        );
    }

    if (!doc) {
        return (
            <div className={styles.previewEmpty}>
                <Spin />
            </div>
        );
    }

    return (
        <div className={styles.previewWrapper}>
            <PdfSingleDocumentViewer url={doc?.fileUrl} fileName={DmsUtils.getDownloadName(doc)} />
        </div>
    );
};

export default memo(RecordFormPreviewPdf);
