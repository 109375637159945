import { orange } from "@ant-design/colors";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { GQL } from "@binale-tech/shared";
import { DmsType, TDocumentInputs } from "@dms/types";
import { Button, Divider, Flex, Form, Space, Spin } from "antd";
import React, { type FC, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";
import { compareInputValues } from "@dms/scripts/helpers";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { DocumentInputs } from "./DocumentInputSets/DocumentInputs";
import { handleKeyDownCancel, handleKeyDownSubmit, handlePress } from "./utils";
import { useNavigate } from "react-router-dom";
import { ContactsContext } from "../../../scripts/context/ContactsContext";
import { DmsDataContext } from "@dms/types/ContextTypes";

import styles from "./DocumentForm.module.scss";

export const SingleDocumentForm: FC = React.memo(() => {
    const [submittable, setSubmittable] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState(false);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<string>();
    const contactsData = useContext(ContactsContext);
    const { isReadOnly } = useContext(SingleFormContext);

    const { singleFormInitialValue, actionConfirm, handleSetNecessaryFields, setChangedDocumentType } =
        useContext(SingleFormContext);

    const { documentTypes } = useContext(DmsDataContext);

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const values: TDocumentInputs = Form.useWatch([], form);

    useEffect(() => {
        setDocumentType(prev => {
            if (!prev) {
                return singleFormInitialValue?.type;
            }
            return prev;
        });
    }, [singleFormInitialValue?.type]);

    useEffect(() => {
        setDocumentType(values?.type);
    }, [values?.type]);

    useEffect(() => {
        setChangedDocumentType(documentType as DmsType);
    }, [documentType, setChangedDocumentType]);

    useEffect(() => {
        if (!values || !singleFormInitialValue) {
            setSubmittable(false);
            return;
        }

        const isEqual = compareInputValues(values, singleFormInitialValue);
        const errors = form.getFieldsError();

        const hasErrors = errors.some(field => field.errors.length > 0);

        setSubmittable(!isEqual && !hasErrors);
    }, [form, singleFormInitialValue, values]);

    useEffect(() => {
        if (!documentType) {
            return;
        }
        const { type } = DmsUtils.getTypeAndSubType(documentType, documentTypes);
        if (!type) {
            return;
        }
        handleSetNecessaryFields(type);
    }, [documentType, documentTypes, handleSetNecessaryFields]);

    useEffect(() => {
        const contactId = values?.partner?.id;
        if (contactId && !values?.currencyData?.currency) {
            const contact = contactsData.contacts.find(el => el.uuid === contactId);

            if (contact && Boolean(contact.defaultCurrencyCode)) {
                form.setFieldValue("currencyData", {
                    ...values.currencyData,
                    currency: {
                        code: contact.defaultCurrencyCode,
                        rate: 1,
                    },
                });
            }
        }
    }, [contactsData?.contacts, form, values?.currencyData, values?.partner?.id]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSuccess(false);
            setIsClose(false);
        }, 1000);

        return () => clearTimeout(timerId);
    }, [success]);

    const handleCancel = (): void => {
        setSubmittable(false);
        form.resetFields();
    };

    const handleFinish = async (inputValues: TDocumentInputs) => {
        setIsLoading(true);

        if (inputValues.type) {
            const { type, subType } = DmsUtils.getTypeAndSubType(inputValues.type, documentTypes);

            const { currencyData, discountPercent, ...rest } = inputValues;

            inputValues = {
                ...rest,
                originalAmount: currencyData?.originalAmount,
                documentAmount: currencyData?.amount ?? undefined,
                currency: currencyData?.currency?.code,
                currencyRate: currencyData?.currency?.rate,
                type,
                subType,
                discountPercent: Number(discountPercent ?? 0),
            };
        }

        delete singleFormInitialValue?.currencyData;

        const data = {
            ...singleFormInitialValue,
            ...inputValues,
        };

        await actionConfirm(data as GQL.IDocument);

        setIsLoading(false);
        setSuccess(true);
        setSubmittable(false);

        if (isClose) {
            navigate("/documents");
        }
    };

    // const handleAutofill = async () => {
    //     setAiBtnIsLoading(true);
    //     const { host, headers } = await getServerlessApi();
    //     await fetch(`${host}/invoiceOcr`, {
    //         method: "POST",
    //         body: JSON.stringify({
    //             companyId: companyGQL.id,
    //             documentId: singleFormInitialValue?.key,
    //         }),
    //         headers,
    //     })
    //         .then(res => {
    //             if (res.status >= 400) {
    //                 throw new Error(res.statusText);
    //             }
    //             return res.json();
    //         })
    //         .then((response: DocumentAi.DocumentAiResponse) => {
    //             const { documentNumber, documentDate, partner } = values;
    //             const currentValues = {
    //                 documentNumber,
    //                 documentDate,
    //                 partner,
    //                 documentAmount: values?.currencyData?.amount,
    //             };
    //             const fieldUpdates = DocumentAi.mapDocumentAiFields(response, currentValues);
    //             if (!fieldUpdates) {
    //                 return;
    //             }
    //             if (!values?.documentNumber && fieldUpdates.documentNumber) {
    //                 form.setFieldsValue({ documentNumber: fieldUpdates.documentNumber });
    //             }
    //             if (!values?.documentDate && fieldUpdates.documentDate) {
    //                 form.setFieldsValue({
    //                     documentDate: fieldUpdates.documentDate,
    //                 });
    //             }
    //             if (!values?.partner?.id && fieldUpdates.partner) {
    //                 form.setFieldsValue({
    //                     partner: fieldUpdates.partner,
    //                 });
    //             }
    //
    //             if (!values?.currencyData?.amount && fieldUpdates.documentAmount) {
    //                 form.setFieldValue("currencyData", {
    //                     amount: fieldUpdates.documentAmount,
    //                     currency: {
    //                         code: fieldUpdates.currency ?? GQL.ICurrencyCode.Eur,
    //                         rate: fieldUpdates.currencyRate ?? 1,
    //                     },
    //                     originalAmount: fieldUpdates.originalAmount,
    //                 });
    //             }
    //         })
    //         .catch(error => {
    //             message.warning("recognition error" + error);
    //         })
    //         .finally(() => {
    //             setAiBtnIsLoading(false);
    //         });
    // };

    useEffect(() => {
        form.resetFields();
    }, [form, singleFormInitialValue]);

    const keyboardKeys = useMemo(() => new Set<string>(), []);

    const isSubmitDisabled = () => {
        return !submittable;
    };

    return (
        <>
            {singleFormInitialValue ? (
                <Form
                    key={singleFormInitialValue.key}
                    form={form}
                    style={{ minHeight: "250px", position: "relative" }}
                    name={"document-form"}
                    layout="vertical"
                    onFinish={handleFinish}
                    initialValues={singleFormInitialValue}
                    autoComplete={"off"}
                    onKeyDown={e => handlePress(e, "down", keyboardKeys, "document-form")}
                    onKeyUp={e => handlePress(e, "up", keyboardKeys, "document-form")}
                >
                    <Flex
                        style={{ color: orange.primary, marginBottom: "10px", minHeight: 25 }}
                        align={"center"}
                        justify={"flex-end"}
                    >
                        {<span></span>}
                    </Flex>
                    {documentType ? (
                        <DocumentInputs type={documentType} />
                    ) : (
                        <div className={styles.inputsSpinWrapper}>
                            <Spin indicator={<LoadingOutlined spin />} size="large" />
                        </div>
                    )}
                    {!isReadOnly && (
                        <>
                            <Divider />
                            <Space style={{ justifyContent: "flex-end", width: "100%" }}>
                                {success && !isLoading && (
                                    <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />
                                )}
                                {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                                <Button
                                    id="formSubmit"
                                    type={"primary"}
                                    onKeyDown={e => handleKeyDownSubmit(e, form)}
                                    disabled={isSubmitDisabled()}
                                    onClick={() => {
                                        setIsClose(false);
                                        form.submit();
                                    }}
                                >
                                    <FormattedMessage id="app.button.confirm" />
                                </Button>
                                <Button
                                    id="formConfAndClose"
                                    onKeyDown={e => handleKeyDownSubmit(e, form)}
                                    onClick={() => {
                                        setIsClose(true);
                                        form.submit();
                                    }}
                                    disabled={isSubmitDisabled()}
                                >
                                    <FormattedMessage id="app.button.confAndClose" />
                                </Button>
                                <Button
                                    id="formCancel"
                                    onKeyDown={e => handleKeyDownCancel(e, handleCancel)}
                                    onClick={handleCancel}
                                >
                                    <FormattedMessage id="app.button.reset" />
                                </Button>
                            </Space>
                        </>
                    )}
                </Form>
            ) : null}
        </>
    );
});
