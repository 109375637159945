import { DmsAccountingConverter } from "./DmsAccountingConverter";
import { useContext, useMemo } from "react";
import { BuContext } from "../../context/BuContext";
import { CompanyContext } from "../../context/CompanyContext";
import { ContactsContext } from "../../context/ContactsContext";
import { RecordsControlContext } from "../../context/accountingData/RecordsControlCtx";
import { ProductFactory } from "../../core/ProductFactory";
import { GQL } from "@binale-tech/shared";
import { get } from "firebase/database";
import { useTemplatesFBRef } from "../../context/accountingData/hooks/useTemplateData";

export const useDmsAccountingConverter = (pk: GQL.IProductKey, year: number, groupId?: string) => {
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const { contacts } = useContext(ContactsContext);
    const { companyBuTimeframes } = useContext(BuContext);
    const recordActions = useContext(RecordsControlContext);
    const { ref: templatesRef, initializer } = useTemplatesFBRef(pk, yearConfig?.year, groupId);

    return useMemo(() => {
        const config = ProductFactory.getAccountingProductConfig(pk, yearConfig, companyGQL, recordActions, groupId);
        if (!config) {
            return { converter: undefined, actions: undefined };
        }
        const { product, actions } = config;
        const converter = new DmsAccountingConverter(
            companyGQL,
            product,
            contacts,
            async () => {
                return get(templatesRef)
                    .then(s => Object.values(s.val() ?? {}))
                    .then((list: Record<string, any>[]) => {
                        return list.map(initializer);
                    });
            },
            yearConfig.skr,
            companyBuTimeframes,
            groupId
        );
        return { converter, actions };
    }, [companyBuTimeframes, companyGQL, contacts, groupId, initializer, pk, recordActions, templatesRef, yearConfig]);
};
