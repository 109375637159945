import { FileAddOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import cn from "classnames";
import React, { type FC, useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { SidebarMenu } from "@dms/components/SidebarMenu/SidebarMenu";
import { DmsPaths } from "@dms/configs/constants";
import { DmsAppContext, DmsAppControlContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";

import { DocumentUploader } from "../DocumentUploader/DocumentUploader";
import styles from "./documentsMenuModule.module.scss";
import { BindModal } from "@dms/components/BindingModal";
import { EditTypeModal } from "@dms/components/EditTypeModal";
import { DmsType } from "@dms/types";

const { Sider } = Layout;

export const DocumentsMenuModule: FC = () => {
    const { isTableSize } = useContext(DmsUserSettingsContext);
    const {
        activeType,
        isTypeChangeMode,
        downloadDocumentsModalOpen,
        openedMenuItemKeys,
        bindModalData,
        editTypeModalData,
    } = useContext(DmsAppContext);

    const { toggleDownloadDocumentsModal, setOpenedMenuItemKeys } = useContext(DmsAppControlContext);
    const [collapsed, setCollapsed] = useState(false);
    const [openKeysForCollapsed, setOpenKeysForCollapsed] = useState<string[]>([]);
    const [documentTypeToDownload, setDocumentTypeToDownload] = useState<string[]>(activeType);

    const location = useLocation();

    useEffect(() => {
        setDocumentTypeToDownload(activeType);
    }, [activeType]);

    useEffect(() => {
        if (location.pathname.includes(DmsPaths.EDITING) || collapsed) {
            onCollapsed();
        }
    }, [location.pathname]);

    useEffect(() => {
        setCollapsed(isTableSize);
    }, [isTableSize]);

    const handleModalOpen = (): void => {
        toggleDownloadDocumentsModal(true);
        setDocumentTypeToDownload([DmsType.new_documents]);
    };

    const handleCancel = useCallback(() => {
        toggleDownloadDocumentsModal(false);

        if (activeType && activeType.length) {
            return setDocumentTypeToDownload(activeType);
        }

        setDocumentTypeToDownload([DmsType.new_documents]);
    }, [activeType, toggleDownloadDocumentsModal]);

    const onCollapsed = () => {
        setCollapsed(prev => {
            requestAnimationFrame(() => {
                if (prev) {
                    setOpenedMenuItemKeys(openKeysForCollapsed);
                } else {
                    setOpenKeysForCollapsed(openedMenuItemKeys);
                    setOpenedMenuItemKeys([]);
                }
            });
            return !prev;
        });
    };

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={300}
            className={cn({
                [styles.sider]: true,
                [styles.siderBackground]: true,
                [styles.isTableSize]: isTableSize,
            })}
        >
            <div className={styles.siderContainer}>
                <Button
                    data-testid={"dms-menu-btn-upload"}
                    onClick={() => {
                        handleModalOpen();
                    }}
                    type="primary"
                    size={"middle"}
                    className={cn({
                        [styles.btn]: true,
                        [styles.btn__collapsed]: collapsed,
                    })}
                    disabled={isTypeChangeMode}
                >
                    <div className={styles.btn__content}>
                        <FileAddOutlined className={styles.btnIcon} />
                        <span
                            className={cn({
                                [styles.flyOutWrapper]: true,
                                [styles.flyOut]: collapsed,
                            })}
                        >
                            <FormattedMessage id="app.dms.add_documents" />
                        </span>
                    </div>
                </Button>
                <div className={styles.collapsedBtn}>
                    <Button
                        shape="circle"
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={onCollapsed}
                        style={{
                            border: "1px solid lightGray",
                        }}
                    />
                </div>
            </div>
            <div className={styles.menuContent}>
                <SidebarMenu />
            </div>

            {downloadDocumentsModalOpen && (
                <DocumentUploader
                    isOpen={downloadDocumentsModalOpen}
                    onCancel={handleCancel}
                    documentType={documentTypeToDownload}
                />
            )}

            {bindModalData.isOpen && <BindModal />}
            {editTypeModalData.isOpen && <EditTypeModal />}
        </Sider>
    );
};
