import Category from "../models/Category";
import Payment, { PaymentRecordRelations, PaymentType } from "../models/Payment";
import { deepEqual } from "fast-equals";
import { CategoryUtils } from "../models/utils/CategoryUtils";
import { GQL } from "@binale-tech/shared";
import { GenericRecord } from "../models/GenericRecord";
import { PaymentUtils } from "../models/utils/PaymentUtils";
import { Product } from "./Product";
import { ProductKey, ProductKeys } from "../models/Product";
import { RecordsCtxData } from "../context/accountingData/RecordsCtx";
import { logger } from "../infrastructure/logger";

export type ExecutionType = "create" | "delete" | "update";

export interface ExecutionItem {
    type: ExecutionType;
    affectsConfirmed: boolean;
    affectsJournaled: boolean;
}

interface PaymentAction {
    payment: Payment;
    executions: ExecutionItem[];
}

const flatten = <T>(list: T[][]): T[] =>
    list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b as unknown as T[][]) : b), []);

export class PaymentExecutionPlan {
    constructor(
        protected readonly _creates: PaymentAction[],
        protected readonly _updates: PaymentAction[],
        protected readonly _deletes: PaymentAction[]
    ) {}

    get creates() {
        return this._creates;
    }

    get updates() {
        return this._updates;
    }

    get deletes() {
        return this._deletes;
    }

    getNonDraftDeletions(): ExecutionItem[] {
        return [
            ...flatten(this.deletes.map(v => v.executions)).filter(v => v && v.affectsConfirmed),
            ...flatten(this.updates.map(v => v.executions)).filter(v => v && v.type === "delete" && v.affectsConfirmed),
        ];
    }

    getNonDraftUpdates(): ExecutionItem[] {
        return flatten(this.updates.map(v => v.executions)).filter(v => v && v.affectsConfirmed);
    }

    getJournaledDeletions(): ExecutionItem[] {
        return [
            ...flatten(this.deletes.map(v => v.executions)).filter(v => v && v.affectsJournaled),
            ...flatten(this.updates.map(v => v.executions)).filter(v => v && v.type === "delete" && v.affectsJournaled),
        ];
    }

    getJournaledUpdates(): ExecutionItem[] {
        return flatten(this.updates.map(v => v.executions)).filter(v => v && v.affectsJournaled);
    }
}

export class PaymentExecutionPlanCreator {
    protected paymentSourceRecordProductKey: ProductKey;

    constructor(
        protected readonly product: Product,
        protected readonly companyGQL: GQL.ICompany,
        protected readonly defaultCategories: Map<number, Category>,
        protected readonly aggregatedRecordsData: RecordsCtxData
    ) {}

    createExecutionPlan(r: GenericRecord, currentPayments: Payment[], newPayments: Payment[]) {
        this.paymentSourceRecordProductKey = r.getProductKey() || this.product.productKey();
        const { creates, updates, deletions } = this.recognizePayments(r, currentPayments, newPayments);
        return new PaymentExecutionPlan(
            this.getPaymentCreates(creates, r),
            this.getPaymentUpdates(updates, r),
            this.getPaymentDeletes(deletions)
        );
    }

    protected recognizePayments(r: GenericRecord, currentPayments: Payment[], newPayments: Payment[]) {
        const creates: Payment[] = [];
        const updates: { old: Payment; cur: Payment }[] = [];
        const deletions: Payment[] = [];

        logger.log({ currentPayments, newPayments });
        newPayments.forEach(payment => {
            if (!payment.key) {
                payment.sourceRecordKey = r.key;
                creates.push(payment);
            } else {
                const existingPayment = currentPayments.find(v => v.key === payment.key);
                if (existingPayment) {
                    if (!deepEqual(payment, existingPayment)) {
                        const hasTypeChanged = PaymentUtils.hasPaymentTypeChanged(payment.type, existingPayment.type);
                        if (hasTypeChanged) {
                            const newPayment = Payment.unserialize(JSON.parse(JSON.stringify(payment)));
                            payment.sourceRecordKey = r.key;
                            newPayment.key = undefined;
                            deletions.push(existingPayment);
                            creates.push(newPayment);
                        } else {
                            updates.push({ cur: payment, old: existingPayment });
                        }
                    }
                } else {
                    logger.logToSentry("payment intend to update but not found in the list of current payments, ", {
                        payment,
                        currentPayments,
                    });
                }
            }
        });
        const newPaymentsKeys = new Set(newPayments.map(v => v.key));
        currentPayments.forEach(payment => {
            if (!newPaymentsKeys.has(payment.key)) {
                deletions.push(payment);
            }
        });
        logger.log({ creates, updates, deletions });
        return { creates, updates, deletions };
    }

    private getPaymentCreates(payments: Payment[], record: GenericRecord): PaymentAction[] {
        const list: PaymentAction[] = [];
        payments.forEach(payment => {
            const executions = [];
            if (this.isBankPayment(payment)) {
                executions.push(this.createBankRecordExecutionItem(payment, record));
            } else if (this.isKassePayment(payment)) {
                executions.push(this.createKasseRecordExecutionItem(payment, record));
            }
            // Anzahlung => FE
            // in this case there will be 3 records in total: ER_A source, KB/Bank representation, FE balancing
            if (this.isPaymentFromAnzahlungen()) {
                executions.push(this.createFERecordBalancingAZExecutionItem(payment, record));
            }
            // ER Verrechnung => FE
            // in this case there will be 2 records in total: ER source, FE representation
            if (this.isPaymentTypeVerrechnung(payment)) {
                executions.push(this.createFERecordRepresentationVRExecutionItem(payment, record));
            }

            list.push({
                payment,
                executions,
            });
        });
        return list;
    }

    private getPaymentDeletes(payments: Payment[]): PaymentAction[] {
        const list: PaymentAction[] = [];
        payments.forEach(payment => {
            let executions: ExecutionItem[] = [];
            if (this.isBankPayment(payment)) {
                executions = executions.concat(this.deleteBankRecordExecutionItem(payment));
            } else if (this.isKassePayment(payment)) {
                executions = executions.concat(this.deleteKasseRecordExecutionItem(payment));
            }
            // Anzahlung => FE
            if (this.isPaymentFromAnzahlungen()) {
                executions = executions.concat(this.deleteFERecordExecutionItem(payment, "autoFEBalancingAZRecordKey"));
            }
            // ER Verrechnung => FE
            if (this.isPaymentTypeVerrechnung(payment)) {
                executions = executions.concat(this.deleteFERecordExecutionItem(payment, "representationRecordKey"));
            }
            // console.debug("getPaymentDeletes", executions, JSON.stringify(executions));

            list.push({
                payment,
                executions,
            });
        });
        return list;
    }

    private getPaymentUpdates(payments: { old: Payment; cur: Payment }[], r: GenericRecord): PaymentAction[] {
        const list: PaymentAction[] = [];
        type FnType = (p: Payment, r?: GenericRecord) => ExecutionItem;
        payments.forEach(payment => {
            const nullFn: FnType = () => null;
            let createFn: FnType = nullFn;
            let deleteFn: FnType = nullFn;
            if (this.isBankPayment(payment.cur)) {
                createFn = (p, rec) => this.createBankRecordExecutionItem(p, rec);
            } else if (this.isKassePayment(payment.cur)) {
                createFn = (p, rec) => this.createKasseRecordExecutionItem(p, rec);
            }
            if (this.isBankPayment(payment.old)) {
                deleteFn = p => this.deleteBankRecordExecutionItem(p);
            } else if (this.isKassePayment(payment.old)) {
                deleteFn = p => this.deleteKasseRecordExecutionItem(p);
            }
            const update = this.updateExecutionItem(payment, r, createFn, deleteFn);
            let executions: ExecutionItem[] = [
                update.executionCreate,
                update.executionDelete,
                update.executionUpdate,
            ].filter(Boolean);

            // console.log("update", {update, executions});
            // Anzahlung => FE
            if (this.isPaymentFromAnzahlungen()) {
                const updateItem = this.updateExecutionItem(
                    payment,
                    r,
                    (p, rec) => this.createFERecordBalancingAZExecutionItem(p, rec),
                    p => this.deleteFERecordExecutionItem(p, "autoFEBalancingAZRecordKey")
                );
                executions = executions.concat(
                    updateItem.executionCreate,
                    updateItem.executionDelete,
                    updateItem.executionUpdate
                );
            }
            // ER Verrechnung => FE
            if (this.isPaymentTypeVerrechnung(payment.old)) {
                const updateItem = this.updateExecutionItem(
                    payment,
                    r,
                    (p, rec) => this.createFERecordRepresentationVRExecutionItem(p, rec),
                    p => this.deleteFERecordExecutionItem(p, "representationRecordKey")
                );
                executions = executions.concat(
                    updateItem.executionCreate,
                    updateItem.executionDelete,
                    updateItem.executionUpdate
                );
            }
            if (this.isPaymentTypeFE(payment.old)) {
                const updateItem = this.updateExecutionItem(
                    payment,
                    r,
                    (p, rec) => null, // todo create regular FE (when enabled any category in the Payment window)
                    p => this.deleteFERecordExecutionItem(p, "representationRecordKey")
                );
                executions = executions.concat(
                    // updateItem.executionCreate,
                    updateItem.executionDelete
                    // updateItem.executionUpdate
                );
            }
            ///
            list.push({
                payment: payment.cur,
                executions,
            });
        });
        return list;
    }

    private updateExecutionItem(
        payment: { old: Payment; cur: Payment },
        r: GenericRecord,
        create: (p: Payment, r: GenericRecord) => ExecutionItem,
        del: (p: Payment) => ExecutionItem
    ) {
        let executionCreate: ExecutionItem;
        let executionUpdate: ExecutionItem;
        let executionDelete: ExecutionItem;
        const deleteExecutionItem = del(payment.old);
        const createExecutionItem = create(payment.cur, r);
        // console.log({deleteExecutionItem, createExecutionItem}, deleteExecutionItem.record.key);
        if (deleteExecutionItem && createExecutionItem && !deleteExecutionItem.affectsJournaled) {
            executionUpdate = deleteExecutionItem;
            executionUpdate.type = "update";
            executionCreate = executionDelete = null;
        } else {
            executionCreate = createExecutionItem;
            executionDelete = deleteExecutionItem;
            // if (executionDelete) {
            //     payment.cur.parentKeys = payment.cur.parentKeys.filter(v => v !== executionDelete.record.key);
            // }
        }
        return { executionUpdate, executionCreate, executionDelete };
    }

    private isPaymentFromAnzahlungen() {
        return this.paymentSourceRecordProductKey === ProductKeys.ER_A;
    }

    private isPaymentTypeVerrechnung(payment: Payment) {
        return payment.type.value === PaymentType.VERRECHNUNG.value;
    }

    private isPaymentTypeFE(payment: Payment) {
        return payment.type.value === PaymentType.FE.value;
    }

    private isKassePayment(p: Payment) {
        return p.type.value === PaymentType.OptionKasse && Boolean(p.type.konto);
    }

    private isBankPayment(p: Payment) {
        return p.type.value === PaymentType.OptionBank && Boolean(p.type.konto);
    }

    private createBankRecordExecutionItem(p: Payment, paymentSourceRecord: GenericRecord): ExecutionItem {
        const item = this.createRepresentationRecordExecutionItem(
            p,
            this.aggregatedRecordsData.recordsBank,
            paymentSourceRecord,
            this.findBank(p),
            ProductKeys.Bank
        );
        return item;
    }

    private createKasseRecordExecutionItem(p: Payment, paymentSourceRecord: GenericRecord): ExecutionItem {
        const item = this.createRepresentationRecordExecutionItem(
            p,
            this.aggregatedRecordsData.recordsKB,
            paymentSourceRecord,
            this.findKb(p),
            ProductKeys.KB
        );
        return item;
    }

    /**
     * Returns an execution item for the record in Bank/KB
     *
     * @param p Payment
     * @param recordsMap
     * @param paymentSourceRecord GenericRecord
     * @param kbBank Group Key: Bank or KB id
     * @param paymentRepresentationRecordProductKey ProductKey
     */
    private createRepresentationRecordExecutionItem(
        p: Payment,
        recordsMap: RecordsCtxData["recordsBank"] | RecordsCtxData["recordsKB"],
        paymentSourceRecord: GenericRecord,
        kbBank: GQL.ICompanyBank | GQL.ICompanyKasse,
        paymentRepresentationRecordProductKey: ProductKey
    ): ExecutionItem {
        return {
            type: "create",
            affectsJournaled: false,
            affectsConfirmed: false,
        };
    }

    /**
     * AZ means Anzahlung
     */
    private createFERecordBalancingAZExecutionItem(p: Payment, paymentSourceRecord: GenericRecord): ExecutionItem {
        return {
            type: "create",
            // product: ProductKeys.FE,
            affectsJournaled: false,
            affectsConfirmed: false,
        };
    }

    /**
     * VR means Verrechnung payment type
     */
    private createFERecordRepresentationVRExecutionItem(p: Payment, r: GenericRecord): ExecutionItem {
        return {
            type: "create",
            // product: ProductKeys.FE,
            affectsJournaled: false,
            affectsConfirmed: false,
        };
    }

    /**
     * Returns an execution item
     * @param p Payment
     * @param recordsMap
     * @param kbBank Group Key: Bank/KB id
     * @param pr Product key
     */
    private deleteRepresentationRecordExecutionItem(
        p: Payment,
        recordsMap: RecordsCtxData["recordsBank"] | RecordsCtxData["recordsKB"],
        kbBank: GQL.ICompanyBank | GQL.ICompanyKasse,
        pr: ProductKey
    ): ExecutionItem {
        console.log(kbBank, recordsMap.groups);
        const record = recordsMap.groups.get(kbBank.id)?.map?.get(p.representationRecordKey);
        // console.log(recordsMap.get(d.uuid).map(r => r.key), p.parentKeys);
        if (!record) {
            logger.debug("data is inconsistent: record for selected payment does not exists", p, JSON.stringify(p));
            throw new Error("data is inconsistent: record for selected payment does not exists");
        }
        logger.log(`Deleting ${kbBank.constructor.name} record`, [record], JSON.stringify(record));
        return {
            type: "delete",
            // product: pr,
            affectsJournaled: record.journaled,
            affectsConfirmed: !record.draft && !record.journaled,
        };
    }

    private findBank(p: Payment) {
        return this.companyGQL.bankList.find(
            b => CategoryUtils.areEqual(String(b.accountNum), p.type.konto) && p.date.getFullYear() === b.year
        );
    }

    private findKb(p: Payment) {
        return this.companyGQL.kasseList.find(
            kb => CategoryUtils.areEqual(String(kb.accountNum), p.type.konto) && p.date.getFullYear() === kb.year
        );
    }

    private deleteBankRecordExecutionItem(p: Payment): ExecutionItem {
        console.log(p.date, this.companyGQL.bankList);
        return this.deleteRepresentationRecordExecutionItem(
            p,
            this.aggregatedRecordsData.recordsBank,
            this.findBank(p),
            ProductKeys.Bank
        );
    }

    private deleteKasseRecordExecutionItem(p: Payment): ExecutionItem {
        return this.deleteRepresentationRecordExecutionItem(
            p,
            this.aggregatedRecordsData.recordsKB,
            this.findKb(p),
            ProductKeys.KB
        );
    }

    private deleteFERecordExecutionItem(p: Payment, relationKey: keyof PaymentRecordRelations): ExecutionItem {
        const recordKey = p[relationKey];
        if (!recordKey) {
            logger.debug("behaviour is inconsistent: recordKey is empty", p, JSON.stringify(p));
            throw new Error("behaviour is inconsistent: recordKey is empty: " + relationKey);
        }
        const record = this.aggregatedRecordsData.recordsFE.map.get(recordKey);
        if (!record) {
            logger.debug("data is inconsistent: record for selected payment does not exists", p, JSON.stringify(p));
            throw new Error("data is inconsistent: record for selected payment does not exists");
        }
        logger.log("Deleting FE record", [record]);
        return {
            type: "delete",
            // product: ProductKeys.FE,
            affectsJournaled: record.journaled,
            affectsConfirmed: false, // there is no confirmation process in FE and this record is Auto, so we create it confirmed and can change without confirmation
        };
    }
}
