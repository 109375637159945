import { AFRelationship, PDFDocument } from "pdf-lib";
import { ApolloClient, gql } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import { hashCalculate } from "../../../scripts/infrastructure/helpers/hashCalculate";
import dayjs from "dayjs";
import { TGoBLOrgParty } from "@inv/types";

const invoiceGenerateXRechnungXml = gql`
    mutation invoiceGenerateXRechnungXml($input: InvoiceGenerateXRechnungXmlInput!) {
        invoiceGenerateXRechnungXml(input: $input) {
            xml
            errors {
                Type
                Message
                Criterion
                Location
            }
        }
    }
`;

type Keys = {
    companyId: string;
    invoiceId: string;
};
export const createXRechnungPdfData = async (
    client: ApolloClient<unknown>,
    keys: Keys,
    pdfBuffer: Buffer,
    xRechnungData: Record<string, any>
): Promise<{
    url: string;
    filename: string;
    type: string;
    file: File;
    xmlBlob: Blob;
    xmlString: string;
    hash: string;
    errors: GQL.IInvoiceXRechnungValidationError[];
}> => {
    const { companyId, invoiceId } = keys;
    const {
        xml: xmlUint8Array,
        xmlString,
        errors,
    } = await client
        .mutate<Pick<GQL.IMutation, "invoiceGenerateXRechnungXml">, GQL.IMutationInvoiceGenerateXRechnungXmlArgs>({
            mutation: invoiceGenerateXRechnungXml,
            variables: {
                input: {
                    companyId,
                    json: JSON.stringify(xRechnungData),
                },
            },
        })
        .then(res => {
            const enc = new TextEncoder();
            return {
                xmlString: res.data.invoiceGenerateXRechnungXml.xml,
                xml: enc.encode(res.data.invoiceGenerateXRechnungXml.xml),
                errors: res.data.invoiceGenerateXRechnungXml.errors,
            };
        });
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const date = dayjs(xRechnungData.issue_date, "YYYY-MM-DD").toDate();

    const supplier: TGoBLOrgParty = xRechnungData.supplier;
    pdfDoc.setTitle("Rechnung " + invoiceId);
    pdfDoc.setAuthor(supplier.name);
    pdfDoc.setSubject("");
    pdfDoc.setProducer("Binale");
    pdfDoc.setCreator("Binale");
    pdfDoc.setModificationDate(date);
    pdfDoc.setCreationDate(date);
    await pdfDoc.attach(xmlUint8Array, "factur-x.xml", {
        mimeType: "application/xml",
        creationDate: date,
        modificationDate: date,
        afRelationship: AFRelationship.Alternative,
        description: "Factur-x invoice",
    });

    const pdfBytes = await pdfDoc.save();

    const type = "application/pdf";
    const blob = new Blob([pdfBytes], { type });
    const url = URL.createObjectURL(blob);
    const filename = [xRechnungData.issue_date, invoiceId].filter(Boolean).join("_") + ".pdf";
    const file = new File([blob], filename, { lastModified: date.getTime() });
    const hash = await hashCalculate(file);
    return {
        hash,
        url,
        filename,
        type,
        file,
        xmlBlob: new Blob([xmlUint8Array], { type: "application/xml" }),
        errors,
        xmlString,
    };
};
