import React, { useContext } from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import { DownOutlined, LoginOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { UserContext } from "scripts/context/UserProvider";
import { auth } from "scripts/api/firebase/firebase";
import { logger } from "scripts/infrastructure/logger";

const menuItems: React.ComponentProps<typeof Menu>["items"] = [
    {
        key: AppRoutes.profile,
        label: (
            <Space>
                <UserOutlined />
                <FormattedMessage id="app.titles.profile" />
            </Space>
        ),
    },
    { type: "divider" },
    {
        key: "__logout",
        label: (
            <Space data-testid="header-profile-menu-item-logout">
                <LogoutOutlined />
                <FormattedMessage id="app.header.logout" />
            </Space>
        ),
    },
];

// If logging in, showCompanyModal nothing
// If logged in, showCompanyModal profile (inkl. logout) button
// If logged out, showCompanyModal login button
export const LoginLogout = React.memo(function LoginLogout() {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const handleMenuClick: React.ComponentProps<typeof Menu>["onClick"] = React.useCallback(
        e => {
            if (e.key === "__logout") {
                auth.signOut().catch(error => {
                    logger.error("error catched on logout", { error });
                });
                return;
            }
            navigate(e.key.toString());
        },
        [navigate]
    );

    const login = React.useCallback(() => {
        navigate(AppRoutes.authLogin);
    }, [navigate]);

    return (
        <>
            {user.fireUser && (
                <Dropdown
                    menu={{
                        onClick: handleMenuClick,
                        selectedKeys: [location.pathname || ""],
                        items: menuItems,
                    }}
                    trigger={["click"]}
                >
                    <Button
                        className="Header--Button"
                        loading={user.isLoading}
                        disabled={user.isLoading}
                        data-testid="header-dropdown-profile"
                    >
                        {user.fireUser.email} <DownOutlined />
                    </Button>
                </Dropdown>
            )}
            {!user.fireUser && (
                <Button
                    className="Header--Button"
                    onClick={login}
                    disabled={user.isLoading}
                    data-testid="header-btn-login"
                >
                    <LoginOutlined />
                    <FormattedMessage id="app.header.login" tagName="span" />
                </Button>
            )}
        </>
    );
});
