import dayjs from "dayjs";
import { AbstractBillTableView } from "../../productER/AbstractBillTableView";
import { ExportData } from "../../../../scripts/csv/exporters/ExportPreprocessor";
import { Exporters, TableExportType } from "../../../../scripts/csv/exporters/Exporters";
import { GenericRecordProperties } from "scripts/core/Product";
import { GenericTableViewState } from "../GenericTableView";

export abstract class AbstractAuswertung<
    S extends GenericTableViewState = GenericTableViewState,
> extends AbstractBillTableView<S> {
    protected abstract getExportFilename(): string;
    protected useCollapsedData() {
        return false;
    }
    private getTableData(columns: string[]): ExportData {
        const labelOverrides = new Map();
        this.props.product.tableColumnOverrides().forEach((v, k) => {
            labelOverrides.set(k, v.label);
        });
        const options: Parameters<typeof Exporters.preprocessor.getExpandedExportData>[0] = {
            columns,
            tableItems: this.state.tableItems,
            tableColumns: this.tableColumns,
            groupByColumns: [
                GenericRecordProperties.RecordCategoryCreditorNum,
                GenericRecordProperties.RecordCategoryCreditorName,
            ],
            columnNames: labelOverrides,
        };
        if (this.useCollapsedData()) {
            return Exporters.preprocessor.getCollapsedExportData(options);
        }
        return Exporters.preprocessor.getExpandedExportData(options);
    }

    protected handleDownload = async (
        type: TableExportType,
        columns?: string[],
        timeframe?: {
            from?: Date;
            to?: Date;
        }
    ) => {
        // TODO: hardcoding columns for CSV Datev
        if (type === TableExportType.csv) {
            columns = [
                GenericRecordProperties.RecordCategoryCreditorNum,
                GenericRecordProperties.RecordCategoryCreditorName,
                GenericRecordProperties.RecordDatum,
                GenericRecordProperties.ComputedBu,
                GenericRecordProperties.ItemCategoryCreditorNum,
                GenericRecordProperties.ItemCategoryCreditorName,
                GenericRecordProperties.RecordBelegfeld1,
                GenericRecordProperties.ItemBuchungstext,
            ];
        }
        const data = this.getTableData(columns);
        const options: Parameters<typeof Exporters.builder.createExporter>[1] = {
            filename: this.getExportFilename() + "." + type,
            subTitle: this.getZeitraum(timeframe),
            companyName: this.props.companyGQL.name,
            title: "Kontenansicht",
        };
        return Promise.resolve().then(() => {
            Exporters.builder.createExporter(type, options).download(data);
        });
    };

    // TODO: to be translated
    protected getZeitraum(timeframe: { from?: Date; to?: Date }): string {
        const str = "Zeitraum: ";
        const von = timeframe.from ? dayjs(timeframe.from).format("DD.MM.YYYY") : "unbegrenzt";
        const bis = timeframe.to ? dayjs(timeframe.to).format("DD.MM.YYYY") : "unbegrenzt";

        return [str, "von", von, "bis", bis].join(" ");
    }
}
