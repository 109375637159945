import React, { FC } from "react";
import cn from "classnames";
import { Draggable } from "react-beautiful-dnd";
import { CarouselItem } from "@ui-components/PdfViewer/DocumentCarousel/CarouselItem";

import styles from "./PdfItemDraggable.module.scss";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { OcrLoadedFile } from "@pdf-tools/types";

interface IProps {
    id: string;
    draggableId: string;
    handleClick: (key: string) => void;
    previewUrl: string;
    index: number;
    document: OcrLoadedFile;
}

export const PdfItemDraggable: FC<IProps> = ({ document, draggableId, id, previewUrl, index, handleClick }) => {
    return (
        <Draggable draggableId={draggableId} index={index}>
            {provided => {
                return (
                    <div
                        className={styles.viewListItemDraggable}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => handleClick(draggableId)}
                        style={{
                            ...provided.draggableProps.style,
                            outline: draggableId === id ? "2px solid #1890ff" : "none",
                        }}
                    >
                        <CarouselItem id={draggableId} previewUrl={previewUrl} />

                        <Tooltip
                            destroyTooltipOnHide
                            placement="top"
                            className={cn(styles.viewListItemNumber, styles.numbers)}
                            styles={{ body: { minWidth: "30px", textAlign: "center" } }}
                            title={index + 1}
                        >
                            {index + 1}
                        </Tooltip>

                        <Tooltip
                            destroyTooltipOnHide
                            placement="top"
                            className={styles.uploadDocumentItemTotalPagesWrapper}
                            title={
                                <>
                                    <FormattedMessage id="app.ocr.totalPages" /> {document.fileNumPage}
                                </>
                            }
                        >
                            <div className={styles.numbers}>
                                {<FormattedMessage id="app.dms.pages" values={{ count: document.fileNumPage }} />}
                            </div>
                        </Tooltip>
                    </div>
                );
            }}
        </Draggable>
    );
};
