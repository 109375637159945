import React, { type FC, useCallback } from "react";
import { DmsViewList } from "@dms/components/DocumentCarouselItems/DmsViewList";
import { CarouselHeaderDms, CarouselHeaderPdfTools } from "@ui-components/PdfViewer/DocumentCarousel/CarouselHeader";
import styles from "./Carousel.module.scss";
import { PdfViewList } from "@pdf-tools/components/PdfToolsPreviewBlock/components/PdfViewList";
import { OcrLoadedFile } from "@pdf-tools/types";
import { GQL } from "@binale-tech/shared";

export const CarouselDms: FC<{
    documents: GQL.IDocument[];
    activeDocumentId: string;
    setDocumentId: (arg: string) => void;
}> = ({ documents, setDocumentId, activeDocumentId }) => {
    const handleClick = useCallback((id: string) => setDocumentId(id), [setDocumentId]);
    if (!documents) {
        return null;
    }
    return (
        <div style={{ width: "100%" }}>
            <CarouselHeaderDms documents={documents} />
            <div className={styles.dmsViewListWrapper}>
                <DmsViewList documents={documents} handleClick={handleClick} activeDocumentId={activeDocumentId} />
            </div>
        </div>
    );
};

export const CarouselPdfTools: FC<{
    files: OcrLoadedFile[];
    activeFileId: string;
    setActiveFileId: (arg: string) => void;
    reorderingFiles: (files: OcrLoadedFile[]) => void;
}> = ({ files, setActiveFileId, activeFileId, reorderingFiles }) => {
    const handleClick = useCallback(() => setActiveFileId(activeFileId), [activeFileId, setActiveFileId]);
    if (!files) {
        return null;
    }
    return (
        <div style={{ width: "100%" }}>
            <CarouselHeaderPdfTools files={files} />
            <div className={styles.pdfToolsViewListWrapper}>
                <PdfViewList
                    documents={files}
                    reorderingFiles={reorderingFiles}
                    handleClick={handleClick}
                    activeFileId={activeFileId}
                />
            </div>
        </div>
    );
};
