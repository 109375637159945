import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Category, Creditor, GenericItem, GenericRecord } from "../../models";
import { CompanyContext, YearPeriodContext } from "../CompanyContext";
import { defaultPredicates, GenericToolbarFilters, IFilters } from "./tableViewFilters";
import { IViewsKeys } from "../../models/User";
import { LogItem } from "../../models/LogItem";
import { Product } from "../../core/Product";
import { ProductKey } from "../../models/Product";
import { RecordActions } from "../accountingData/RecordsControlCtx";
import { useTemplateData } from "../accountingData/hooks/useTemplateData";
import { GQL } from "@binale-tech/shared";
import { Subject } from "rxjs";

type LeftTableMessage = Partial<Pick<GenericItem, "debetor" | "creditor" | "category" | "tag">>;
type TableViewCtx = {
    view: keyof IViewsKeys;
    product: Product;
    productKey: ProductKey;
    moduleRecords: GenericRecord[];
    moduleActions: RecordActions;
    moduleLogLister: (recordKey: string) => Promise<LogItem[]>;
    selectedRecordGroup?: string;
    moduleTemplates?: GenericRecord[];
    onSelectLeftTableAccount?: (v: LeftTableMessage) => void;
    leftTableSubject?: Subject<LeftTableMessage>;
};

export type FilterValue = {
    value: any;
    predicate: (record: GenericRecord) => boolean;
};
export type E2EFilterEntity = { account?: Creditor | Category; contact?: { id?: string; name: string } };
type FilterCtx = {
    filters: Map<keyof IFilters, FilterValue>;
    e2eFilterEntity?: E2EFilterEntity;
    e2eFilterMode: "account" | "contact";
    e2eFilterEntityFormFollow?: boolean;
    e2eFilterYear: number;
};
type FiltersList = [key: keyof IFilters, value: FilterValue][];
type FilterControlCtx = {
    setFilter: (key: keyof IFilters, value: FilterValue) => void;
    setFilters: (v: FiltersList) => void;
    replaceFilters: (v: FilterCtx["filters"]) => void;
    resetFilters: () => void;
    setE2eFilterEntity: (v?: E2EFilterEntity) => void;
    setE2eFilterMode: (v: FilterCtx["e2eFilterMode"]) => void;
    setE2eFilterEntityFormFollow: (v: boolean) => void;
    setE2eFilterYear: (v: number) => void;
};
export const TableViewContext = React.createContext<TableViewCtx>({
    view: undefined,
    product: undefined,
    productKey: undefined,
    moduleRecords: undefined,
    moduleActions: undefined,
    moduleLogLister: undefined,
    onSelectLeftTableAccount: () => {},
});

export const TableFiltersContext = React.createContext<FilterCtx>({
    filters: new Map(),
    e2eFilterMode: "account",
    e2eFilterYear: new Date().getFullYear(),
});

export const getDefaultYearPeriodFilters = (year: number, period: number): FilterCtx["filters"] =>
    new Map([
        [GenericToolbarFilters.Year, { predicate: defaultPredicates.year(year), value: year }],
        [GenericToolbarFilters.Period, { predicate: defaultPredicates.period(period), value: period }],
    ]);
export const TableFiltersControlContext = React.createContext<FilterControlCtx>({
    setFilter: () => {},
    setFilters: () => {},
    replaceFilters: () => {},
    resetFilters: () => {},
    setE2eFilterEntity: () => {},
    setE2eFilterMode: () => {},
    setE2eFilterEntityFormFollow: () => {},
    setE2eFilterYear: () => {},
});

export const DEFAULT_FILTER_KEY = "default";

export const useDefaultYearPeriodFilters = () => {
    const { year, period } = useContext(YearPeriodContext);
    return React.useMemo<FilterCtx["filters"]>(() => {
        return getDefaultYearPeriodFilters(year, period);
    }, [year, period]);
};

const getYearPeriodFilters = (defaultFilters: FilterCtx["filters"], year: number, period: number): FiltersList => {
    const filterYear = defaultFilters.has(GenericToolbarFilters.Year) ? year : undefined;
    const filterPeriod = defaultFilters.has(GenericToolbarFilters.Period) ? period : undefined;
    return [
        [GenericToolbarFilters.Year, { predicate: defaultPredicates.year(filterYear), value: filterYear }],
        [GenericToolbarFilters.Period, { predicate: defaultPredicates.period(filterPeriod), value: filterPeriod }],
    ];
};

export const TableViewContextProvider: React.FC<
    React.PropsWithChildren<{
        tableCtx: React.ContextType<typeof TableViewContext>;
        defaultFilters: FilterCtx["filters"];
    }>
> = ({ children, tableCtx, defaultFilters }) => {
    const leftTableSubject = useRef(new Subject<LeftTableMessage>());
    const { programSettingsProvider, companyGQL } = React.useContext(CompanyContext);
    const { year, period } = useContext(YearPeriodContext);
    const yearPeriodRef = useRef({ year, period });
    const accountingYears = companyGQL?.accountingYears || [];
    const prevFiltersRef = React.useRef<FilterCtx["filters"]>(defaultFilters);
    const [filters, setFilters] = React.useState<FilterCtx["filters"]>(defaultFilters);
    const [e2eFilterYear, setE2eFilterYear] = React.useState<number>(
        accountingYears.length ? accountingYears[0] : new Date().getFullYear()
    );
    const [e2eFilterMode, setE2eFilterMode] = React.useState<FilterCtx["e2eFilterMode"]>("account");
    const [e2eFilterEntity, setE2eFilterEntity] = React.useState<E2EFilterEntity>(undefined);
    const [e2eFilterEntityFormFollow, setE2eFilterEntityFormFollow] = React.useState<boolean>(false);
    const duplicates = filters.get(GenericToolbarFilters.Duplicates)?.value;

    const moduleTemplates = useTemplateData(tableCtx.productKey as GQL.IProductKey, tableCtx.selectedRecordGroup);

    const settings = React.useMemo(
        () => programSettingsProvider(tableCtx.productKey),
        [tableCtx.productKey, programSettingsProvider]
    );

    useEffect(() => {
        yearPeriodRef.current = { year, period };
    }, [year, period]);

    const onSetFilters: FilterControlCtx["setFilters"] = React.useCallback(list => {
        setFilters(f => {
            if (list.length === 1) {
                const [[key, filter]] = list;
                if (f.get(key)?.value === filter.value) {
                    return f;
                }
            }
            prevFiltersRef.current = f;
            const newFilters = new Map(f);

            list.forEach(([key, value]) => {
                newFilters.set(key, value);
            });
            if (f.has(DEFAULT_FILTER_KEY)) {
                newFilters.set(DEFAULT_FILTER_KEY, f.get(DEFAULT_FILTER_KEY));
            }

            return newFilters;
        });
    }, []);
    const onReplaceFilters: FilterControlCtx["replaceFilters"] = React.useCallback(list => {
        setFilters(f => {
            prevFiltersRef.current = f;
            const newFilters = new Map();

            list.forEach((value, key) => {
                newFilters.set(key, value);
            });
            if (f.has(DEFAULT_FILTER_KEY)) {
                newFilters.set(DEFAULT_FILTER_KEY, f.get(DEFAULT_FILTER_KEY));
            }

            return newFilters;
        });
    }, []);
    const onSetFilter: FilterControlCtx["setFilter"] = React.useCallback(
        (key, value) => onSetFilters([[key, value]]),
        [onSetFilters]
    );
    const onResetFilters: FilterControlCtx["resetFilters"] = React.useCallback(() => {
        setFilters(f => {
            prevFiltersRef.current = f;
            return new Map(defaultFilters);
        });
    }, [defaultFilters]);

    useEffect(() => {
        // react on global year/period change (unless e2e search or duplicates are enabled)
        if (e2eFilterEntity || duplicates) {
            return;
        }
        onSetFilters(getYearPeriodFilters(defaultFilters, year, period));
    }, [onSetFilters, year, period, e2eFilterEntity, duplicates, defaultFilters]);

    useEffect(() => {
        // reset mode selection when Contact is being disabled in settings
        if (e2eFilterMode === "contact" && settings.hideContacts) {
            setE2eFilterMode("account");
        }
    }, [e2eFilterMode, settings.hideContacts]);

    useEffect(() => {
        // reset filter when changing entity type
        setE2eFilterEntity(undefined);
    }, [e2eFilterMode]);

    useEffect(() => {
        // set filters according to the entity + year
        if (e2eFilterEntity?.account) {
            const value = e2eFilterEntity.account;
            onSetFilters([
                [GenericToolbarFilters.E2EKonto, { value, predicate: defaultPredicates.e2eKonto(value) }],
                [GenericToolbarFilters.Contact, { value: undefined, predicate: () => true }],
                [
                    GenericToolbarFilters.Year,
                    { predicate: defaultPredicates.year(e2eFilterYear), value: e2eFilterYear },
                ],
                [GenericToolbarFilters.Period, { value: null, predicate: () => true }],
            ]);
        } else if (e2eFilterEntity?.contact) {
            const value = e2eFilterEntity.contact;
            onSetFilters([
                [GenericToolbarFilters.E2EKonto, { value: undefined, predicate: () => true }],
                [GenericToolbarFilters.Contact, { value, predicate: defaultPredicates.contact(value) }],
                [
                    GenericToolbarFilters.Year,
                    { predicate: defaultPredicates.year(e2eFilterYear), value: e2eFilterYear },
                ],
                [GenericToolbarFilters.Period, { value: null, predicate: () => true }],
            ]);
        } else {
            const { year: refYear, period: refPeriod } = yearPeriodRef.current;
            onSetFilters([
                [GenericToolbarFilters.E2EKonto, { value: undefined, predicate: () => true }],
                [GenericToolbarFilters.Contact, { value: undefined, predicate: () => true }],
                ...getYearPeriodFilters(defaultFilters, refYear, refPeriod),
            ]);
        }
    }, [onSetFilters, e2eFilterEntity, e2eFilterYear, defaultFilters]);

    useEffect(() => {
        // reset e2e year when resetting entity
        if (!e2eFilterEntity) {
            setE2eFilterYear(year);
        }
    }, [e2eFilterEntity, year]);

    const controlValue = React.useMemo(
        () => ({
            setFilter: onSetFilter,
            setFilters: onSetFilters,
            resetFilters: onResetFilters,
            replaceFilters: onReplaceFilters,
            setE2eFilterMode,
            setE2eFilterEntity,
            setE2eFilterEntityFormFollow,
            setE2eFilterYear,
        }),
        [onResetFilters, onSetFilter, onSetFilters, onReplaceFilters]
    );
    const filtersCtx = useMemo(
        () => ({ filters, e2eFilterMode, e2eFilterEntity, e2eFilterEntityFormFollow, e2eFilterYear }),
        [filters, e2eFilterMode, e2eFilterEntity, e2eFilterEntityFormFollow, e2eFilterYear]
    );

    const tableViewCtx: TableViewCtx = useMemo(
        () => ({
            ...tableCtx,
            moduleTemplates,
            onSelectLeftTableAccount: account => {
                leftTableSubject.current.next(account);
            },
            leftTableSubject: leftTableSubject.current,
        }),
        [tableCtx, moduleTemplates]
    );

    return (
        <TableViewContext.Provider value={tableViewCtx}>
            <TableFiltersContext.Provider value={filtersCtx}>
                <TableFiltersControlContext.Provider value={controlValue}>
                    {children}
                </TableFiltersControlContext.Provider>
            </TableFiltersContext.Provider>
        </TableViewContext.Provider>
    );
};
