import { AppCheck, getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";

const projectConfigs: Record<string, { region: string; config: FirebaseOptions }> = {
    production: {
        region: "europe-west3",
        config: {
            apiKey: import.meta.env.VITE_FIREBASE_KEY_BINALE,
            authDomain: "binale-app-de.firebaseapp.com",
            databaseURL: "https://binale.europe-west1.firebasedatabase.app",
            projectId: "binale-app-de",
            storageBucket: "binale-app-de.appspot.com",
            messagingSenderId: "726144543922",
            appId: "1:726144543922:web:872fac9d7e01881fe0edab",
            measurementId: "G-2QHH5W6NMK",
        },
    },
    development: {
        region: "us-central1",
        config: {
            apiKey: "AIzaSyBF8_wxW1dVhJGUSC6ygHGvD5O0e_x-ELI",
            authDomain: "steuerflow-testing.firebaseapp.com",
            databaseURL: "https://steuerflow-testing.firebaseio.com",
            projectId: "steuerflow-testing",
            storageBucket: "steuerflow-testing.appspot.com",
            messagingSenderId: "560876633077",
            appId: "1:560876633077:web:38e177c8ee1f903a507ebc",
            measurementId: "G-5LZ3ZXX3HJ",
        },
    },
    dms: {
        region: "europe-west3",
        config: {
            apiKey: "AIzaSyCIBHFAmVFrDddxx5eBB5szJ0sBh0B3GpQ",
            authDomain: "binale-dms.firebaseapp.com",
            databaseURL: "https://binale-dms-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "binale-dms",
            storageBucket: "binale-dms.appspot.com",
            messagingSenderId: "211125218905",
            appId: "1:211125218905:web:41323d53ca63591d094b48",
            measurementId: "G-PL5BG73VZQ",
        },
    },
};

const getConfig = (): (typeof projectConfigs)[string] => {
    if (import.meta.env.VITE_PRODUCTION_DATABASE) {
        return projectConfigs.production;
    }
    if (import.meta.env.VITE_FIREBASE_ENV) {
        const key = import.meta.env.VITE_FIREBASE_ENV;
        if (!projectConfigs[key]) {
            throw new Error(`Config from env "${key}" is not defined`);
        }
        return projectConfigs[key];
    }
    return projectConfigs.development;
};

const firebaseApp = initializeApp(getConfig().config);
export const database = getDatabase(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const remoteConfig = getRemoteConfig(firebaseApp);
let appCheck: AppCheck;
if (import.meta.env.VITE_PRODUCTION_DATABASE || import.meta.env.VITE_FIREBASE_ENV === "production") {
    appCheck = initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaEnterpriseProvider("6LcSDHIpAAAAAClRc0dLZtwJzaqlVUtqwWBVrft7"),
        isTokenAutoRefreshEnabled: true,
    });
}
export const getAppCheckToken = async () => {
    if (!appCheck) {
        return "";
    }
    try {
        const appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
        return appCheckTokenResponse.token;
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        return "";
    }
};

export function encodeKey(s: string) {
    return s.replace(/\./g, "%2E");
}

export function decodeKey(s: string) {
    return s.replace(/%2E/g, ".");
}
