import styles from "../../UploadDocuments/UploadDocumentContainer.module.scss";
import { ParkedDocumentItem } from "./ParkedDocumentItem";
import { Droppable } from "react-beautiful-dnd";
import React, { useContext } from "react";
import { PdfToolsContext } from "../../../context";

import { PagesCarousel } from "../../PagesCarousel";
import { DroppableIds } from "../../../types";
import { FormattedMessage } from "react-intl";

export const ParkedDocumentList = () => {
    const { viewParkedDocuments, draggableId } = useContext(PdfToolsContext);

    return (
        <Droppable droppableId={DroppableIds.ParkedPages} isDropDisabled={false} isCombineEnabled={false}>
            {provided => {
                return (
                    <div
                        id={"carouselWrapper"}
                        className={styles.uploadedPagesWrapper}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {viewParkedDocuments.length ? (
                            <PagesCarousel initialIndex={viewParkedDocuments.length - 1} draggable={!!draggableId}>
                                {viewParkedDocuments.map((document, docIdx) => (
                                    <ParkedDocumentItem
                                        draggableId={document.id}
                                        pages={document.pages}
                                        key={document.id}
                                        idx={docIdx}
                                    />
                                ))}
                            </PagesCarousel>
                        ) : (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    display: draggableId ? "none" : "block",
                                }}
                            >
                                <FormattedMessage id="app.ocr.parking" tagName="span" />
                            </div>
                        )}
                        {provided.placeholder}
                    </div>
                );
            }}
        </Droppable>
    );
};
