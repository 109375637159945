import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Collapse, Flex, Form, Input, message, Modal, Space } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { GlobalYearSelect } from "../../../../../components/shared/Toolbar/YearSelect";
import { DeleteOutlined, ReloadOutlined, UploadOutlined } from "@ant-design/icons";
import { VATContext, VATControlContext } from "../VatContext";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";

import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import Dropzone from "react-dropzone";
import { PdfSingleDocumentViewer } from "@ui-components/PdfViewer";
import type { ValidateStatus } from "antd/es/form/FormItem";
import { captureException } from "@sentry/react";
import { PeriodQuarterSelect } from "../../../../../components/shared/Toolbar/PeriodQuarterSelect";
import { Switch } from "@heroui/switch";
import { base64StringToFile, fileToBase64 } from "../../../../../../scripts/converters/converters";

const mutationElsterUStVA = gql`
    mutation elsterUStVA($input: ElsterUStVAInput!) {
        elsterUStVA(input: $input) {
            pdf
            transferticket
        }
    }
`;

export const VatToolbar: React.FC = () => {
    const { year } = useContext(YearPeriodContext);
    const { companyGQL } = useContext(CompanyContext);
    const { period, canReload, dataSource } = useContext(VATContext);
    const { setPeriod, reload } = useContext(VATControlContext);
    const client = useApolloClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isCorrecting, setIsCorrecting] = useState<boolean>(false);
    const [isInvoice, setIsInvoice] = useState<boolean>(false);
    const [elsterResponse, setElsterResponse] = useState<GQL.IElsterUStVaResponse>(null);
    const intl = useIntl();
    const [file, setFile] = useState<File>();
    const [pin, setPin] = useState<string>("");
    const [pinValidation, setPinValidation] = useState<ValidateStatus>();
    const [certValidation, setCertValidation] = useState<ValidateStatus>();
    const [validationErrors, setValidationErrors] = useState<string[]>();

    const kzItems: GQL.IUStVaKzItem[] = useMemo(() => {
        const kzList: GQL.IUStVaKzItem[] = [];

        dataSource
            .filter(v => !v.isGroupTitle)
            .forEach(v => {
                if (period === null) {
                    return;
                }
                const push = (kz: string, isBmg: boolean) => {
                    const segment = period > 40 ? v.quartals[period - 41] : v.periods[period];
                    const amount = isBmg ? segment.bmg : segment.st;
                    if (kz === "83" || amount !== 0) {
                        kzList.push({ kz: Number(kz), amount: amount / 100 });
                    }
                };
                if (v.titleBmg) {
                    push(v.titleBmg, true);
                }
                if (v.titleSt && !v.titleSt.startsWith("X")) {
                    push(v.titleSt, false);
                }
            });
        if (isCorrecting) {
            kzList.push({ kz: 10, amount: 1 });
        }
        if (isInvoice) {
            kzList.push({ kz: 22, amount: 1 });
        }
        return kzList;
    }, [dataSource, period, isCorrecting, isInvoice]);

    useEffect(() => {
        if (!isModalOpen) {
            setFile(undefined);
            setPin("");
            setElsterResponse(null);
            setPinValidation(undefined);
            setCertValidation(undefined);
            setValidationErrors(undefined);
            setIsCorrecting(false);
            setIsInvoice(false);
        }
    }, [isModalOpen]);

    const onExport = async () => {
        setIsLoading(true);
        setPinValidation("validating");
        setCertValidation("validating");
        setValidationErrors(undefined);
        client
            .mutate<Pick<GQL.IMutation, "elsterUStVA">, GQL.IMutationElsterUStVaArgs>({
                mutation: mutationElsterUStVA,
                variables: {
                    input: {
                        payload: {
                            year,
                            period,
                            companyId: companyGQL.id,
                            kzItems,
                        },
                        meta: {
                            cert: await fileToBase64(file),
                            pin,
                        },
                    },
                },
            })
            .then(async res => {
                const { transferticket, pdf } = res.data.elsterUStVA;
                const filePdf = base64StringToFile(pdf, `${transferticket}.pdf`, "application/pdf");
                const fileUrl = URL.createObjectURL(filePdf);

                setElsterResponse({ transferticket, pdf: fileUrl });
                message.success("Elster UStVA ok");
            })
            .catch(e => {
                if (e instanceof ApolloError) {
                    e.graphQLErrors.forEach(err => {
                        if (err.extensions.code === "ERIC_CRYPT_E_PIN_WRONG") {
                            setPinValidation("error");
                        } else if (err.extensions.code === "ERIC_CRYPT_E_P12_DECODE") {
                            setCertValidation("error");
                        } else if (err.extensions.code === "ERIC_GLOBAL_PRUEF_FEHLER") {
                            setValidationErrors(err.extensions.validation_problems as string[]);
                        } else if (err.extensions.code === "ERIC_TRANSFER_ERR_XML_THEADER") {
                            setValidationErrors([err.extensions.server_error as string]);
                        } else {
                            message.error(`Elster error: ${err.extensions.code} ${err.extensions.server_error ?? ""}`);
                            captureException(new Error("Elster exception: " + err.extensions.code), {
                                data: err,
                            });
                        }
                    });
                }
            })
            .finally(() => setIsLoading(false));
    };
    return (
        <Flex justify="space-between">
            <Space>
                <GlobalYearSelect />
                <PeriodQuarterSelect period={period} onChange={setPeriod} />
                <Button shape="circle" icon={<ReloadOutlined />} disabled={!canReload} onClick={reload} />
            </Space>
            <Space>
                <Switch isSelected={isCorrecting} onValueChange={v => setIsCorrecting(v)} size={"sm"}>
                    Berichtigte Anmeldung
                </Switch>
                <Switch isSelected={isInvoice} onValueChange={v => setIsInvoice(v)} size={"sm"}>
                    Belege werden gesondert eingereicht
                </Switch>
                <Button
                    type={period === null || !companyGQL.invoiceTax?.stnr ? "default" : "primary"}
                    loading={isLoading}
                    onClick={() => {
                        if (!companyGQL.invoiceTax?.stnr) {
                            message.warning(
                                "um fortzufahren, geben Sie bitte die Steuerinformationen in Ihren Unternehmenseinstellungen ein"
                            );
                        } else if (period === null) {
                            message.info("um fortzufahren, wählen Sie bitte einen bestimmten Zeitraum aus");
                        } else {
                            setIsModalOpen(true);
                        }
                    }}
                >
                    Elster
                </Button>
            </Space>
            <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={700} destroyOnClose>
                <Form layout="vertical" onFinish={onExport}>
                    <Form.Item
                        label="Elster .pfx"
                        extra={
                            file ? (
                                <Space>
                                    <span>{file.name}</span>{" "}
                                    <Button
                                        disabled={isLoading}
                                        type={"link"}
                                        icon={<DeleteOutlined />}
                                        onClick={() => setFile(undefined)}
                                    />
                                </Space>
                            ) : undefined
                        }
                        validateStatus={certValidation}
                        help={certValidation === "error" ? ".pfx Zertifikat ist defekt" : undefined}
                    >
                        <Dropzone
                            accept={{ "application/x-pkcs12": [".pfx"] }}
                            multiple={false}
                            disabled={Boolean(file)}
                            onDropAccepted={files => {
                                setCertValidation(undefined);
                                setValidationErrors(undefined);
                                setFile(files[0]);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <Button icon={<UploadOutlined />} htmlType="button" disabled={Boolean(file)}>
                                        {intl.formatMessage({ id: "app.button.upload" })}
                                        &nbsp;Elster&nbsp;.pfx
                                    </Button>
                                </div>
                            )}
                        </Dropzone>
                    </Form.Item>
                    <Form.Item
                        label="PIN"
                        validateStatus={pinValidation}
                        help={pinValidation === "error" ? "PIN ist falsch" : undefined}
                    >
                        <Input.Password
                            value={pin}
                            onChange={e => {
                                setPinValidation(undefined);
                                setValidationErrors(undefined);
                                setPin(e.target.value);
                            }}
                        />
                    </Form.Item>
                    {elsterResponse && (
                        <Form.Item>
                            <Collapse
                                size="small"
                                items={[
                                    {
                                        key: "1",
                                        label: (
                                            <>
                                                Transferticket: <b>{elsterResponse.transferticket}</b>
                                            </>
                                        ),
                                        children: (
                                            <div style={{ height: "60vh", position: "relative", display: "flex" }}>
                                                <PdfSingleDocumentViewer
                                                    url={elsterResponse.pdf}
                                                    type="application/pdf"
                                                    fileName={elsterResponse.transferticket + ".pdf"}
                                                />
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </Form.Item>
                    )}
                    {validationErrors && (
                        <Form.Item>
                            <Collapse
                                collapsible={"disabled"}
                                activeKey={["1"]}
                                size="small"
                                items={[
                                    {
                                        key: "1",
                                        label: (
                                            <>
                                                <FormattedMessage id={"app.fields.recordReview.error"} /> (
                                                {validationErrors.length})
                                            </>
                                        ),
                                        children: (
                                            <div>
                                                {validationErrors.map((error, index) => (
                                                    <div key={"ve" + index} style={{ marginBottom: 10 }}>
                                                        <code>{error}</code>
                                                    </div>
                                                ))}
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </Form.Item>
                    )}
                    <Form.Item>
                        <Button
                            htmlType={"submit"}
                            type={"primary"}
                            disabled={!file || !pin || Boolean(elsterResponse)}
                            loading={isLoading}
                        >
                            Export
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Flex>
    );
};
