import { orange } from "@ant-design/colors";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Form, Space, Spin } from "antd";
import React, { type FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { compareInputValues, isNotEmpty } from "@dms/scripts/helpers";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { TDocumentInputs } from "@dms/types";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DocumentInputs } from "./DocumentInputSets/DocumentInputs";
import { handleKeyDownCancel, handleKeyDownSubmit, handlePress } from "./utils";
import { useNavigate } from "react-router-dom";

type TProps = {
    groupFormInitialValue: TDocumentInputs;
};

type TCheckBoxInitialStates = Partial<Record<`${keyof TDocumentInputs}Checkbox`, false>>;

const ignoreInputs: Partial<Record<keyof TCheckBoxInitialStates, true>> = {
    documentNumberCheckbox: true,
    descriptionCheckbox: true,
    documentDateCheckbox: true,
    typeCheckbox: true,
    fileNameCheckbox: true,
    partnerCheckbox: true,
    currencyDataCheckbox: true,
    interneNumberCheckbox: true,
    deadlineDaysCheckbox: true,
    dueDateCheckbox: true,
    paymentTypeCheckbox: true,
    discountDaysCheckbox: true,
    UStIdNrCheckbox: true,
    discountDateCheckbox: true,
    discountPercentCheckbox: true,
    discountAmountCheckbox: true,
};

const GroupDocumentForm: FC<TProps> = ({ groupFormInitialValue }) => {
    const [submittable, setSubmittable] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState(false);
    const [documentType, setDocumentType] = useState<string>();
    const [isClose, setIsClose] = useState<boolean>(false);
    const {
        actionConfirm,
        fileNameSuffix,
        notEqualField,
        disableInputs,
        setRequiredFields,
        handleSetNecessaryGroupFields,
    } = useContext(GroupFormContext);
    const { checkboxCarousel } = useContext(FormWrapperContext);

    const [form] = Form.useForm();
    const { documentTypes } = useContext(DmsDataContext);

    const navigate = useNavigate();

    const values: TDocumentInputs & TCheckBoxInitialStates = Form.useWatch([], form);

    const submitRef = useRef<HTMLButtonElement>(null);
    const cancelRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        handleCancel();
    }, [groupFormInitialValue]);

    useEffect(() => {
        setDocumentType(groupFormInitialValue?.type);
    }, [groupFormInitialValue?.type]);

    useEffect(() => {
        if (values?.type) {
            setDocumentType(values?.type);
        }
    }, [values?.type]);

    useEffect(() => {
        if (!documentType) {
            return;
        }
        const { type } = DmsUtils.getTypeAndSubType(documentType, documentTypes);
        if (!type) {
            return;
        }
        handleSetNecessaryGroupFields(type);
    }, [documentType]);

    useEffect(() => {
        if (!values || !groupFormInitialValue) {
            setSubmittable(false);
            return;
        }

        disableInputs(false);

        if (values.fileNameCheckbox) {
            setRequiredFields(["fileName"]);
            form.validateFields(["fileName"]);
        } else {
            setRequiredFields([]);
            form.validateFields();
        }

        const isEqual = compareInputValues(values, groupFormInitialValue, ignoreInputs);
        const errors = form.getFieldsError();

        const hasErrors = errors.some(field => field.errors.length > 0);

        setSubmittable(!isEqual || hasErrors);

        if (values.landCode && !values.UStIdNr) {
            setSubmittable(false);
        }
    }, [values]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSuccess(false);
            setIsClose(false);
        }, 1000);

        return () => clearTimeout(timerId);
    }, [success]);

    async function handleFinish(inputValues: TDocumentInputs) {
        setIsLoading(true);

        const reducedValues = {} as TDocumentInputs;

        Object.keys(inputValues).forEach(el => {
            const key = el as keyof TDocumentInputs;

            if (key in groupFormInitialValue && isNotEmpty(inputValues[key])) {
                if (key === "currencyData") {
                    if (inputValues.currencyData?.currency?.code && isNotEmpty(inputValues.currencyData.amount)) {
                        reducedValues.currency = inputValues.currencyData.currency.code;
                        reducedValues.currencyRate = inputValues.currencyData.currency.rate;
                        reducedValues.documentAmount = inputValues.currencyData.amount;
                        reducedValues.originalAmount = inputValues.currencyData?.originalAmount;
                    }
                } else {
                    reducedValues[key] = inputValues[key] as never;
                }
            }

            if (key === "isAttachment" && !notEqualField.includes("isAttachment")) {
                reducedValues[key] = inputValues[key];
            }
        });

        if (inputValues.type) {
            const { type, subType } = DmsUtils.getTypeAndSubType(inputValues.type, documentTypes);
            reducedValues.type = type;
            reducedValues.subType = subType;
        }

        if (inputValues.fileName) {
            reducedValues.fileName = inputValues.fileName + fileNameSuffix;
        }

        await actionConfirm(reducedValues);

        setIsLoading(false);
        setSuccess(true);
        setSubmittable(false);
        disableInputs(true);

        if (isClose) {
            navigate("/documents");
        }
    }

    function handleCancel(): void {
        setSubmittable(false);
        disableInputs(true);
        form.resetFields();
    }

    const keyboardKeys = useMemo(() => new Set<string>(), []);

    return (
        <>
            {groupFormInitialValue && (
                <>
                    <Form
                        style={{ minHeight: "250px" }}
                        form={form}
                        name={"group-documents-form"}
                        layout="vertical"
                        onFinish={handleFinish}
                        initialValues={{
                            ...groupFormInitialValue,
                        }}
                        onKeyDown={e => handlePress(e, "down", keyboardKeys, "group-documents-form")}
                        onKeyUp={e => handlePress(e, "up", keyboardKeys, "group-documents-form")}
                        autoComplete={"off"}
                    >
                        <Flex
                            style={{ color: orange.primary, fontWeight: 600, marginBottom: "10px", minHeight: 25 }}
                            align={"center"}
                            justify={"flex-end"}
                        >
                            {<span>{checkboxCarousel.length} documents edited</span>}
                        </Flex>
                        {<DocumentInputs type={documentType} />}
                        <Divider />
                        <Flex wrap gap={8} style={{ justifyContent: "flex-end", width: "100%" }}>
                            {success && !isLoading && (
                                <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />
                            )}
                            {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                            <Button
                                ref={submitRef}
                                type={"primary"}
                                onKeyDown={e => handleKeyDownSubmit(e, form)}
                                onClick={() => {
                                    setIsClose(false);
                                    form.submit();
                                }}
                                disabled={!submittable}
                            >
                                <FormattedMessage id="app.button.confirm" />
                            </Button>
                            <Button
                                id="formConfAndCloseID"
                                onKeyDown={e => handleKeyDownSubmit(e, form)}
                                onClick={() => {
                                    setIsClose(true);
                                    form.submit();
                                }}
                                disabled={!submittable}
                            >
                                <FormattedMessage id="app.button.confAndClose" />
                            </Button>
                            <Button
                                ref={cancelRef}
                                onKeyDown={e => handleKeyDownCancel(e, handleCancel)}
                                onClick={handleCancel}
                            >
                                <FormattedMessage id="app.button.reset" />
                            </Button>
                        </Flex>
                    </Form>
                </>
            )}
        </>
    );
};

export const GroupDocumentFormWrapper: FC = () => {
    const { groupFormInitialValue } = useContext(GroupFormContext);

    return groupFormInitialValue && <GroupDocumentForm groupFormInitialValue={groupFormInitialValue} />;
};
