import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Flex } from "antd";

import { CloseOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { BanksAppContext, BanksAppControlContext } from "@banks/scripts/context";
import { OptionsValues } from "@banks/types/enums";
import { requiredFields, transactionFieldsDict } from "@banks/configs/constants/transactionFieldsDict";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { BanksContext } from "../../../../../../scripts/context/BanksContext";
import { AppRoutes } from "../../../../../../scripts/routing/routeConstants";

import { SelectBankTitle } from "./SelectBankTitle";
import { MatchKeyList } from "./MatchKeyList";
import { BankSelect } from "./BankSelect";
import {
    PreviewParseDataContext,
    PreviewParseDataControlContext
} from "@banks/modules/ParseTransactionModule/context/PreviewParseDataProvider";

export const PreviewParseDataToolbar: FC = () => {
    const [bankSelectValue, setBankSelectValue] = useState<string>();

    const { banksData } = useContext(BanksContext);
    const { addBankData } = useContext(BanksAppContext);
    const { setAddBankData } = useContext(BanksAppControlContext);
    const { missingBankData, parseBankData, unmappedDataKeys } = useContext(PreviewParseDataContext);
    const { intoParseTransaction, setBankData } = useContext(PreviewParseDataControlContext);

    const navigate = useNavigate();

    const handleAddBank = () => {
        setAddBankData({
            isModalOpen: true,
            bankData: missingBankData?.IBAN ? { IBAN: missingBankData.IBAN } : null,
            isNeedSave: true,
        });
    };

    const handleSelect = (arg: string) => {
        if (arg === OptionsValues.ADD_NEW_BANK) {
            setBankSelectValue(null);
            return handleAddBank();
        }
        setBankSelectValue(arg);
    };

    useEffect(() => {
        if (!parseBankData) {
            return setBankSelectValue(null);
        }

        setBankSelectValue(parseBankData.id);
    }, [parseBankData]);

    useEffect(() => {
        if (!addBankData.bankData) {
            return;
        }

        const { accountNumber, bankId } = addBankData.bankData;
        const foundData = Object.values(banksData).find(
            el => el.bankId === bankId && el.accountNumber === accountNumber
        );
        if (!foundData) {
            return setBankSelectValue(null);
        }

        setBankSelectValue(foundData.id);
    }, [addBankData.bankData, banksData]);

    useEffect(() => {
        if (!bankSelectValue) {
            return;
        }

        const foundBank = Object.values(banksData).find(el => el.id === bankSelectValue);
        if (!foundBank) {
            return;
        }

        setBankData(foundBank);
    }, [bankSelectValue, banksData]);

    useEffect(() => {
        if (missingBankData) {
            setAddBankData({
                isModalOpen: false,
                bankData: missingBankData?.IBAN ? { IBAN: missingBankData.IBAN } : null,
                isNeedSave: true,
            });
        }
    }, [missingBankData]);

    const checkRequiredFields = () => {
        return !unmappedDataKeys.find(key => requiredFields.includes(key as keyof typeof transactionFieldsDict));
    };

    const isNextBtnDisabled = !!missingBankData || (!missingBankData && !bankSelectValue) || !checkRequiredFields();

    const goToAllBank = () => {
        navigate(AppRoutes.banks);
    };

    return (
        <Flex vertical>
            <Flex gap={15} align={"center"} justify={"space-between"} style={{ minHeight: 32, marginBottom: 10 }}>
                <SelectBankTitle />

                <Flex gap={10}>
                    <Button onClick={goToAllBank} icon={<CloseOutlined />}>
                        <FormattedMessage id={"app.button.cancel"} />
                    </Button>
                </Flex>
            </Flex>

            <Flex justify={"space-between"} gap={15}>
                <MatchKeyList />
                <Flex
                    gap={10}
                    align={"start"}
                    justify={"end"}
                    style={{
                        width: "100%",
                        backgroundColor: "rgb(250, 250, 250)",
                        borderRadius: 8,
                        border: "1px solid",
                        borderColor: "rgba(210, 210, 210)",
                        padding: "5px 10px",
                    }}
                >
                    {missingBankData ? (
                        <Button type={"primary"} icon={<PlusOutlined />} onClick={handleAddBank}>
                            <FormattedMessage id={"app.banks.newBank"} />
                        </Button>
                    ) : (
                        <BankSelect value={bankSelectValue} selectBank={handleSelect} />
                    )}
                    <Button
                        type={"primary"}
                        onClick={intoParseTransaction}
                        disabled={isNextBtnDisabled}
                        icon={<RightOutlined />}
                        iconPosition={"end"}
                    >
                        <FormattedMessage id={"app.button.next"} />
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};
