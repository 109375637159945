import React, { type FC } from "react";
import { DocumentInfo } from "@dms/components/DocumentInfo/DocumentInfo";
import { GroupDocumentFormWrapper as GroupDocumentForm } from "../../components/DocumentForm/GroupDocumentForm";
import { GroupFormProvider } from "./context/GroupFormContext";
import { GQL } from "@binale-tech/shared";

type TProps = {
    documentsData: GQL.IDocument[];
};

export const GroupFormModule: FC<TProps> = ({ documentsData }) => {
    return (
        <>
            <DocumentInfo />
            <GroupFormProvider documentsData={documentsData}>
                <GroupDocumentForm />
            </GroupFormProvider>
        </>
    );
};
