import React, { type FC, useContext, useMemo } from "react";
import { IHeaderParams } from "ag-grid-community";
import { ViewColumnsControl } from "@inv/components/ViewColumnsControl";
import {
    InvoiceTableContext,
    InvoiceTableControlContext
} from "@inv/components/InvoiceFormLineItemsTable/context/InvoiceTableProvider";
import { InvoiceFormTableColumns } from "@inv/components/InvoiceFormLineItemsTable/config/InvoiceFormTableColumns";
import { InvoiceColumns, TInvoicesValues } from "@inv/types";
import { Form } from "antd";
import {
    InvoiceColumnsTranslate
} from "@inv/components/InvoiceFormLineItemsTable/components/SettingsColumnHeaderComponent/constants";

export const SettingsColumnHeaderComponent: FC<IHeaderParams> = () => {
    const { columnsConfig, isOpenSettingsCol } = useContext(InvoiceTableContext);
    const { setColumnsConfig, setIsOpenSettingsCol } = useContext(InvoiceTableControlContext);
    const form = Form.useFormInstance<TInvoicesValues>();
    const isTaxIncluded = Form.useWatch(["isTaxIncluded"], form);

    const requiredTableCols = useMemo(() => {
        return isTaxIncluded
            ? InvoiceFormTableColumns.RequiredTableCols
            : [...InvoiceFormTableColumns.RequiredTableCols, InvoiceColumns.TAX];
    }, [isTaxIncluded]);

    return (
        <ViewColumnsControl
            columnsConfig={columnsConfig}
            setColumnsConfig={setColumnsConfig}
            columnTranslations={InvoiceColumnsTranslate}
            setIsOpenSettingsCol={setIsOpenSettingsCol}
            isOpenSettingsCol={isOpenSettingsCol}
            requiredColumns={requiredTableCols}
        />
    );
};
