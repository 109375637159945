import { GQL } from "@binale-tech/shared";
import React, {
    Dispatch,
    type FC,
    type PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { DmsDefaultSubType, DmsType, TBindSubType, TUploadFile, UploadFileStatus } from "@dms/types";

import { DmsDataContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { ALL_DOCUMENTS } from "@dms/configs/constants";
import { useBindSubtype } from "@dms/hooks/useBindSubtype";
import { DocumentsApi } from "@dms/scripts/DocumentsApi";
import pLimit from "p-limit";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { useGqlMutator } from "../../../../scripts/graphql/useGqlMutator";
import { ContactsContext } from "../../../../scripts/context/ContactsContext";
import { decodePdfInvoiceData } from "../../../../scripts/models/converters/DmsAccountingConverter";

type TProps = {
    onClose: () => void;
    useAutoclose?: boolean;
    onFilesAdd?: (arg: string[]) => void; // notifies uploader in the Accounting record form about newly uploaded files
    productData?: { productKey: GQL.IProductKey; selectedRecordGroup: string };
    documentType?: string[];
};

type TValue = {
    allFilesInfo: TUploadFile[];
    documentType?: { type: DmsType | typeof ALL_DOCUMENTS; subType?: string };
    queueIsEmpty: boolean;
    unsupportedFiles: { file: File; reason: string }[];
};

type TActionValue = {
    setAllFilesInfo: (arg: Pick<TUploadFile, "file" | "hash">[]) => void;
    handleFilesDrop: (arg: Pick<TUploadFile, "file" | "hash">[]) => void;
    setUnsupportedFiles: Dispatch<SetStateAction<{ file: File; reason: string }[]>>;
    deleteAllNotUniq: (processedFiles: Record<string, number>, notUniqFiles: Record<string, TUploadFile[]>) => void;
    confirmChoiceNotUniq: (processedFiles: Record<string, number>, notUniqFiles: Record<string, TUploadFile[]>) => void;
    removeNotUniqGroup: (key: string, notUniqFiles: Record<string, TUploadFile[]>) => void;
};

/**
 Context for File Uploader state
 */
export const FileUploaderContext = React.createContext({} as TValue);
export const FileUploaderControlContext = React.createContext({} as TActionValue);

/**
 Context Provider for File Uploader state
 */
export const FileUploaderProvider: FC<PropsWithChildren<TProps>> = ({
    children,
    onFilesAdd,
    productData,
    documentType,
    useAutoclose,
    onClose,
}) => {
    const [type, setType] = useState<{ type: DmsType | typeof ALL_DOCUMENTS; subType?: string }>({
        type: DmsType.new_documents,
    });

    const [allFilesInfo, setAllFilesInfo] = useState<TUploadFile[]>([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState<{ file: File; reason: string }[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [queueIsEmpty, setQueueIsEmpty] = useState(true);

    const [allUploaderFilesKeys, setAllUploaderFilesKeys] = useState<string[]>([]);

    const getSubTypeByBoundId = useBindSubtype();

    const { documentsKV } = useContext(DmsDataContext);
    const { companyGQL } = useContext(CompanyContext);
    const { contacts } = useContext(ContactsContext);
    const mutator = useGqlMutator();

    useEffect(() => {
        if (documentType && documentType.length) {
            const { type, subType } = DmsUtils.getActiveTypeAndSubType(documentType);
            let checkedSubType;
            if (subType) {
                const isDefaultSubtype =
                    subType.includes(DmsDefaultSubType.all_subTypes) || subType.includes(DmsDefaultSubType.no_subTypes);
                if (!isDefaultSubtype) {
                    checkedSubType = subType;
                }
            }

            return setType({ type, subType: checkedSubType });
        }

        if (productData) {
            const { productKey, selectedRecordGroup } = productData;
            let typeByProduct: DmsType;
            if (productKey in DmsType) {
                typeByProduct = productKey as unknown as DmsType;
            } else if (productKey === GQL.IProductKey.ErA) {
                typeByProduct = GQL.IProductKey.Er as unknown as DmsType;
            } else {
                return;
            }

            const subType = getSubTypeByBoundId(selectedRecordGroup, typeByProduct as TBindSubType);

            setType({ type: typeByProduct, subType });
        }
    }, [productData, documentType]);

    const handleFilesDrop = useCallback(
        (droppedFiles: Pick<TUploadFile, "file" | "hash">[]) => {
            if (!droppedFiles.length) {
                return;
            }

            setAllFilesInfo(prevState => {
                const newState = [...prevState];
                droppedFiles.forEach(el => {
                    if (documentsKV[el.hash]) {
                        newState.push({ ...el, status: UploadFileStatus.ALREADY_UPLOADED });
                        return;
                    }

                    const sameHashFileIdx = newState.findIndex(file => file.hash === el.hash);

                    if (sameHashFileIdx !== -1) {
                        newState[sameHashFileIdx].status = UploadFileStatus.NOT_UNIQ;
                        newState.push({ ...el, status: UploadFileStatus.NOT_UNIQ });
                        return;
                    }

                    newState.push({ ...el, status: UploadFileStatus.READY });
                });
                return newState;
            });
        },
        [documentsKV]
    );

    const setFileStatus = (key: string, data: Pick<TUploadFile, "status" | "error" | "isUploaded">) => {
        const { status, error, isUploaded } = data;
        setAllFilesInfo(prev => {
            const arr = [...prev];
            arr.forEach(el => {
                if (
                    el.hash !== key ||
                    (el.status !== UploadFileStatus.READY && el.status !== UploadFileStatus.PROGRESS)
                ) {
                    return;
                }
                el.status = status;
                el.error = error ?? false;
                el.isUploaded = isUploaded;
            });

            return arr;
        });
    };

    useEffect(() => {
        if (!allFilesInfo.length) {
            setQueueIsEmpty(true);
            return;
        }

        const doneFileArr = allFilesInfo
            .filter(f => f.status === UploadFileStatus.ALREADY_UPLOADED || f.status === UploadFileStatus.DONE)
            .map(f => f.hash);

        setAllUploaderFilesKeys(prev => {
            return [...new Set([...prev, ...doneFileArr])];
        });

        setQueueIsEmpty(
            !allFilesInfo.some(
                file => file.status === UploadFileStatus.READY || file.status === UploadFileStatus.PROGRESS
            )
        );

        if (isLoading) {
            return;
        }

        const arr = allFilesInfo.filter(el => el.status === UploadFileStatus.READY);
        if (!arr.length) {
            return;
        }

        setIsLoading(true);

        const limit = pLimit(5);

        const res = arr.map(fileData =>
            limit(async () => {
                const bytes = await fileData.file.arrayBuffer().then(buf => new Uint8Array(buf));
                const xRechnungData = await decodePdfInvoiceData(bytes, GQL.IProductKey.Er, contacts);
                return DocumentsApi.createDocument({
                    fileUploadData: { fileData, companyData: companyGQL, documentType: type, xRechnungData },
                    mutator,
                    setFileStatus,
                });
            })
        );

        Promise.all(res).then(() => {
            setIsLoading(false);
        });
    }, [allFilesInfo]);

    useEffect(() => {
        if (onFilesAdd) {
            onFilesAdd([...allUploaderFilesKeys]);
            if (useAutoclose && allUploaderFilesKeys.length) {
                onClose?.();
            }
        }
    }, [allUploaderFilesKeys.length]);

    const value: TValue = useMemo(
        () => ({
            allFilesInfo,
            queueIsEmpty,
            unsupportedFiles,
        }),
        [allFilesInfo, queueIsEmpty, unsupportedFiles]
    );

    const action: TActionValue = useMemo(() => {
        return {
            setAllFilesInfo,
            handleFilesDrop,
            setUnsupportedFiles,
            confirmChoiceNotUniq: (
                processedFiles: Record<string, number>,
                notUniqFiles: Record<string, TUploadFile[]>
            ) => {
                setAllFilesInfo(prev => {
                    let copyPrev = [...prev];
                    Object.keys(processedFiles).forEach(key => {
                        const arr = copyPrev.filter(file => file.hash !== key);
                        const notUniqArr = notUniqFiles[key];
                        const formatedStatusArr = notUniqArr.map((el, i) => {
                            if (i === processedFiles[key]) {
                                return { ...el, status: UploadFileStatus.READY };
                            }

                            return { ...el, status: UploadFileStatus.DECLINE };
                        });

                        copyPrev = [...arr, ...formatedStatusArr];
                    });

                    return copyPrev;
                });
            },
            deleteAllNotUniq: (processedFiles: Record<string, number>, notUniqFiles: Record<string, TUploadFile[]>) => {
                setAllFilesInfo(prev => {
                    let copyPrev = [...prev];
                    Object.keys(processedFiles).forEach(key => {
                        const arr = copyPrev.filter(file => file.hash !== key);
                        const notUniqArr = notUniqFiles[key];
                        const formatedStatusArr = notUniqArr.map(el => {
                            return { ...el, status: UploadFileStatus.DECLINE };
                        });

                        copyPrev = [...arr, ...formatedStatusArr];
                    });

                    return copyPrev;
                });
            },
            removeNotUniqGroup: (key: string, notUniqFiles: Record<string, TUploadFile[]>) => {
                setAllFilesInfo(prev => {
                    const arr = prev.filter(file => file.hash !== key);
                    const notUniqArr = notUniqFiles[key];
                    const formatedStatusArr = notUniqArr.map(el => {
                        return { ...el, status: UploadFileStatus.DECLINE };
                    });

                    return [...arr, ...formatedStatusArr];
                });
            },
        };
    }, [handleFilesDrop]);

    return (
        <FileUploaderContext.Provider value={value}>
            <FileUploaderControlContext.Provider value={action}>{children}</FileUploaderControlContext.Provider>
        </FileUploaderContext.Provider>
    );
};
