import React, { FC, useEffect, useRef, useState } from "react";
import { PDFSlickThumbnails, TUsePDFSlickStore, usePDFSlick } from "@pdfslick/react";
import { PDFDocument } from "pdf-lib";
import cn from "classnames";
import { Button, Flex, Input, InputRef, Progress, Space, Spin } from "antd";
import {
    DoubleLeftOutlined,
    DoubleRightOutlined,
    DownloadOutlined,
    LoadingOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from "@ant-design/icons";

import "@pdfslick/react/dist/pdf_viewer.css";
import styles from "./PdfViewer.module.css";
import "./PdfViewer.css";
import { blue } from "@ant-design/colors";

type TProps = {
    url: string;
    appCheckToken: string;
    showThumbnails?: boolean;
    fileName?: string;
    type?: string;
};

async function createPdfFromImage(imageUrl: string) {
    const isPng = imageUrl.includes(".png?");
    const imageBuffer = await fetch(imageUrl).then(response => response.arrayBuffer());
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const image = isPng ? await pdfDoc.embedPng(imageBuffer) : await pdfDoc.embedJpg(imageBuffer);
    page.setSize(image.width, image.height);
    page.drawImage(image, {
        x: 0,
        y: 0,
        width: image.width,
        height: image.height,
    });

    return pdfDoc.save();
}
type ThumbnailsProps = {
    usePDFSlickStore: TUsePDFSlickStore;
    thumbsRef: (instance: HTMLElement | null) => void;
    show: boolean;
};

const Thumbnails = ({ usePDFSlickStore, thumbsRef, show }: ThumbnailsProps) => {
    const pdfSlick = usePDFSlickStore(s => s.pdfSlick);
    const currentPage = usePDFSlickStore(s => s.pageNumber);
    // const [ref, { width }] = useMeasure<HTMLDivElement>();

    // const [cols, setCols] = useState(1);
    //
    // useEffect(() => {
    //     setCols(Math.round(width / 230));
    // }, [width]);

    return (
        <div
            style={{ width: "100%", height: "100%", overflow: "auto" }}
            className={cn("overflow-auto absolute inset-0", { invisible: !show })}
            // ref={(el) => {
            //     ref(el!);
            // }}
        >
            <div className="pr-2 relative h-full">
                <PDFSlickThumbnails {...{ thumbsRef, usePDFSlickStore }}>
                    {({ pageNumber, width, height, src, pageLabel, loaded }) => (
                        <div className="box-border pt-4 py-2 h-full w-full inline-flex justify-center">
                            <div>
                                <div className="flex justify-center">
                                    <button onClick={() => pdfSlick?.gotoPage(pageNumber)}>
                                        <div
                                            className={cn("box-border relative border", {
                                                [styles.thumbActive]: loaded && pageNumber === currentPage,
                                            })}
                                            style={{
                                                borderRadius: 5,
                                                position: "relative",
                                                width: `${width + 2}px`,
                                                height: `${height + 2}px`,
                                            }}
                                        >
                                            {src && (
                                                <img
                                                    src={src}
                                                    width={width}
                                                    height={height}
                                                    style={{ display: "block", borderRadius: 5 }}
                                                />
                                            )}
                                            {!loaded && (
                                                <div className={"h-full w-full flex justify-center items-center"}>
                                                    <Spin />
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </PDFSlickThumbnails>
            </div>
        </div>
    );
};

export const PdfViewer: FC<TProps> = React.memo(({ url, type, showThumbnails, fileName, appCheckToken }) => {
    const pageNumberRef = useRef<InputRef>(null);
    const [pageNumberInputValue, setPageNumberInputValue] = useState<string>("");
    const [loadedPerc, setLoadedPerc] = useState(0);

    const [file, setFile] = useState<string | Uint8Array>();
    const [isThumbsbarOpen, setIsThumbsbarOpen] = useState<boolean>(showThumbnails ?? false);
    const { viewerRef, usePDFSlickStore, PDFSlickViewer, thumbsRef, isDocumentLoaded } = usePDFSlick(file, {
        filename: fileName,
        getDocumentParams: {
            isEvalSupported: false,
            httpHeaders: {
                "X-Firebase-AppCheck": appCheckToken,
            },
        },
        onProgress: ({ total, loaded }) => {
            setLoadedPerc((100 * loaded) / total);
        },
    });
    const pdfSlick = usePDFSlickStore(s => s.pdfSlick);
    const pageNumber = usePDFSlickStore(s => s.pageNumber);
    const numPages = usePDFSlickStore(s => s.numPages);
    const scale = usePDFSlickStore(s => s.scale);
    const pagesRotation = usePDFSlickStore(s => s.pagesRotation);

    useEffect(() => {
        setPageNumberInputValue(String(pageNumber || 1));
    }, [pageNumber]);

    useEffect(() => {
        if (!url) {
            return;
        }
        setTimeout(() => {
            if (new URL(url).pathname.toLowerCase().endsWith(".pdf") || type === "application/pdf") {
                setFile(url);
            } else {
                createPdfFromImage(url).then(setFile);
            }
        }, 50);
        return () => setFile(undefined);
    }, [type, url]);

    return (
        <Flex vertical className={"h-full w-full"} data-testid={"pdf-viewer"}>
            <Flex
                style={{ width: "100%", borderRadius: 10 }}
                justify={"space-between"}
                align={"center"}
                className={"p-2 mb-2 box-border relative border"}
            >
                <Space>
                    {showThumbnails && (
                        <Button
                            icon={isThumbsbarOpen ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
                            shape={"circle"}
                            onClick={() => {
                                setIsThumbsbarOpen(v => {
                                    const isOpen = !v;
                                    if (isOpen) {
                                        pdfSlick?.viewer?.decreaseScale();
                                    } else {
                                        pdfSlick?.viewer?.increaseScale();
                                    }
                                    return isOpen;
                                });
                            }}
                        />
                    )}
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            const newPageNumber = parseInt(pageNumberInputValue + "", 10);
                            if (Number.isInteger(newPageNumber)) {
                                if (newPageNumber < 1) {
                                    setPageNumberInputValue("1");
                                    pdfSlick?.gotoPage(1);
                                } else if (newPageNumber > numPages) {
                                    setPageNumberInputValue(`${numPages}`);
                                    pdfSlick?.gotoPage(numPages);
                                } else {
                                    pdfSlick?.gotoPage(newPageNumber);
                                }
                            } else {
                                setPageNumberInputValue(String(pageNumber));
                            }
                        }}
                    >
                        <Input
                            disabled={numPages <= 1}
                            addonAfter={`${numPages}`}
                            min={1}
                            max={numPages}
                            style={{ width: 60 + numPages.toString().length * 10 }}
                            ref={pageNumberRef}
                            value={pageNumberInputValue}
                            onFocus={() => pageNumberRef.current.select()}
                            onChange={e => setPageNumberInputValue(e.currentTarget.value)}
                            onKeyDown={e => {
                                switch (e.key) {
                                    case "Down":
                                    case "ArrowDown":
                                        pdfSlick?.viewer.nextPage();
                                        break;
                                    case "Up":
                                    case "ArrowUp":
                                        pdfSlick?.viewer.previousPage();
                                        break;
                                    case "Enter":
                                    case "Backspace":
                                        return;
                                }
                                if (!/^\d$/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </form>
                    {!isDocumentLoaded && (
                        <Space>
                            <LoadingOutlined />
                            <Progress percent={Math.round(loadedPerc)} steps={5} size="small" strokeColor={blue[6]} />
                        </Space>
                    )}
                </Space>
                <Space.Compact>
                    <Button
                        icon={<ZoomOutOutlined />}
                        disabled={!isDocumentLoaded || scale <= 0.5}
                        onClick={() => pdfSlick?.viewer?.decreaseScale()}
                    />
                    <Input disabled style={{ width: 60 }} value={`${(scale * 100).toFixed(0)}%`} />
                    <Button
                        icon={<ZoomInOutlined />}
                        disabled={!isDocumentLoaded || scale >= 2}
                        onClick={() => pdfSlick?.viewer?.increaseScale()}
                    />
                </Space.Compact>
                <Space>
                    <Space.Compact>
                        <Button
                            icon={<RotateLeftOutlined />}
                            disabled={!isDocumentLoaded}
                            onClick={() => pdfSlick?.setRotation(pagesRotation - 90)}
                        />
                        <Button
                            icon={<RotateRightOutlined />}
                            disabled={!isDocumentLoaded}
                            onClick={() => pdfSlick?.setRotation(pagesRotation + 90)}
                        />
                    </Space.Compact>
                    <Button icon={<DownloadOutlined />} shape={"circle"} onClick={() => pdfSlick.downloadOrSave()} />
                </Space>
            </Flex>
            <div className={"flex flex-row relative h-full w-full"}>
                {file && (
                    <>
                        <div
                            className={"relative h-full"}
                            style={{ width: isThumbsbarOpen ? 150 : 0 }}
                            data-testid={"pdf-viewer-thumbnails"}
                        >
                            <Thumbnails {...{ thumbsRef, usePDFSlickStore, show: isThumbsbarOpen }} />
                        </div>
                        <div
                            className={"relative h-full"}
                            style={{
                                width: isThumbsbarOpen ? "calc(100% - 150px)" : "100%",
                            }}
                        >
                            <PDFSlickViewer {...{ viewerRef, usePDFSlickStore }} className={"pdfSlickViewer"} />
                        </div>
                    </>
                )}
            </div>
        </Flex>
    );
});
