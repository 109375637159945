import App from "./App";
import React, { ComponentProps, type FC, useContext, useEffect } from "react";
import { DmsModalModule } from "./modules/DmsModalModule";

import "./index.css";
import { DmsAppControlContext } from "@dms/types/ContextTypes";

export const DMS: FC = () => {
    const { resetAppContext } = useContext(DmsAppControlContext);

    useEffect(() => {
        return resetAppContext;
    }, [resetAppContext]);

    return <App />;
};

export const DmsModal: FC<ComponentProps<typeof DmsModalModule>> = props => {
    return <DmsModalModule {...props} />;
};

export * from "./scripts/utils/DmsUtils";
