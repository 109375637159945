import { InvoicingPaths } from "@inv/types";
import { BankingPaths } from "@banks/types/enums";
import { DmsPaths } from "@dms/configs/constants";

export enum ModuleSubGroups {
    ER = "rechnungen",
    ErA = "anzahlungen",
    Default = "default",
}

export enum AppRoutes {
    home = "/home",
    manageCompanies = "/companies",
    admin = "/admin",
    profile = "/profile",
    agb = "/agb",
    impressum = "/impressum",
    datenschutz = "/datenschutz",
    haftungsausschluss = "/haftungsausschluss",
    datevCallback = "/datevOpenId/callback",

    // auth
    authView = "/auth-actions",
    authLogin = "/login",
    authPasswordReset = "/reset",
    authNewPassword = "/new-password",
    authRegister = "/register",
    authVerifyEmail = "/verify-email",

    // { OCR } pdf - tool
    ocr = "/pdf-tool",

    // { ER } purchase invoices
    invoice = "/eingangsrechnungen",
    invoiceModule = "/eingangsrechnungen/:subGroup",
    invoiceAnalysisOpos = "/eingangsrechnungen/:subGroup/auswertung/opos",
    invoiceAnalysisAvis = "/eingangsrechnungen/:subGroup/auswertung/avis",
    invoiceAnalysisFalligkeit = "/eingangsrechnungen/:subGroup/auswertung/falligkeit",
    invoiceFestschreibung = "/eingangsrechnungen/:subGroup/festschreibung",
    invoiceTemplates = "/eingangsrechnungen/:subGroup/templates",

    // { Deb } debitors
    debitor = "/debitors",
    debitorAnalysisOpos = "/debitors/auswertung/opos",
    debitorAnalysisAvis = "/debitors/auswertung/avis",
    debitorTemplates = "/debitors/templates",

    // { KB } kassenbuch
    kassenbuch = "/kassenbuch",
    kassenbuchManage = "/kassenbuch/kassenbucher",
    kassenbuchOverview = "/kassenbuch/overview/:uuid",
    kassenbuchTemplates = "/kassenbuch/templates/:uuid",

    // { Bank } bank module
    bank = "/bank",
    manageBanks = "/bank/banks",
    bankOverview = "/bank/overview/:uuid",
    bankTemplates = "/bank/templates/:uuid",

    avm = "/avm", // not used yet - "Fixed Assets"

    // { LA }
    paySlip = "/lohnabrechnungen",
    paySlipTemplates = "/debitors/templates",

    // { FE } freieerfassung
    freieErfassung = "/freieerfassung",
    freieErfassungTemplates = "/freieerfassung/templates",

    // {POS}
    pos = "/pos",
    posTemplates = "/pos/templates",

    // { Common }
    SuSa = "/susa",
    BWA = "/bwa",
    UStVA = "/ustva",
    UStVAOverview = "/overview",
    UStVAExports = "/exports",
    accountsView = "/kontenauskunft",
    filtersAndSearchView = "/auswertung",
    recordImportExport = "/records",
    recordImport = "/records/import",
    recordImportAccounts = "/records/importAccounts",
    recordExport = "/records/export",

    manageAccount = "/kontenverwalten",
    manageAccountCreditors = "/kontenverwalten/kreditor",
    manageAccountCategories = "/kontenverwalten/kategorie",
    manageAccountTag = "/kontenverwalten/kostenstelle",
    manageAccountDebitor = "/kontenverwalten/debitor",
    contacts = "/contacts",

    dms = "/" + DmsPaths.ROOT,
    banks = "/" + BankingPaths.ROOT,
    invoicing = "/" + InvoicingPaths.ROOT,
}

export interface RouterParams extends Record<string, string> {
    uuid?: string;
    subGroup?: ModuleSubGroups;
}

export const getRoute = (routeTemplate: AppRoutes, params?: RouterParams): string => {
    let result: string = routeTemplate;
    if (params) {
        for (const property in params) {
            if (
                !!params[property] ||
                params[property] === "false" ||
                params[property] === "0" ||
                params[property] === ""
            ) {
                result = result.replace(`:${property}`, params[property]);
            }
        }
    }
    return result.replaceAll("//", "/");
};
