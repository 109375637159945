import { Button, Modal, Space, Tooltip } from "antd";
import { saveAs } from "file-saver";
import {
    CheckOutlined,
    DownloadOutlined,
    FileExcelOutlined,
    LoadingOutlined,
    WarningOutlined
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { GQL } from "@binale-tech/shared";

type Props = {
    xml: string;
    filename: string;
    onOpenErrors: () => void;
    isValidating?: boolean;
    validationErrors: GQL.IInvoiceXRechnungValidationError[];
};
export const XRechnungXmlPreviewComponent: React.FC<Props> = ({
    xml,
    filename,
    onOpenErrors,
    validationErrors,
    isValidating,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <>
            <Space.Compact>
                <Tooltip
                    title={
                        validationErrors?.length > 0
                            ? "E-Rechnung enthält Fehler oder Warnungen"
                            : isValidating
                              ? "Validierung..."
                              : "E-Rechnung Rechnung ist gültig"
                    }
                >
                    <Button
                        loading={isValidating}
                        icon={
                            isValidating ? (
                                <LoadingOutlined />
                            ) : validationErrors?.length > 0 ? (
                                <WarningOutlined />
                            ) : (
                                <CheckOutlined />
                            )
                        }
                        danger={validationErrors?.length > 0}
                        disabled={!validationErrors?.length}
                        onClick={onOpenErrors}
                    />
                </Tooltip>
                <Button icon={<FileExcelOutlined />} onClick={() => setIsModalOpen(true)} disabled={!xml}>
                    E-Rechnung XML anzeigen
                </Button>
            </Space.Compact>
            <Modal
                destroyOnClose
                title={
                    <Space>
                        <div>E-Rechnung XML</div>
                        <Button
                            onClick={() => {
                                const enc = new TextEncoder();
                                const uint8Array = enc.encode(xml);
                                const blob = new Blob([uint8Array], { type: "application/xml" });
                                saveAs(blob, filename + ".xml");
                            }}
                            icon={<DownloadOutlined />}
                        >
                            <FormattedMessage id={"app.button.download"} />
                            XML
                        </Button>
                    </Space>
                }
                footer={null}
                width={1000}
                onCancel={() => setIsModalOpen(false)}
                open={isModalOpen}
            >
                <pre>{xml}</pre>
            </Modal>
        </>
    );
};
