import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Input, Popconfirm, Row } from "antd";
import { Accordion, AccordionItem } from "@heroui/accordion";
import { Contacts, GQL } from "@binale-tech/shared";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import BinaleForm from "appearance/components/shared/form/BinaleForm";
import { Addresses } from "./Addresses";
import { BankDetails } from "./BankDetails";
import { Communication } from "./Communication";
import { ContactBasicInfo } from "./ContactBasicInfo";
import { ContactPerson } from "./ContactPerson";
import { LegalInformation } from "./LegalInformation";
import { PaymentTerms } from "./PaymentTerms";
import { CommunicationERechnung } from "@app/views/productContacts/components/communication";
import { red } from "@ant-design/colors";

enum CollapsePanelKey {
    Addresses = "addresses",
    LegalInfo = "legalInfo",
    Banks = "banks",
    Communication = "communications",
    ContactPersons = "contactPersons",
    TermsOfPayment = "termsOfPayment",
}

export interface IContactProps {
    item: Contacts.Contact | null;
    onSave: (v: Contacts.Contact) => Promise<void>;
    onCancel?: () => void;
    defaultInternalName: string;
}

export const defaultContactFormValue: Partial<Contacts.Contact> = {
    addresses: [],
    banks: [{}],
    communications: [
        { type: GQL.ICommunicationType.Email, comment: CommunicationERechnung },
        { type: GQL.ICommunicationType.Phone },
    ],
    relations: [{}, {}, {}, {}, {}] as any,
    defaultCurrencyCode: GQL.ICurrencyCode.Eur,
};

const getDefaultValue = (item?: Contacts.Contact) => {
    if (!item) {
        return { ...defaultContactFormValue };
    }
    const relations = Contacts.ContactConstants.relationTypeKontoOrder.map(type =>
        item.relations.find(r => r.type === type)
    );
    return { ...defaultContactFormValue, ...item, relations };
};

export const ContactForm: React.FC<IContactProps> = React.memo(function ContactForm({
    item,
    onCancel,
    onSave,
    defaultInternalName,
}) {
    const intl = useIntl();
    const [showConformCancel, setShowConfirmCancel] = useState(false);

    const methods = useForm({ defaultValues: getDefaultValue(item), mode: "all" });
    // const [activeKeys, setActiveKeys] = useState<CollapsePanelKey[]>([]);

    const errors = useMemo(() => methods.formState.errors, [methods.formState]);
    const isValid = useMemo(() => methods.formState.isValid, [methods.formState]);
    const dirtyFields = useMemo(() => methods.formState.dirtyFields, [methods.formState]);

    const getErrorStyle = (key: keyof typeof errors) =>
        errors[key] ? { boxShadow: `0px 0px 3px 1px ${red[5]}` } : undefined;

    const isDirty = Boolean(Object.keys(dirtyFields).length);

    return (
        <FormProvider {...methods}>
            <BinaleForm onSubmit={methods.handleSubmit(onSave)} className="ContactForm ContactForm__condensed">
                {/* <Card variant={"outlined"}> */}
                {/*     <ContactBasicInfo item={item} defaultInternalName={defaultInternalName} /> */}
                {/* </Card> */}
                <Accordion selectionMode="multiple" variant="splitted" defaultExpandedKeys={["basic"]}>
                    <AccordionItem title={"Basisinformationen"} key={"basic"}>
                        <ContactBasicInfo item={item} defaultInternalName={defaultInternalName} />
                    </AccordionItem>

                    <AccordionItem
                        title={<FormattedMessage id="app.fields.address" />}
                        key={CollapsePanelKey.Addresses}
                        data-testid={`collapse-pannel-${CollapsePanelKey.Addresses}`}
                        style={errors[CollapsePanelKey.Addresses] ? { border: `1px solid ${red[5]}` } : undefined}
                    >
                        <Addresses />
                    </AccordionItem>

                    <AccordionItem
                        title={<FormattedMessage id="app.titles.legalInformation" />}
                        key={CollapsePanelKey.LegalInfo}
                        data-testid={`collapse-pannel-${CollapsePanelKey.LegalInfo}`}
                        style={getErrorStyle(CollapsePanelKey.LegalInfo)}
                    >
                        <LegalInformation item={item} />
                    </AccordionItem>

                    <AccordionItem
                        title={<FormattedMessage id="app.titles.contacts.bankDetails" />}
                        key={CollapsePanelKey.Banks}
                        data-testid={`collapse-pannel-${CollapsePanelKey.Banks}`}
                        style={getErrorStyle(CollapsePanelKey.Banks)}
                    >
                        <BankDetails item={item} />
                    </AccordionItem>

                    <AccordionItem
                        title={<FormattedMessage id="app.titles.communication" />}
                        key={CollapsePanelKey.Communication}
                        data-testid={`collapse-pannel-${CollapsePanelKey.Communication}`}
                        style={getErrorStyle(CollapsePanelKey.Communication)}
                    >
                        <Communication />
                    </AccordionItem>

                    <AccordionItem
                        title={<FormattedMessage id="app.titles.contactPerson" />}
                        key={CollapsePanelKey.ContactPersons}
                        data-testid={`collapse-pannel-${CollapsePanelKey.ContactPersons}`}
                        style={getErrorStyle(CollapsePanelKey.ContactPersons)}
                    >
                        <ContactPerson />
                    </AccordionItem>

                    <AccordionItem
                        title={<FormattedMessage id="app.titles.termsOfPayment" />}
                        key={CollapsePanelKey.TermsOfPayment}
                        data-testid={`collapse-pannel-${CollapsePanelKey.TermsOfPayment}`}
                    >
                        <PaymentTerms />
                    </AccordionItem>
                </Accordion>

                <Controller
                    name="notes"
                    render={({ field }) => (
                        <Form.Item style={{ marginTop: 12 }}>
                            <Input.TextArea
                                rows={4}
                                placeholder={intl.formatMessage({ id: "app.fields.buchtext2" })}
                                {...field}
                            />
                        </Form.Item>
                    )}
                />

                <Row justify="space-between">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="wide-button"
                        loading={methods.formState.isSubmitting}
                        data-testid="submitBtn"
                        disabled={!isValid || !isDirty || !!Object.keys(errors).length}
                    >
                        <FormattedMessage id="app.button.save" tagName="span" />
                    </Button>
                    <Popconfirm
                        title={<FormattedMessage id="app.global.saveChanges" />}
                        onConfirm={() => {
                            setShowConfirmCancel(false);
                            methods.handleSubmit(onSave)();
                        }}
                        placement="topRight"
                        cancelButtonProps={{ autoFocus: true }}
                        okText={<FormattedMessage id="app.global.yes" />}
                        cancelText={<FormattedMessage id="app.global.no" />}
                        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                        overlayStyle={{ minWidth: 400 }}
                        open={showConformCancel}
                        data-testid="popconfirm"
                        onCancel={onCancel}
                        destroyTooltipOnHide
                    >
                        <Button
                            type="default"
                            className="wide-button"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (isDirty) {
                                    setShowConfirmCancel(true);
                                } else {
                                    onCancel();
                                }
                            }}
                        >
                            <FormattedMessage id="app.button.close" />
                        </Button>
                    </Popconfirm>
                </Row>
            </BinaleForm>
        </FormProvider>
    );
});
