import { getLineItemAmounts, TInvoiceTableItem, TInvoiceTablePinnedRow } from "@inv/types";
import { decimalFormatter } from "@dms/scripts/helpers";

export const calculateFormattedNum = (num: number) => decimalFormatter(num * 100) ?? "0,00";

export const stringToFloat = (value: string) => {
    if (typeof value === "number") {
        return value;
    }
    return Number(parseFloat(value.replace(",", ".")).toFixed(2));
};

export const invoicesListTotal = (rowData: TInvoiceTableItem[]): TInvoiceTablePinnedRow => {
    const res: TInvoiceTablePinnedRow = {
        brutto: 0,
        netto: 0,
        tax: 0,
    };
    if (!rowData?.length) {
        return res;
    }

    rowData.forEach(item => {
        if (item.isChildRow) {
            return;
        }
        item.lineItems.forEach(lineItem => {
            const { netto, brutto, tax } = getLineItemAmounts(lineItem);
            res.brutto += brutto;
            res.netto += netto;
            res.tax += tax;
        });
    });
    return res;
};

export const transformServicePeriod = (period: string): string => {
    const [startDate, endDate] = JSON.parse(period);
    return `${startDate} - ${endDate}`;
};

export const searchInAllFields = (doc: TInvoiceTableItem, term: string): boolean => {
    term = term.toLowerCase();

    return Object.values(doc).some(value => {
        const stringValue = String(value).toLowerCase();
        return stringValue.includes(term);
    });
};
