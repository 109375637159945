import { Col, RefSelectProps, Row, Space } from "antd";
import { PickerRef } from "rc-picker";
import React, { type FC, useContext, useEffect, useRef } from "react";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";

import { FormDivider } from "../../Divider/FormDivider";
import { DocumentContactField } from "../DocumentFormFields/DocumentContactField";
import { DocumentDateField } from "../DocumentFormFields/DocumentDateField";
import { DocumentDescriptionField } from "../DocumentFormFields/DocumentDescriptionField";
import { DocumentInterneNumberField } from "../DocumentFormFields/DocumentInterneNumberField";
import { DocumentInvoiceCurrencyField } from "../DocumentFormFields/DocumentInvoiceCurrencyField";
import { DocumentInvoiceNumberField } from "../DocumentFormFields/DocumentInvoiceNumberField";
import { DocumentNotesField } from "../DocumentFormFields/DocumentNotesField";
import { DocumentTypeSelectField } from "../DocumentFormFields/DocumentTypeSelectField";
import { PaymentInputs } from "./PaymentInputs";
import { VatNumberInputs } from "./VatNumberInputs";

export const InvoicesInputs: FC = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);
    const dateFieldRef = useRef<PickerRef>(null);
    const typeSelectRef = useRef<RefSelectProps>(null);

    useEffect(() => {
        if (dateFieldRef.current && !isGroupEditor) {
            dateFieldRef.current.focus();
        }
        if (typeSelectRef.current && isGroupEditor) {
            typeSelectRef.current.focus();
        }
    }, [isGroupEditor]);

    return (
        <Space size={"middle"} direction={"vertical"} style={{ width: "100%" }}>
            <DocumentTypeSelectField ref={typeSelectRef} />
            <FormDivider />
            <Row gutter={[15, 15]}>
                <Col span={12}>
                    <DocumentDateField ref={dateFieldRef} />
                </Col>
                <Col span={12}>
                    <DocumentInvoiceNumberField />
                </Col>
            </Row>

            <DocumentContactField />

            <Row>
                <Col span={24}>
                    <DocumentInvoiceCurrencyField />
                </Col>
            </Row>

            <VatNumberInputs />

            <DocumentInterneNumberField />

            <Row>
                <Col span={24}>
                    <DocumentDescriptionField />
                </Col>
            </Row>

            <PaymentInputs />

            {!isGroupEditor ? (
                <>
                    <FormDivider />
                    <Row>
                        <Col span={24}>
                            <DocumentNotesField />
                        </Col>
                    </Row>
                </>
            ) : null}
        </Space>
    );
};
