import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { MODE_RESET_PASSWORD, MODE_VERIFY_EMAIL } from "scripts/api/firebase/firebaseOodCodeEnums";

const makeQueryParams = (mode: string, oobCode: string) => `?mode=${mode}&oobCode=${encodeURIComponent(oobCode)}`;

const AuthActionsView = (): null => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    React.useEffect(() => {
        const mode = query.get("mode");
        const oobCode = query.get("oobCode");

        if (mode === MODE_VERIFY_EMAIL) {
            navigate(AppRoutes.authVerifyEmail + makeQueryParams(mode, oobCode));
            return;
        }

        if (mode === MODE_RESET_PASSWORD) {
            navigate(AppRoutes.authNewPassword + makeQueryParams(mode, oobCode));
            return;
        }
    }, [navigate, query]);
    return null;
};

export default AuthActionsView;
