import React from "react";
import { getLineItemNetto, InvoiceColumns, InvoiceFormInputTranslate, TInvoiceFormColumnsConfig } from "@inv/types";
import styles from "@inv/components/InvoiceFormLineItemsTable/styles/InvoiceTable.module.scss";
import { calculateFormattedNum } from "@inv/scripts/utils/utils";
import { ColDef, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { AbstractTableColumns } from "@app/components/shared/AgGridTable/columns/AbstractTableColumns";
import { formatDefault } from "@dms/scripts/helpers";
import {
    SettingsColumnHeaderComponent
} from "@inv/components/InvoiceFormLineItemsTable/components/SettingsColumnHeaderComponent/SettingsColumnHeaderComponent";

import {
    ActionsCellRenderer,
    ActionsCellRendererProps
} from "@inv/components/InvoiceFormLineItemsTable/components/ActionsCellRenderer/ActionsCellRenderer";
import { useLineItemOptions } from "@inv/hooks/useLineItemOptions";
import { GQL } from "@binale-tech/shared";

const UnitCellRenderer = ({ value }: ICellRendererParams<GQL.IInvoiceLineItem>) => {
    const { unitOptions } = useLineItemOptions();
    return <span>{unitOptions.find(v => v.value === value)?.label}</span>;
};
const TaxCellRenderer = ({ value }: ICellRendererParams<GQL.IInvoiceLineItem>) => {
    const { taxOptions } = useLineItemOptions();
    return <span>{taxOptions.find(v => v.value === value)?.label}</span>;
};
const NameCellRenderer = ({ data }: ICellRendererParams<GQL.IInvoiceLineItem>) => {
    return (
        <div>
            <div>{data.name}</div>
            <small>{data.description}</small>
        </div>
    );
};
const DiscountCellRenderer = ({ data }: ICellRendererParams<GQL.IInvoiceLineItem>) => {
    const { price, discount } = data;
    const discountPercent = discount / price;

    return <>{calculateFormattedNum(discountPercent)} %</>;
};

export class InvoiceFormTableColumns extends AbstractTableColumns {
    private static get dragColumn(): Readonly<ColDef> {
        return {
            headerName: "",
            field: InvoiceColumns.DRAG,
            pinned: "left",
            editable: false,
            resizable: false,
            width: 52,
            rowDrag: true,
            cellClass: [styles.cell],
        };
    }

    private static get positionColumn(): Readonly<ColDef> {
        return {
            ...this.rowNumberColumn,
            field: InvoiceColumns.POSITION,
            cellClass: [styles.cell, styles.cellCentred],
        };
    }

    private static get nameColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.NAME }),
            field: InvoiceColumns.NAME,
            wrapText: true,
            autoHeight: true,
            flex: 1,
            cellRenderer: NameCellRenderer,
            cellStyle: { justifyContent: "flex-start", whiteSpace: "pre-wrap", lineHeight: 1.4 },
            cellClassRules: {
                [styles.cell]: () => true,
            },
        };
    }

    private static get unitColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.UNIT }),
            field: InvoiceColumns.UNIT,
            cellRenderer: UnitCellRenderer,
            cellClass: [styles.cell, styles.cellStarted],
        };
    }

    private static get quantityColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.QUANTITY }),
            field: InvoiceColumns.QUANTITY,
            valueGetter: ({ data }: ValueGetterParams<GQL.IInvoiceLineItem>) => formatDefault(data.quantity * 100),
        };
    }

    private static get priceColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.PRICE }),
            field: InvoiceColumns.PRICE,
            valueGetter: ({ data }: ValueGetterParams<GQL.IInvoiceLineItem>) => formatDefault(data.price),
        };
    }

    private static get taxColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.TAX }),
            field: InvoiceColumns.TAX,
            cellRenderer: TaxCellRenderer,
        };
    }

    private static get discountColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.DISCOUNT }),
            field: InvoiceColumns.DISCOUNT,
            width: 100,
            cellRenderer: DiscountCellRenderer,
        };
    }

    private static get totalNettoColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: "app.fields.netto" }),
            field: InvoiceColumns.TOTAL_NETTO,
            editable: false,
            resizable: false,
            width: 100,
            cellRenderer: ({ data }: ICellRendererParams<GQL.IInvoiceLineItem>) => {
                return formatDefault(getLineItemNetto(data));
            },
            cellClass: [styles.cell, styles.cellEnd, styles.cellBold],
        };
    }

    private static get actionColumn(): Readonly<ColDef> {
        return {
            headerComponent: SettingsColumnHeaderComponent,
            field: InvoiceColumns.ACTIONS,
            width: 100,
            pinned: "right",
            resizable: false,
            editable: false,
            cellRenderer: ActionsCellRenderer,
            cellRendererParams: (params: ICellRendererParams<GQL.IInvoiceLineItem>): ActionsCellRendererProps => {
                return { isDeleteDisabled: params.api.getRenderedNodes().length <= 1 };
            },
            cellClass: [styles.cell, styles.cellCentred],
        };
    }

    static get InitColumnConfig(): TInvoiceFormColumnsConfig {
        return {
            [InvoiceColumns.DRAG]: true,
            [InvoiceColumns.POSITION]: true,
            [InvoiceColumns.NAME]: true,
            [InvoiceColumns.UNIT]: true,
            [InvoiceColumns.QUANTITY]: true,
            [InvoiceColumns.PRICE]: true,
            [InvoiceColumns.DISCOUNT]: true,
            [InvoiceColumns.TOTAL_NETTO]: true,
            [InvoiceColumns.TAX]: true,
            [InvoiceColumns.ACTIONS]: true,
        };
    }

    static get RequiredTableCols(): string[] {
        return [InvoiceColumns.DRAG, InvoiceColumns.NAME, InvoiceColumns.ACTIONS];
    }

    private static getColumn = (colKey: InvoiceColumns): Readonly<ColDef> => {
        switch (colKey) {
            case InvoiceColumns.DRAG:
                return { ...this.dragColumn };
            case InvoiceColumns.POSITION:
                return { ...this.positionColumn };
            case InvoiceColumns.NAME:
                return { ...this.nameColumn };
            case InvoiceColumns.UNIT:
                return { ...this.unitColumn };
            case InvoiceColumns.QUANTITY:
                return { ...this.quantityColumn };
            case InvoiceColumns.PRICE:
                return { ...this.priceColumn };
            case InvoiceColumns.DISCOUNT:
                return { ...this.discountColumn };
            case InvoiceColumns.TAX:
                return { ...this.taxColumn };
            case InvoiceColumns.TOTAL_NETTO:
                return { ...this.totalNettoColumn };
            case InvoiceColumns.ACTIONS:
                return { ...this.actionColumn };
        }
    };

    public static getColumns = (activeKeys?: string[]): ColDef[] =>
        activeKeys.map(item => this.getColumn(item as InvoiceColumns));
}
