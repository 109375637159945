export function base64StringToFile(base64: string, filename: string, type: string, lastModified?: number) {
    const binaryString = atob(base64);
    const uint8array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        uint8array[i] = binaryString.charCodeAt(i);
    }

    return new File([uint8array], filename, { lastModified: lastModified ?? Date.now(), type });
}

export function fileToBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const base64String = btoa(String.fromCharCode(...new Uint8Array(reader.result as ArrayBuffer)));
            resolve(base64String);
        };
        reader.onerror = reject;
    });
}
