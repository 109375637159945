import React, { FC, useContext, useMemo } from "react";
import { Button, Flex, Space, Switch, Typography } from "antd";
import dayjs from "dayjs";
import { TransactionSaveButtons } from "banks/components/TransactionSaveButtons";
import { getTransactionBankTitle } from "@banks/scripts/helpers";
import { TransactionRangePicker } from "@banks/components/TransactionRangePicker";
import { useNavigate } from "react-router-dom";

import { AppstoreOutlined, LeftOutlined, TableOutlined } from "@ant-design/icons";

import { FormattedMessage } from "react-intl";
import { BanksContext } from "../../../../../../scripts/context/BanksContext";
import { useDebounce } from "../../../../../../scripts/infrastructure/hooks/useDebounce";
import { AppRoutes } from "../../../../../../scripts/routing/routeConstants";
import { PreviewParseDataControlContext } from "@banks/modules/ParseTransactionModule/context/PreviewParseDataProvider";
import {
    ParseDataContext,
    ParseDataControlContext
} from "@banks/modules/ParseTransactionModule/context/ParseDataProvider";
import { BankingPaths } from "@banks/types/enums";

const { Title } = Typography;

type TProps = {
    handleSelectChecked: (arg: boolean) => void;
    handleHideIsAlreadyExist: (arg: boolean) => void;
};

export const ParseDataViewToolbar: FC<TProps> = ({ handleSelectChecked, handleHideIsAlreadyExist }) => {
    const { transactionsData } = useContext(BanksContext);
    const { intoParsePreview } = useContext(PreviewParseDataControlContext);
    const { transactionBank, selectDateRange, selectedRows, filteredRows } = useContext(ParseDataContext);
    const { setSelectDateRange, saveParseData, saveSelectedTransactions } = useContext(ParseDataControlContext);

    const navigate = useNavigate();

    const transactions = useDebounce(transactionsData[transactionBank?.id], 1000);

    const isAlreadyExistTransaction = useMemo(() => {
        const alreadyExistTransaction = Object.values(transactions || {}).map(tr => tr.csvLine);

        return filteredRows.every(row => {
            return alreadyExistTransaction.includes(row.csvLine);
        });
    }, [transactions, filteredRows]);

    const handleChange = (range: [dayjs.Dayjs, dayjs.Dayjs]) => {
        setSelectDateRange({ firstData: range[0], lastData: range[1] });
    };

    const goToPrev = () => {
        intoParsePreview();
    };
    const goToAllBank = () => {
        navigate(AppRoutes.banks);
    };
    const goToBankTransactions = () => {
        navigate(AppRoutes.banks + `/${BankingPaths.TRANSACTIONS}/${transactionBank.id}`);
    };

    return (
        <Flex vertical gap={10}>
            <Flex gap={15} align={"center"} justify={"space-between"} style={{ minHeight: 32 }}>
                {transactionBank ? (
                    <Title style={{ marginBottom: 0 }} level={5}>
                        {getTransactionBankTitle(transactionBank ? { exist: transactionBank } : undefined)}
                    </Title>
                ) : (
                    <Title style={{ marginBottom: 0 }} level={5}>
                        Bank undefined
                    </Title>
                )}

                <Flex gap={10}>
                    <Button onClick={goToPrev} disabled={isAlreadyExistTransaction} icon={<LeftOutlined />}>
                        <FormattedMessage id={"app.button.back"} />
                    </Button>
                    <Button onClick={goToBankTransactions} icon={<TableOutlined />}>
                        <FormattedMessage id={"app.global.bank"} />
                    </Button>
                    <Button onClick={goToAllBank} icon={<AppstoreOutlined />}>
                        <FormattedMessage id={"app.banks.allBanks"} />
                    </Button>
                </Flex>
            </Flex>
            <Flex justify={"space-between"} align={"center"}>
                <Flex gap={20} align={"center"} style={{ width: "100%" }}>
                    <TransactionRangePicker
                        onSelectRange={handleChange}
                        initialRange={[selectDateRange?.firstData, selectDateRange?.lastData]}
                    />
                    <Space align={"center"}>
                        <Space align={"center"}>
                            <Switch onChange={handleSelectChecked} />

                            <Typography.Text ellipsis>Checked</Typography.Text>
                        </Space>
                        <Space align={"center"}>
                            <Switch onChange={handleHideIsAlreadyExist} />

                            <Typography.Text ellipsis>Hide existing transactions</Typography.Text>
                        </Space>
                    </Space>
                </Flex>

                <TransactionSaveButtons
                    saveAll={saveParseData}
                    saveSelected={saveSelectedTransactions}
                    selectedCount={selectedRows.length}
                    isDisabled={isAlreadyExistTransaction}
                />
            </Flex>
        </Flex>
    );
};
