import React, { type FC, ReactNode, useContext, useMemo, useState } from "react";
import styles from "./SidebarMenuItemContent.module.scss";
import { ALL_DOCUMENTS } from "@dms/configs/constants";
import { DmsAppContext, DmsAppControlContext, DmsDataContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { DeleteOutlined, EditOutlined, ExportOutlined, LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { Dropdown, MenuProps, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import { OverflowTooltip } from "@ui-components/OverflowTooltip/OverflowTooltip";
import { SidebarMenuItemDropWrapper } from "../../../DragAndDrop/SidebarMenuItemDropWrapper";
import { blue, orange } from "@ant-design/colors";
import cn from "classnames";
import { useGqlMutator } from "../../../../../scripts/graphql/useGqlMutator";
import { DmsDefaultSubType, DmsType, SubTypeActions, TBindSubType } from "@dms/types";
import { CompanyContext } from "../../../../../scripts/context/CompanyContext";
import { ProductAccessUtils } from "../../../../../scripts/models/utils/ProductAccessUtils";
import { useBindData } from "@dms/hooks/useBindData";

interface IProps {
    id: string;
    name: ReactNode;
    countDraft?: number;
    countTotal?: number;
    isSelected?: boolean;
    isNode: boolean;
    typeKeyPath: string[];
    isContextMenuAvailable?: boolean;
    boundId?: string;
}

const counterColorTotal = {
    color: blue[7],
    backgroundColor: blue[0],
    border: `1px solid ${blue.primary}`,
};
const counterColorDraft = {
    color: orange[6],
    backgroundColor: orange[0],
    border: `1px solid ${orange.primary}`,
};
// const disabledColor = {
//     color: "#bfbfbf",
//     backgroundColor: "#f5f5f5",
//     border: `1px solid #bfbfbf`,
// };

export const SidebarMenuItemContent: FC<IProps> = React.memo(
    ({ id, name, countDraft, countTotal, boundId, isNode, typeKeyPath, isContextMenuAvailable }) => {
        const [dropStyle, setDropStyle] = useState({});
        const [isHover, setIsHover] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        const { isDraggingRightNow, openedMenuItemKeys } = useContext(DmsAppContext);
        const { setOpenedMenuItemKeys, bindModalDataAction, editTypeModalDataAction } =
            useContext(DmsAppControlContext);
        const { setTableEnabledColumns, setFilterConfig, deleteTypeConfig } = useContext(DmsUserSettingsContext);
        const { fetchTypes, documents } = useContext(DmsDataContext);
        const { companyGQL } = useContext(CompanyContext);
        const { kbList, bankList } = useBindData();

        const hasAccounting = ProductAccessUtils.hasCompanyAccounting(companyGQL);

        const { type, subType } = DmsUtils.getActiveTypeAndSubType(typeKeyPath);

        const mutator = useGqlMutator();

        const showCount = useMemo(() => {
            if (openedMenuItemKeys?.includes(id)) {
                return false;
            }
            if (!countDraft && !countTotal) {
                return false;
            }
            return true;
        }, [countDraft, countTotal, id, openedMenuItemKeys]);

        const isSubtypeHasDocument = useMemo(() => {
            return DmsUtils.isSubtypeHasDocument(typeKeyPath, documents);
        }, [typeKeyPath, documents]);

        // const colorSetDraft =
        //     (isTypeChangeMode || isDraggingRightNow) && (id === DmsType.new_documents || id === ALL_DOCUMENTS)
        //         ? disabledColor
        //         : counterColorDraft;
        // const colorSetTotal =
        //     (isTypeChangeMode || isDraggingRightNow) && (id === DmsType.new_documents || id === ALL_DOCUMENTS)
        //         ? disabledColor
        //         : counterColorTotal;

        const handlerDropStyle = (isDrop: boolean) => {
            setDropStyle({ fontWeight: isDrop ? 700 : "", color: isDrop ? blue[5] : "" });
        };

        const openInNewTab = () => {
            localStorage.setItem("keyPath", JSON.stringify(typeKeyPath));
            const url = window.location.href;
            window.open(url.toString(), "_blank");
        };

        const onEdit = () => {
            editTypeModalDataAction({
                isOpen: true,
                typeKeyPath,
                action: SubTypeActions.updateSubType,
                currentVal: name.toString(),
            });
        };

        const onBind = () => {
            bindModalDataAction({
                isOpen: true,
                id: subType,
                type: type as TBindSubType,
                currentVal: boundId,
            });
        };

        const onDelete = async () => {
            if (isSubtypeHasDocument) {
                return;
            }

            const value: string | undefined = name ? name.toString() : undefined;

            setIsLoading(true);

            await DmsUtils.menuSubTypeAction({
                typeKeyPath,
                action: SubTypeActions.deleteSubType,
                companyId: companyGQL.id,
                mutator,
                fetchTypes,
                setTableEnabledColumns,
                setFilterConfig,
                deleteTypeConfig,
                value,
            });
        };

        const getDropDownItems = (parentType: TBindSubType) => {
            const dropDownItems: MenuProps["items"] = [
                {
                    label: (
                        <div className={styles.dropDownItem}>
                            <span className={styles.text}>
                                <FormattedMessage id="app.button.openInNewTab" />
                            </span>
                            <ExportOutlined style={{ marginRight: 10 }} />
                        </div>
                    ),
                    onClick: arg => {
                        arg.domEvent.stopPropagation();
                        openInNewTab();
                    },
                    key: "0",
                },
            ];

            if (isContextMenuAvailable) {
                dropDownItems.push({
                    label: (
                        <div className={styles.dropDownItem}>
                            <span className={styles.text}>
                                <FormattedMessage id="app.button.edit" />
                            </span>
                            <EditOutlined style={{ marginRight: 10 }} />
                        </div>
                    ),
                    onClick: arg => {
                        arg.domEvent.stopPropagation();
                        onEdit();
                    },
                    key: "1",
                });
                dropDownItems.push({
                    label: (
                        <div className={styles.dropDownItem}>
                            <span className={styles.text}>
                                <FormattedMessage id="app.button.delete" />
                            </span>
                            <DeleteOutlined style={{ marginRight: 10 }} />
                        </div>
                    ),
                    onClick: async arg => {
                        arg.domEvent.stopPropagation();
                        await onDelete();
                    },
                    key: "2",
                    disabled: isSubtypeHasDocument || !!boundId,
                });

                if (parentType === DmsType.Bank) {
                    dropDownItems.splice(2, 0, {
                        label: (
                            <div className={styles.dropDownItem}>
                                <span className={styles.text}>
                                    {boundId ? (
                                        <FormattedMessage id="app.button.unbind" />
                                    ) : (
                                        <FormattedMessage id="app.button.bind" />
                                    )}
                                </span>
                                <LinkOutlined style={{ marginRight: 10 }} />
                            </div>
                        ),
                        onClick: arg => {
                            arg.domEvent.stopPropagation();
                            onBind();
                        },
                        key: "3",
                        disabled: !bankList.length && !boundId && hasAccounting,
                    });
                } else if (parentType === DmsType.KB) {
                    dropDownItems.splice(2, 0, {
                        label: (
                            <div className={styles.dropDownItem}>
                                <span className={styles.text}>
                                    {boundId ? (
                                        <FormattedMessage id="app.button.unbind" />
                                    ) : (
                                        <FormattedMessage id="app.button.bind" />
                                    )}
                                </span>
                                <LinkOutlined style={{ marginRight: 10 }} />
                            </div>
                        ),
                        onClick: arg => {
                            arg.domEvent.stopPropagation();
                            onBind();
                        },
                        key: "3",
                        disabled: !kbList.length && !boundId && hasAccounting,
                    });
                }
            }

            return dropDownItems;
        };

        const onMouseEnter = () => {
            if (!isHover) {
                setIsHover(true);
            }
        };

        const onMouseLeave = () => {
            setIsHover(false);
        };

        const menuItem = (
            <div
                data-testid={"dms-menuitem-" + typeKeyPath.join(",")}
                className={cn(styles.menuItemWrapper, {
                    [styles.menuItemWrapperWithPadding]: typeKeyPath.length > 1,
                })}
                style={{
                    ...dropStyle,
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onContextMenu={e => e.preventDefault()}
            >
                {boundId ? (
                    <LinkOutlined
                        style={{
                            position: "absolute",
                            color: "#52c41a",
                            left: 35,
                            top: 5,
                            fontSize: 12,
                        }}
                    />
                ) : null}
                <OverflowTooltip>{name}</OverflowTooltip>

                {showCount && (
                    <div className={styles.countWrapper}>
                        <div
                            className={cn(styles.count, styles.countLeft)}
                            title={"Entwurf: " + String(countDraft ?? 0)}
                            style={{
                                ...counterColorDraft,
                                borderRightWidth: 0,
                            }}
                        >
                            {countDraft ?? 0}
                        </div>
                        <div
                            className={cn(styles.count, styles.countLeftDiagonal)}
                            style={{ ...counterColorDraft, borderLeftWidth: 0, borderRightWidth: 0 }}
                        />
                        <div
                            className={cn(styles.count, styles.countRightDiagonal)}
                            style={{ ...counterColorTotal, borderLeftWidth: 0, borderRightWidth: 0 }}
                        />
                        <div
                            className={cn(styles.count, styles.countRight)}
                            title={"Total: " + String(countTotal ?? 0)}
                            style={{
                                ...counterColorTotal,
                                borderLeftWidth: 0,
                            }}
                        >
                            {countTotal ?? 0}
                        </div>
                    </div>
                )}
            </div>
        );

        const menuItemDropDown = (
            <Dropdown
                destroyPopupOnHide
                overlayStyle={{ color: "red" }}
                overlayClassName={`${styles.menuDropDown} ${styles.dropDown} ${styles.dropDownWrapper}`}
                menu={{ items: getDropDownItems(type as TBindSubType) }}
                trigger={["contextMenu"]}
            >
                {menuItem}
            </Dropdown>
        );

        const onDragOverHandle = () => {
            if (isDraggingRightNow) {
                if (isNode && id !== ALL_DOCUMENTS && id !== DmsType.new_documents) {
                    setOpenedMenuItemKeys([id]);
                    return;
                }
            }
        };

        if (id === DmsType.new_documents || id === DmsDefaultSubType.all_subTypes || id === ALL_DOCUMENTS) {
            return menuItemDropDown;
        }

        if (isNode) {
            return (
                <div style={{ width: "100%" }} onDragOver={onDragOverHandle}>
                    {menuItem}
                </div>
            );
        }

        return (
            <SidebarMenuItemDropWrapper
                id={id}
                typeKeyPath={typeKeyPath}
                onDropStyle={isDrop => {
                    handlerDropStyle(isDrop);
                }}
            >
                <Spin
                    spinning={isLoading}
                    indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                    style={{ position: "absolute", top: 12, bottom: 0, left: 15 }}
                />
                {menuItemDropDown}
            </SidebarMenuItemDropWrapper>
        );
    }
);
