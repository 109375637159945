import React, { FC, useContext, useMemo } from "react";
import classNames from "classnames";
import { ArrowLeftOutlined, ArrowRightOutlined, CloseCircleOutlined, ThunderboltFilled } from "@ant-design/icons";
import { Base, GQL } from "@binale-tech/shared";
import { Button, Space, Tooltip } from "antd";
import { Checkbox } from "@heroui/checkbox";
import { FormattedMessage, useIntl } from "react-intl";
import { Switch } from "@heroui/switch";

import { BuContext } from "scripts/context/BuContext";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ContactsContext } from "scripts/context/ContactsContext";
import { KontoContext } from "scripts/context/accountingData/KontoEntitiesProvider";
import { CategoryCreditorMode, CategoryCreditorModes } from "scripts/core/Product";
import { CombinedCombobox } from "../../shared/form/baseComponents";
import { ContactsAutocomplete } from "@ui-components/contacts/Autocomplete";
import { FieldLabel } from "@ui-components/FieldLabel";
import { Creditor } from "scripts/models";
import { CreditorComboboxBlock, DebitorComboboxBlock, SelectBlockAnt } from "../../shared/form/FormBlocks";
import { ProductAccessUtils } from "../../../../scripts/models/utils/ProductAccessUtils";
import {
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext,
} from "../../../../scripts/context/accountingTable/tableViewContext";
import { logger } from "scripts/infrastructure/logger";
import { useCategoriesByYearMonth } from "scripts/infrastructure/hooks";

export interface ICreditorCombobox {
    value: Creditor;
    setValue: (value: Creditor | null) => void;
}

export const CreditorCombobox: FC<ICreditorCombobox> = React.memo(function CreditorCombobox({ value, setValue }) {
    return (
        <CreditorComboboxBlock
            labelProps={{
                style: { width: 200 },
            }}
            showHint
            placeholder={<FormattedMessage id="app.global.not_selected" />}
            onChange={setValue}
            value={value}
        />
    );
});
export const DebitorCombobox: FC<ICreditorCombobox> = React.memo(function DebitorCombobox({ value, setValue }) {
    return (
        <DebitorComboboxBlock
            labelProps={{
                style: { width: 200 },
            }}
            showHint
            placeholder={<FormattedMessage id="app.global.not_selected" />}
            onChange={setValue}
            value={value}
        />
    );
});

interface KAComboboxProps {
    categoryCreditorMode?: CategoryCreditorMode;
}

const KACombobox: FC<KAComboboxProps> = React.memo(function KACombobox({ categoryCreditorMode }) {
    const { formatMessage } = useIntl();
    const { e2eFilterMode, e2eFilterEntity } = useContext(TableFiltersContext);
    const { setE2eFilterMode, setE2eFilterEntity } = useContext(TableFiltersControlContext);
    const { product, productKey } = useContext(TableViewContext);
    const { programSettingsProvider, companyGQL } = React.useContext(CompanyContext);
    const settings = React.useMemo(() => programSettingsProvider(productKey), [productKey, programSettingsProvider]);
    const { contacts } = useContext(ContactsContext);
    const { companyBuTimeframes } = React.useContext(BuContext);
    const { creditors, debitors } = React.useContext(KontoContext);
    const { userCategories } = useCategoriesByYearMonth();

    const combined: Base.IExtNum[] = React.useMemo(
        () => [].concat(userCategories, creditors, debitors),
        [userCategories, creditors, debitors]
    );

    const hasContactsAccess = ProductAccessUtils.hasCompanyProductAccess(GQL.IProductKey.Contacts, companyGQL);

    const itemsList = useMemo(() => {
        switch (categoryCreditorMode) {
            case CategoryCreditorModes.DEB:
                return debitors;
            case CategoryCreditorModes.CAT:
                return userCategories;
            case CategoryCreditorModes.CRED:
                return creditors;
            case CategoryCreditorModes.CCD:
                return combined;
            default:
                logger.error("unknown mode", categoryCreditorMode);
                return [];
        }
    }, [categoryCreditorMode, debitors, creditors, userCategories, combined]);
    const label = (
        <Switch
            isDisabled={!product.getConfig().useContact || settings.hideContacts || !hasContactsAccess}
            isSelected={e2eFilterMode === "contact"}
            onValueChange={v => setE2eFilterMode(v ? "contact" : "account")}
            size="sm"
            color="default"
        >
            {e2eFilterMode === "contact" ? (
                <FormattedMessage id={"app.titles.Contacts"} />
            ) : (
                formatMessage({
                    id:
                        categoryCreditorMode === CategoryCreditorModes.CRED
                            ? "app.fields.creditor_view"
                            : "app.fields.konto_view",
                })
            )}
        </Switch>
    );
    const onShift = (v: -1 | 1) => {
        if (!itemsList.length) {
            return;
        }
        if (!e2eFilterEntity?.account) {
            setE2eFilterEntity({ account: itemsList[0] });
            return;
        }

        const idx = itemsList.findIndex(cc => cc.equalsTo(e2eFilterEntity?.account));
        if (idx === -1) {
            setE2eFilterEntity(undefined);
            return;
        }
        let newIdx = idx + v;
        if (newIdx < 0) {
            newIdx = itemsList.length - 1;
        }
        if (newIdx >= itemsList.length) {
            newIdx = 0;
        }
        setE2eFilterEntity({ account: itemsList[newIdx] });
    };

    return (
        <FieldLabel label={label} style={{ width: 210 }} className={"GenericToolbar__KreditorComboboxBlock"}>
            {e2eFilterMode === "contact" ? (
                <ContactsAutocomplete
                    hasContactsWriteAccess={false}
                    contacts={contacts}
                    value={
                        e2eFilterEntity?.contact
                            ? { partnerId: e2eFilterEntity.contact.id, partnerName: e2eFilterEntity.contact.name }
                            : undefined
                    }
                    onChange={({ partnerId, partnerName }) => {
                        const value = partnerId || partnerName ? { id: partnerId, name: partnerName } : undefined;
                        setE2eFilterEntity(value ? { contact: value } : undefined);
                    }}
                />
            ) : (
                <Space.Compact>
                    <CombinedCombobox
                        dropdownStyle={{ maxWidth: 325 }}
                        allowClear
                        disabled={companyBuTimeframes.length === 0}
                        placeholder={formatMessage({ id: "app.global.not_selected" })}
                        items={itemsList}
                        onChange={v => setE2eFilterEntity(v ? { account: v } : undefined)}
                        value={e2eFilterEntity?.account}
                    />
                    <Button icon={<ArrowLeftOutlined />} onClick={() => onShift(-1)} />
                    <Button icon={<ArrowRightOutlined />} onClick={() => onShift(1)} />
                </Space.Compact>
            )}
        </FieldLabel>
    );
});

export interface KAProps {
    years?: number[];
    categoryCreditorMode?: CategoryCreditorMode;
    className?: string;
}

export const KASection: React.FC<KAProps> = React.memo(function KASection(props) {
    const { e2eFilterEntity, e2eFilterEntityFormFollow, e2eFilterYear } = useContext(TableFiltersContext);
    const { setE2eFilterEntityFormFollow, setE2eFilterEntity, setE2eFilterYear } =
        useContext(TableFiltersControlContext);
    const { categoryCreditorMode, years } = props;

    const { formatMessage } = useIntl();

    const isActiveFollowBlock = Boolean(e2eFilterEntity) || e2eFilterEntityFormFollow;
    const krAnsClassName = classNames("GenericToolbar__KreditorenAnsicht", props.className, {
        "GenericToolbar__KreditorenAnsicht--selected": isActiveFollowBlock,
    });

    const entityName = e2eFilterEntity?.account?.name || e2eFilterEntity?.contact?.name;

    return (
        <div className={krAnsClassName}>
            <KACombobox categoryCreditorMode={categoryCreditorMode} />
            <SelectBlockAnt
                className="GenericToolbar__FullSizeSelect"
                disabled={!isActiveFollowBlock}
                label={
                    <Checkbox
                        isSelected={e2eFilterEntityFormFollow}
                        className="GenericToolbar__Schleppend"
                        onValueChange={setE2eFilterEntityFormFollow}
                        icon={<ThunderboltFilled />}
                    >
                        <span style={{ fontSize: 12.25 }}>{formatMessage({ id: "app.fields.schleppend" })}</span>
                    </Checkbox>
                }
                options={years}
                onChange={v => setE2eFilterYear(Number(v))}
                value={e2eFilterYear}
            />
            <div className="GenericToolbar__KreditorenAnsicht__Name">
                <Tooltip placement="topLeft" title={entityName}>
                    {entityName}
                </Tooltip>
            </div>
            <div
                className="GenericToolbar__KreditorenAnsicht__Close"
                onClick={() => {
                    setE2eFilterEntity(undefined);
                    setE2eFilterEntityFormFollow(false);
                }}
            >
                <CloseCircleOutlined />
            </div>
        </div>
    );
});
