import dayjs from "dayjs";
import { FC, useCallback, useEffect, useState } from "react";
import { GQL } from "@binale-tech/shared";
import { collection, getDocs } from "firebase/firestore";
import { CsvConverter } from "../../../scripts/csv/exporters/CsvConverter";
import { CompanyMetadata, IFilterOptions, PdfToolsMetadata, SplitMode } from "../../types";
import { PdfStats } from "../../components/PdfStats";
import { downloadCsv } from "scripts/infrastructure/downloader";
import { firestore } from "scripts/api/firebase/firebase";

interface Props {
    companies: Pick<GQL.ICompany, "id" | "name">[];
}

export const PdfStatsView: FC<Props> = props => {
    const [initialData, setInitialData] = useState<CompanyMetadata[]>([]);
    const [viewData, setViewData] = useState<CompanyMetadata[]>([]);
    const [filters, setFilters] = useState<IFilterOptions>({});
    const [loading, setLoading] = useState(true);

    const getCompanyMetadata = useCallback(async () => {
        setLoading(true);

        const list = await Promise.all(
            props.companies.map(async company => {
                const colData = await getDocs(collection(firestore, "ocr/metadata/" + company.id));
                const docs = colData.empty
                    ? []
                    : colData.docs.map<PdfToolsMetadata>(doc => doc.data() as PdfToolsMetadata);
                const companyDocs: CompanyMetadata[] = docs.map((doc, docIndex) => ({
                    ...doc,
                    companyId: company.id,
                    companyName: company.name,
                    key: company.id + docIndex,
                }));
                return companyDocs;
            })
        ).then(results => {
            return results.reduce((acc, v) => acc.concat(...v), []).sort((a, b) => a.dt - b.dt);
        });

        setViewData(list);
        setInitialData(list);
        setFilters({});
        setLoading(false);
        return list;
    }, [props.companies]);

    useEffect(() => {
        getCompanyMetadata();
    }, [getCompanyMetadata]);

    useEffect(() => {
        if (!initialData || !initialData.length) {
            return;
        }
        const data = initialData.filter(doc => {
            if (filters.company && !doc.companyName?.toLowerCase().includes(filters.company.toLowerCase())) {
                return false;
            }
            if (filters.email && !doc.userEmail?.toLowerCase().includes(filters.email.toLowerCase())) {
                return false;
            }
            if (filters.modes && !filters.modes.includes(doc.mode)) {
                return false;
            }
            if (filters.dates) {
                const dt = dayjs(doc.dt);
                return dt.isAfter(filters.dates[0]) && dt.isBefore(filters.dates[1]);
            }
            return true;
        });
        setViewData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const onDownloadCsv = () => {
        const header = ["companyId", "companyName", "date", "document", "pages", "mode", "email"];
        const rows: string[][] = viewData.map(doc => [
            doc.companyId,
            doc.companyName,
            new Date(doc.dt).toString(),
            doc.name,
            doc.pages.toString(),
            SplitMode[doc.mode],
            doc.userEmail,
        ]);
        const res = CsvConverter.getCsv([header, ...rows]);
        downloadCsv(res, "report.csv");
    };

    const onChangeFilter = (newFilters: IFilterOptions) => {
        setFilters(newFilters);
    };

    return PdfStats({ data: viewData, onDownloadCsv, loading, onChangeFilter, filters });
};
