import React, { FC, memo, useContext } from "react";
import { blue } from "@ant-design/colors";
import Checkbox from "antd/es/checkbox/Checkbox";
import { Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { CarouselContext } from "@dms/modules/DocumentFormModule/context/CarouselContext";

import styles from "./CarouselHeader.module.scss";
import { GQL } from "@binale-tech/shared";
import { OcrLoadedFile } from "@pdf-tools/types";

const CarouselHeaderWrapper: FC<{ count: number; checkboxSection?: React.ReactNode }> = ({
    count,
    checkboxSection,
}) => {
    return (
        <div
            className={styles.header}
            style={{
                backgroundColor: blue[0],
                border: `1px  solid ${blue[1]}`,
            }}
        >
            <div className={styles.headerItems}>
                {checkboxSection}

                <div>
                    <Typography.Text type="secondary">
                        <FormattedMessage id="app.dms.totalDocuments" values={{ count }} />
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
};
export const CarouselHeaderDms: FC<{ documents: GQL.IDocument[] }> = memo(({ documents }) => {
    const { checkedAll, indeterminate, handleCheckedAll } = useContext(CarouselContext);

    return (
        <CarouselHeaderWrapper
            count={documents.length}
            checkboxSection={
                <div className={styles.headerCheckbox}>
                    <Checkbox
                        onChange={event => handleCheckedAll(event.target.checked, documents)}
                        checked={checkedAll}
                        indeterminate={indeterminate}
                    />
                    <Typography.Text>
                        <FormattedMessage id="app.dms.selectAll" />
                    </Typography.Text>
                </div>
            }
        />
    );
});

export const CarouselHeaderPdfTools: FC<{ files: OcrLoadedFile[] }> = memo(({ files }) => {
    return <CarouselHeaderWrapper count={files.length} />;
});
