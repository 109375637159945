import React, { useContext, useMemo } from "react";

import { BuContext } from "scripts/context/BuContext";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { Category } from "scripts/models";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { KontoContext } from "scripts/context/accountingData/KontoEntitiesProvider";
import { CategoryUtils } from "../../models/utils/CategoryUtils";

export const useDefaultCategoriesByYearMonth = () => {
    const { year } = useContext(YearPeriodContext);
    const { companyBuTimeframes } = useContext(BuContext);
    const { yearConfig } = useContext(CompanyContext);
    const skr = yearConfig?.skr;

    const defaultCategories: Map<number, Category> = useMemo(
        () => BuTaxesSKR.getBuTimeframeCategoriesByYear(skr, year, companyBuTimeframes),
        [skr, year, companyBuTimeframes]
    );

    return defaultCategories;
};

export const useCategoriesByYearMonth = () => {
    const defaultCategories = useDefaultCategoriesByYearMonth();

    const { categoryOverrides } = useContext(KontoContext);
    const { yearConfig } = useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;

    const returnCategories = useMemo(() => {
        const categories = Category.mergeCategories(defaultCategories, categoryOverrides, kontoExt);
        return {
            allCategories: categories.all,
            userCategories: categories.user,
            defaultCategories: Array.from(defaultCategories.values()),
        };
    }, [categoryOverrides, defaultCategories, kontoExt]);
    return returnCategories;
};

export const useCombinedCCDList = () => {
    const { yearConfig } = React.useContext(CompanyContext);
    const { creditors, debitors } = React.useContext(KontoContext);
    const { userCategories, defaultCategories } = useCategoriesByYearMonth();
    const defaultCategoriesFiltered = React.useMemo(
        () => defaultCategories.filter(v => !CategoryUtils.isAggregationCategory(v, yearConfig?.skr)),
        [defaultCategories, yearConfig]
    );
    const combinedCCDList = React.useMemo(() => {
        return [...userCategories, ...debitors, ...creditors];
    }, [creditors, debitors, userCategories]);
    return { defaultCategoriesFiltered, combinedCCDList };
};
