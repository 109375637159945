import React, { createContext, useContext, useMemo, useRef, useState } from "react";
import { FormWrapperContext } from "./FormWrapperContext";
import { GQL } from "@binale-tech/shared";

interface TValue {
    checkedAll: boolean;
    indeterminate: boolean;
    containerRef: React.Ref<HTMLDivElement>;
    handleCheckedItems: (doc: Pick<GQL.IDocument, "key">[]) => void;
    handleCheckedAll: (checked: boolean, doc: Pick<GQL.IDocument, "key">[]) => void;
}

export const CarouselContext = createContext({} as TValue);

export const CarouselProvider = (props: { children: React.ReactNode | null | undefined }) => {
    const [checkedAll, setCheckedAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const { checkboxCarousel, setCheckboxCarousel } = useContext(FormWrapperContext);

    const value: TValue = useMemo(
        () => ({
            checkedAll,
            indeterminate,
            containerRef,
            handleCheckedItems: (doc: GQL.IDocument[]) => {
                setIndeterminate(!(checkboxCarousel.length === 0) && checkboxCarousel.length < doc.length);
                setCheckedAll(checkboxCarousel.length === doc.length);
            },
            handleCheckedAll: (checked: boolean, doc: GQL.IDocument[]) => {
                setCheckboxCarousel(checked ? doc.map(el => el.key) : []);
                setCheckedAll(checked);
                setIndeterminate(false);
            },
        }),
        [checkboxCarousel.length, checkedAll, indeterminate, setCheckboxCarousel]
    );

    return <CarouselContext.Provider value={value}>{props.children}</CarouselContext.Provider>;
};
