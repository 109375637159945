import {
    AnsichtBtn,
    ConfirmButton,
    ExportBtn,
    JournalButton,
    PaymentStatusSelector,
    PrioritySection,
    ReviewStatusSelector,
    ShowRemovedSwitch,
} from "./buttons";

import { CreditorCombobox, DebitorCombobox, KASection } from "./comboboxes";
import { DateField, DateSection } from "./dates";
import { NumField, SearchInput } from "./inputs";

export class ToolbarComponents {
    static readonly CreditorCombobox = CreditorCombobox;
    static readonly DebitorCombobox = DebitorCombobox;
    static readonly KASection = KASection;

    static readonly DateField = DateField;
    static readonly DateSection = DateSection;

    static readonly NumField = NumField;
    static readonly SearchInput = SearchInput;

    static readonly AnsichtBtn = AnsichtBtn;
    static readonly ExportBtn = ExportBtn;
    static readonly JournalButton = JournalButton;
    static readonly ShowRemovedSwitch = ShowRemovedSwitch;
    static readonly PaymentStatusSelector = PaymentStatusSelector;
    static readonly ReviewStatusSelector = ReviewStatusSelector;
    static readonly ConfirmButton = ConfirmButton;
    static readonly PrioritySection = PrioritySection;
}
