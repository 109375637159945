import { useCallback } from "react";
import dayjs from "dayjs";
import { useFormRecordStateUpdater } from "@app/components/recordform/hooks/useFormRecordStateUpdater";

export const useFormHandlerDueDate = () => {
    const { updateEditableRecord } = useFormRecordStateUpdater();
    return useCallback(
        (v: dayjs.Dayjs) => {
            updateEditableRecord({ recordFalligkeit: v ? v.toDate() : null });
        },
        [updateEditableRecord]
    );
};
