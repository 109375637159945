import {
    AreaChartOutlined,
    AuditOutlined,
    BankOutlined,
    BookOutlined,
    CheckOutlined,
    DeleteOutlined,
    DeliveredProcedureOutlined,
    DownloadOutlined,
    EuroOutlined,
    FileAddOutlined,
    ReconciliationOutlined,
    ShopOutlined,
    SolutionOutlined,
    StopOutlined,
    UploadOutlined
} from "@ant-design/icons";
import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import {
    SidebarMenuCreateSubTypeBtn
} from "@dms/components/SidebarMenu/components/SidebarMenuCreateSubTypeBtn/SidebarMenuCreateSubTypeBtn";
import {
    SidebarMenuItemContent
} from "@dms/components/SidebarMenu/components/SidebarMenuItemContent/SidebarMenuItemContent";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { AppConfigUtils } from "@dms/scripts/utils/AppConfigUtils";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { DmsDefaultSubType, DmsType, IDocumentSubType } from "@dms/types";
import { DmsTypeOptions } from "@dms/configs/constants";
import { ItemType } from "antd/es/menu/interface";

const backgroundColorSelectedType = "#c4e5ff";

const icons: Record<DmsType, React.ReactNode> = {
    [DmsType.new_documents]: <FileAddOutlined />,
    [DmsType.Bank]: <BankOutlined />,
    [DmsType.KB]: <BookOutlined />,
    [DmsType.LA]: <SolutionOutlined />,
    [DmsType.ER]: <DownloadOutlined />,
    [DmsType.Deb]: <UploadOutlined />,
    [DmsType.taxes]: <AuditOutlined />,
    [DmsType.contracts]: <ReconciliationOutlined />,
    [DmsType.year_report]: <AreaChartOutlined />,
    [DmsType.uncategorized]: <StopOutlined />,
    [DmsType.POS]: <EuroOutlined />,
    [DmsType.FE]: <DeliveredProcedureOutlined />,
    [DmsType.company]: <ShopOutlined />,
    [DmsType.trash]: <DeleteOutlined />,
};

type BinaleMenuItemType = ItemType & {
    typeKeyPath?: string[];
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    disabled: boolean,
    typeKeyPath: string[],
    icon?: React.ReactNode,
    children?: ItemType[],
    type?: "group",
    style?: React.CSSProperties,
    onTitleClick?: () => void
): BinaleMenuItemType {
    return { key, disabled, icon, children, label, type, style, onTitleClick, typeKeyPath } as BinaleMenuItemType;
}

const getBoundId = (dbSubType: IDocumentSubType) => {
    switch (dbSubType.dmsDocumentTypeId) {
        case DmsType.Bank:
            return dbSubType.bankId;
        case DmsType.KB:
            return dbSubType.kbId;
        default:
            return undefined;
    }
};

const resetPadding = {
    paddingLeft: "0",
    paddingRight: "0",
};

export const useMenuDocumentTypes = (activeTypeChangeMode: string): BinaleMenuItemType[] => {
    const { documentTypes, documents } = useContext(DmsDataContext);
    const { activeType, isTypeChangeMode, isDraggingRightNow } = useContext(DmsAppContext);
    const { toggleOpenMenuItem } = useContext(DmsAppControlContext);

    const { type, subType } = DmsUtils.getActiveTypeAndSubType(activeType);

    return useMemo(() => {
        if (!documentTypes) {
            return [];
        }
        const { draftCount, totalCount } = DmsUtils.countCalc(documents);

        return DmsTypeOptions.map(({ value, isGroup, translationKey, isDivider, defaultSubtypes }) => {
            if (isDivider) {
                return { type: "divider", typeKeyPath: [] } as BinaleMenuItemType;
            }

            const finedType = documentTypes.find(el => el.id === value);

            if (value && isGroup) {
                let children: BinaleMenuItemType[] = [];

                if (finedType?.subTypes) {
                    const subTypes = [...finedType.subTypes];

                    const finedChildren = subTypes.map(dbSubType => {
                        const typeKeyPath = [dbSubType.id, finedType.id];
                        return getItem(
                            <SidebarMenuItemContent
                                id={dbSubType.id}
                                name={
                                    value === DmsType.taxes ? (
                                        <FormattedMessage id={`app.dms.${dbSubType.id}`} />
                                    ) : (
                                        dbSubType.name
                                    )
                                }
                                boundId={getBoundId(dbSubType)}
                                countDraft={DmsUtils.getDocumentCount([dbSubType.id, value], draftCount)}
                                countTotal={DmsUtils.getDocumentCount([dbSubType.id, value], totalCount)}
                                isSelected={subType === dbSubType.id}
                                isNode={false}
                                typeKeyPath={typeKeyPath}
                                isContextMenuAvailable={value !== DmsType.taxes}
                            />,
                            dbSubType.id,
                            false,
                            typeKeyPath,
                            activeTypeChangeMode === dbSubType.id ? <CheckOutlined /> : null,
                            undefined,
                            undefined,
                            {
                                ...resetPadding,
                                backgroundColor: subType === dbSubType.id ? backgroundColorSelectedType : "",
                            }
                        );
                    });

                    if (finedChildren) {
                        children = [...finedChildren];
                    }

                    if (defaultSubtypes) {
                        const defaultSubtypesItems = defaultSubtypes
                            .map(defaultSubtype => {
                                if (defaultSubtype.value) {
                                    const typeKeyPath = [
                                        AppConfigUtils.getDefaultSubTypeItemKey(value, defaultSubtype.value),
                                        finedType.id,
                                    ];
                                    const defaultTypeKey = [
                                        AppConfigUtils.getDefaultSubTypeItemKey(value, defaultSubtype.value),
                                    ];
                                    return getItem(
                                        <SidebarMenuItemContent
                                            id={defaultSubtype.value}
                                            name={
                                                defaultSubtype.translationKey ? (
                                                    <FormattedMessage id={defaultSubtype.translationKey} />
                                                ) : (
                                                    defaultSubtype.value
                                                )
                                            }
                                            countDraft={DmsUtils.getDocumentCount(defaultTypeKey, draftCount)}
                                            countTotal={DmsUtils.getDocumentCount(defaultTypeKey, totalCount)}
                                            isNode={false}
                                            typeKeyPath={typeKeyPath}
                                        />,
                                        AppConfigUtils.getDefaultSubTypeItemKey(value, defaultSubtype.value),
                                        defaultSubtype.value === DmsDefaultSubType.all_subTypes &&
                                            (isTypeChangeMode || isDraggingRightNow),
                                        typeKeyPath,
                                        undefined,
                                        undefined,
                                        undefined,
                                        {
                                            ...resetPadding,
                                            backgroundColor:
                                                subType ===
                                                AppConfigUtils.getDefaultSubTypeItemKey(value, defaultSubtype.value)
                                                    ? backgroundColorSelectedType
                                                    : "",
                                        }
                                    );
                                }
                                return null;
                            })
                            .filter(Boolean);

                        children = [...defaultSubtypesItems, ...children];
                    }

                    if (value !== DmsType.taxes) {
                        const typeKeyPath = [value];
                        children.push(
                            getItem(
                                <SidebarMenuCreateSubTypeBtn typeKeyPath={typeKeyPath} />,
                                `create_new_${value}`,
                                true,
                                typeKeyPath
                            )
                        );
                    }
                }

                const onTitleClick = () => {
                    toggleOpenMenuItem(value);
                };

                return getItem(
                    <SidebarMenuItemContent
                        id={value}
                        name={<FormattedMessage id={translationKey} />}
                        countDraft={DmsUtils.getDocumentCount([value], draftCount)}
                        countTotal={DmsUtils.getDocumentCount([value], totalCount)}
                        isNode={isGroup}
                        typeKeyPath={[value]}
                    />,
                    value,
                    false,
                    [value],
                    icons[value],
                    children,
                    undefined,
                    undefined,
                    onTitleClick
                );
            }

            if (value && finedType?.id) {
                const typeKeyPath = [finedType.id];
                return getItem(
                    <SidebarMenuItemContent
                        id={value}
                        name={<FormattedMessage id={translationKey} />}
                        countDraft={DmsUtils.getDocumentCount([value], draftCount)}
                        countTotal={DmsUtils.getDocumentCount([value], totalCount)}
                        isSelected={type === value}
                        isNode={false}
                        typeKeyPath={typeKeyPath}
                    />,
                    value,
                    (isTypeChangeMode || isDraggingRightNow) && value === DmsType.new_documents,
                    typeKeyPath,
                    icons[value],
                    undefined,
                    undefined,
                    { backgroundColor: type === value ? backgroundColorSelectedType : "" }
                );
            }

            return null;
        }).filter(Boolean) as ItemType[];
    }, [
        activeTypeChangeMode,
        documents,
        documentTypes,
        isDraggingRightNow,
        isTypeChangeMode,
        subType,
        toggleOpenMenuItem,
        type,
    ]);
};
