import { AuthErrorCodes, AuthError } from "firebase/auth";
import { IMessagesType } from "../../intl/translations/de";
import { logger } from "../../infrastructure/logger";

type KeyOfErrorCodesType = keyof typeof AuthErrorCodes;
export type FirebaseAuthErrorCodesType = (typeof AuthErrorCodes)[KeyOfErrorCodesType];
type ErrorTranslationKeys = {
    [K in FirebaseAuthErrorCodesType]?: keyof IMessagesType;
};

export const authErrorTranstalions: ErrorTranslationKeys = {
    [AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER]: "app.firebase.error.auth_too_many_requests",
    [AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN]: "app.firebase.error.auth_requires_recent_login",
    [AuthErrorCodes.USER_DELETED]: "app.firebase.error.auth_user_not_found",
    [AuthErrorCodes.EMAIL_EXISTS]: "app.firebase.error.duplicate_email",
    [AuthErrorCodes.WEAK_PASSWORD]: "app.firebase.error.auth_weak_password",
    [AuthErrorCodes.INVALID_EMAIL]: "app.firebase.error.auth_invalid_email",
    [AuthErrorCodes.INVALID_MFA_SESSION]: "app.2fa.token_error",
    [AuthErrorCodes.INTERNAL_ERROR]: "app.global.error",
};

export const getFirebaseErrorMessageByError = (error: AuthError) => {
    const translatedErrorId = authErrorTranstalions[error.code as never] as string;
    if (!translatedErrorId) {
        logger.log({ message: "no_translation_for_error_code", errorCode: error.code });
    }
    return translatedErrorId || authErrorTranstalions[AuthErrorCodes.INTERNAL_ERROR];
};
