import React, { useCallback } from "react";
import { Category, Creditor, Debitor, GenericRecord, Tag } from "../../../models";
import { BuTaxesSKR } from "../../../models/BuTaxUtils";
import { logger } from "../../../infrastructure/logger";
import { CompanyContext } from "../../CompanyContext";
import { BuContext } from "../../BuContext";
import { AccountMaps, KontoContext } from "../KontoEntitiesProvider";

export const useEnrichAccounts = () => {
    const { yearConfig } = React.useContext(CompanyContext);
    const { companyBuTimeframes } = React.useContext(BuContext);
    const { accountMaps } = React.useContext(KontoContext);
    const accountsRef = React.useRef<AccountMaps>(accountMaps);
    React.useEffect(() => {
        accountsRef.current = accountMaps;
    }, [accountMaps]);
    return useCallback(
        (value: GenericRecord): GenericRecord => {
            if (!yearConfig || !companyBuTimeframes) {
                return value;
            }

            const enrichCat = (category?: Category): Category => {
                if (!category) {
                    return undefined;
                }
                const { num } = category;
                if (!num) {
                    console.warn("inconsistent category", category);
                    return;
                }
                const buTimeframe = BuTaxesSKR.getBuTimeframeYearPeriod(
                    yearConfig.skr,
                    companyBuTimeframes,
                    value.year,
                    value.period
                );
                if (!buTimeframe) {
                    console.warn(
                        "buTimeframe not found :(",
                        value.date,
                        companyBuTimeframes.map(v => v.id)
                    );
                }
                const shortNum = Number(category.num.substring(0, 4));
                const defaultCat = buTimeframe.defaultCategories.get(shortNum);
                // fallback to the default cat name in case of the race condition (save record + new cat)
                const name = accountsRef.current.categoryOverridesMap.get(num)?.name || defaultCat?.name || "";
                let cat = defaultCat;
                const encoded = JSON.stringify(defaultCat);
                try {
                    cat = JSON.parse(encoded);
                } catch (e) {
                    logger.logToSentry("enrich cat error", {
                        value,
                        num,
                        shortNum,
                        defaultCat,
                        encoded,
                        tf: buTimeframe.defaultCategories?.size,
                        ucat: accountsRef.current.categoryOverridesMap?.size,
                    });
                }
                return Category.unserialize({ ...cat, num, name });
            };
            const enrichCred = (creditor?: Creditor): Creditor => {
                if (!creditor || !accountsRef.current.creditorsMap.has(creditor.num)) {
                    return creditor ?? undefined;
                }
                return accountsRef.current.creditorsMap.get(creditor.num);
            };
            const enrichDeb = (debitor?: Debitor): Debitor => {
                if (!debitor || !accountsRef.current.debitorsMap.has(debitor.num)) {
                    return debitor ?? undefined;
                }
                return accountsRef.current.debitorsMap.get(debitor.num);
            };
            const enrichTag = (tag?: Tag): Tag => {
                if (!tag || !accountsRef.current.tagsMap.has(tag.num)) {
                    return tag ?? undefined;
                }
                return accountsRef.current.tagsMap.get(tag.num);
            };

            value.category = enrichCat(value.category);
            value.creditor = enrichCred(value.creditor);
            value.debetor = enrichDeb(value.debetor);
            value.items.forEach(item => (item.category = enrichCat(item.category)));
            value.items.forEach(item => (item.creditor = enrichCred(item.creditor)));
            value.items.forEach(item => (item.debetor = enrichDeb(item.debetor)));
            value.items.forEach(item => (item.tag = enrichTag(item.tag)));
            return value;
        },
        [companyBuTimeframes, yearConfig, accountsRef]
    );
};
