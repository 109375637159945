import React, { ComponentProps, FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { Alert, Descriptions, Empty, Flex, Space, Spin, Tooltip } from "antd";
import { GQL } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";
import { formatDefault } from "@dms/scripts/helpers";
import { AlertTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import { ContactsContext } from "../../../../scripts/context/ContactsContext";
import {
    fetchAndGetInvoiceData,
    XRechnungResponse,
} from "../../../../scripts/models/converters/DmsAccountingConverter";

import styles from "./XRechnungDataPreview.module.scss";
import { gql, useApolloClient } from "@apollo/client";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { XRechnungValidationModal } from "./XRechnungValidationModal";
import { XRechnungXmlPreviewComponent } from "@app/components/shared/XRechnung/XRechnungXmlPreviewComponent";

const invoiceValidateXRechnungXml = gql`
    mutation invoiceValidateXRechnungXml($input: InvoiceValidateXRechnungXmlInput!) {
        invoiceValidateXRechnungXml(input: $input) {
            errors {
                Type
                Message
                Criterion
                Location
            }
        }
    }
`;

type Props = {
    fileId: string;
    productKey?: GQL.IProductKey;
};

export const XRechnungDataPreview: FC<Props> = ({ fileId, productKey }) => {
    const client = useApolloClient();
    const { companyGQL } = useContext(CompanyContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isErrorsModalOpen, setIsErrorsModalOpen] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const [data, setData] = useState<XRechnungResponse>(null);
    const [validationErrors, setValidationErrors] = useState<GQL.IInvoiceXRechnungValidationError[]>([]);
    const { documentsKV } = useContext(DmsDataContext);
    const { contacts } = useContext(ContactsContext);
    const file = documentsKV[fileId];
    const contactsRef = useRef(contacts);

    useEffect(() => {
        contactsRef.current = contacts;
    }, [contacts]);

    const fetchData = useCallback(
        async (file: GQL.IDocument) => {
            try {
                const docData = await fetchAndGetInvoiceData(file.fileUrl, productKey, contactsRef.current);
                setData(docData);
                setIsLoading(false);

                setIsValidating(true);
                const validationRes = await client.mutate<
                    Pick<GQL.IMutation, "invoiceValidateXRechnungXml">,
                    GQL.IMutationInvoiceValidateXRechnungXmlArgs
                >({
                    mutation: invoiceValidateXRechnungXml,
                    variables: {
                        input: {
                            xml: docData.rawXml,
                            companyId: companyGQL?.id,
                        },
                    },
                });

                setValidationErrors(validationRes?.data?.invoiceValidateXRechnungXml?.errors ?? []);
                setIsValidating(false);
            } catch (e) {
                setIsLoading(false);
                setIsValidating(false);
            }
            fetchAndGetInvoiceData(file.fileUrl, productKey, contactsRef.current).then(v => setData(v));
        },
        [productKey, client, companyGQL?.id]
    );

    useEffect(() => {
        if (!file) {
            return;
        }
        fetchData(file);
    }, [file, fetchData]);

    if (!file) {
        return null;
    }

    const items: ComponentProps<typeof Descriptions>["items"] = [
        {
            key: "supplier",
            span: 2,
            label: <FormattedMessage id="app.titles.contacts.partner" />,
            children: (
                <div>
                    <Flex justify={"space-between"}>
                        <span>
                            Name <sup>BT-27</sup>
                        </span>
                        {data?.raw?.supplier?.name && <span>{data.raw.supplier.name}</span>}
                    </Flex>
                    <Flex justify={"space-between"}>
                        <span>
                            <FormattedMessage id={"app.fields.vatNb"} /> <sup>BT-31</sup>
                        </span>
                        {data?.raw?.supplier?.vatId && <span>{data.raw.supplier.vatId}</span>}
                    </Flex>
                    <br />
                    <Flex justify={"space-between"}>
                        <div>
                            <div>
                                <b>
                                    <FormattedMessage id={"app.fields.address"} />{" "}
                                </b>
                                <sup>BG-5</sup>
                            </div>
                            {data?.raw?.supplier?.addressLines && (
                                <>
                                    {data.raw.supplier.addressLines.map(s => (
                                        <div key={s}>{s}</div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div>
                            <div>
                                <b>
                                    <FormattedMessage id={"app.titles.communication"} />{" "}
                                </b>
                                <sup>BG-6</sup>
                            </div>
                            {data?.raw?.supplier?.communications && (
                                <>
                                    {data.raw.supplier.communications.map(s => (
                                        <div key={s}>{s}</div>
                                    ))}
                                </>
                            )}
                        </div>
                    </Flex>
                </div>
            ),
        },
        {
            key: "buyer",
            span: 2,
            label: <FormattedMessage id="app.titles.contacts.client" />,
            children: (
                <div>
                    <Flex justify={"space-between"}>
                        <span>
                            Name <sup>BT-44</sup>
                        </span>
                        {data?.raw?.buyer?.name && <span>{data.raw.buyer.name}</span>}
                    </Flex>
                    <Flex justify={"space-between"}>
                        <span>
                            <FormattedMessage id={"app.fields.vatNb"} /> <sup>BT-48</sup>
                        </span>
                        {data?.raw?.buyer?.vatId && <span>{data.raw.buyer.vatId}</span>}
                    </Flex>
                    <br />
                    <Flex justify={"space-between"}>
                        <div>
                            <div>
                                <b>
                                    <FormattedMessage id={"app.fields.address"} />
                                </b>
                                <sup>BG-8</sup>
                            </div>
                            {data?.raw?.buyer?.addressLines && (
                                <>
                                    {data.raw.buyer.addressLines.map(s => (
                                        <div key={s}>{s}</div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div>
                            <div>
                                <b>E-mail</b>
                                <sup>BT-49</sup>
                            </div>
                            {data?.raw?.buyer?.email && <div>{data?.raw?.buyer?.email}</div>}
                        </div>
                    </Flex>
                </div>
            ),
        },
        {
            key: "date",
            span: 1,
            label: <FormattedMessage id={"app.fields.invoice"} />,
            children: data?.generated?.document && (
                <div>
                    <Flex justify={"space-between"}>
                        <span>
                            Nummer <sup>BT-1</sup>
                        </span>
                        <span>{data.generated.document.documentNumber}</span>
                    </Flex>
                    <Flex justify={"space-between"}>
                        <span>
                            <FormattedMessage id={"app.fields.date"} />
                            <sup>BT-2</sup>
                        </span>
                        <span>{data.generated.document.documentDate}</span>
                    </Flex>
                    {data.raw.deliveryDate && (
                        <Flex justify={"space-between"}>
                            <span>
                                <FormattedMessage id={"app.invoice.deliveryDate"} />
                                <sup>BT-72</sup>
                            </span>
                            <span>{data.raw.deliveryDate}</span>
                        </Flex>
                    )}
                </div>
            ),
        },
        {
            key: "payment",
            span: 3,
            label: "Zahlungsdetails",
            children: (
                <div>
                    {data?.raw?.supplier?.IBANAccountName && (
                        <Flex justify={"space-between"}>
                            <span>
                                Kontoinhaber <sup>BT-85</sup>
                            </span>
                            {data.raw.supplier.IBANAccountName}
                        </Flex>
                    )}
                    {data?.raw?.supplier?.IBAN && (
                        <Flex justify={"space-between"}>
                            <span>
                                IBAN <sup>BT-84</sup>
                            </span>
                            {data.raw.supplier.IBAN}
                        </Flex>
                    )}
                    {data?.raw?.supplier?.BIC && (
                        <Flex justify={"space-between"}>
                            <span>
                                BIC <sup>BT-86</sup>
                            </span>
                            {data.raw.supplier.BIC}
                        </Flex>
                    )}
                    {data?.raw?.paymentReference && (
                        <Flex justify={"space-between"}>
                            <span>
                                Verwendungszweck <sup>BT-83</sup>
                            </span>
                            {data.raw.paymentReference}
                        </Flex>
                    )}
                    {data?.raw?.paymentType === "1" && <span>Zahlungsmethode wurde nicht angegeben</span>}
                </div>
            ),
        },
        {
            key: "payment_terms",
            span: 4,
            label: (
                <span>
                    Zahlungsbedingungen <sup>BT-20</sup>
                </span>
            ),
            children: data?.raw?.lines && (
                <div>
                    {data?.raw?.paymentDescription && <p>{data.raw.paymentDescription}</p>}
                    {data?.raw?.paymentDueDate && (
                        <Flex justify={"space-between"} gap={10}>
                            <div style={{ textWrap: "nowrap" }}>
                                Fälligkeitsdatum&nbsp;<sup>BT-9</sup>
                            </div>
                            <div>{data.raw.paymentDueDate}</div>
                        </Flex>
                    )}
                </div>
            ),
        },
        {
            key: "r_pos",
            span: 4,
            label: (
                <span>
                    Rechnungspositionen <sup>BG-25</sup>
                </span>
            ),
            children: data?.raw?.lines && (
                <div>
                    {data.raw.lines.map((l, index) => (
                        <Flex
                            justify={"space-between"}
                            key={"position" + index}
                            style={{ paddingBottom: 2, paddingTop: 2, borderBottom: "1px solid #ebebeb" }}
                        >
                            <span>{l.name}</span>
                            <code>
                                {formatDefault(l.netto)} | {l.tax}% | BU{l.bu} |{" "}
                                {formatDefault((l.netto * (100 + l.tax)) / 100)}
                            </code>
                        </Flex>
                    ))}
                </div>
            ),
        },
        {
            key: "brutto",
            span: 1,
            label: (
                <span>
                    <b>
                        <FormattedMessage id={"app.fields.brutto"} />{" "}
                    </b>
                    <sup>BT-112</sup> / <FormattedMessage id={"app.fields.currency"} /> <sup>BT-5</sup>
                </span>
            ),
            children: data?.generated?.record && (
                <p>
                    {formatDefault(data.generated.record.getBrutto())} {data.raw.currency ?? ""}
                </p>
            ),
        },
        {
            key: "rec_pos",
            span: 3,
            label: (
                <span>
                    <strong>Buchungspositionen</strong> <sup>BG-23</sup>
                </span>
            ),
            children: data?.generated?.record && (
                <div>
                    {data.generated?.record.items.map((i, index) => (
                        <Flex
                            justify={"space-between"}
                            key={"position_rec" + index}
                            style={{ paddingBottom: 2, paddingTop: 2, borderBottom: "1px solid #ebebeb" }}
                        >
                            <div>
                                {i.text}{" "}
                                <Tooltip
                                    title={
                                        <div>
                                            <Flex justify={"space-between"}>
                                                <span>
                                                    Netto <sup>BT-116</sup>
                                                </span>
                                                <div style={{ width: 10 }}></div>
                                                <span>{formatDefault(data.raw.taxItems[index].nettoAmount)}</span>
                                            </Flex>
                                            <Flex justify={"space-between"}>
                                                <span>
                                                    % <sup>BT-119</sup> / Steuer <sup>BT-117</sup>
                                                </span>
                                                <div style={{ width: 10 }}></div>
                                                <span>
                                                    {data.raw.taxItems[index].tax}% /{" "}
                                                    {formatDefault(data.raw.taxItems[index].taxAmount)}
                                                </span>
                                            </Flex>
                                        </div>
                                    }
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                                {i.text2 && (
                                    <>
                                        <br />
                                        <small>{i.text2}</small>
                                    </>
                                )}
                            </div>
                            <code>
                                BU{i.bu} | {formatDefault(i.brutto)}
                            </code>
                        </Flex>
                    ))}
                </div>
            ),
        },
    ].filter(Boolean);
    return (
        <div className={styles.previewInvoice}>
            <Spin spinning={isLoading}>
                {data && (
                    <Space direction={"vertical"} style={{ width: "100%" }}>
                        <Flex justify={"space-between"} align={"center"} gap={8}>
                            <div>
                                <b>
                                    <FormattedMessage id={"app.titles.Contact"} />:
                                </b>
                                <span>&nbsp;</span>
                                {data?.generated?.record?.partner ? (
                                    <span>{data.generated.record.partner.name}</span>
                                ) : (
                                    <span>N/A</span>
                                )}
                            </div>
                            {data?.info && (
                                <div>
                                    <Alert
                                        message={
                                            <Space>
                                                <b>{data.info.versionText}</b>
                                                {data.info.isDeprecated && (
                                                    <>
                                                        <AlertTwoTone twoToneColor={"#dd1212"} />
                                                        <span>Achtung, dieses Format ist veraltet!</span>
                                                    </>
                                                )}
                                            </Space>
                                        }
                                        type={data.info.isDeprecated ? "error" : "info"}
                                    />
                                    {}
                                </div>
                            )}
                            <XRechnungXmlPreviewComponent
                                xml={data.rawXml}
                                isValidating={isValidating}
                                validationErrors={validationErrors}
                                onOpenErrors={() => setIsErrorsModalOpen(true)}
                                filename={[
                                    data.generated.document.documentDate.split(".").reverse().join("."),
                                    data.generated.document.documentNumber,
                                ]
                                    .filter(Boolean)
                                    .join("_")}
                            />
                        </Flex>
                        <Descriptions items={items} layout="vertical" bordered size={"small"} column={{ xs: 4 }} />
                    </Space>
                )}
                {!data && <Empty />}
            </Spin>
            <XRechnungValidationModal
                open={isErrorsModalOpen}
                onCancel={() => setIsErrorsModalOpen(false)}
                errors={validationErrors}
            />
        </div>
    );
};
