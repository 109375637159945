import React, { useContext, useState } from "react";
import { FileProtectOutlined, FundOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { Button as NUIButton } from "@heroui/button";
import { motion } from "framer-motion";

import classNames from "classnames";
import logo from "../assets/NU-Logo.png";
import { AppRoutes } from "scripts/routing/routeConstants";
import { AppSettingsContext } from "scripts/context/AppSettingsProvider";
import { Clock } from "../components/shared/appearance/Clock";
import { CompanyContext } from "scripts/context/CompanyContext";
import { GQL } from "@binale-tech/shared";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";
import { ProductDetails, ProductGroups, ProductKey, ProductKeys } from "scripts/models/Product";
import { ReadinessContext } from "scripts/context/DataReadyness";
import { UserContext } from "scripts/context/UserProvider";
import "./HomeView.css";
import { useIubendaCookieSolution } from "@app/views/auth/useIubendaCookieSolution";

interface ComponentButtonProps {
    link: string;
    product: ProductKey;
}

const item = {
    hidden: { y: 50, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    },
};
const ComponentButton: React.FC<React.PropsWithChildren<ComponentButtonProps>> = props => {
    const { companyGQL } = React.useContext(CompanyContext);
    const { isReady } = React.useContext(ReadinessContext);
    const navigate = useNavigate();
    const user = React.useContext(UserContext);
    const disabled = !isReady || !ProductAccessUtils.canRead(props.product, companyGQL, user);

    return (
        <motion.div variants={item}>
            <NUIButton
                size="lg"
                variant="faded"
                color="default"
                className="HomeView_button"
                isDisabled={disabled}
                onClick={() => navigate(props.link)}
            >
                {props.children}
            </NUIButton>
        </motion.div>
    );
};
const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.05,
        },
    },
};

const HomeView = () => {
    const [isModalBuch, setModalBuch] = useState<boolean>(false);
    const { isLoaded, companyGQL, yearConfig } = React.useContext(CompanyContext);
    const { useClassicImage } = useContext(AppSettingsContext);
    useIubendaCookieSolution();

    return (
        <div>
            <div
                className={classNames("HomeView__bg", {
                    "HomeView__bg--classic": useClassicImage,
                })}
            />
            <div className="HomeView__container">
                <motion.div variants={container} initial="hidden" animate="visible">
                    <motion.div variants={item}>
                        <NUIButton
                            size="lg"
                            variant="faded"
                            color="default"
                            className="HomeView_button"
                            onClick={() => setModalBuch(true)}
                            isDisabled={!ProductAccessUtils.hasCompanyAccounting(companyGQL)}
                        >
                            <FundOutlined />
                            <FormattedMessage id="app.titles.accounting" tagName="span" />
                        </NUIButton>
                    </motion.div>
                    <ComponentButton link={ProductDetails.details[ProductKeys.OCR].link} product={ProductKeys.OCR}>
                        {ProductDetails.details[ProductKeys.OCR].icon}
                        <FormattedMessage id={"app.titles." + ProductDetails.details[ProductKeys.OCR].pk} />
                    </ComponentButton>
                    <ComponentButton
                        link={ProductDetails.details[ProductKeys.Contacts].link}
                        product={ProductKeys.Contacts}
                    >
                        {ProductDetails.details[ProductKeys.Contacts].icon}
                        <FormattedMessage id={"app.titles." + ProductDetails.details[ProductKeys.Contacts].pk} />
                    </ComponentButton>
                    <ComponentButton link={ProductDetails.details[ProductKeys.DMS].link} product={ProductKeys.DMS}>
                        <FileProtectOutlined /> <FormattedMessage id="app.titles.DMS" tagName="span" />
                    </ComponentButton>
                    <ComponentButton link={ProductDetails.details[ProductKeys.Banks].link} product={ProductKeys.Banks}>
                        <FileProtectOutlined /> <FormattedMessage id={"app.titles.Banks"} tagName="span" />
                    </ComponentButton>
                    <ComponentButton
                        link={ProductDetails.details[ProductKeys.Invoices].link}
                        product={ProductKeys.Invoices}
                    >
                        <FileProtectOutlined /> <FormattedMessage id={"app.titles.Invoices"} tagName="span" />
                    </ComponentButton>
                </motion.div>
                <div style={{ flexGrow: 1 }} />
                <div>
                    <Clock />
                </div>
            </div>
            {companyGQL?.name.startsWith("NU ") && (
                <img src={logo} alt="logotype" style={{ width: 128, bottom: 15, right: 10, position: "absolute" }} />
            )}
            {companyGQL?.logo && (
                <img
                    src={companyGQL.logo}
                    width={200}
                    style={{ width: 128, bottom: 15, right: 10, position: "absolute" }}
                />
            )}
            <div className="HomeView__bottomLine">
                <Link to={AppRoutes.impressum}>Impressum</Link>
                <Link to={AppRoutes.haftungsausschluss}>Haftungsausschluss</Link>
                <Link to={AppRoutes.agb}>AGB</Link>
                <Link to="https://www.iubenda.com/privacy-policy/52953099" target="_blank" title="Datenschutzerklärung">
                    Datenschutzerklärung
                </Link>
                <Link
                    to="https://www.iubenda.com/privacy-policy/52953099/cookie-policy"
                    target="_blank"
                    title="Cookie-Richtlinie"
                >
                    Cookie-Richtlinie
                </Link>
                <a href="#" className="iubenda-cs-preferences-link">
                    Ihre Privatsphäre
                </a>
                <span>{import.meta.env.VITE_VERSION || "local"}</span>
            </div>
            <Modal
                open={isModalBuch}
                onCancel={() => setModalBuch(false)}
                footer={null}
                className="HomeView__Modal"
                style={{ top: 50 }}
                width={800}
                destroyOnClose
            >
                <motion.div
                    style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}
                    variants={container}
                    initial="hidden"
                    animate="visible"
                >
                    <div>
                        {ProductDetails.getGroup(ProductGroups.Buch).map(d => (
                            <ComponentButton key={d.pk} link={d.link} product={d.pk}>
                                {d.icon}
                                <FormattedMessage id={"app.titles." + d.pk} />
                            </ComponentButton>
                        ))}
                    </div>
                    <div>
                        <ComponentButton link={AppRoutes.accountsView} product={ProductKeys.AccountingCommon}>
                            <FormattedMessage id="app.titles.KA" tagName="span" />
                        </ComponentButton>
                        <ComponentButton link={AppRoutes.SuSa} product={ProductKeys.AccountingCommon}>
                            <FormattedMessage id="app.titles.SuSa" tagName="span" />
                        </ComponentButton>
                        <ComponentButton link={AppRoutes.BWA} product={ProductKeys.AccountingCommon}>
                            BWA
                        </ComponentButton>
                        {yearConfig?.taxation !== GQL.ICompanyTaxation.Kust && (
                            <ComponentButton link={AppRoutes.UStVA} product={ProductKeys.AccountingCommon}>
                                <FormattedMessage id="app.titles.Vat" tagName="span" />
                            </ComponentButton>
                        )}
                        <ComponentButton link={AppRoutes.recordImportExport} product={ProductKeys.AccountingCommon}>
                            <FormattedMessage id="app.titles.import_export" tagName="span" />
                        </ComponentButton>
                        <ComponentButton link={AppRoutes.filtersAndSearchView} product={ProductKeys.AccountingCommon}>
                            <FormattedMessage id="app.titles.filtern" tagName="span" />
                        </ComponentButton>
                        <ComponentButton link={AppRoutes.manageAccount} product={ProductKeys.AccountingCommon}>
                            <FormattedMessage id="app.titles.kontenverwaltung" tagName="span" />
                        </ComponentButton>
                    </div>
                </motion.div>
            </Modal>
        </div>
    );
};

export default HomeView;
