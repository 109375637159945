import React, { useRef } from "react";
import cx from "classnames";
import { Form } from "antd";
import { focusNextElement } from "scripts/infrastructure/helpers/focus";

import "./style.css";

const isSubmitBtn = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const target = e.target as Element;
    return target.nodeName.toUpperCase() === "BUTTON" && target.getAttribute("type") === "submit";
};

interface Props extends React.PropsWithChildren<React.HTMLProps<HTMLFormElement>> {
    disableEnterNavigation?: boolean;
}

export const useFormKeyHandlers = ({
    disableEnterNavigation,
    onKeyUp,
    onKeyDown,
    onSubmit,
    formClassName,
}: Pick<Props, "disableEnterNavigation" | "onKeyUp" | "onKeyDown" | "onSubmit"> & { formClassName: string }) => {
    const element = useRef<unknown>(null);
    const handleEventPropagation = React.useCallback(
        (e: React.KeyboardEvent<HTMLFormElement>) => {
            if (e.key === "Enter" && !disableEnterNavigation && !isSubmitBtn(e)) {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        [disableEnterNavigation]
    );
    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLFormElement>) => {
            element.current = e.target;
            if (onKeyDown) {
                onKeyDown(e);
            }
            handleEventPropagation(e);
        },
        [handleEventPropagation, onKeyDown]
    );
    const handleKeyUp = React.useCallback(
        (e: React.KeyboardEvent<HTMLFormElement>) => {
            if (onKeyUp) {
                onKeyUp(e);
            }
            // if during submitting focus has shifted.
            if (element.current !== e.target) {
                return;
            }
            // allow to go back from submit btn
            if (isSubmitBtn(e) && !e.shiftKey) {
                return;
            }
            if (e.key === "Enter" && !disableEnterNavigation) {
                focusNextElement({ goBack: e.shiftKey, parentSelector: `.${formClassName}` });
                e.preventDefault();
                e.stopPropagation();
            }
        },
        [disableEnterNavigation, onKeyUp]
    );
    const handleSubmit = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.stopPropagation();
            e.preventDefault();
            if (onSubmit) {
                onSubmit(e);
            }
        },
        [onSubmit]
    );
    return React.useMemo(
        () => ({ handleKeyUp, handleKeyDown, handleEventPropagation, handleSubmit }),
        [handleEventPropagation, handleKeyDown, handleKeyUp, handleSubmit]
    );
};

const BinaleForm = ({ children, className, disableEnterNavigation, onKeyDown, onKeyUp, onSubmit, ...rest }: Props) => {
    const element = useRef<unknown>(null);

    const handleKeyUp = React.useCallback(
        (e: React.KeyboardEvent<HTMLFormElement>) => {
            if (onKeyUp) {
                onKeyUp(e);
            }
            // if during submitting focus has shifted.
            if (element.current !== e.target) {
                return;
            }
            // allow to go back from submit btn
            if (isSubmitBtn(e) && !e.shiftKey) {
                return;
            }
            if (e.key === "Enter" && !disableEnterNavigation) {
                focusNextElement({ goBack: e.shiftKey, parentSelector: ".BinaleForm" });
                e.preventDefault();
                e.stopPropagation();
            }
        },
        [disableEnterNavigation, onKeyUp]
    );

    const handleEventPropagation = React.useCallback(
        (e: React.KeyboardEvent<HTMLFormElement>) => {
            if (e.key === "Enter" && !disableEnterNavigation && !isSubmitBtn(e)) {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        [disableEnterNavigation]
    );

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLFormElement>) => {
            element.current = e.target;
            if (onKeyDown) {
                onKeyDown(e);
            }
            handleEventPropagation(e);
        },
        [handleEventPropagation, onKeyDown]
    );

    const handleSubmit = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.stopPropagation();
            e.preventDefault();
            if (onSubmit) {
                onSubmit(e);
            }
        },
        [onSubmit]
    );

    return (
        <form
            {...rest}
            className={cx("ant-form ant-form-vertical BinaleForm", className, {
                "BinaleForm--reducedMarginBottom": true,
            })}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onSubmit={handleSubmit}
            onKeyPress={handleEventPropagation}
        >
            <Form layout="vertical" component="div">
                {children}
            </Form>
        </form>
    );
};

export default React.memo(BinaleForm);
