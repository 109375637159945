import { AllRecordsData, RecordsCtxData } from "../../context/accountingData/RecordsCtx";
import { Base, Utils } from "@binale-tech/shared";
import { GenericRecord } from "../GenericRecord";
import { IGenericRecord } from "../Interfaces";
import { PaymentPrototype } from "../Payment";
import { PaymentUtils } from "./PaymentUtils";
import { PaymentsCtxData } from "../../context/accountingData/PaymentsProvider";

interface IGetBindedPayment {
    representationRecordKey: string;
    allRecords: AllRecordsData;
    paymentsRecordRelation: PaymentsCtxData["recordRelation"];
}

interface IGetReprRecordPaymentProto {
    representationRecord: IGenericRecord;
    allRecords: AllRecordsData;
    paymentsRecordRelation: PaymentsCtxData["recordRelation"];
}

type RecordsType = Pick<RecordsCtxData, "recordsER" | "recordsDeb" | "recordsAZ">;
type IFilterPayments = {
    paymentRepresentationRecord: GenericRecord;
    payments: PaymentsCtxData;
    useInvoiceNum?: boolean;
};

interface IGetFilterPayments extends RecordsType, IFilterPayments {}

export class PaymentBindingUtils {
    static getConnectedPaymentSourceRecord = ({
        representationRecordKey,
        allRecords,
        paymentsRecordRelation,
    }: IGetBindedPayment) => {
        if (!representationRecordKey) {
            return { record: null, payment: null };
        }
        // try to find payment binding in payments table:
        const relatedPayments = paymentsRecordRelation.get(representationRecordKey);
        if (!relatedPayments?.length) {
            return { record: null, payment: null };
        }
        if (relatedPayments.length > 1) {
            // todo: this is not ok, not error here is causing a corner case failure
            //  when one payment is created and previous is not gone yet, so we return null
            return { record: null, payment: null };
        }
        const [payment] = relatedPayments;
        const record = allRecords.map.get(payment.sourceRecordKey);
        if (!record) {
            throw new Error("error, sourceRecord not found, recordKey: " + payment.sourceRecordKey);
        }
        return { record, payment };
    };

    static getRepresentationRecordPaymentProto = ({
        representationRecord,
        allRecords,
        paymentsRecordRelation,
    }: IGetReprRecordPaymentProto): PaymentPrototype => {
        if (!representationRecord) {
            return null;
        }
        const isProductPaymentRepresentation = Utils.PaymentUtils.isProductPaymentRepresentation(
            representationRecord.getProductKey()
        );
        if (!isProductPaymentRepresentation) {
            return null;
        }
        const { record: sourceRecord, payment } = PaymentBindingUtils.getConnectedPaymentSourceRecord({
            allRecords,
            paymentsRecordRelation,
            representationRecordKey: representationRecord.key,
        });
        return PaymentUtils.toPrototype(payment, representationRecord.getProductKey(), sourceRecord?.getProductKey());
    };

    protected static getRepresentationRecordBelegfeld1Set = (paymentRepresentationRecord: GenericRecord) => {
        const set = new Set(paymentRepresentationRecord.items.map(v => v.belegfeld1).filter(Boolean));
        if (paymentRepresentationRecord.num) {
            // in case of FE
            set.add(paymentRepresentationRecord.num);
        }
        return set;
    };
    protected static getRepresentationRecordCredDebSet = (paymentRepresentationRecord: GenericRecord) => {
        const set = new Set(
            paymentRepresentationRecord.items
                .filter(v => v.creditor || v.debetor)
                .map(v => v.getCategoryCreditor().getExtNum(Base.CompanyKontoExtMax))
        );
        const recordCred = paymentRepresentationRecord.creditor || paymentRepresentationRecord.debetor;
        if (recordCred) {
            set.add(recordCred.getExtNum(Base.CompanyKontoExtMax));
        }
        return set;
    };

    protected static matchingPaymentSourceFilter = (
        mv: GenericRecord,
        itemInvoiceNumbers: Set<string>,
        credDebSet: Set<string>,
        payments: IGetFilterPayments["payments"],
        useInvoiceNum: boolean
    ) => {
        // check by konto number
        const testCC = mv.getRecordCategoryCreditor().getExtNum(Base.CompanyKontoExtMax);
        if (!credDebSet.has(testCC)) {
            return false;
        }
        // check by invoice number
        if (useInvoiceNum && itemInvoiceNumbers.size > 0 && !itemInvoiceNumbers.has(mv.num)) {
            return false;
        }
        // check if invoice wasn't paid or is avis
        const paymentRelationsMV = payments.recordRelation.get(mv.key);
        if (0 === mv.getOpenBrutto(paymentRelationsMV)) {
            return false;
        }
        return true;
    };

    static getMatchingPaymentSourceRecords = ({
        paymentRepresentationRecord,
        payments,
        recordsDeb,
        recordsER,
        recordsAZ,
        useInvoiceNum = true,
    }: IGetFilterPayments) => {
        const emptyRes = { matched: [] as GenericRecord[], filteredByInvoiceNum: false };
        if (!paymentRepresentationRecord) {
            return emptyRes;
        }

        const credDebSet = this.getRepresentationRecordCredDebSet(paymentRepresentationRecord);
        if (credDebSet.size === 0) {
            return emptyRes;
        }

        const belegfeld1Set = this.getRepresentationRecordBelegfeld1Set(paymentRepresentationRecord);

        const filterFunction = (mv: GenericRecord, itemInvoiceNumbers: Set<string>) => {
            return this.matchingPaymentSourceFilter(mv, itemInvoiceNumbers, credDebSet, payments, useInvoiceNum);
        };

        const getFiltered = (itemInvoiceNumbers: Set<string>) => {
            const matchedER = recordsER.list.filter(v => filterFunction(v, itemInvoiceNumbers));
            const matchedDeb = recordsDeb.list.filter(v => filterFunction(v, itemInvoiceNumbers));
            const matchedAZ = recordsAZ.list.filter(v => filterFunction(v, itemInvoiceNumbers));
            return [...matchedDeb, ...matchedER, ...matchedAZ];
        };

        let matched = getFiltered(belegfeld1Set);
        let filteredByInvoiceNum = belegfeld1Set.size > 0 && useInvoiceNum;

        if (!matched.length && belegfeld1Set.size) {
            matched = getFiltered(new Set());
            filteredByInvoiceNum = false;
        }

        matched.sort((a, b) => b.date.getTime() - a.date.getTime());

        return { matched, filteredByInvoiceNum };
    };

    static getMatchingPaymentSourceRecordsForReconciliation = ({
        paymentRepresentationRecord,
        payments,
        displayRecords,
    }: IFilterPayments & {
        displayRecords: GenericRecord[];
    }): GenericRecord[] => {
        const credDebSet = this.getRepresentationRecordCredDebSet(paymentRepresentationRecord);
        if (credDebSet.size === 0) {
            return [];
        }

        const belegfeld1Set = this.getRepresentationRecordBelegfeld1Set(paymentRepresentationRecord);
        if (belegfeld1Set.size === 0) {
            return [];
        }

        const filterFunction = (v: GenericRecord, itemInvoiceNumbers: Set<string>) => {
            if (Utils.PaymentUtils.isRecordPaymentSource(v.getProductKey())) {
                const sourceRecord = v;
                const relatedPayments = payments.recordRelation.get(sourceRecord.key);
                if (0 === sourceRecord.getOpenBrutto(relatedPayments)) {
                    return false;
                }
                return this.matchingPaymentSourceFilter(sourceRecord, itemInvoiceNumbers, credDebSet, payments, true);
            }
            return false;
        };
        return displayRecords.filter(v => filterFunction(v, belegfeld1Set));
    };
}
