import GenericTableViewCtxWrapper from "../productSharedComponents/GenericTableViewCtxWrapper";
import React, { memo } from "react";
import { ColumnOverrides } from "../../columns/ColumnConfig";
import { Utils } from "@binale-tech/shared";
import { GenericRecord } from "scripts/models";
import { GenericRecordProperties, ProductFE, TableProperties } from "scripts/core/Product";
import { GenericRecordTableItem } from "../../components/shared/Table/Table";
import { GenericTableViewState } from "../productSharedComponents/GenericTableView";
import { GenericToolbar } from "../../components/toolbar/GenericToolbar";
import { KassenbuchViewClass } from "../productKB/KBView";
import { LAProvider } from "./LAProvider";

class LAViewClass extends KassenbuchViewClass<GenericTableViewState> {
    protected getHeader(): React.ReactNode {
        return null;
    }

    protected getToolbarBlock() {
        const selectedToJournal = this.domain.getSelectedToJournal(this.state.tableItems, this.props.records, true);
        const selectedToConfirm = this.state.tableItems.filter(v => v.selected && v.item.draft);
        return (
            <GenericToolbar
                withJournal
                toJournal={selectedToJournal.length}
                onJournalClicked={() => {
                    this.onJournal(selectedToJournal.map(v => v.item));
                    this.handleDeselect();
                }}
                withConfirm
                onConfirmClicked={() => this.onConfirm(selectedToConfirm.map(v => v.item.clone()))}
                toConfirm={selectedToConfirm.length}
                tableColumns={this.tableColumns}
                showRemoved={this.state.showCancelled}
                onToggleHidden={this.onToggleHidden}
                withKontenAnsicht
                categoryCreditorMode={this.props.product.getConfig().itemAccountMode}
            />
        );
    }

    protected getSaldoHeader(): number {
        return undefined;
    }

    protected tableConfigGetters() {
        const getters: ColumnOverrides<GenericRecord>["getters"] = {
            [GenericRecordProperties.RecordCategoryCreditorNum]: (
                tableItem: GenericRecordTableItem,
                kontoExt?: number
            ) => {
                return (
                    <>
                        {tableItem.item.getRecordCategoryCreditor().getExtNumPrint(kontoExt)}{" "}
                        {ProductFE.getSHHint(tableItem.item.getBrutto()).konto}
                    </>
                );
            },
            [GenericRecordProperties.RecordCategoryCreditorName]: (tableItem: GenericRecordTableItem) => {
                return tableItem.item.getRecordCategoryCreditor().name;
            },
            [GenericRecordProperties.ItemCategoryCreditorNum]: (
                tableItem: GenericRecordTableItem,
                kontoExt?: number
            ) => {
                return (
                    <>
                        {tableItem.item.getItemCategoryCreditor().getExtNumPrint(kontoExt)}{" "}
                        {ProductFE.getSHHint(tableItem.item.getBrutto()).gkonto}
                    </>
                );
            },
            [GenericRecordProperties.ItemCategoryCreditorName]: (tableItem: GenericRecordTableItem) => {
                return tableItem.item.getItemCategoryCreditor().name;
            },
            [GenericRecordProperties.RecordBrutto]: (tableItem: GenericRecordTableItem) => {
                return Utils.CurrencyUtils.currencyFormat(Math.abs(tableItem.item.getBrutto()));
            },
            [GenericRecordProperties.ComputedUSt]: (tableItem: GenericRecordTableItem) => {
                return Utils.CurrencyUtils.currencyFormat(
                    Math.abs(tableItem.item.getVatEuro(this.props.yearConfig.skr))
                );
            },
            [GenericRecordProperties.ComputedNetto]: (tableItem: GenericRecordTableItem) => {
                return Utils.CurrencyUtils.currencyFormat(Math.abs(tableItem.item.getNetto(this.props.yearConfig.skr)));
            },
            [TableProperties.ControlLog]: (tableItem: GenericRecordTableItem) => this.getControlLogButton(tableItem),
            [TableProperties.ControlAction]: (tableItem: GenericRecordTableItem) => this.getActionButtons(tableItem),
            [GenericRecordProperties.RecordReview]: (tableItem: GenericRecordTableItem) =>
                this.getRecordReview(tableItem, this.handleReviewItem(tableItem)),
        };
        return getters;
    }
    render() {
        return this.getTablePage({ useInlineForm: true, useLeftTable: true });
    }
}

export const LAView: React.FC = memo(function LAView() {
    return (
        <LAProvider>
            <GenericTableViewCtxWrapper Component={LAViewClass} />
        </LAProvider>
    );
});
