import React, { useContext } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";

import BinaleForm from "appearance/components/shared/form/BinaleForm";
import { AntButtonBlock } from "../../../../components/shared/form/FormBlocks";
import { GenericRecordProperties } from "scripts/core/Product";
import { IAuswertungToolbarProps } from "./CommonAuswertungToolbar";
import { Layout } from "../../../../components/shared/Layout";
import { TableExportType } from "../../../../../scripts/csv/exporters/Exporters";
import {
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext,
} from "../../../../../scripts/context/accountingTable/tableViewContext";
import { ToolbarComponents } from "appearance/components/toolbar/components";
import { defaultPredicates } from "../../../../../scripts/context/accountingTable/tableViewFilters";

const exportColumns = [
    GenericRecordProperties.RecordFalligkeit,
    GenericRecordProperties.RecordDatum,
    GenericRecordProperties.RecordCategoryCreditorNum,
    GenericRecordProperties.RecordCategoryCreditorName,
    GenericRecordProperties.RecordBelegfeld1,
    GenericRecordProperties.RecordBrutto,
    GenericRecordProperties.ComputedSkonto,
    GenericRecordProperties.ComputedOffen,
    GenericRecordProperties.ItemBuchungstext,
];
export const ToolbarAuswertungFalligkeit: React.FC<IAuswertungToolbarProps> = props => {
    const { filters } = useContext(TableFiltersContext);
    const { setFilter, resetFilters } = useContext(TableFiltersControlContext);
    const { view } = useContext(TableViewContext);

    const onDownload = (type: TableExportType, columns: string[]) => {
        if (props.onDownload) {
            return props.onDownload(type, columns, {
                from: filters.get("dateFrom")?.value,
                to: filters.get("dateTo")?.value,
            });
        }
        return Promise.resolve();
    };

    return (
        <BinaleForm className="Toolbar AuswertungToolbar" disableEnterNavigation>
            <Layout>
                <Form.Item label="Von">
                    <ToolbarComponents.DateField
                        value={filters.get("dateFrom")?.value}
                        setValue={value =>
                            setFilter("dateFrom", {
                                predicate: defaultPredicates.dateFrom(value),
                                value,
                            })
                        }
                        setStartOfDay
                    />
                </Form.Item>
                <Form.Item label="Bis">
                    <ToolbarComponents.DateField
                        value={filters.get("dateTo")?.value}
                        setValue={value =>
                            setFilter("dateTo", {
                                predicate: defaultPredicates.dateTo(value),
                                value,
                            })
                        }
                        setEndOfDay
                    />
                </Form.Item>
                <ToolbarComponents.CreditorCombobox
                    value={filters.get("creditor")?.value}
                    setValue={value =>
                        setFilter("creditor", {
                            predicate: defaultPredicates.creditor(value),
                            value,
                        })
                    }
                />
                <AntButtonBlock icon={<CloseOutlined />} onClick={resetFilters} shape={"circle"} />
                <div style={{ flexGrow: 1 }} />
                <Form.Item label={<span>&nbsp;</span>}>
                    <ToolbarComponents.ExportBtn
                        tableColumns={props.tableColumns}
                        onDownload={onDownload}
                        exportColumns={exportColumns}
                    />
                </Form.Item>
                <Form.Item label={<span>&nbsp;</span>}>
                    <ToolbarComponents.AnsichtBtn tableColumns={props.tableColumns} view={view} />
                </Form.Item>
            </Layout>
        </BinaleForm>
    );
};
