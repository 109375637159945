import React, {
    FC,
    MouseEvent,
    SyntheticEvent,
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useRef,
} from "react";
import classNames from "classnames";
import { Button, Checkbox, Flex, Spin, Tooltip } from "antd";
import { Draggable } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { Loading3QuartersOutlined, MenuOutlined, PlusOutlined, ScissorOutlined } from "@ant-design/icons";

import { ControlButtons } from "../../ControlButtons";
import { PdfToolsContext, PdfToolsControlContext } from "../../../context";
import { OcrPage } from "../../../types";
import { useImageRotation } from "../../../hooks/useImageRotation";
import { getChildWidth } from "../../../tools/helpers";

interface IProps {
    page: OcrPage;
    docNumber?: number;
    pageNumber: number;
    unifyBtn?: boolean;
    splitBtn?: boolean;
    scrollBtn?: boolean;
    active?: boolean;
    topBordered: boolean;
}

export const PageItem: FC<IProps> = ({ page, ...props }) => {
    const { pages, documents } = useContext(PdfToolsContext);
    const actions = useContext(PdfToolsControlContext);
    const { rotateImage } = useImageRotation([page]);
    const itemRef = useRef<HTMLDivElement>(null);

    const isFirstPage = useMemo(() => {
        return props.pageNumber === 1;
    }, [pages, props.pageNumber]);

    const isLastPage = useMemo(() => {
        return props.pageNumber === pages.length;
    }, [pages, props.pageNumber]);

    const togglePage = useCallback(
        (pageId: string) => {
            const checked = pages.filter(p => p.checked).map(p => p.id);
            if (checked.includes(pageId)) {
                actions.checkPages(checked.filter(ch => ch !== pageId));
            } else {
                actions.checkPages([...checked, pageId]);
            }
        },
        [pages, actions]
    );

    const toggleDeletingPage = useCallback(
        (pageToAct: OcrPage) => {
            if (pageToAct.deleted) {
                actions.restorePages([pageToAct.id]);
            } else {
                actions.deletePages([pageToAct.id]);
            }
        },
        [actions]
    );

    useLayoutEffect(() => {
        const handleResize = () => {
            if (!itemRef.current) {
                return;
            }

            const newWidth = getChildWidth("scrollBlock");
            const cardPadding = 10;
            const newHeight = (newWidth - cardPadding) * 1.41;

            itemRef.current.style.height = `${newHeight}px`;
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Draggable draggableId={page.id} index={props.pageNumber}>
            {(provided, snapshot) => {
                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classNames("pdf-tools-Page_container", {
                            "pdf-tools-Page_container--top-bordered": props.topBordered,
                        })}
                        id={page.id}
                    >
                        <Flex ref={itemRef} justify="center" align="center" className="pdf-tools-Image_container">
                            {page.isCalculated ? (
                                <Spin
                                    indicator={
                                        <Loading3QuartersOutlined style={{ fontSize: 36 }} color="primary" spin />
                                    }
                                />
                            ) : (
                                <img alt="preview image" src={page.imageUrl} className="pdf-tools-Image" />
                            )}
                        </Flex>

                        <div
                            className={classNames("pdf-tools-Image_overlay", {
                                "pdf-tools-Image_overlay--active": props.active,
                                "pdf-tools-Image_overlay--disabled": page.deleted,
                            })}
                            onClick={() => actions.setActivePageId(page.id)}
                        />
                        {props.splitBtn && !snapshot.isDragging && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: -16,
                                    right: 0,
                                    left: 0,
                                    height: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    icon={<ScissorOutlined />}
                                    shape="circle"
                                    danger
                                    className="pdf-tools-image__cut"
                                    onClick={(e: SyntheticEvent) => {
                                        e.stopPropagation();
                                        actions.splitDocuments([page.id]);
                                    }}
                                />
                            </div>
                        )}
                        <ControlButtons
                            className="pdf-tools-Image__Controls"
                            isHidden={page.deleted}
                            onHide={() => toggleDeletingPage(page)}
                            onRotate={rotateImage}
                            isDisabled={false}
                        />
                        {props.scrollBtn && <MenuOutlined className="pdf-tools-Block__drag" />}
                        <Tooltip
                            destroyTooltipOnHide
                            placement="top"
                            title={
                                <>
                                    <FormattedMessage id="app.ocr.page" /> {props.pageNumber} / {pages.length}
                                </>
                            }
                            className="pdf-tools-Page_page_number"
                        >
                            {props.pageNumber}
                        </Tooltip>
                        {props.docNumber && (
                            <Tooltip
                                placement="top"
                                title={
                                    <>
                                        <FormattedMessage id="app.ocr.document" /> {props.docNumber} /{" "}
                                        {documents.length}
                                    </>
                                }
                                destroyTooltipOnHide
                                className="pdf-tools-Page_document_number"
                            >
                                {props.docNumber}
                            </Tooltip>
                        )}
                        {props.unifyBtn && !snapshot.isDragging && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: -21,
                                    right: 0,
                                    left: 0,
                                    height: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    icon={<PlusOutlined />}
                                    shape="circle"
                                    type="primary"
                                    className="pdf-tools-image__unify"
                                    onClick={(e: MouseEvent) => {
                                        e.stopPropagation();
                                        actions.unifyDocuments([page.id]);
                                    }}
                                />
                            </div>
                        )}

                        {(isLastPage || isFirstPage) && !snapshot.isDragging && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: isFirstPage ? -21 : "unset",
                                    bottom: isLastPage ? 10 : "unset",
                                    right: 0,
                                    left: 0,
                                    height: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    icon={<PlusOutlined />}
                                    shape="circle"
                                    type="primary"
                                    className="pdf-tools-image__unify"
                                    onClick={(e: MouseEvent) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </div>
                        )}

                        {(isLastPage || isFirstPage) && !snapshot.isDragging && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: isFirstPage ? -21 : "unset",
                                    bottom: isLastPage ? 10 : "unset",
                                    right: 0,
                                    left: 0,
                                    height: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    icon={<PlusOutlined />}
                                    shape="circle"
                                    type="primary"
                                    className="pdf-tools-image__unify"
                                    onClick={(e: MouseEvent) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </div>
                        )}
                        <Checkbox
                            className="pdf-tools-Image__Checkbox"
                            checked={page.checked}
                            onChange={() => togglePage(page.id)}
                        />
                    </div>
                );
            }}
        </Draggable>
    );
};
