import React, { FC, useMemo } from "react";
import { themeConfig } from "@inv/theme";
import { FormattedMessage } from "react-intl";
import { getLineItemAmounts, InvoiceFormInputTranslate, TInvoicesValues } from "@inv/types";
import { Flex, Form } from "antd";
import { TotalItem } from "@inv/components/InvoiceFormLineItemsTable/components/InvoiceTotal/TotalItem";
import { decimalFormatter } from "@dms/scripts/helpers";

export const InvoiceTotal: FC = () => {
    const form = Form.useFormInstance<TInvoicesValues>();
    const values = form.getFieldsValue();
    const { currencyCode, lineItems, isTaxIncluded: isUStMode } = values;
    const totalState = useMemo(() => {
        const { netto, tax7, tax19, amount7, amount19 } = lineItems.reduce(
            (acc, row) => {
                const { netto, tax } = getLineItemAmounts(row);
                acc.netto += netto;

                if (row.tax === 7) {
                    acc.tax7 += tax;
                    acc.amount7 += netto;
                } else if (row.tax === 19) {
                    acc.tax19 += tax;
                    acc.amount19 += netto;
                }

                return acc;
            },
            {
                netto: 0,
                tax7: 0,
                tax19: 0,
                amount7: 0,
                amount19: 0,
            }
        );

        const total = netto + tax7 + tax19;

        return {
            subtotal: decimalFormatter(netto / 100),
            tax7: tax7 ? decimalFormatter(tax7 / 100) : null,
            tax19: tax19 ? decimalFormatter(tax19 / 100) : null,
            amount7: tax7 ? decimalFormatter(amount7 / 100) : null,
            amount19: tax19 ? decimalFormatter(amount19 / 100) : null,
            total: decimalFormatter((isUStMode ? total : netto) / 100),
        };
    }, [isUStMode, lineItems]);

    return (
        <Flex
            vertical
            align="start"
            style={{
                height: "100%",
                maxWidth: 420,
                padding: 2,
                width: "100%",
                border: themeConfig.border,
                borderRadius: 8,
            }}
        >
            {isUStMode && (
                <>
                    <TotalItem
                        label={<FormattedMessage id={InvoiceFormInputTranslate.SUB_TOTAL} />}
                        value={totalState.subtotal}
                        currencyCode={currencyCode}
                        isBold={false}
                    />
                    {totalState.tax7 && (
                        <TotalItem
                            label={`zzgl. Umsatzsteuer 7%`}
                            value={totalState.tax7}
                            amount={totalState.amount7}
                            currencyCode={currencyCode}
                            isBold={false}
                        />
                    )}
                    {totalState.tax19 && (
                        <TotalItem
                            label={`zzgl. Umsatzsteuer 19%`}
                            value={totalState.tax19}
                            amount={totalState.amount19}
                            currencyCode={currencyCode}
                            isBold={false}
                        />
                    )}
                </>
            )}

            <TotalItem
                label={<FormattedMessage id={InvoiceFormInputTranslate.TOTAL_AMOUNT} />}
                value={totalState.total}
                currencyCode={currencyCode}
                isBold={true}
            />
        </Flex>
    );
};
