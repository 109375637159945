import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { Creditor } from "scripts/models";
import { ExtNumCombobox, ExtNumComboboxProps } from "./ExtNumCombobox";
import { KontoContext } from "scripts/context/accountingData/KontoEntitiesProvider";

export const CreditorCombobox: React.FC<Omit<ExtNumComboboxProps<Creditor>, "items">> = props => {
    const { creditors } = React.useContext(KontoContext);

    return (
        <ExtNumCombobox<Creditor>
            items={creditors}
            notFoundContent={
                <span>
                    <p>
                        <FormattedMessage id="app.messages.no_elements_found" />
                    </p>
                    <Link to={AppRoutes.manageAccountCreditors} target="_blank">
                        <Button type="primary" tabIndex={-1}>
                            <FormattedMessage id="app.items.create_creditor" />
                        </Button>
                    </Link>
                </span>
            }
            {...props}
        />
    );
};
