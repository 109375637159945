import React, { type FC, memo, useState } from "react";
import { Button, Radio, Space, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { TUploadFile } from "@dms/types";

interface IProps {
    hash: string;
    files: TUploadFile[];
    handleSelectNotUniqFiles: (key: string, idx: number) => void;
}

export const FilesSameHashItem: FC<IProps> = memo(({ hash, files, handleSelectNotUniqFiles }) => {
    const [value, setValue] = useState<number>(0);

    const handleSelect = (index: number) => {
        setValue(index);
        handleSelectNotUniqFiles(hash, index);
    };

    const handleRemove = () => {
        handleSelectNotUniqFiles(hash, -1);
    };

    return (
        <div
            style={{
                position: "relative",
                marginTop: "5px",
                padding: "8px",
                border: "1px solid #1677ff",
                borderRadius: "5px",
            }}
        >
            <Button
                icon={<DeleteOutlined />}
                style={{ position: "absolute", top: "7px", right: "7px" }}
                danger
                type={"text"}
                shape={"circle"}
                onClick={handleRemove}
            />
            <div style={{ padding: "5px 8px 0" }}>
                <Radio.Group
                    onChange={e => {
                        handleSelect(e.target.value);
                    }}
                    value={value}
                >
                    <Space direction="vertical">
                        {files.map((el, i) => {
                            const { file } = el;
                            return (
                                <Radio value={i} key={el.hash + i}>
                                    <div
                                        style={{
                                            marginLeft: "5px",
                                        }}
                                    >
                                        {file.name}
                                    </div>
                                </Radio>
                            );
                        })}
                    </Space>
                </Radio.Group>
            </div>
        </div>
    );
});
