import { getDocument, PDFDocumentProxy } from "pdfjs-dist";

export class PdfUtils {
    static getPdfDocument = async (file: File) => {
        try {
            const pdfArrayBuffer = await file.arrayBuffer();
            return getDocument({ data: pdfArrayBuffer, isEvalSupported: false }).promise;
        } catch (e) {
            console.error(e.message);
        }
    };

    private static generatePreviewCanvas = async (pdf: PDFDocumentProxy, numPage = 1): Promise<HTMLCanvasElement> => {
        const page = await pdf.getPage(numPage);

        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement("canvas");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const res = canvas.getContext("2d");
        if (!res || !(res instanceof CanvasRenderingContext2D)) {
            throw new Error("Failed to get 2D context");
        }
        await page.render({
            canvasContext: res,
            viewport,
        }).promise;

        return canvas;
    };
    static generatePreview = async (pdf: PDFDocumentProxy): Promise<Blob | null> => {
        const canvas = await this.generatePreviewCanvas(pdf);

        return new Promise(resolve => canvas.toBlob(resolve));
    };
    static generatePreviewDataUrl = async (pdf: PDFDocumentProxy, numPage = 1): Promise<string> => {
        const canvas = await this.generatePreviewCanvas(pdf, numPage);

        return canvas.toDataURL("image/jpeg", 0.75);
    };

    static previewUrlGenerate = async (fileUrl: string) => {
        const arrayBuffer = await fetch(fileUrl).then(r => r.arrayBuffer());
        const pdf = await getDocument({ data: arrayBuffer, isEvalSupported: false }).promise;
        return this.generatePreview(pdf).then(blob => {
            return URL.createObjectURL(blob);
        });
    };
    static getPreviewDataUrl = async (fileUrl: string) => {
        const arrayBuffer = await fetch(fileUrl).then(r => r.arrayBuffer());
        const pdf = await getDocument({ data: arrayBuffer, isEvalSupported: false }).promise;
        return this.generatePreviewDataUrl(pdf);
    };
    static getPdfNumPages = async (fileUrl: string): Promise<number> => {
        const arrayBuffer = await fetch(fileUrl).then(r => r.arrayBuffer());
        const pdf = await getDocument({ data: arrayBuffer, isEvalSupported: false }).promise;
        return pdf.numPages;
    };
}
