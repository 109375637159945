import React from "react";
import { Form, Select, SelectProps, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { StarFilled } from "@ant-design/icons";
import { AppRoutes } from "scripts/routing/routeConstants";
import { CompanyContext } from "scripts/context/CompanyContext";
import { GQL } from "@binale-tech/shared";
import { IPaymentType } from "scripts/models/Payment";
import { KontoContext } from "scripts/context/accountingData/KontoEntitiesProvider";
import { UserContext } from "scripts/context/UserProvider";
import { PaymentUtils } from "scripts/models/utils/PaymentUtils";
import { ProductAccessUtils } from "../../../../../scripts/models/utils/ProductAccessUtils";

import "./style.css";

interface IProps extends Omit<SelectProps<string>, "onChange" | "value"> {
    year: number;
    value: IPaymentType | null;
    onChange: (value: IPaymentType | null) => void;
}

export const PaymentTypeSelector: React.FC<IProps> = ({ value, onChange, year, ...props }) => {
    const { companyGQL } = React.useContext(CompanyContext);
    const { categoryOverrides } = React.useContext(KontoContext);
    const user = React.useContext(UserContext);
    const hasAccessFe = ProductAccessUtils.canRead(GQL.IProductKey.Fe, companyGQL, user);

    const selectOptionsStandard = React.useMemo(
        () => PaymentUtils.getCompanyPaymentMethods(companyGQL, year),
        [year, companyGQL]
    );
    // const selectOptionsFe = React.useMemo(
    //   () => hasAccessFe ? categoryOverrides.map(v => PaymentUtils.getPaymentType(v as Category, GQL.IPaymentType.Fe)) : [],
    //   [year, companyGQL, categoryOverrides, hasAccessFe]
    // );

    const handleChange = (paymentTypeForm: string) => {
        // const paymentType = [...selectOptionsStandard, ...selectOptionsFe].find(
        const paymentType = [...selectOptionsStandard].find(
            type => type.konto === paymentTypeForm || type.value === paymentTypeForm
        );
        const { isFavourite, ...data } = paymentType;
        onChange(data);
    };

    return (
        <Form.Item
            label={<FormattedMessage id="app.fields.paymentMethod" />}
            className="ZahlungsForm__Zahlungsart SelectBlockAnt"
        >
            <Select
                {...props}
                onChange={handleChange}
                value={value?.konto || value?.value || ""}
                notFoundContent={<FormattedMessage id="app.components.table.no_items" />}
            >
                <Select.OptGroup label={"Standard"} key={"Standard"}>
                    {selectOptionsStandard.map((option, index) => {
                        return (
                            <Select.Option key={index} value={option.konto || option.value}>
                                <div className="PaymentTypeSelector--option">
                                    {PaymentUtils.getPaymentTypeStringValue(option, companyGQL, year)}
                                    {option.isFavourite && (
                                        <Tooltip
                                            title={<FormattedMessage id="app.tooltip.manageFavouritePaymentType" />}
                                        >
                                            <Link to={AppRoutes.manageCompanies} target="__blank">
                                                <StarFilled />
                                            </Link>
                                        </Tooltip>
                                    )}
                                </div>
                            </Select.Option>
                        );
                    })}
                </Select.OptGroup>
                {/* {selectOptionsFe.length && (<Select.OptGroup label={"Erweitert"} key={"Erweitert"}> */}
                {/*     {selectOptionsFe.map((option, index) => { */}
                {/*         return ( */}
                {/*           <Select.Option key={index} value={option.konto || option.value} disabled> */}
                {/*               <div className="PaymentTypeSelector--option"> */}
                {/*                   {PaymentUtils.getPaymentTypeStringValue(option, companyGQL, year)} */}
                {/*                   /!* {option.isFavourite && ( *!/ */}
                {/*                   /!*   <Tooltip title={<FormattedMessage id="app.tooltip.manageFavouritePaymentType" />}> *!/ */}
                {/*                   /!*       <Link to={Routes.manageCompanies} target="__blank"> *!/ */}
                {/*                   /!*           <StarFilled /> *!/ */}
                {/*                   /!*       </Link> *!/ */}
                {/*                   /!*   </Tooltip> *!/ */}
                {/*                   /!* )} *!/ */}
                {/*               </div> */}
                {/*           </Select.Option> */}
                {/*         ); */}
                {/*     })} */}
                {/* </Select.OptGroup>)} */}
            </Select>
        </Form.Item>
    );
};
