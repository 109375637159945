import React, {
    createContext,
    type Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { DmsAppContext, DmsDataContext } from "@dms/types/ContextTypes";

interface TValue {
    isGroupEditor: boolean;
    checkboxCarousel: string[];
    setCheckboxCarousel: Dispatch<SetStateAction<string[]>>;
    carouselKeys: string[];
}

/**
 Context for Document Form
 */
export const FormWrapperContext = createContext({} as TValue);

type TProps = {
    isGroup: boolean;
};

/**
 Context Provider for Document Form
 */

export const FormWrapperProvider: FC<PropsWithChildren & TProps> = ({ children, isGroup }) => {
    const { selectedRowKeys } = useContext(DmsAppContext);

    const [isGroupEditor, setIsGroupEditor] = useState(false);
    const [checkboxCarousel, setCheckboxCarousel] = useState<string[]>(isGroup ? (selectedRowKeys as string[]) : []);
    const [carouselKeys, setCarouselKeys] = useState<string[]>(isGroup ? (selectedRowKeys as string[]) : []);

    const { documentsKV } = useContext(DmsDataContext);

    useEffect(() => {
        setCarouselKeys(prev => {
            const newKeys = prev.filter(el => Boolean(documentsKV[el]));
            return newKeys.length === prev.length ? prev : newKeys;
        });
    }, [documentsKV]);

    useEffect(() => {
        setIsGroupEditor(checkboxCarousel.length > 1);
    }, [checkboxCarousel]);

    const value = useMemo(() => {
        return {
            isGroupEditor: isGroupEditor && isGroup,
            checkboxCarousel,
            carouselKeys,
            setCheckboxCarousel,
        };
    }, [carouselKeys, checkboxCarousel, isGroup, isGroupEditor]);

    return <FormWrapperContext.Provider value={value}>{children}</FormWrapperContext.Provider>;
};
