import { Col, Row } from "antd";
import React, { type FC, useContext, useEffect, useRef, useState } from "react";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormModule } from "@dms/modules/GroupFormModules/GroupFormModule";
import { SingleFormModule } from "@dms/modules/SingleFormModule/SingleFormModule";
import { DocumentData } from "../DocumentData/DocumentData";
import { PdfMultiDocumentViewer } from "@ui-components/PdfViewer";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { GQL } from "@binale-tech/shared";
import { CarouselDms } from "@ui-components/PdfViewer/DocumentCarousel/Carousel";

export const GroupDocumentLayout: FC = () => {
    const [carouselDocuments, setCarouselDocuments] = useState<GQL.IDocument[]>([]);
    const [documentData, setDocumentData] = useState<GQL.IDocument>();
    const [activeDocumentId, setActiveDocumentId] = useState<string>();
    const { documentsKV } = useContext(DmsDataContext);
    const { isGroupEditor, carouselKeys } = useContext(FormWrapperContext);

    const initialActiveDocumentId = useRef<string>(null);

    useEffect(() => {
        const carouselDocumentsArr = carouselKeys.map(key => documentsKV[key]);

        setCarouselDocuments(carouselDocumentsArr);

        if (!initialActiveDocumentId.current && carouselDocumentsArr.length) {
            initialActiveDocumentId.current = carouselDocumentsArr[0].key;
        }
    }, [documentsKV, carouselKeys]);

    useEffect(() => {
        setActiveDocumentId(initialActiveDocumentId.current);
    }, []);

    useEffect(() => {
        const foundDocument = carouselDocuments.find(el => el.key === activeDocumentId);
        if (!foundDocument) {
            return;
        }
        setDocumentData({ ...foundDocument });
    }, [carouselDocuments, activeDocumentId]);

    return carouselDocuments.length ? (
        <Row>
            <PdfMultiDocumentViewer
                documentData={documentData}
                colSpans={[5, 13]}
                carousel={
                    <CarouselDms
                        documents={carouselDocuments}
                        activeDocumentId={activeDocumentId}
                        setDocumentId={setActiveDocumentId}
                    />
                }
            />
            <Col span={6} style={{ paddingRight: 5 }}>
                <DocumentData>
                    {isGroupEditor ? (
                        <GroupFormModule documentsData={carouselDocuments} />
                    ) : (
                        <SingleFormModule key={documentData?.key} documentInitialValue={documentData} />
                    )}
                </DocumentData>
            </Col>
        </Row>
    ) : null;
};
