import React, { FC, useState } from "react";
import { Button, Form, Progress, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { DatevButton } from "../../../../components/oauth/DatevButton";
import { CompanyContext } from "../../../../../scripts/context/CompanyContext";
import { Datev } from "@binale-tech/shared";
import { Checkbox } from "@heroui/checkbox";

type Props = {
    disabled: boolean;
    onExportRecords: (useDatevSeparation: boolean, exportBelegfeld2?: boolean) => void;
    onDatevExport: (exportBelegfeld2?: boolean) => void;
    isLoadingAgenda: boolean;
    isLoadingDatev: boolean;
    datevExportProgress?: number;
};
export const ExportEXTF: FC<Props> = ({
    disabled,
    onExportRecords,
    isLoadingAgenda,
    isLoadingDatev,
    datevExportProgress,
    onDatevExport,
}) => {
    const [exportBelegfeld2, setExportBelegfeld2] = useState(true);
    const { companyGQL } = React.useContext(CompanyContext);
    const isDatevConnected = companyGQL?.datevOAuth && Datev.getRefreshTokenValidity(companyGQL.datevOAuth) > 0;
    return (
        <div>
            <Form layout="vertical">
                <Space>
                    <Form.Item
                        label={
                            <Checkbox isSelected={exportBelegfeld2} onValueChange={() => setExportBelegfeld2(v => !v)}>
                                Belegfeld2 exportieren
                            </Checkbox>
                        }
                    >
                        <Button
                            disabled={disabled}
                            onClick={() => onExportRecords(true, exportBelegfeld2)}
                            loading={isLoadingDatev}
                        >
                            <FormattedMessage id="app.button.download" />
                            Datev .CSV
                        </Button>
                    </Form.Item>
                    <Form.Item label={<span>&nbsp;</span>}>
                        <DatevButton
                            disabled={!isDatevConnected}
                            onClick={() => onDatevExport(exportBelegfeld2)}
                            loading={Number.isFinite(datevExportProgress)}
                        >
                            Exportiren nach DATEV-Schnittstelle
                        </DatevButton>
                    </Form.Item>

                    {Number.isFinite(datevExportProgress) && (
                        <div style={{ width: 200 }}>
                            <Progress
                                percent={datevExportProgress}
                                status={datevExportProgress === 100 ? "success" : "active"}
                            />
                        </div>
                    )}
                </Space>
            </Form>

            <Form layout="vertical">
                <Space>
                    <Form.Item label={<span>&nbsp;</span>}>
                        <Button
                            disabled={disabled}
                            onClick={() => onExportRecords(false, true)}
                            loading={isLoadingAgenda}
                        >
                            <FormattedMessage id="app.button.download" />
                            Agenda
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </div>
    );
};
