import { RecordsContext } from "scripts/context/accountingData/RecordsCtx";
import { urlFormatter } from "@dms/utils/urlFormatter";
import { useContext, useMemo, useState } from "react";
import { GQL } from "@binale-tech/shared";
import { deepEqual } from "fast-equals";

export interface RecordSnapshot {
    date: Date;
    productKey: GQL.IProductKey;
    recordAccountNum: string;
    recordAccountName: string;
    itemAccountNum: string;
    itemAccountName: string;
}
export type DmsRecordsData = {
    recordsAssetsSet: Set<string>;
    recordSnapshots: Record<string, RecordSnapshot[]>;
};
export const useDmsRecordsData = (): DmsRecordsData => {
    const { allRecords } = useContext(RecordsContext);
    const [recordsAssetsSet, setRecordsAssetsSet] = useState<Set<string>>(new Set());
    const [recordSnapshots, setRecordSnapshots] = useState<Record<string, RecordSnapshot[]>>({});

    useMemo(() => {
        const assetsSet = new Set<string>();
        const snapshots: DmsRecordsData["recordSnapshots"] = {};

        allRecords.list.forEach(el => {
            const recordDataItem: RecordSnapshot = {
                date: el.date,
                productKey: el.getProductKey(),
                recordAccountName: el.getRecordCategoryCreditor().name,
                recordAccountNum: el.getRecordCategoryCreditor().num,
                itemAccountName: el.getItemCategoryCreditor().name,
                itemAccountNum: el.getItemCategoryCreditor().num,
            };

            if (el.documents) {
                el.documents.forEach(({ url }) => {
                    const formattedUrl = urlFormatter(url);

                    assetsSet.add(formattedUrl);

                    if (!snapshots[formattedUrl]) {
                        snapshots[formattedUrl] = [];
                    }

                    snapshots[formattedUrl].push(recordDataItem);
                });
            }
        });
        setRecordsAssetsSet(prevState => {
            if (deepEqual(prevState, assetsSet)) {
                return prevState;
            }
            return assetsSet;
        });
        setRecordSnapshots(prevState => {
            if (deepEqual(prevState, snapshots)) {
                return prevState;
            }
            return snapshots;
        });
    }, [allRecords]);

    return useMemo(
        () => ({
            recordsAssetsSet,
            recordSnapshots,
        }),
        [recordSnapshots, recordsAssetsSet]
    );
};
